import styled from 'styled-components'

import { customBackgroundStyles } from 'common-styles'
import { Color } from 'root-constants'

export const StyledAllRoundApproach = {
  Root: styled.div`
    padding-top: 25px;
    position: relative;

    ${customBackgroundStyles}
  `,
  Title: styled.header`
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    margin-bottom: 24px;
  `,
  List: styled.ul`
    margin-bottom: 32px;
  `,
  ListItem: styled.li`
    display: flex;
    align-items: center;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.8);
    margin-bottom: 12px;
    padding: 12px;
    min-height: 106px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Image: styled.img`
    max-width: 62px;
    margin-right: 20px;
    border-radius: 20px;
    height: auto;
  `,
}
