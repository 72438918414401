import styled from 'styled-components'

import chevronRight from 'assets/images/chevron-right.svg'

import { Color } from 'root-constants'

export const StyledGender = {
  TitleContainer: styled.header`
    margin-bottom: 16px;
  `,
  OptionsContainer: styled.div``,

  Root: styled.div`
    padding: 82px 0 18px;
  `,
  Title: styled.h1`
    color: ${Color.MIDNIGHT_BLUE};
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
    text-align: center;
    text-transform: uppercase;
  `,
  Subtitle: styled.h2`
    color: ${Color.MIDNIGHT_BLUE};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;
    text-transform: uppercase;
  `,
  CallToAction: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${Color.MIDNIGHT_BLUE};
    text-align: center;
    margin-bottom: 72px;
  `,
  Actions: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin: 0 -4px;

    & input:checked + div > div {
      border: 2px solid ${Color.PRIMARY};
      background: #ffe0de;
    }

    & label {
      margin-bottom: 0;
    }
  `,
  Action: styled.div`
    display: flex;
    min-height: 232px;
    position: relative;
    padding-top: 11px;
  `,
  Image: styled.img`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 196px;
    z-index: 1;
  `,
  Answer: styled.div`
    flex-basis: 100%;
    background-color: ${Color.WHITE};
    border: 2px solid transparent;
    border-radius: 12px;
    transition: background-color 0.1s ease-out;
    box-shadow: 0px 3px 13px 0px #d6dcf4;
  `,
  SkinType: styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    border-radius: 0 0 12px 12px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: ${Color.WHITE};
    position: absolute;
    bottom: 0;
    left: 0;
    height: 36px;
    width: 100%;
    padding: 8px 12px;
    z-index: 2;
    background: ${Color.PRIMARY};
    margin-right: 6px;
  `,
  SkinTypeValue: styled.span`
    margin-right: 6px;
  `,
  ChevronRight: styled.span`
    width: 6px;
    height: 12px;
    background: url(${chevronRight}) no-repeat center;
    background-size: contain;
  `,
  Terms: styled.div`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: ${Color.LIGHT};
    margin: 98px auto 0;

    a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.LIGHT};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
