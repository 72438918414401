import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Chat, DelayedBottomContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { Cohort, I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import {
  QUESTION,
  SKIN_TYPE_ANSWERS_MAP,
  SKIN_TYPE_DEFAULT_OPTIONS,
} from './constants'

export const LikeChatSkinType: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: PageId.SKIN_TYPE,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue(answer)
  }, [answer, handleContinue])

  const handleSetAnswer = useCallback((value) => {
    setAnswer(value)
  }, [])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      onChange: (value: string) => {
        setIsAnswersDisabled(true)
        handleChange(value, handleSetAnswer)
      },
    }),
    [
      handleChange,
      handleSetAnswer,
      isAnswersDisabled,
      pageId,
      setIsAnswersDisabled,
    ],
  )

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.USER_ANSWER)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_QUESTION ||
            (chatStep === ChatStep.USER_ANSWER && !answer)
          }
        >
          {t('onboarding.likeChatSkinType.title')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.USER}
          {...(!!answer && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.EXPERT_EXPRESSION)}
        >
          {answer && (
            <Trans
              i18nKey={SKIN_TYPE_ANSWERS_MAP[answer]}
              components={[<strong />]}
              context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
            />
          )}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          {...(chatStep === ChatStep.EXPERT_EXPRESSION && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_EXPRESSION ||
            chatStep === ChatStep.FINISH
          }
        >
          {t('onboarding.likeChatSkinType.expression')}
        </ChatMessage>
      </Chat>
      <DelayedBottomContainer isShown={chatStep === ChatStep.FINISH}>
        <Button onClick={handleNextClick}>{t('actions.great')}</Button>
      </DelayedBottomContainer>
      <DelayedBottomContainer
        isShown={!answer && chatStep === ChatStep.USER_ANSWER}
      >
        {SKIN_TYPE_DEFAULT_OPTIONS.map(({ value, text }) => (
          <Option {...optionProps} value={value} key={value}>
            <ChatAnswerButton>
              <Trans
                i18nKey={text}
                context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
                components={[<strong />]}
              />
            </ChatAnswerButton>
          </Option>
        ))}
      </DelayedBottomContainer>
    </Container>
  )
}
