import { ProgressHTMLAttributes } from 'react'

import styled, { css } from 'styled-components'

import progressCheckImg from 'assets/images/progress-check.png'

import { Color } from 'root-constants'

interface IProps extends ProgressHTMLAttributes<HTMLProgressElement> {
  color?: string
  background?: string
}

const progressValueStyles = css`
  background-color: currentColor;
  transition: all 0.2s ease-out;
`

export const StyledOnboardingProgressBar = {
  Wrapper: styled.div`
    margin: 20px 20px 15px;
    display: flex;
    justify-content: center;
  `,
  StepBlock: styled.div`
    position: relative;
    display: flex;
    align-items: center;
  `,
  StepBlockProgress: styled.progress<IProps>`
    overflow: hidden;
    max-width: 56px;
    height: 4px;
    appearance: none;
    border: none;
    display: block;
    background: ${({ background }) => background || Color.WHITE};
    color: ${({ color }) => color || Color.PRIMARY};

    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }

    &::-webkit-progress-bar {
      background: #ecf0f7;
    }

    &::-webkit-progress-value {
      background: linear-gradient(
        270deg,
        rgba(255, 130, 118, 0) 0%,
        #ff8276 99.99%,
        rgba(255, 130, 118, 0) 100%
      );
    }

    &[data-active='true'] {
      &::-webkit-progress-value {
        background: linear-gradient(
          270deg,
          rgba(255, 130, 118, 0.3) 0%,
          #ff8276 99.99%,
          rgba(255, 130, 118, 0) 100%
        );
      }
    }

    &[data-first-progress='true'] {
      max-width: 30px;

      &::-webkit-progress-value {
        background: linear-gradient(
          90deg,
          rgba(255, 130, 118, 0) 0%,
          #ff8276 99.99%,
          rgba(255, 130, 118, 0) 100%
        );
      }
    }

    &[data-last-progress='true'] {
      max-width: 30px;
    }
  `,
  StepNumber: styled.div`
    height: 32px;
    width: 32px;
    margin: 0;
    padding-left: 1px;
    border-radius: 50%;
    background: #eceef4;
    font-size: 15px;
    line-height: 34px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    color: #95979f;
    transition: background 0.2s linear;

    &[data-active='true'] {
      color: transparent;
      background: url(${progressCheckImg}) center center no-repeat;
      background-size: contain;
    }
  `,
}
