import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TPageTitleDescriptionProps = {
  isSmallSize?: boolean
}

export const StyledPageTitleDescription = {
  PageTitleDescription: styled.p<TPageTitleDescriptionProps>`
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT};
    ${({ isSmallSize }) =>
      isSmallSize
        ? css`
            font-size: 16px;
            line-height: 24px;
          `
        : css`
            font-size: 17px;
            line-height: 20px;
          `}
  `,
}
