import { useMemo } from 'react'

import { GET_SUBSCRIPTION_LIST } from 'root-redux/actions/common'

import { usePurchaseStore } from './usePurchaseStore'

export const usePricesStatus = () => {
  const { fetchingActionsList } = usePurchaseStore()
  const arePricesReady = useMemo(
    () => !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST),
    [fetchingActionsList],
  )

  return { arePricesReady }
}
