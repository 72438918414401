import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ConnectToDBProgressBar } from 'components/ConnectToDBProgressBar'

import { selectAnswers, selectLanguage } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  Cohort,
  I18N_CONTEXT_COHORT_MAP,
  USER_GOALS_TITLE,
  UserGoal,
} from 'root-constants'

import { StyledPreliminaryProgress as S } from './PreliminaryProgress.styles'
import { IMAGE_PATH, QUESTION } from './constants'

export const PreliminaryProgress: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const language = useSelector(selectLanguage)
  const { isLuvlyIntro2TCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const userGoal = useMemo(() => {
    const goal = userAnswers?.[PageId.INTRO]
    return t(
      USER_GOALS_TITLE[goal] || USER_GOALS_TITLE[UserGoal.WRINKLE_FREE_SKIN],
      {
        context: isLuvlyIntro2TCohort
          ? I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_INTRO2T]
          : '',
      },
    )
  }, [isLuvlyIntro2TCohort, t, userAnswers])

  const isWrinkleFreeSkinSelected = useMemo(() => {
    const goal = userAnswers?.[PageId.INTRO]
    return !goal || goal === UserGoal.WRINKLE_FREE_SKIN
  }, [userAnswers])

  const imageLink = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH}_${language}.png`,
    [language],
  )

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  const { counter } = useCounter(true, {
    duration: 5000,
    callback: handleNextClick,
  })

  return (
    <S.Wrapper>
      <S.ProgressbarContainer>
        <ConnectToDBProgressBar value={counter}>
          <Trans i18nKey="onboarding.preliminaryProgress.progressTitle" />
        </ConnectToDBProgressBar>
      </S.ProgressbarContainer>
      <S.GraphContainer
        userGoal={userGoal}
        isWrinkleFreeSkinSelected={isWrinkleFreeSkinSelected}
      >
        <img src={imageLink} alt="graph" />
      </S.GraphContainer>
    </S.Wrapper>
  )
}
