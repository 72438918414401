import React from 'react'

import { StyledSwitch as S } from './Switch.styles'

type TSwitchTypes = {
  isChecked: boolean
  onChange: (event) => void
  value: string
}

export type TInputTheme = {
  themeColor?: string
  small?: boolean
}

export const Switch: React.FC<TSwitchTypes & TInputTheme> = ({
  isChecked = false,
  onChange,
  value,
  themeColor,
  small = false,
}) => (
  <S.Root small={small}>
    <S.Input
      themeColor={themeColor}
      value={value}
      checked={isChecked}
      onChange={onChange}
      type="checkbox"
    />
    <S.Button small={small} />
    <S.Track />
  </S.Root>
)
