import React, { ButtonHTMLAttributes } from 'react'

import { StyledLoadingButton as S } from './LoadingButton.styles'

export const enum LoadingButtonTheme {
  DARK = 'dark',
  LIGHT = 'light',
}

export interface ILoadingButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void
  theme?: LoadingButtonTheme
}

export const LoadingButton: React.FC<ILoadingButtonProps> = ({
  onClick,
  theme = LoadingButtonTheme.LIGHT,
  ...props
}) => (
  <S.LoadingButton {...props} onClick={onClick} theme={theme}>
    <div />
    <div />
    <div />
  </S.LoadingButton>
)
