import styled from 'styled-components'

export const StyledProblematicAreas = {
  TitleContainer: styled.header`
    max-width: 360px;
    padding: 0 10px;
    margin: 0 auto 16px;
  `,
  OptionContentWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  OptionImage: styled.img`
    width: 48px;
    height: 48px;
    margin-right: 16px;
  `,
}
