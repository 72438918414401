import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { FeaturesReady } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import {
  DEFAULT_CUSTOMER_REVIEWS_V3,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2,
} from 'components/CustomerReviews/constants'
import { Spinner } from 'components/Spinner'
import { Ticker } from 'components/Ticker'

import {
  getSubscriptionListAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'
import { sendUserConfigAction } from 'root-redux/actions/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'
import { useScrollToTarget } from 'hooks/useScrollToTarget'
import { useTrackPageScrollDepth } from 'hooks/useTrackPageScrollDepth'

import { CancelOfferDiscountInfo } from 'modules/purchase/components/CancelOfferDiscountInfo'
import { LongPaywallAppGallery } from 'modules/purchase/components/LongPaywallAppGallery'
import { LongPaywallCustomerReviews } from 'modules/purchase/components/LongPaywallCustomerReviews'
import { LongPaywallShortUserProfile } from 'modules/purchase/components/LongPaywallShortUserProfile'
import { LongPaywallUserBenefits } from 'modules/purchase/components/LongPaywallUserBenefits'
import { LongPaywallUserGoalsGallery } from 'modules/purchase/components/LongPaywallUserGoals'
import { LongPaywallUserTarget } from 'modules/purchase/components/LongPaywallUserTarget'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PurchaseDisclaimer } from 'modules/purchase/components/PurchaseDisclaimer'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsIntroOfferBlock } from 'modules/purchase/components/SubscriptionsIntroOfferBlock'
import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  INTRO_SALES_COHORTS_DISCOUNT_MAP,
  SUBSCRIPTION_DISCOUNTS,
  TEN_MINUTES,
} from 'modules/purchase/constants'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  usePurchaseStore,
  useTimerForTarget,
} from 'modules/purchase/hooks'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import {
  selectDynamicDiscount,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  Cohort,
  ES_COUNTRY_CODES,
  Locale,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { useScrollEvent } from '../../hooks'
import { CheckoutList } from '../CheckoutList'
import { StyledPurchaseVariant7 as S } from './PurchaseVariant7.styles'
import {
  ES_REGION_TAG,
  HIGH_PRICES_AGES,
  LONG_PAYWALL_TRACKED_BREAKPOINTS,
  NUMBER_ONE_APP_IMAGE_PATH,
} from './constants'

export const PurchaseVariant7: React.FC = () => {
  const [isCheckoutShown, setIsCheckoutShown] = useState<boolean>(false)

  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const topSubscriptionsBlock = useRef<HTMLDivElement | null>(null)
  const bottomSubscriptionsBlock = useRef<HTMLDivElement | null>(null)
  const timerElementRef = useRef<HTMLParagraphElement | null>(null)
  const topDuplicateTimerElementRef = useRef<HTMLSpanElement | null>(null)
  const bottomDuplicateTimerElementRef = useRef<HTMLSpanElement | null>(null)

  const subscription = useSelector(selectSubscription)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const cohortToUse = useCohortToUse()

  const { arePricesReady } = usePricesStatus()
  const hasVatInfo = useVatInfo()
  const { isLuvlyIntroFastCohort, isLuvly14Cohort, isDynamicDiscountCohort } =
    useActiveCohortIdentifier()
  const {
    cohort,
    periodName,
    periodQuantity,
    price,
    language,
    currency,
    priceLookupKey,
    trialPeriodDays,
    trialPrice,
    userAge,
    userCountryCode,
  } = usePurchaseStore()

  const { areAgeHighPricesActive } = useABTest()

  const { scrollToTarget } = useScrollToTarget()

  useDefaultSubscription()

  const isLowerPrices = useMemo(() => {
    return isLuvly14Cohort && ES_COUNTRY_CODES.includes(userCountryCode)
  }, [isLuvly14Cohort, userCountryCode])

  const isCancelOfferRoute = useIsCurrentRoute(
    PageId.PURCHASE_INTRO_CANCEL_OFFER,
  )

  const { scrollEvent } = useScrollEvent(
    isCancelOfferRoute ? ScreenName.CANCEL_OFFER_LONG : ScreenName.PURCHASE,
  )

  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(
    isCancelOfferRoute ? ScreenName.CANCEL_OFFER_LONG : ScreenName.PURCHASE,
  )

  const isEnLocale = useMemo(() => language === Locale.ENGLISH, [language])

  const hasHighPrices = useMemo(
    () =>
      areAgeHighPricesActive &&
      isLuvlyIntroFastCohort &&
      HIGH_PRICES_AGES.includes(userAge),
    [areAgeHighPricesActive, isLuvlyIntroFastCohort, userAge],
  )

  useLayoutEffect(() => {
    dispatch(
      isCancelOfferRoute
        ? setScreenNameAction(ScreenName.CANCEL_OFFER_LONG)
        : setScreenNameAction(ScreenName.PURCHASE),
    )

    const pricesTags: string[] = []

    hasVatInfo
      ? pricesTags.push(SubscriptionTagsType.TAX)
      : pricesTags.push(SubscriptionTagsType.NO_TAX)

    hasHighPrices && pricesTags.push(SubscriptionTagsType.AGE_30_59)

    isCancelOfferRoute && pricesTags.push(SubscriptionTagsType.CANCEL_OFFER)

    isLowerPrices && pricesTags.push(ES_REGION_TAG)

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        pricesTags.join(','),
      ),
    )

    window.axon && window.axon('track', 'view_item')
  }, [dispatch, hasVatInfo, hasHighPrices, isCancelOfferRoute, isLowerPrices])

  useTimerForTarget(timerElementRef, TEN_MINUTES.IN_SECONDS, [
    topDuplicateTimerElementRef,
    bottomDuplicateTimerElementRef,
  ])

  useTrackPageScrollDepth({
    trackedBreakpoints: LONG_PAYWALL_TRACKED_BREAKPOINTS,
    eventCallback: scrollEvent,
    preventSendingEvents: isCheckoutShown,
  })

  const handleShowCheckout = useCallback(async () => {
    if (
      cohort === Cohort.LUVLY_CANCEL_INTRO_FAST ||
      cohort === Cohort.LUVLY_CANCEL_INTRO_1
    ) {
      await dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          price_id: priceLookupKey,
          subscription_duration: `${periodQuantity}${periodName}`,
          subscription_price: price,
          trial_period: trialPeriodDays,
          trial_price: trialPrice,
        }),
      )
    }

    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }, [
    cohort,
    sendGeneralPurchaseEvents,
    dispatch,
    priceLookupKey,
    currency,
    periodQuantity,
    price,
    trialPeriodDays,
    trialPrice,
    periodName,
  ])

  const discount = useMemo(() => {
    if (isDynamicDiscountCohort) {
      const introSaleDiscount =
        dynamicDiscount?.amount ||
        INTRO_SALES_COHORTS_DISCOUNT_MAP[cohortToUse] ||
        DEFAULT_DYNAMIC_DISCOUNT

      return isCancelOfferRoute
        ? introSaleDiscount + CANCEL_OFFER_ADDITIONAL_DISCOUNT
        : introSaleDiscount
    }

    return (
      subscription?.mainPrices.oldPrices.percentOfDiscount ||
      (isCancelOfferRoute
        ? SUBSCRIPTION_DISCOUNTS.CANCEL_DEFAULT
        : SUBSCRIPTION_DISCOUNTS.DEFAULT)
    )
  }, [
    cohortToUse,
    dynamicDiscount,
    isCancelOfferRoute,
    isDynamicDiscountCohort,
    subscription?.mainPrices.oldPrices.percentOfDiscount,
  ])

  return arePricesReady ? (
    <>
      {!isCheckoutShown && (
        <S.Wrapper>
          <S.NegativeOffsetContainer>
            {isDynamicDiscountCohort && <Ticker />}
          </S.NegativeOffsetContainer>
          <S.TimerContainer isIntroSaleCohort={isDynamicDiscountCohort}>
            <S.TimerContent>
              <S.TimerText>
                <p>
                  {discount}% {t('purchase7.timer')}
                </p>
                <S.CountdownTime ref={timerElementRef}>
                  {!timerElementRef?.current?.hasAttribute('data-value')
                    ? TEN_MINUTES.AS_STRING
                    : null}
                </S.CountdownTime>
              </S.TimerText>
              <S.TopButton
                onClick={() => scrollToTarget(topSubscriptionsBlock)}
              >
                {t('actions.getMyPlan')}
              </S.TopButton>
            </S.TimerContent>
          </S.TimerContainer>
          {isCancelOfferRoute && <CancelOfferDiscountInfo />}
          <LongPaywallUserTarget />
          <S.ContainerWrapper isEnLocaleStyle={isEnLocale}>
            <LongPaywallShortUserProfile
              timerRef={topDuplicateTimerElementRef}
            />
            <S.SubscriptionsContainer ref={topSubscriptionsBlock}>
              <SubscriptionsIntroOfferBlock hasTitle={false} />
            </S.SubscriptionsContainer>
            <S.ButtonContainer>
              <Button data-testid="start-plan-btn" onClick={handleShowCheckout}>
                {t`actions.getMyPlan`}
              </Button>
            </S.ButtonContainer>
            <PurchaseDisclaimer />
          </S.ContainerWrapper>
          <LongPaywallAppGallery />
          <Container>
            <S.NumberOneImage
              src={`${CDN_FOLDER_LINK}${NUMBER_ONE_APP_IMAGE_PATH}_${language}.png`}
              alt="number-one-app"
            />
            <Button onClick={() => scrollToTarget(bottomSubscriptionsBlock)}>
              {t('actions.getMyPlan')}
            </Button>
            <LongPaywallUserBenefits />
          </Container>
          <LongPaywallCustomerReviews
            title="purchase7.reviewsSubtitle"
            customerReviews={DEFAULT_CUSTOMER_REVIEWS_V3}
            additionalCustomerReviews={DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2}
          />
          <Container>
            <LongPaywallUserGoalsGallery />
          </Container>
          <S.Container isEnLocaleStyle={isEnLocale}>
            <LongPaywallShortUserProfile
              title="purchase7.visibleResultSubtitle"
              timerRef={bottomDuplicateTimerElementRef}
            />
            <S.SubscriptionsContainer ref={bottomSubscriptionsBlock}>
              <SubscriptionsIntroOfferBlock hasTitle={false} />
              <S.ButtonContainer>
                <Button onClick={handleShowCheckout}>
                  {t`actions.getMyPlan`}
                </Button>
              </S.ButtonContainer>
              <PurchaseDisclaimer />
            </S.SubscriptionsContainer>
            <MoneyBackGuarantee />
            <SecurityInfo />
          </S.Container>
        </S.Wrapper>
      )}
      {isCheckoutShown && (
        <FeaturesReady fallback={<Spinner />}>
          <CheckoutList
            setIsCheckoutShown={setIsCheckoutShown}
            isCancelOffer={isCancelOfferRoute}
          />
        </FeaturesReady>
      )}
    </>
  ) : null
}
