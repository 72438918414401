import styled from 'styled-components'

export const StyledConfettiBackdrop = {
  Root: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
  `,
}
