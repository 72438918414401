import styled from 'styled-components'

export const StyledPartnerContainer = {
  PartnersContainer: styled.div`
    margin: 0 0 40px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 24px;
  `,
  Partner: styled.img`
    max-height: 28px;
    max-width: 185px;
  `,
}
