import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledReasonForCancellation = {
  Title: styled.h2`
    margin-bottom: 30px;
    font-size: 23px;
    line-height: 31px;
    font-weight: 500;
    text-align: center;

    @media screen and (min-width: ${MediaBreakpoint.SMALL_PHONE}px) {
      margin-left: -10px;
      margin-right: -10px;
      font-size: 26px;
    }
  `,
  Options: styled.div`
    width: 100%;
  `,
  OptionContent: styled.div`
    width: 100%;
    min-height: 80px;
    display: flex;
    align-items: center;
    padding: 20px 10px 20px 30px;
    border-radius: 20px;
    box-shadow: 0 4px 12px -2px #ccd2e9;
    background-color: ${Color.WHITE};
    font-size: 16px;
    line-height: 19px;
  `,
}
