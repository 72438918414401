import styled from 'styled-components'

export const StyledLikeChatCareCosmetics = {
  OptionsWrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    & > label:not(:last-of-type) {
      width: calc(50% - 6px);
    }

    & > label:last-of-type {
      width: 100%;
    }
  `,
}
