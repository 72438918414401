import { IProgressStep } from 'models/common.model'

import { Cohort } from 'root-constants'

import { PageId } from './pages'

export const OB_STEPS_SECTION_ONE: Record<string, IProgressStep[]> = {
  VARIANT_ONE: [
    { id: PageId.INTRO },
    { id: PageId.HAVE_YOU_HEARD },
    { id: PageId.WHAT_IS_FACE_YOGA },
    { id: PageId.SATISFY_WITH_SKIN_CONDITION, hasProgressBar: true },
    { id: PageId.SKIN_CARE_ROUTINE, hasProgressBar: true },
    { id: PageId.CARE_COSMETICS, hasProgressBar: true },
    { id: PageId.SUN_SAFETY, hasProgressBar: true },
    { id: PageId.VISIT_TO_COSMETOLOGIST, hasProgressBar: true },
  ],
  VARIANT_TWO: [
    { id: PageId.AGE_AS_INTRO },
    { id: PageId.GOAL_VARIANT_2, hasProgressBar: true },
    { id: PageId.HAVE_YOU_HEARD },
    { id: PageId.WHAT_IS_FACE_YOGA },
    { id: PageId.SATISFY_WITH_SKIN_CONDITION, hasProgressBar: true },
    { id: PageId.OB_TAGS, hasProgressBar: true },
    { id: PageId.SKIN_CARE_ROUTINE, hasProgressBar: true },
    { id: PageId.CARE_COSMETICS, hasProgressBar: true },
    { id: PageId.SUN_SAFETY, hasProgressBar: true },
    { id: PageId.VISIT_TO_COSMETOLOGIST, hasProgressBar: true },
  ],
  VARIANT_THREE: [{ id: PageId.INTRO }],
}

export const OB_STEPS_SECTION_TWO: Record<string, IProgressStep[]> = {
  VARIANT_ONE: [
    { id: PageId.COSMETOLOGIST_VS_LUVLY },
    { id: PageId.EATING_PATTERNS, hasProgressBar: true },
    { id: PageId.SUGAR, hasProgressBar: true },
    { id: PageId.DIET, hasProgressBar: true },
    { id: PageId.VEGETABLES, hasProgressBar: true },
    { id: PageId.DAILY_WATER, hasProgressBar: true },
  ],
  VARIANT_TWO: [
    { id: PageId.TARGET_GOAL },
    { id: PageId.EATING_PATTERNS, hasProgressBar: true },
    { id: PageId.DIET, hasProgressBar: true },
    { id: PageId.SUGAR, hasProgressBar: true },
    { id: PageId.VEGETABLES, hasProgressBar: true },
    { id: PageId.DAILY_WATER, hasProgressBar: true },
  ],
  VARIANT_THREE: [
    { id: PageId.LUVLY_USERS },
    { id: PageId.GENDER, hasProgressBar: true },
    { id: PageId.AGE, hasProgressBar: true },
    { id: PageId.SKIN_TYPE, hasProgressBar: true },
    { id: PageId.SATISFY_WITH_SKIN_CONDITION, hasProgressBar: true },
    { id: PageId.SKIN_CONCERNS, hasProgressBar: true },
  ],
}

export const OB_STEPS_SECTION_THREE: Record<string, IProgressStep[]> = {
  VARIANT_ONE: [
    { id: PageId.GENDER, hasProgressBar: true },
    { id: PageId.PHOTO_RESULT, hasProgressBar: true },
    { id: PageId.AGING_TYPE, hasProgressBar: true },
    { id: PageId.AGE, hasProgressBar: true },
    { id: PageId.PROBLEMATIC_AREA, hasProgressBar: true },
    { id: PageId.SKIN_TYPE, hasProgressBar: true },
    { id: PageId.SKIN_TYPE_EXPLANATION, hasProgressBar: true },
    { id: PageId.SKIN_CONCERNS, hasProgressBar: true },
    { id: PageId.MOTIVATION, hasProgressBar: true },
    { id: PageId.SHORT_PROGRAM_DESCRIPTION, hasProgressBar: true },
    { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION, hasProgressBar: true },
    { id: PageId.PRACTICE_DURATION, hasProgressBar: true },
    { id: PageId.PREFERRED_FOOD, hasProgressBar: true },
    { id: PageId.FACE_SHAPE, hasProgressBar: true },
  ],
  VARIANT_TWO: [
    { id: PageId.GENDER, hasProgressBar: true },
    { id: PageId.PHOTO_RESULT },
    { id: PageId.AGE, hasProgressBar: true },
    { id: PageId.PROBLEMATIC_AREA, hasProgressBar: true },
    { id: PageId.SKIN_TYPE, hasProgressBar: true },
    { id: PageId.SKIN_TYPE_EXPLANATION, hasProgressBar: true },
    { id: PageId.SKIN_CONCERNS, hasProgressBar: true },
    { id: PageId.MOTIVATION, hasProgressBar: true },
    { id: PageId.SHORT_PROGRAM_DESCRIPTION, hasProgressBar: true },
    { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION, hasProgressBar: true },
    { id: PageId.PREFERRED_FOOD, hasProgressBar: true },
    { id: PageId.PRACTICE_DURATION, hasProgressBar: true },
    { id: PageId.FACE_SHAPE, hasProgressBar: true },
  ],
  VARIANT_THREE: [
    { id: PageId.LUVLY_GUIDE },
    { id: PageId.SKIN_CARE_ROUTINE, hasProgressBar: true },
    { id: PageId.CARE_COSMETICS, hasProgressBar: true },
    { id: PageId.SUN_SAFETY, hasProgressBar: true },
    { id: PageId.PRODUCT_INGREDIENT_FEEDBACK },
    { id: PageId.USELESS_PRODUCTS, hasProgressBar: true },
    { id: PageId.SCANNER_FEEDBACK },
    { id: PageId.TRUST_FEEDBACK },
  ],
}

export const OB_STEPS_SECTION_FOUR: Record<string, IProgressStep[]> = {
  VARIANT_ONE: [
    { id: PageId.MIRROR_REFLECTION },
    { id: PageId.COMPARE_MYSELF_TO_OTHERS },
    { id: PageId.APPEARANCE_EFFECT },
    { id: PageId.AFRAID_TO_LOOK_OLDER },
    { id: PageId.COSMETICS_HELP },
    { id: PageId.PRELIMINARY_PROGRESS },
    { id: PageId.MENSTRUAL_CYCLE },
    { id: PageId.MENTAL_HEALTH },
    { id: PageId.PREPARE_PLAN },
    { id: PageId.YOU_ARE_IN_GOOD_HANDS },
    { id: PageId.FACE_YOGA_EXPERTS },
    { id: PageId.REVERSE_YOUR_WRINKLES },
    { id: PageId.ANALYZING_ANSWERS },
  ],
  VARIANT_TWO: [
    { id: PageId.MIRROR_REFLECTION },
    { id: PageId.COMPARE_MYSELF_TO_OTHERS },
    { id: PageId.APPEARANCE_EFFECT },
    { id: PageId.AFRAID_TO_LOOK_OLDER },
    { id: PageId.COSMETICS_HELP },
    { id: PageId.PRELIMINARY_PROGRESS },
    { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND, hasProgressBar: true },
    { id: PageId.MENSTRUAL_CYCLE },
    { id: PageId.MENTAL_HEALTH },
    { id: PageId.PREPARE_PLAN },
    { id: PageId.YOU_ARE_IN_GOOD_HANDS },
    { id: PageId.FACE_YOGA_EXPERTS },
    { id: PageId.REVERSE_YOUR_WRINKLES },
    { id: PageId.ANALYZING_ANSWERS },
  ],
  VARIANT_THREE: [
    { id: PageId.FACE_SHAPE_CHANGE },
    { id: PageId.PROBLEMATIC_AREA, hasProgressBar: true },
    { id: PageId.MOTIVATION, hasProgressBar: true },
    { id: PageId.HAVE_YOU_HEARD },
    { id: PageId.ALL_ROUND_APPROACH },
    { id: PageId.MENSTRUAL_CYCLE },
    { id: PageId.MENTAL_HEALTH },
    { id: PageId.SKIN_REQUIRES_COMMITMENT },
    { id: PageId.COMPARE_MYSELF_TO_OTHERS },
    { id: PageId.TO_BE_EXPERT },
    { id: PageId.HAPPINESS_IS_HABIT },
    { id: PageId.COSMETICS_HELP },
    { id: PageId.PREPARE_PLAN },
    { id: PageId.SHORT_PROGRAM_DESCRIPTION, hasProgressBar: true },
    { id: PageId.YOU_ARE_IN_GOOD_HANDS },
    { id: PageId.FACE_YOGA_EXPERTS },
    { id: PageId.REVERSE_YOUR_WRINKLES },
    { id: PageId.ANALYZING_ANSWERS },
    { id: PageId.ENTER_EMAIL },
  ],
}

const DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP = [
  OB_STEPS_SECTION_ONE.VARIANT_TWO,
  OB_STEPS_SECTION_TWO.VARIANT_ONE,
  OB_STEPS_SECTION_THREE.VARIANT_ONE,
  OB_STEPS_SECTION_FOUR.VARIANT_ONE,
]

export const ONBOARDING_STEPS_MAP: Partial<Record<Cohort, IProgressStep[][]>> =
  {
    [Cohort.QUIZ_2]: [
      OB_STEPS_SECTION_ONE.VARIANT_ONE,
      OB_STEPS_SECTION_TWO.VARIANT_ONE,
      OB_STEPS_SECTION_THREE.VARIANT_ONE,
      OB_STEPS_SECTION_FOUR.VARIANT_ONE,
    ],
    [Cohort.LUVLY_INTRO_FAST]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
    [Cohort.LUVLY_COSMETIC_SCANNER]: [
      OB_STEPS_SECTION_ONE.VARIANT_THREE,
      OB_STEPS_SECTION_TWO.VARIANT_THREE,
      OB_STEPS_SECTION_THREE.VARIANT_THREE,
      OB_STEPS_SECTION_FOUR.VARIANT_THREE,
    ],
    [Cohort.LUVLY_9]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
    [Cohort.LUVLY_10]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
    [Cohort.LUVLY_INTRO2T]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
    [Cohort.LUVLY_14]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
    [Cohort.LUVLY_15]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
    [Cohort.LUVLY_17]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
    [Cohort.LUVLY_18]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
    [Cohort.LUVLY_19]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
    [Cohort.LUVLY_INTRO_SALE]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
    [Cohort.LUVLY_INTRO3_SALE]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
    [Cohort.LUVLY_20]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
    [Cohort.LUVLY_REF]: DEFAULT_INTRO_FAST_ONBOARDING_STEPS_MAP,
  }

export const PROGRESSBAR_STEPS = [
  [{ id: PageId.FINISHING_TOUCHES, text: 'login.buyPlan' }],
  [{ id: PageId.LOGIN, text: 'login.confirmInformation' }],
  [{ id: PageId.DOWNLOAD, text: 'login.createAccount' }],
]

export const UPSELL_PROGRESSBAR_STEPS = [
  [
    { id: PageId.UPSELL_PAYWALL, text: 'login.buyPlan' },
    { id: PageId.IN_APP_PAYWALL, text: 'login.buyPlan' },
    { id: PageId.IN_APP_CANCEL_PAYWALL, text: 'login.buyPlan' },
    { id: PageId.HAIR_CARE_PAYWALL, text: 'login.buyPlan' },
  ],
  [{ id: PageId.FINISHING_TOUCHES, text: 'login.addOffer' }],
  [{ id: PageId.LOGIN, text: 'login.confirmInformation' }],
  [{ id: PageId.DOWNLOAD, text: 'login.createAccount' }],
]

export const CHAT_BOT_PROGRESSBAR_STEPS = [
  [{ id: PageId.LOGIN, text: 'login.buyPlan' }],
  [{ id: PageId.DOWNLOAD, text: 'login.createAccount' }],
]

export const CHAT_BOT_UPSELL_PROGRESSBAR_STEPS = [
  [{ id: PageId.UPSELL_PAYWALL, text: 'login.buyPlan' }],
  [{ id: PageId.LOGIN, text: 'login.addOffer' }],
  [{ id: PageId.DOWNLOAD, text: 'login.createAccount' }],
]

export const PROGRESS_BAR_STEPS_MAP = {
  [Cohort.QUIZ_2]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_EMAIL_7]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_INTRO_FAST]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_EMAIL_8]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_9]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_10]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_12]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_INTRO2T]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_14]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_15]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_17]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_18]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_19]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_INTRO_SALE]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_INTRO3_SALE]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_12_SALE]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_20]: PROGRESSBAR_STEPS,
  [Cohort.LUVLY_REF]: PROGRESSBAR_STEPS,
}

export const UPSELL_PROGRESS_BAR_STEPS_MAP = {
  [Cohort.QUIZ_2]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_EMAIL_7]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_INTRO_FAST]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_EMAIL_8]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_9]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_10]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_12]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_INTRO2T]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_14]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_15]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_17]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_18]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_19]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_INTRO_SALE]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_INTRO3_SALE]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_12_SALE]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_20]: UPSELL_PROGRESSBAR_STEPS,
  [Cohort.LUVLY_REF]: UPSELL_PROGRESSBAR_STEPS,
}
