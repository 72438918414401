import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { useCohortToUse } from 'hooks/useCohortToUse'

import { I18N_CONTEXT_COHORT_MAP } from 'root-constants'

import { StyledLongPaywallUserGoalsGallery as S } from './LongPaywallUserGoalsGallery.styles'
import { USERS_GALLERY } from './constants'

export const LongPaywallUserGoalsGallery: React.FC = () => {
  const { t } = useTranslation()
  const cohortToUse = useCohortToUse()

  return (
    <S.Container>
      <S.Subtitle>
        <Trans
          i18nKey="purchase7.userGoalsGallery.subtitle"
          context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
        />
      </S.Subtitle>
      <Carousel
        slidesPerView={1}
        loop
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {USERS_GALLERY.map(({ name, img }) => (
          <SwiperSlide key={name}>
            <S.ImageWrapper>
              <S.SlideImage src={img} alt="user-image-before-after" />
              <S.User>{t(name)}</S.User>
            </S.ImageWrapper>
          </SwiperSlide>
        ))}
      </Carousel>
    </S.Container>
  )
}
