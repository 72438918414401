import styled, { css } from 'styled-components'

import { TLocaleStylesProps } from 'modules/purchase/types'

import { Color } from 'root-constants'

type TBenefitProps = {
  bgColor: string
}

export const StyledLongPaywallUserBenefits = {
  Wrapper: styled.div`
    margin: 48px auto;
  `,
  Subtitle: styled.h2<TLocaleStylesProps>`
    text-align: center;
    color: ${Color.DARK};
    font-weight: 700;

    ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle
        ? css`
            font-size: 28px;
            line-height: 32px;
          `
        : css`
            font-size: 24px;
            line-height: 30px;
          `}
  `,
  BenefitCardsWrapper: styled.div`
    margin: 20px auto 48px;

    & > div:not(:last-of-type) {
      margin-bottom: 16px;
    }
  `,
  BenefitCard: styled.div`
    width: 100%;
    display: flex;
    border-radius: 20px;
  `,
  BenefitImage: styled.img`
    width: 88px;
    height: 120px;
  `,
  Benefit: styled.div<TBenefitProps & TLocaleStylesProps>`
    display: flex;
    align-items: center;
    border-radius: 0 20px 20px 0;
    background: ${({ bgColor }) => bgColor};
    font-weight: 500;
    color: ${Color.BLUE_DARK};

    ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle
        ? css`
            padding: 24px 16px;
            line-height: 24px;
            font-size: 16px;
          `
        : css`
            padding: 20px 16px;
            line-height: 20px;
            font-size: 15px;
          `}
  `,
  DailyScheduleImage: styled.img`
    margin-top: 24px;
    width: 100%;
  `,
}
