import { useMemo } from 'react'

import { NAVIGATION_FREE_COHORTS } from 'root-constants'

import { useCohortToUse } from './useCohortToUse'

export const useIsNavigationOn = () => {
  const cohortToUse = useCohortToUse()

  return useMemo(
    () => !NAVIGATION_FREE_COHORTS.includes(cohortToUse),
    [cohortToUse],
  )
}
