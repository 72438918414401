import styled from 'styled-components'

import checkIcon from 'assets/images/check-icon.svg'

import { Color } from 'root-constants'

export const StyledWhatIsFaceYoga = {
  TitleContainer: styled.div`
    margin: 15px 0 16px;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  Description: styled.p`
    margin: 15px 0;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  ListTile: styled.h2`
    font-weight: 500;
    font-size: 23px;
    line-height: 24px;
    text-align: center;
  `,
  ListItem: styled.li`
    position: relative;
    margin-top: 12px;
    padding-left: 32px;
    font-size: 14px;
    line-height: 20px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background-image: url(${checkIcon});
    }
  `,
  SubListItem: styled.li`
    position: relative;
    margin-top: 5px;
    padding-left: 22px;
    font-size: 14px;
    line-height: 22px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: ${Color.DARK};
    }
  `,
  ButtonContainer: styled.div`
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    padding: 25px 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    );
  `,
}
