import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { StyledIntroLikeChat as S } from './IntroLikeChat.styles'
import { CHAT_QUESTION } from './constants'

export const IntroLikeChat: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: CHAT_QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    dispatch(sendFacebookParamsAction())
    window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
    googleAnalyticsLogger.logFirstPageCompleted()
    eventLogger.logFirstPageCompleted({
      question: CHAT_QUESTION,
    })

    handleContinue('')
  }, [dispatch, handleContinue, uuid])

  return (
    <S.Root>
      <IntroHeader colorTheme={IntroHeaderColor.LIGHT} />
      <S.Container>
        <div>
          <S.Title>{t('onboarding.introLikeChat.title')}</S.Title>
          <S.Subtitle>{t`onboarding.introLikeChat.subtitle`}</S.Subtitle>
          <S.ButtonContainer>
            <Button onClick={handleNextClick}>
              {t('onboarding.introLikeChat.CTA')}
            </Button>
          </S.ButtonContainer>
        </div>
      </S.Container>
    </S.Root>
  )
}
