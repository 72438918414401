import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { useTimerForTarget } from 'modules/purchase/hooks'

import { StyledPersonalizedPlan as S } from './PersonalizedPlan.styles'

interface IProps {
  elemForComparisonRef: React.RefObject<HTMLDivElement>
  className?: string
}

export const PersonalizedPlan: React.FC<IProps> = ({
  elemForComparisonRef,
  className = '',
}) => {
  const { t } = useTranslation()
  const timerElementRef = useRef<HTMLSpanElement>(null)
  const isTargetHidden = useTargetHiddenOnScroll(elemForComparisonRef)

  useTimerForTarget(timerElementRef, FIFTEEN_MINUTES_IN_SECONDS)

  return (
    <S.Wrapper>
      <S.PlanDescription>{t`purchase1.personalizedPlanDescription.title`}</S.PlanDescription>
      {isTargetHidden && (
        <S.Text>{t`purchase1.personalizedPlanDescription.timerTextFirst`}</S.Text>
      )}
      <S.TimerContainer
        className={className}
        data-is-target-hidden={isTargetHidden}
      >
        <S.TimerText data-is-target-hidden={isTargetHidden}>
          {!isTargetHidden
            ? t`purchase1.personalizedPlanDescription.timerTextFirst`
            : t`purchase1.personalizedPlanDescription.timerTextSecond`}
        </S.TimerText>
        <strong ref={timerElementRef} />
      </S.TimerContainer>
      <S.Disclaimer>
        {t('purchase1.personalizedPlanDescription.disclaimer')}
      </S.Disclaimer>
    </S.Wrapper>
  )
}
