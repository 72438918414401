import styled from 'styled-components'

export const StyledLikeChatFaceYoga = {
  AnswerWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  Icon: styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
  `,
}
