import popsugarLogo from 'assets/images/popsugar-logo.png'
import vogueLogo from 'assets/images/vogue-logo.png'
import womenHealthLogo from 'assets/images/women-health-logo.png'

export const PARTNERS = [
  {
    partner: 'vogue',
    image: vogueLogo,
  },
  {
    partner: `women's health`,
    image: womenHealthLogo,
  },
  {
    partner: 'popsugar',
    image: popsugarLogo,
  },
]
