import styled from 'styled-components'

import { ChatAnswerButton } from 'components/ChatAnswerButton'

export const StyledLikeChatProblematicAreas = {
  OptionsWrapper: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    row-gap: 16px;
    margin-bottom: 24px;
  `,
  OptionContentWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  OptionImage: styled.img`
    width: 36px;
    height: 36px;
    margin-right: 6px;
  `,
  ShortenedChatAnswerButton: styled(ChatAnswerButton)`
    padding: 9px 32px 9px 8px;
    margin-bottom: -4px;

    &::after {
      right: 8px;
    }
  `,
}
