import styled from 'styled-components'

import { Button } from 'components/Button'

import greenLabel from 'assets/images/green-label.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledUpsellWellnessBundle = {
  Wrapper: styled.div`
    padding-bottom: 40px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 360px;
      margin: 0 auto;
    }
  `,
  Hero: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    position: relative;
    z-index: 1;
    margin-top: -15px;
    aspect-ratio: 360/360;
  `,
  HeroTitle: styled.h1`
    color: ${Color.WHITE};
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    position: absolute;
    z-index: 2;
    top: 24px;
    left: 24px;
    max-width: 210px;
  `,
  CancelOfferContainer: styled.div`
    padding: 16px 12px;
    border-radius: 16px;
    background: rgba(59, 161, 63, 0.12);
    display: flex;
    align-items: center;
    margin: 16px auto 32px;
  `,
  CancelOfferImage: styled.div`
    margin-right: 10px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: rgba(59, 161, 63, 0.16);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      margin-right: 12px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 28px;
      height: 28px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 28px 28px;
      background-image: url(${greenLabel});
    }
  `,
  CancelOfferText: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.BLUE_DARK};

    & strong {
      color: #3ba13f;
      font-weight: 600;
    }
  `,
  TimerValue: styled.span`
    color: #3ba13f;
    font-weight: 600;
    line-height: 24px;
    margin: 16px auto;
  `,
  PurchaseButton: styled(Button)`
    margin-bottom: 40px;
  `,
  GlowUpContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    position: relative;
  `,
}
