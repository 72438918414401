import React from 'react'
import { useTranslation } from 'react-i18next'

import { ISvg, SvgImage } from 'components/SvgImage'

import { StyledUpsellGuashaPersonalBonus as S } from './UpsellGuashaPersonalBonus.styles'

type TUpsellGuashaPersonalBonus = {
  title: string
  description: string
  img: string
  icon: ISvg
  backgroundIconColor: string
}

export const UpsellGuashaPersonalBonus: React.FC<
  TUpsellGuashaPersonalBonus
> = ({ title, description, img, icon, backgroundIconColor }) => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <S.Icon backgroundColor={backgroundIconColor}>
        <SvgImage svg={icon} width={48} height={48} />
      </S.Icon>
      <S.Container>
        <S.Title>{t(title)}</S.Title>
        <S.Description>{t(description)}</S.Description>
        <img src={img} alt="" />
      </S.Container>
    </S.Root>
  )
}
