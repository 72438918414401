import styled from 'styled-components'

import chevronRight from 'assets/images/chevron-right-coral.png'

import { Color } from 'root-constants'

export const StyledAgeUnisexAsIntro = {
  Root: styled.div`
    padding-top: 56px;
  `,
  Title: styled.h1`
    color: ${Color.MIDNIGHT_BLUE};
    text-align: center;
    font-size: 32px;
    line-height: 38px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 12px;
    text-transform: uppercase;
    margin-top: 24px;
  `,
  Subtitle: styled.h2`
    color: ${Color.LIGHT};
    text-align: center;
    font-size: 18px;
    line-height: 21px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 12px;
    text-transform: uppercase;
  `,
  CallToAction: styled.p`
    color: ${Color.MIDNIGHT_BLUE};
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 24px;
  `,
  Actions: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin: 0 -4px;
    input:checked + div > div {
      border: 2px solid ${Color.PRIMARY};
    }
    & label {
      flex-basis: calc(50% - 8px);
      margin-bottom: 0;
    }
  `,
  Terms: styled.div`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: ${Color.LIGHT};
    margin: 24px auto 0;
    a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.LIGHT};
    margin: 0 5px;
    vertical-align: middle;
  `,
  Action: styled.div`
    display: flex;
    min-height: 176px;
    position: relative;
    padding-top: 11px;
  `,
  Image: styled.img`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 140px;
    z-index: 1;
  `,
  Answer: styled.div`
    flex-basis: 100%;
    background-color: #eceef4;
    border: 2px solid transparent;
    border-radius: 18px;
    transition: background-color 0.1s ease-out;
  `,
  AnswerWrapper: styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s;
    border-radius: 16px;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    font-weight: 600;
    color: ${Color.WHITE};
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    height: 48px;
    width: calc(100% - 8px);
    padding: 12px;
    z-index: 2;
    background: ${Color.PRIMARY};
  `,
  AnswerValue: styled.span``,
  ChevronRight: styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    background-color: ${Color.WHITE};

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      background: url(${chevronRight}) no-repeat center;
      background-size: contain;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  `,
}
