import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { StyledCancelOfferWhatYouGet as S } from './CancelOfferWhatYouGet.styles'
import { RECEIVED_ITEMS } from './constants'

export const CancelOfferWhatYouGet: React.FC<{
  handleShowCheckout?: () => void
  buttonTitle?: string
}> = ({ buttonTitle, handleShowCheckout }) => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <Container>
        {RECEIVED_ITEMS.map(({ title, description, icon }) => (
          <S.Card key={description}>
            <S.Caption>
              <img src={icon} alt="" />
              <S.Title>{t(title)}</S.Title>
            </S.Caption>
            <S.Description>
              <Trans i18nKey={description} components={[<br />]} />
            </S.Description>
          </S.Card>
        ))}
        {buttonTitle && handleShowCheckout && (
          <S.ButtonContainer>
            <Button type="button" onClick={handleShowCheckout}>
              {buttonTitle}
            </Button>
          </S.ButtonContainer>
        )}
      </Container>
    </S.Root>
  )
}
