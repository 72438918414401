import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import { getButtonTapEventData } from 'helpers/getGuashaButtonsEventData'

import { eventLogger } from 'services/eventLogger.service'

import guashaAndRoller from 'assets/images/guasha.png'
import femaleIcon from 'assets/images/sprite/female-stroke.svg'
import handmadeIcon from 'assets/images/sprite/handmade.svg'
import sustainableIcon from 'assets/images/sprite/sustainable.svg'

import { ScreenName } from 'root-constants'

import { StyledUpsellGuashaFacts as S } from './UpsellGuashaFacts.styles'

type TUpsellGuashaFactsProps = {
  onAddToCartCallback: () => void
  buttonOrder: number
  screenName: ScreenName
}

export const UpsellGuashaFacts: React.FC<TUpsellGuashaFactsProps> = ({
  onAddToCartCallback,
  buttonOrder,
  screenName,
}) => {
  const { t } = useTranslation()

  const addToCart = useCallback(
    (event) => {
      const { buttonText, buttonOrder: order } = getButtonTapEventData(event)
      eventLogger.logInAppButtonTap(buttonText, order, screenName)
      onAddToCartCallback()
    },
    [screenName, onAddToCartCallback],
  )
  return (
    <S.Root>
      <Container fields={16}>
        <S.Content>
          <S.List>
            <S.Item>
              <SvgImage svg={femaleIcon} width={48} height={48} />
              {t('guashaUpsell.fact1')}
            </S.Item>
            <S.Item>
              <SvgImage svg={handmadeIcon} width={48} height={48} />
              {t('guashaUpsell.fact2')}
            </S.Item>
            <S.Item>
              <SvgImage svg={sustainableIcon} width={48} height={48} />
              {t('guashaUpsell.fact3')}
            </S.Item>
          </S.List>
          <S.ImageContainer>
            <img src={guashaAndRoller} alt="" />
          </S.ImageContainer>
        </S.Content>
        <Button onClick={addToCart} data-order={buttonOrder}>
          {t('guashaUpsell.subscription.withGuashaButton')}
        </Button>
      </Container>
    </S.Root>
  )
}
