import React from 'react'

import { TComponentProps } from 'models/common.model'

import { StyledAnswerButtonMini as S } from './AnswerButtonMini.styles'

export const AnswerButtonMini: React.FC<TComponentProps> = ({
  children,
  className,
}) => <S.Root className={className}>{children}</S.Root>
