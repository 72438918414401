import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Chat, DelayedBottomContainer, DelayedContainer } from 'common-styles'
import {
  CHAT_PAGE_TRANSITION_DELAY,
  Cohort,
  I18N_CONTEXT_COHORT_MAP,
  OptionType,
} from 'root-constants'

import { StyledLikeChatCareCosmetics as S } from './LikeChatCareCosmetics.styles'
import {
  CARE_COSMETICS_MAP,
  CARE_COSMETICS_OPTIONS_MAP,
  OPTION_VALUES,
  QUESTION,
} from './constants'

export const LikeChatCareCosmetics: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)
  const { handleChange } = useDelayedContinue(CHAT_PAGE_TRANSITION_DELAY)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useEffect(() => {
    if (chatStep === ChatStep.FINISH) {
      const uniqueAnswers = [...new Set(answers)]
      handleChange(uniqueAnswers, handleContinue)
    }
  }, [answers, chatStep, handleChange, handleContinue])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        const isNoneOptionTouched = value === OPTION_VALUES.NONE
        if (isChecked && isNoneOptionTouched) {
          setAnswers([value])

          return
        }

        if (isChecked) {
          setAnswers(
            [...answers, value].filter((item) => item !== OPTION_VALUES.NONE),
          )

          return
        }

        setAnswers(answers.filter((item) => item !== value))
      },
    }),
    [pageId, answers],
  )

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.SHOW_OPTIONS)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_QUESTION ||
            chatStep === ChatStep.SHOW_OPTIONS
          }
        >
          {t('onboarding.likeChatCareCosmetics.title')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.USER}
          {...(chatStep === ChatStep.USER_ANSWER && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
        >
          {answers.length &&
            answers.map((item, i) => (
              <React.Fragment key={item}>
                <span>{i ? ', ' : ''}</span>
                <Trans
                  i18nKey={CARE_COSMETICS_MAP[item].text}
                  context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
                />
              </React.Fragment>
            ))}
        </ChatMessage>
      </Chat>
      <DelayedContainer isShown={chatStep === ChatStep.SHOW_OPTIONS}>
        <S.OptionsWrapper>
          {CARE_COSMETICS_OPTIONS_MAP[Cohort.LUVLY_CHAT].map(
            ({ value, text }) => (
              <Option
                {...optionProps}
                key={value}
                value={value}
                withoutMargin
                checked={answers.includes(value)}
              >
                <ChatAnswerButton withCheckbox>
                  <Trans
                    i18nKey={text}
                    context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
                  />
                </ChatAnswerButton>
              </Option>
            ),
          )}
        </S.OptionsWrapper>
      </DelayedContainer>
      <DelayedBottomContainer isShown={chatStep === ChatStep.SHOW_OPTIONS}>
        <Button
          onClick={() => setChatStep(ChatStep.USER_ANSWER)}
          disabled={!answers.length}
        >
          {t('actions.send')}
        </Button>
      </DelayedBottomContainer>
    </Container>
  )
}
