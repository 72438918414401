import {
  selectUserConfigData,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { getStripePurchaseCommonEventParams } from 'modules/purchase/helpers/getStripePurchaseCommonEventParams'

import { IAppState } from 'models/store.model'

import { CookieConsentOption } from 'root-constants'

export const getStripePurchaseSuccessEventParams = (state: IAppState) => {
  const {
    trialPeriodPrice,
    uuid,
    periodName,
    periodQuantity,
    currency,
    screenName,
    stripeAccountName,
    stripeAccountId,
    isPersonalDataAllowed,
  } = getStripePurchaseCommonEventParams(state)
  const email = selectUserOnboardingEmail(state)
  const config = selectUserConfigData(state)
  const userCookieConsentAnswer = config?.cookieConsent as string[]

  return {
    trialPrice: trialPeriodPrice,
    uuid,
    periodName,
    periodQuantity,
    currency,
    screenName,
    email: userCookieConsentAnswer?.includes(
      CookieConsentOption.FUNCTIONAL_COOKIES,
    )
      ? email
      : '',
    stripeAccountName,
    stripeAccountId,
    isPersonalDataAllowed,
  }
}
