import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledCancelOfferWhatYouGet = {
  Root: styled.div`
    padding: 32px 0;
    background: #fef8f4;
  `,
  Card: styled.div`
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  `,
  Caption: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    & img {
      max-width: 48px;
      margin-right: 16px;
    }
  `,
  Title: styled.h3`
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: ${Color.BLUE_DARK};
  `,
  Description: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.GRAY};
  `,
  ButtonContainer: styled.div`
    margin-top: 24px;
  `,
}
