import angelaRosoff from 'assets/images/angela-rosoff.jpg'
import briannaImg from 'assets/images/brianna-before-after-min.jpg'
import girlAge18Image from 'assets/images/girl-age-18-29-v2.png'
import girlAge30Image from 'assets/images/girl-age-30-39-v2.png'
import girlAge40Image from 'assets/images/girl-age-40-49-v2.png'
import girlAge50Image from 'assets/images/girl-age-50-v2.png'
import jenniferImg from 'assets/images/jennifer-before-after-min.jpg'
import jilianOsborne from 'assets/images/jilian-osborne.jpg'
import lindaImg from 'assets/images/linda-before-after-min.jpg'
import marinaVasilevskaya from 'assets/images/marina-vasilevskaya.jpg'
import melissaImg from 'assets/images/melissa-before-after-min.png'

export const QUESTION = 'Age'

export const TRACKED_BREAKPOINTS: number[] = [16, 33, 50, 66, 83, 100]

export const NUMBER_ONE_APP_IMAGE_PATH = '/images/face-yoga-app-number-one'
export const PERSONAL_PROGRAM_IMAGE_PATH = '/images/personal-program'
export const INGREDIENT_CHECKER_IMAGE_PATH = '/images/ingredient-checker'
export const PROGRESS_TRACKER_IMAGE_PATH = '/images/progress-tracker'
export const COURSES_AND_INSIGHTS_IMAGE_PATH = '/images/courses-and-insights'

export const OPTION_VALUES = {
  TWENTY: '20', // 18-29
  THIRTY: '30', // 30-39
  FORTY: '40', // 40-49
  FIFTY: '50', // 50-59
}

export const AGE_OPTION_VALUES_MAP = {
  [OPTION_VALUES.TWENTY]: '18-29',
  [OPTION_VALUES.THIRTY]: '30-39',
  [OPTION_VALUES.FORTY]: '40-49',
  [OPTION_VALUES.FIFTY]: '50-59',
}

export const ANSWERS = [
  {
    value: OPTION_VALUES.TWENTY,
    range: '18-29',
    img: girlAge18Image,
  },
  {
    value: OPTION_VALUES.THIRTY,
    range: '30-39',
    img: girlAge30Image,
  },
  {
    value: OPTION_VALUES.FORTY,
    range: '40-49',
    img: girlAge40Image,
  },
  {
    value: OPTION_VALUES.FIFTY,
    range: '50+',
    img: girlAge50Image,
  },
]

export const ROADMAP = [
  {
    title: 'onboarding.introVariantLong.personalProgram.title',
    subtitle: 'onboarding.introVariantLong.personalProgram.subtitle',
    img: PERSONAL_PROGRAM_IMAGE_PATH,
  },
  {
    title: 'onboarding.introVariantLong.ingredientChecker.title',
    subtitle: 'onboarding.introVariantLong.ingredientChecker.subtitle',
    img: INGREDIENT_CHECKER_IMAGE_PATH,
  },
  {
    title: 'onboarding.introVariantLong.progressTracker.title',
    subtitle: 'onboarding.introVariantLong.progressTracker.subtitle',
    img: PROGRESS_TRACKER_IMAGE_PATH,
  },
  {
    title: 'onboarding.introVariantLong.coursesAndInsights.title',
    subtitle: 'onboarding.introVariantLong.coursesAndInsights.subtitle',
    img: COURSES_AND_INSIGHTS_IMAGE_PATH,
  },
]

export const CREATORS = [
  {
    name: 'onboarding.introVariantLong.creators.creator1.name',
    text: 'onboarding.introVariantLong.creators.creator1.text',
    img: angelaRosoff,
  },
  {
    name: 'onboarding.introVariantLong.creators.creator2.name',
    text: 'onboarding.introVariantLong.creators.creator2.text',
    img: jilianOsborne,
  },
  {
    name: 'onboarding.introVariantLong.creators.creator3.name',
    text: 'onboarding.introVariantLong.creators.creator3.text',
    img: marinaVasilevskaya,
  },
]

export const USERS_GALLERY = [
  {
    name: 'onboarding.introVariantLong.user1.name',
    text: 'onboarding.introVariantLong.user1.text',
    img: melissaImg,
  },
  {
    name: 'onboarding.introVariantLong.user2.name',
    text: 'onboarding.introVariantLong.user2.text',
    img: briannaImg,
  },
  {
    name: 'onboarding.introVariantLong.user3.name',
    text: 'onboarding.introVariantLong.user3.text',
    img: lindaImg,
  },
  {
    name: 'onboarding.introVariantLong.user4.name',
    text: 'onboarding.introVariantLong.user4.text',
    img: jenniferImg,
  },
]
