import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import firebase from 'firebase/app'

import { Modal } from 'components/Modal'

import { resetErrorAction } from 'root-redux/actions/common'
import { setTestEnvironmentQueryParamAction } from 'root-redux/actions/user'
import { selectError } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useAuthObserver } from 'hooks/useAuthObserver'
import { useInitFirebase } from 'hooks/useInitFirebase'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { StyledLoginButton } from 'common-styles'
import { PlatformOS, TEST_ENV_QUERY_PARAM } from 'root-constants'

import { StyledUnsubscribeLogin as S } from './UnsubscribeLogin.styles'
import { EmailLogin } from './components/EmailLogin'
import { getSecretAndUUIDAction } from './redux'

const signInFirebase = (provider) => firebase.auth().signInWithPopup(provider)
const signOutFirebase = () => firebase.auth().signOut()

export const UnsubscribeLogin: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch: TAppDispatch = useDispatch()
  const error = useSelector(selectError)
  const [isModalShown, setIsModalShown] = useState(false)
  const isAndroid = useMemo(
    () => getMobileOperatingSystem() === PlatformOS.ANDROID,
    [],
  )

  useEffect(() => {
    if (error) {
      setIsModalShown(true)
    }
  }, [error])

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const hasTestEnvQueryParam = URLParams.has(TEST_ENV_QUERY_PARAM)
    const testEnvQueryParamFromUrl = hasTestEnvQueryParam
      ? `?${TEST_ENV_QUERY_PARAM}`
      : ''

    dispatch(setTestEnvironmentQueryParamAction(testEnvQueryParamFromUrl))
  }, [dispatch, search])

  const handleContinueWithGoogle = useCallback(async () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    await signInFirebase(provider)
  }, [])

  const handleContinueWithFb = useCallback(async () => {
    await signInFirebase(new firebase.auth.FacebookAuthProvider())
  }, [])

  const handleContinueWithApple = useCallback(async () => {
    await signInFirebase(new firebase.auth.OAuthProvider('apple.com'))
  }, [])

  const authStateChangeHandler = useCallback(
    (token: string) => {
      const errorCallback = () => {
        signOutFirebase()
      }
      dispatch(getSecretAndUUIDAction(token, errorCallback))
    },
    [dispatch],
  )

  useInitFirebase()
  useAuthObserver(authStateChangeHandler)

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{t`unsubscribe.login.title`}</S.Title>
        <EmailLogin />
        <S.Text>or</S.Text>
        <StyledLoginButton.Google onClick={handleContinueWithGoogle}>
          {t`actions.continueWithGoogle`}
        </StyledLoginButton.Google>
        <StyledLoginButton.Facebook onClick={handleContinueWithFb}>
          {t`actions.continueWithFacebook`}
        </StyledLoginButton.Facebook>
        {!isAndroid && (
          <StyledLoginButton.Apple onClick={handleContinueWithApple}>
            {t`actions.continueWithApple`}
          </StyledLoginButton.Apple>
        )}
      </S.Column>

      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsModalShown(false)
        }}
        isShown={isModalShown}
        error={error}
      />
    </S.Wrapper>
  )
}
