import React from 'react'

import { StyledConnectToDBProgress as S } from './ConnectToDBProgressBar.styles'

type TProps = {
  value: number
  color?: string
  backgroundColor?: string
  children?: React.ReactNode
}
export const ConnectToDBProgressBar: React.FC<TProps> = ({
  value,
  color,
  backgroundColor,
  children,
}) => (
  <>
    <S.Label>{children}</S.Label>
    <S.ProgressWrapper data-progress-value={`${value}%`}>
      <S.Progress
        value={value}
        color={color}
        background={backgroundColor}
        max={100}
      />
    </S.ProgressWrapper>
  </>
)
