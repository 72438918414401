import styled from 'styled-components'

import heartIcon from 'assets/images/heart-icon.png'

export const StyledLikeChatSkinQuality = {
  AnswerWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  Icon: styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
  `,
  Emoji: styled.span`
    display: inline-flex;
    width: 12px;
    height: 12px;
    position: relative;
    background: url(${heartIcon}) no-repeat center;
    background-size: contain;
    z-index: 1;
  `,
  Animation: styled.span`
    display: inline-flex;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  `,
}
