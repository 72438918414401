export const BENEFITS_LIST = [
  {
    title: 'purchase1.holidayCancelOffer.benefits.title',
    options: [
      {
        hasAdditionalText: true,
        dictionaryPath: 'purchase1.holidayCancelOffer.benefits.first',
      },
      {
        dictionaryPath: 'purchase1.holidayCancelOffer.benefits.second',
      },
      {
        dictionaryPath: 'purchase1.holidayCancelOffer.benefits.third',
      },
      {
        dictionaryPath: 'purchase1.holidayCancelOffer.benefits.fourth',
      },
    ],
  },
  {
    title: 'purchase1.holidayCancelOffer.results.title',
    options: [
      {
        dictionaryPath: 'purchase1.holidayCancelOffer.results.first',
      },
      {
        dictionaryPath: 'purchase1.holidayCancelOffer.results.second',
      },
      {
        dictionaryPath: 'purchase1.holidayCancelOffer.results.third',
      },
      {
        dictionaryPath: 'purchase1.holidayCancelOffer.results.fourth',
      },
      {
        dictionaryPath: 'purchase1.holidayCancelOffer.results.fifth',
      },
    ],
  },
]
