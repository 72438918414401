import styled from 'styled-components'

import { PageTitle as Title } from 'components/PageTitle'

import { BottomButtonContainer } from 'common-styles'
import { Color } from 'root-constants'

export const StyledAlmostThere = {
  PageTitle: styled(Title)`
    text-align: start;
    margin: 16px auto 8px;
  `,
  Image: styled.img`
    width: 328px;
    height: 236px;
  `,
  Description: styled.p`
    color: ${Color.LIGHT};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
  ButtonContainer: styled(BottomButtonContainer)`
    padding: 40px 0;
  `,
}
