import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledLuvlyBenefitsCosmeticScanner as S } from './LuvlyBenefitsCosmeticScanner.styles'
import { COSMETIC_SCANNER_LUVLY_BENEFITS } from './constants'

export const LuvlyBenefitsCosmeticScanner: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Title>{t`purchase1.luvlyBenefits.title`}</S.Title>
      <S.List>
        {COSMETIC_SCANNER_LUVLY_BENEFITS.map(({ id, text }) => (
          <S.ListItem key={id}>{t(text)}</S.ListItem>
        ))}
      </S.List>
    </S.Wrapper>
  )
}
