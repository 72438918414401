import styled from 'styled-components'

import primaryCheck from 'assets/images/primary-check.png'

import { Color } from 'root-constants'

export const StyledHolidaysLuvlyBenefits = {
  Root: styled.div`
    padding: 40px 0;

    .swiper-slide {
      height: auto;
    }

    & .swiper-pagination-bullets {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: ${Color.CREAMY};
    }

    & .swiper-pagination-bullet {
      background: #ff8276;
    }
  `,
  Container: styled.div`
    display: flex;
    position: relative;
    height: 100%;
  `,
  Benefits: styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 320px;
    margin: 0 auto 81px auto;
    position: relative;
    box-shadow: 0 8px 20px #e8e8e8;
    background-color: ${Color.WHITE};
    padding-top: 28px;
    border-radius: 14px 14px 0 0;
    min-height: 319px;
  `,
  Title: styled.h2`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 16px;
    letter-spacing: 0.5px;
  `,
  List: styled.ul`
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    margin: 0;
    padding: 0 32px;
    flex-grow: 1;
  `,
  Item: styled.li`
    flex-basis: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.DARK};
    padding-left: 28px;
    margin-bottom: 12px;
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 20px;
      height: 20px;
      background: url(${primaryCheck}) no-repeat center;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
  WaveShapeItem: styled.li`
    height: 45px;
    background-color: transparent;
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    position: absolute;
    bottom: -45px;
    left: 0;
    right: 0;
    z-index: 1;

    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 19px;
      background-color: ${Color.WHITE};
      z-index: 3;
      position: absolute;
      left: 0;
      top: 0;
    }
  `,
  WaveShapeSlice: styled.div`
    width: 16.666666%;
    height: 34px;
    border-radius: 50% 50% 50% 50% / 0% 0% 100% 100%;
    background-color: ${Color.WHITE};
    position: relative;
    box-shadow: 0 1px 7px 5px #e8e8e8;

    &:last-child {
      &::before {
        display: block;
        content: '';
        position: absolute;
        width: 19px;
        height: 18px;
        border: 7px solid ${Color.WHITE};
        border-top: none;
        border-bottom: none;
        border-right: none;
        border-radius: 120px/103px;
        box-sizing: content-box;
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        left: -12px;
        bottom: -4px;
      }
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 19px;
      height: 18px;
      border: 6px solid ${Color.WHITE};
      border-top: none;
      border-bottom: none;
      border-right: none;
      border-radius: 120px/103px;
      box-sizing: content-box;
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      right: -12px;
      bottom: -5px;
    }
  `,
}
