import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledOccasionResult = {
  Root: styled.div`
    padding-bottom: 50px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: ${Color.BLUE_DARK};
    margin-left: -4px;
    margin-right: -4px;
    margin-bottom: 16px;
  `,
  Description: styled.p`
    font-family: Rubik;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.LIGHT};
    margin-bottom: 28px;
    text-align: center;
  `,
  Goal: styled.h2`
    color: ${Color.BLUE_DARK};
    text-align: center;
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${Color.GRAY_LIGHT};
    padding-bottom: 12px;
  `,
  Occasion: styled.p`
    font-family: Rubik;
    color: ${Color.LIGHT};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 32px;
    text-align: center;
  `,
  AnimationContainer: styled.div`
    width: 100%;
    height: 148px;
  `,
  Graph: styled.div`
    background-color: ${Color.WHITE};
    padding: 20px;
    box-shadow: 0px 3.836px 14px 0px rgba(68, 85, 127, 0.19);
    border-radius: 16px;
    margin: 0 auto;
    position: relative;
  `,
  Intervals: styled.div`
    display: flex;
    justify-content: space-between;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #918c8c;
    padding: 8px 10px 0;
  `,
}
