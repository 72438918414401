import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { TAppDispatch } from 'root-redux/store/store'

import { setSelectedSubscriptionAction } from '../redux/actions/common'
import { usePurchaseStore } from './usePurchaseStore'

export const useDefaultSubscription = () => {
  const dispatch: TAppDispatch = useDispatch()
  const { subscriptions } = usePurchaseStore()

  useEffect(() => {
    const defaultSubscription = subscriptions.find(({ isDefault }) => isDefault)

    defaultSubscription &&
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
  }, [dispatch, subscriptions])
}
