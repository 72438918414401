import {
  CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
  EMAIL_LOGIN_INSTRUCTION_LINK,
} from 'root-constants'

export const getDeepLink = (customToken: string | null): string =>
  EMAIL_LOGIN_INSTRUCTION_LINK.replace(
    CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
    customToken || '',
  )
