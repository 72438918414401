import styled from 'styled-components'

import { Color } from 'root-constants'

import { PersonalizedPlan } from '../../components/PersonalizedPlan'

export const StyledPurchaseIntroOffer = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px 20px;
  `,
  TitleContainer: styled.header`
    margin-bottom: 16px;
  `,
  Title: styled.h1`
    margin: 0 -10px 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.DARK};
  `,
  PersonalizedPlan: styled(PersonalizedPlan)`
    justify-content: flex-start;

    &[data-is-target-hidden='true'] {
      justify-content: center;
    }
  `,
  Subtitle: styled.h2`
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  PaymentMethodTitle: styled.h2`
    margin: 30px 0 23px;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  ButtonContainer: styled.div`
    margin: 24px 0;
  `,
  PaymentContainer: styled.div`
    &[data-is-visible='false'] {
      display: none;
    }
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
}
