import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { selectCurrency } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { IN_APP_PRODUCTS_DATA } from '../UpsellGuashaSubscriptionBlock'
import { StyledGuashaOrderSummary as S } from './GuashaOrderSummary.styles'

type TGuashaOrderSummaryProps = {
  selectedSubscription: ISubscription
}

export const GuashaOrderSummary: React.FC<TGuashaOrderSummaryProps> = ({
  selectedSubscription,
}) => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)

  return (
    <>
      <S.Title>{t('checkoutGuasha.title')}</S.Title>
      <S.GuashaProductWrapper>
        <S.GuashaImageWrapper
          image={
            IN_APP_PRODUCTS_DATA[selectedSubscription.id].orderSummaryImage
          }
        />
        <S.GuashaProductText>
          {t(IN_APP_PRODUCTS_DATA[selectedSubscription.id].name)}
        </S.GuashaProductText>
        <S.GuashaPrice>
          {CURRENCY_SYMBOLS[currency]}
          {selectedSubscription.trialPrices.fullPrice}
        </S.GuashaPrice>
      </S.GuashaProductWrapper>
      <S.SubtotalWrapper>
        <S.SubtotalLabel>{t('checkoutGuasha.subtotalLabel')}</S.SubtotalLabel>
        <S.SubtotalValue>
          {CURRENCY_SYMBOLS[currency]}
          {selectedSubscription.trialPrices.fullPrice}
        </S.SubtotalValue>
      </S.SubtotalWrapper>
      <S.ShippingWrapper>
        <S.ShippingText>{t('checkoutGuasha.shippingLabel')}</S.ShippingText>
        <S.ShippingText>{t('checkoutGuasha.free')}</S.ShippingText>
      </S.ShippingWrapper>
      <S.TotalWrapper>
        <S.TotalText>{t('checkoutGuasha.totalLabel')}</S.TotalText>
        <S.TotalAmount>
          <S.TotalText>
            {CURRENCY_SYMBOLS[currency]}
            {selectedSubscription.trialPrices.fullPrice}
          </S.TotalText>
        </S.TotalAmount>
      </S.TotalWrapper>
    </>
  )
}
