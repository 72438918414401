import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledCosmetologistVsLuvly = {
  TitleContainer: styled.header`
    max-width: 360px;
    padding: 0 10px;
    margin: 0 auto 16px;
  `,
  Description: styled.div`
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: ${Color.DARK};
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  ImageContainer: styled.div`
    aspect-ratio: 320/246;
  `,
  Graph: styled.img`
    max-width: 100%;
    height: auto;
    margin-bottom: 14px;
  `,
}
