import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { BottomButtonContainer } from 'common-styles'
import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledMentalHealth as S } from './MentalHealth.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const MentalHealth: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const cohortToUse = useCohortToUse()
  const includeNavigation = useIsNavigationOn()
  const { isSeniorCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(answers),
    [answers, handleContinue],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        const isSingleOption =
          value === OPTION_VALUES.NONE_OF_THE_ABOVE ||
          value === OPTION_VALUES.PREFER_NOT_TO_ANSWER

        if (isChecked && isSingleOption) {
          setAnswers([value])

          return
        }

        if (isChecked) {
          setAnswers(
            [...answers, value].filter(
              (item) =>
                item !== OPTION_VALUES.NONE_OF_THE_ABOVE &&
                item !== OPTION_VALUES.PREFER_NOT_TO_ANSWER,
            ),
          )

          return
        }

        setAnswers(answers.filter((item) => item !== value))
      },
    }),
    [answers, pageId],
  )

  return (
    <div>
      <Container>
        <S.TitleContainer isSeniorCohortStyles={isSeniorCohort}>
          <Trans
            i18nKey="onboarding.mentalHealth.question"
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </S.TitleContainer>
        {isSeniorCohort && (
          <S.DescriptionContainer>
            {t`onboarding.mentalHealth.description`}
          </S.DescriptionContainer>
        )}
        <Option
          {...optionProps}
          value={OPTION_VALUES.WAS_STRESSED}
          checked={answers.includes(OPTION_VALUES.WAS_STRESSED)}
        >
          <AnswerButton withCheckbox>
            <Trans
              i18nKey="onboarding.mentalHealth.wasStressed"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </AnswerButton>
        </Option>
        <Option
          {...optionProps}
          value={OPTION_VALUES.HAVE_HAD_INSOMNIA}
          checked={answers.includes(OPTION_VALUES.HAVE_HAD_INSOMNIA)}
        >
          <AnswerButton withCheckbox>
            <Trans
              i18nKey="onboarding.mentalHealth.haveHadInsomnia"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </AnswerButton>
        </Option>
        <Option
          {...optionProps}
          value={OPTION_VALUES.SUFFERING_FROM_ANXIETY}
          checked={answers.includes(OPTION_VALUES.SUFFERING_FROM_ANXIETY)}
        >
          <AnswerButton withCheckbox>
            <Trans
              i18nKey="onboarding.mentalHealth.sufferingFromAnxiety"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </AnswerButton>
        </Option>
        <Option
          {...optionProps}
          value={OPTION_VALUES.FEELING_DEPRESSED}
          checked={answers.includes(OPTION_VALUES.FEELING_DEPRESSED)}
        >
          <AnswerButton withCheckbox>
            <Trans
              i18nKey="onboarding.mentalHealth.feelingDepressed"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </AnswerButton>
        </Option>
        <Option
          {...optionProps}
          value={OPTION_VALUES.NONE_OF_THE_ABOVE}
          checked={answers.includes(OPTION_VALUES.NONE_OF_THE_ABOVE)}
        >
          <AnswerButton withCheckbox>
            {t`onboarding.mentalHealth.noneOfTheAbove`}
          </AnswerButton>
        </Option>
        <Option
          {...optionProps}
          value={OPTION_VALUES.PREFER_NOT_TO_ANSWER}
          checked={answers.includes(OPTION_VALUES.PREFER_NOT_TO_ANSWER)}
        >
          <AnswerButton withCheckbox>
            {t`onboarding.mentalHealth.preferNotToAnswer`}
          </AnswerButton>
        </Option>
      </Container>
      {includeNavigation ? (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answers.length}
        />
      ) : (
        <BottomButtonContainer>
          <Button onClick={handleNextClick} disabled={!answers.length}>
            {t`actions.continue`}
          </Button>
        </BottomButtonContainer>
      )}
    </div>
  )
}
