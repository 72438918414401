import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledWellnessBundleBenefits = {
  Wrapper: styled.div`
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 16px;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  `,
  ListItem: styled.li`
    position: relative;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
  `,
  IconWrapper: styled.div`
    min-width: 48px;
    height: 48px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 130, 118, 0.16);
    margin-right: 16px;
  `,
  Icon: styled.img`
    width: 24px;
    height: 24px;
  `,
  BenefitContent: styled.div``,
  Benefit: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    color: ${Color.BLUE_DARK};
  `,
  BenefitDescription: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};
  `,
}
