import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Option } from 'components/Option'

import { unsubscribeUserByTokenAction } from 'root-redux/actions/user'
import { TAppDispatch } from 'root-redux/store/store'

import { UnsubscribeReason } from 'modules/unsubscribe/constants'

import { eventLogger } from 'services/eventLogger.service'

import { OptionType } from 'root-constants'

import { StyledReasonForCancellation as S } from './ReasonForCancellation.styles'

export const ReasonForCancellation: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const optionProps = useMemo(
    () => ({
      name: 'reason',
      type: OptionType.RADIO,
      onChange: (value) => {
        dispatch(unsubscribeUserByTokenAction(value))
      },
    }),
    [dispatch],
  )

  useEffect(() => {
    eventLogger.logCancelInstructionSelected()
  }, [])

  return (
    <>
      <S.Title>{t`unsubscribe.reasonForCancellation.title`}</S.Title>

      <S.Options>
        <Option {...optionProps} value={UnsubscribeReason.NO_IMPROVEMENTS}>
          <S.OptionContent>
            {t`unsubscribe.reasonForCancellation.noImprovements`}
          </S.OptionContent>
        </Option>

        <Option {...optionProps} value={UnsubscribeReason.NOT_HAPPY}>
          <S.OptionContent>
            {t`unsubscribe.reasonForCancellation.notHappy`}
          </S.OptionContent>
        </Option>

        <Option {...optionProps} value={UnsubscribeReason.PROGRAM_OVERWHELMED}>
          <S.OptionContent>
            {t`unsubscribe.reasonForCancellation.programOverwhelmed`}
          </S.OptionContent>
        </Option>

        <Option {...optionProps} value={UnsubscribeReason.HIGH_PRICE}>
          <S.OptionContent>{t`unsubscribe.reasonForCancellation.highPrice`}</S.OptionContent>
        </Option>

        <Option {...optionProps} value={UnsubscribeReason.OTHER}>
          <S.OptionContent>{t`unsubscribe.reasonForCancellation.other`}</S.OptionContent>
        </Option>
      </S.Options>
    </>
  )
}
