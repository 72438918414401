import guidePicture1 from 'assets/images/hair-care-guide-program-1.png'
import guidePicture2 from 'assets/images/hair-care-guide-program-2.png'
import guidePicture3 from 'assets/images/hair-care-guide-program-3.png'

export const GUIDE_PROGRAM_PICTURES = [
  guidePicture1,
  guidePicture2,
  guidePicture3,
]

export const STORIES = [
  {
    name: 'upsellHairCare.story1.name',
    date: 'upsellHairCare.story1.date',
    title: 'upsellHairCare.story1.title',
    text: 'upsellHairCare.story1.text',
  },
  {
    name: 'upsellHairCare.story2.name',
    date: 'upsellHairCare.story2.date',
    title: 'upsellHairCare.story2.title',
    text: 'upsellHairCare.story2.text',
  },
  {
    name: 'upsellHairCare.story3.name',
    date: 'upsellHairCare.story3.date',
    title: 'upsellHairCare.story3.title',
    text: 'upsellHairCare.story3.text',
  },
  {
    name: 'upsellHairCare.story4.name',
    date: 'upsellHairCare.story4.date',
    title: 'upsellHairCare.story4.title',
    text: 'upsellHairCare.story4.text',
  },
]
