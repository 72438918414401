import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  INITIAL_PROGRESS_VALUE,
  PROGRESS_SECTION_STEP,
} from 'components/PostPaymentProgressBar/constants'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectIsPostPaymentProgressbarStatic,
} from 'root-redux/selects/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { PageId, UPSELL_PAGES_MAP } from 'page-constants'
import { Cohort } from 'root-constants'

import {
  PROGRESS_BAR_STEPS_MAP,
  UPSELL_PROGRESS_BAR_STEPS_MAP,
} from '../../constants/progressSteps'
import { StyledPostPaymentProgressBar as S } from './PostPaymentProgressBar.styles'

export const PostPaymentProgressBar: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = (parentCohort || cohort) as Cohort
  const currentPageId = useMemo(
    () => getPageIdFromPathName(pathname),
    [pathname],
  )
  const isProgressBarStatic = useSelector(selectIsPostPaymentProgressbarStatic)

  const stepsMap = useMemo(
    () =>
      UPSELL_PAGES_MAP[cohortToUse].length
        ? UPSELL_PROGRESS_BAR_STEPS_MAP[cohortToUse] ||
          UPSELL_PROGRESS_BAR_STEPS_MAP[Cohort.QUIZ_2]
        : PROGRESS_BAR_STEPS_MAP[cohortToUse] ||
          PROGRESS_BAR_STEPS_MAP[Cohort.QUIZ_2],
    [cohortToUse],
  )

  const currentSectionIndex = useMemo(
    () =>
      stepsMap.findIndex(
        (block) => !!block.find((step) => step.id === currentPageId),
      ),
    [stepsMap, currentPageId],
  )

  return (
    <S.Wrapper isStatic={isProgressBarStatic}>
      {stepsMap.map(([{ id, text }], index, arr) => {
        const progressValue =
          currentSectionIndex >= index ? arr.length : INITIAL_PROGRESS_VALUE
        const nextSectionIndex = currentSectionIndex + PROGRESS_SECTION_STEP

        return (
          <S.StepBlock key={id}>
            <S.Step
              isActive={nextSectionIndex === index}
              isPassed={currentSectionIndex >= index}
            >
              <S.StepText>
                <Trans i18nKey={text} components={[<br />]} />
              </S.StepText>
            </S.Step>
            <S.StepBlockProgress
              value={progressValue}
              max={arr.length}
              pointsNumber={arr.length}
            />
          </S.StepBlock>
        )
      })}
      <S.Step isActive={currentPageId === PageId.DOWNLOAD}>
        <S.StepText>{t('login.accessPlan')}</S.StepText>
      </S.Step>
    </S.Wrapper>
  )
}
