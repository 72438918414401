import { AnswerButtonHeight } from 'components/AnswerButton'

import { Cohort } from 'root-constants'

export const DEFAULT_QUESTION =
  'Do you have something that will motivate you to do face yoga exercises and stick to a skin care routine?'

export const COHORT_QUESTIONS_MAP = {
  [Cohort.LUVLY_COSMETIC_SCANNER]:
    'What motivates you to stick to a skincare routine?',
  [Cohort.LUVLY_CHAT]: 'What motivates you to do face yoga and skin care?',
}

export const enum OPTION_VALUES {
  WANT_TO_LOOK_YOUNGER = 'want_to_look_younger',
  WANT_TO_USE_LESS_COSMETICS = 'want_to_use_less_cosmetics',
  WANT_TO_IMPRESS_PEOPLE = 'want_to_impress_people',
  WANT_TO_FEEL_MORE_CONFIDENT = 'want_to_feel_more_confident',
  AFRAID_MY_PARTNER_BREAK_UP_WITH_ME = 'afraid_my_partner_break_up_with_me',
  WANT_TO_PREVENT_AGING = 'want_to_prevent_aging',
  WANT_TO_CREATE_MY_BEAUTY_RITUALS = 'want_to_create_my_beauty_rituals',
  OTHER = 'other',
}

export const MOTIVATION_DEFAULT_OPTIONS = [
  {
    value: OPTION_VALUES.WANT_TO_LOOK_YOUNGER,
    text: 'onboarding.motivation.wantToLookYounger',
    height: AnswerButtonHeight.L,
  },
  {
    value: OPTION_VALUES.WANT_TO_USE_LESS_COSMETICS,
    text: 'onboarding.motivation.wantToUseLessCosmetics',
  },
  {
    value: OPTION_VALUES.WANT_TO_IMPRESS_PEOPLE,
    text: 'onboarding.motivation.wantToImpressPeople',
    height: AnswerButtonHeight.L,
  },
  {
    value: OPTION_VALUES.AFRAID_MY_PARTNER_BREAK_UP_WITH_ME,
    text: 'onboarding.motivation.afraidMyPartnerBreakUpWithMe',
    height: AnswerButtonHeight.L,
  },
  {
    value: OPTION_VALUES.WANT_TO_PREVENT_AGING,
    text: 'onboarding.motivation.wantToPreventAging',
  },
  {
    value: OPTION_VALUES.WANT_TO_CREATE_MY_BEAUTY_RITUALS,
    text: 'onboarding.motivation.wantToCreateMyBeautyRituals',
    height: AnswerButtonHeight.L,
  },
]

export const MOTIVATION_COSMETIC_SCANNER_OPTIONS = [
  {
    value: OPTION_VALUES.WANT_TO_LOOK_YOUNGER,
    text: 'onboarding.motivation.wantToLookYounger_cosmetic',
  },
  {
    value: OPTION_VALUES.WANT_TO_USE_LESS_COSMETICS,
    text: 'onboarding.motivation.wantToUseLessCosmetics',
  },
  {
    value: OPTION_VALUES.WANT_TO_IMPRESS_PEOPLE,
    text: 'onboarding.motivation.wantToImpressPeople',
  },
  {
    value: OPTION_VALUES.WANT_TO_CREATE_MY_BEAUTY_RITUALS,
    text: 'onboarding.motivation.wantToCreateMyBeautyRituals',
  },
]

export const MOTIVATION_INTRO_2T_OPTIONS = [
  {
    value: OPTION_VALUES.WANT_TO_LOOK_YOUNGER,
    text: 'onboarding.motivation.wantToLookYounger',
  },
  {
    value: OPTION_VALUES.WANT_TO_USE_LESS_COSMETICS,
    text: 'onboarding.motivation.wantToUseLessCosmetics',
  },
  {
    value: OPTION_VALUES.WANT_TO_IMPRESS_PEOPLE,
    text: 'onboarding.motivation.wantToImpressPeople',
  },
  {
    value: OPTION_VALUES.WANT_TO_CREATE_MY_BEAUTY_RITUALS,
    text: 'onboarding.motivation.wantToCreateMyBeautyRituals',
  },
]

export const LIKE_CHAT_MOTIVATION_OPTIONS = [
  {
    value: OPTION_VALUES.WANT_TO_LOOK_YOUNGER,
    text: 'onboarding.motivation.wantToLookYounger',
  },
  {
    value: OPTION_VALUES.WANT_TO_USE_LESS_COSMETICS,
    text: 'onboarding.motivation.wantToUseLessCosmetics',
  },
  {
    value: OPTION_VALUES.WANT_TO_IMPRESS_PEOPLE,
    text: 'onboarding.motivation.wantToImpressPeople',
  },
  {
    value: OPTION_VALUES.AFRAID_MY_PARTNER_BREAK_UP_WITH_ME,
    text: 'onboarding.motivation.afraidMyPartnerBreakUpWithMe',
  },
  {
    value: OPTION_VALUES.WANT_TO_PREVENT_AGING,
    text: 'onboarding.motivation.wantToPreventAging',
  },
  {
    value: OPTION_VALUES.WANT_TO_CREATE_MY_BEAUTY_RITUALS,
    text: 'onboarding.motivation.wantToCreateMyBeautyRituals',
  },
  {
    value: OPTION_VALUES.OTHER,
    text: 'onboarding.motivation.other',
  },
]

export const MOTIVATION_OPTIONS_MAP = {
  [Cohort.LUVLY_COSMETIC_SCANNER]: MOTIVATION_COSMETIC_SCANNER_OPTIONS,
  [Cohort.LUVLY_INTRO2T]: MOTIVATION_INTRO_2T_OPTIONS,
}

export const MOTIVATION_ANSWERS_MAP = {
  [OPTION_VALUES.WANT_TO_LOOK_YOUNGER]:
    'onboarding.motivation.wantToLookYounger',
  [OPTION_VALUES.WANT_TO_USE_LESS_COSMETICS]:
    'onboarding.motivation.wantToUseLessCosmetics',
  [OPTION_VALUES.WANT_TO_IMPRESS_PEOPLE]:
    'onboarding.motivation.wantToImpressPeople',
  [OPTION_VALUES.AFRAID_MY_PARTNER_BREAK_UP_WITH_ME]:
    'onboarding.motivation.afraidMyPartnerBreakUpWithMe',
  [OPTION_VALUES.WANT_TO_PREVENT_AGING]:
    'onboarding.motivation.wantToPreventAging',
  [OPTION_VALUES.WANT_TO_CREATE_MY_BEAUTY_RITUALS]:
    'onboarding.motivation.wantToCreateMyBeautyRituals',
  [OPTION_VALUES.OTHER]: 'onboarding.motivation.other',
}
