export const QUESTION = 'What is your face shape?'

export const OPTION_VALUES = {
  OVAL: 'oval',
  SQUARE: 'square',
  ROUND: 'round',
  TRIANGLE: 'triangle',
  HEART: 'heart',
  RECTANGLE: 'rectangle',
}
