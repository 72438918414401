import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'
import lottie from 'lottie-web/build/player/lottie_light'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { selectAnswers, selectUserGoal } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StickyButtonContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { CDN_FOLDER_LINK, UserGoal } from 'root-constants'

import { StyledOccasionResult as S } from './OccasionResult.styles'
import {
  DAYS_TO_GOAL,
  GRAPH_PATH,
  MONTHS_NUMBERS_TO_DISPLAY,
  NO_OCCASION,
  QUESTION,
} from './constants'

export const OccasionResult: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const userGoal = useSelector(selectUserGoal)
  const userAnswers = useSelector(selectAnswers)

  const animationContainerRef = useRef<HTMLDivElement>(null)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const goalDate = useMemo(() => {
    const currentDate = dayjs()
    return dayjs(currentDate.add(DAYS_TO_GOAL, 'day')).format('MMM DD')
  }, [])

  const monthsRange = useMemo(() => {
    return MONTHS_NUMBERS_TO_DISPLAY.map((addedMonth) => {
      const currentDate = dayjs()
      return dayjs(currentDate.add(addedMonth, 'month')).format('MMM')
    })
  }, [])

  const userOccasion = useMemo(
    () => userAnswers?.[PageId.OCCASION] || NO_OCCASION,
    [userAnswers],
  )

  useEffect(() => {
    if (animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        path: `${CDN_FOLDER_LINK}${GRAPH_PATH}_${
          userGoal || UserGoal.REDUCE_FACE_FAT
        }.json`,
        loop: false,
        autoplay: true,
      })
    }

    return () => lottie.destroy()
  }, [userGoal])

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <S.Root>
      <Container fields={16}>
        <S.Title>{t('onboarding.occasionResult.question')}</S.Title>
        <S.Description>
          {t('onboarding.occasionResult.description')}
        </S.Description>
        <S.Goal>
          <Trans
            i18nKey="onboarding.occasionResult.userGoalSelected"
            context={userGoal || UserGoal.REDUCE_FACE_FAT}
          />{' '}
          {goalDate}
        </S.Goal>

        <S.Occasion>
          <Trans
            i18nKey="onboarding.occasionResult.userOccasionSelected"
            context={userOccasion || NO_OCCASION}
          />
        </S.Occasion>

        <S.Graph>
          <S.AnimationContainer ref={animationContainerRef} />
          <S.Intervals>
            {!!monthsRange.length &&
              monthsRange.map((month) => <span key={month}>{month}</span>)}
          </S.Intervals>
        </S.Graph>
        <StickyButtonContainer customBackground="transparent">
          <Button onClick={handleNextClick}>{t('actions.continue')}</Button>
        </StickyButtonContainer>
      </Container>
    </S.Root>
  )
}
