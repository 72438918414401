import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LiveChatWidget } from '@livechat/widget-react'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { sendUserConfigAction } from 'root-redux/actions/user'
import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import {
  selectGeneralSubscriptionDuration,
  selectGeneralSubscriptionPrice,
  selectGeneralSubscriptionPriceId,
  selectGeneralSubscriptionTrialPeriod,
  selectGeneralSubscriptionTrialPrice,
  selectUpsellGuide,
  selectUpsellProduct,
  selectUserCountryCode,
  selectUserPaymentCurrency,
  selectUserPaymentSystem,
  selectUserStatus,
} from 'root-redux/selects/user'

import { useLiveChatAvailability } from 'hooks/useLiveChatAvailability'

import { usePurchaseStore } from 'modules/purchase/hooks'
import {
  selectIsSubscriptionUpgraded,
  selectUpgradeSubscriptionPrice,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PageId } from 'page-constants'
import {
  Cohort,
  FREE_USER_GROUP_ID,
  INTRO_EMAIL_COHORTS,
  LIVE_CHAT_PUBLIC_KEY,
  LiveChatStatus,
  PAID_USER_GROUP_ID,
} from 'root-constants'

dayjs.extend(utc)
dayjs.extend(tz)

export const LiveChat: React.FC = () => {
  const [isNotFirstOpen, setIsNotFirstOpen] = useState(false)

  const dispatch = useDispatch()
  const userAnswersCollection = useSelector(selectAnswers)
  const paymentCurrency = useSelector(selectUserPaymentCurrency)
  const price = useSelector(selectGeneralSubscriptionPrice)
  const priceId = useSelector(selectGeneralSubscriptionPriceId)
  const subscriptionDuration = useSelector(selectGeneralSubscriptionDuration)
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)
  const trialPrice = useSelector(selectGeneralSubscriptionTrialPrice)
  const upsellProduct = useSelector(selectUpsellProduct)
  const upsellGuide = useSelector(selectUpsellGuide)
  const amountToPay = useSelector(selectUpgradeSubscriptionPrice)
  const isUpgraded = useSelector(selectIsSubscriptionUpgraded)
  const paymentSystem = useSelector(selectUserPaymentSystem)
  const userCountryCode = useSelector(selectUserCountryCode)
  const userStatus = useSelector(selectUserStatus)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort

  const { email, uuid } = usePurchaseStore()

  const { isLiveChatVisible } = useLiveChatAvailability()

  const isEmailCohort = useMemo(
    () => INTRO_EMAIL_COHORTS.includes(cohortToUse as Cohort),
    [cohortToUse],
  )

  const isSubsDetailsRequired = useMemo(() => {
    return cohortToUse !== Cohort.LUVLY_EMAIL_INTRO_FAST
  }, [cohortToUse])

  const groupId = useMemo(
    () =>
      userStatus?.hasSubscription ? PAID_USER_GROUP_ID : FREE_USER_GROUP_ID,
    [userStatus],
  )

  useEffect(() => {
    const emailCohortVars = {
      email_user: !!isEmailCohort,
    }

    const subscriptionVars = userStatus?.hasSubscription &&
      isSubsDetailsRequired && {
        subscription_price: price.toString(),
        subscription_duration: subscriptionDuration,
        price_id: priceId,
        trial_period: `${trialPeriodDays}`,
        trial_price: `${trialPrice}`,
        payment_currency: paymentCurrency,
        payment_system: paymentSystem,
        upsell_product: upsellProduct || '',
        upsell_guide: upsellGuide || '',
      }

    window.LiveChatWidget.call('update_session_variables', {
      email,
      username: userAnswersCollection?.[PageId.NAME] || 'No username',
      ...subscriptionVars,
      ...emailCohortVars,
    })

    dispatch(
      sendUserConfigAction({
        email,
        username: userAnswersCollection?.[PageId.NAME] || 'No username',
      }),
    )
  }, [
    dispatch,
    paymentCurrency,
    email,
    price,
    priceId,
    subscriptionDuration,
    trialPeriodDays,
    trialPrice,
    userAnswersCollection,
    isUpgraded,
    amountToPay,
    paymentSystem,
    userCountryCode,
    userStatus,
    isEmailCohort,
    isSubsDetailsRequired,
    upsellProduct,
    upsellGuide,
  ])

  useEffect(() => {
    if (isLiveChatVisible) {
      window.LiveChatWidget.call('update_session_variables', {
        livechat_opened: true,
      })

      dispatch(
        sendUserConfigAction({
          livechat_opened: true,
        }),
      )

      window.LiveChatWidget.call('maximize')
    } else {
      window.LiveChatWidget.call('hide')
    }
  }, [dispatch, isLiveChatVisible])

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>
    if (isLiveChatVisible) {
      timerId = setTimeout(() => {
        document.documentElement.style.setProperty(
          '--livechat-visibility',
          'block',
        )

        eventLogger.logLiveChatOpened()
        googleAnalyticsLogger.logLiveChatOpened()

        setIsNotFirstOpen(true)
      }, 1000)
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [isLiveChatVisible])

  const handleHideGreeting = useCallback(() => {
    eventLogger.logLiveChatClosed()
    googleAnalyticsLogger.logLiveChatClosed()
  }, [])

  const handleChatVisibilityChanged = useCallback(
    ({ visibility }) => {
      if (!isLiveChatVisible) return

      if (visibility === LiveChatStatus.MINIMIZED) {
        eventLogger.logLiveChatClosed()
        googleAnalyticsLogger.logLiveChatClosed()
      }

      if (isNotFirstOpen && visibility === LiveChatStatus.MAXIMIZED) {
        eventLogger.logLiveChatOpened()
        googleAnalyticsLogger.logLiveChatOpened()
      }
    },
    [isLiveChatVisible, isNotFirstOpen],
  )

  return (
    <LiveChatWidget
      group={groupId}
      license={LIVE_CHAT_PUBLIC_KEY}
      customerEmail={uuid}
      customerName={userAnswersCollection?.[PageId.NAME] || 'No username'}
      visibility="maximized"
      onGreetingHidden={handleHideGreeting}
      onVisibilityChanged={handleChatVisibilityChanged}
    />
  )
}
