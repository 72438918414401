import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { AnswerButton, AnswerButtonHeight } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import saggerFace from 'assets/images/sagger-face.png'
import sinkerFace from 'assets/images/sinker-face.png'
import wrinklerFace from 'assets/images/wrinkler-face.png'

import { goBack, replaceHistory } from 'browser-history'
import { OptionType } from 'root-constants'

import { StyledAgingType as S } from './AgingType.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const AgingType: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const isPageAvailable = useFeatureIsOn('lv_313_aging_type_test')
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const includeNavigation = useIsNavigationOn()

  const { isSeniorCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useLayoutEffect(() => {
    if (!isPageAvailable) replaceHistory(nextPagePath)
  }, [isPageAvailable, nextPagePath])

  const handleNextClick = useCallback(
    () => handleContinue(answer),
    [answer, handleContinue],
  )

  const handleAnswerChange = useCallback(
    (value: string) => {
      if (includeNavigation) {
        setAnswer(value)
        return
      }

      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, includeNavigation, setIsAnswersDisabled],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: handleAnswerChange,
    }),
    [handleAnswerChange, pageId],
  )

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <PageTitle>
            <Trans i18nKey="onboarding.agingType.question" />
          </PageTitle>
        </S.TitleContainer>

        <S.OptionsContainer>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.SINKER}
          >
            <AnswerButton
              iconSrc={sinkerFace}
              iconHeight={48}
              iconWidth={48}
              {...(isSeniorCohort && { height: AnswerButtonHeight.L })}
            >
              <Trans i18nKey="onboarding.agingType.sinker" />
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.SAGGER}
          >
            <AnswerButton
              iconHeight={48}
              iconWidth={48}
              iconSrc={saggerFace}
              {...(isSeniorCohort && { height: AnswerButtonHeight.L })}
            >
              <Trans i18nKey="onboarding.agingType.sagger" />
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.WRINKLER}
          >
            <AnswerButton
              iconHeight={48}
              iconWidth={48}
              iconSrc={wrinklerFace}
              {...(isSeniorCohort && { height: AnswerButtonHeight.L })}
            >
              <Trans i18nKey="onboarding.agingType.wrinkler" />
            </AnswerButton>
          </Option>
        </S.OptionsContainer>
      </Container>
      {includeNavigation && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
