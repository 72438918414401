import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import {
  selectAnswers,
  selectCurrentVariantCohort,
  selectLanguage,
  selectUserGoal,
} from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'

import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  Cohort,
  Gender,
  I18N_CONTEXT_COHORT_MAP,
  Locale,
  UserGoal,
} from 'root-constants'

import {
  LevelIndication,
  StyledLongPaywallUserTarget as S,
} from './LongPaywallUserTarget.styles'
import {
  ANIMATION_PATH,
  LEVEL_INDICATION_BLANK,
  USER_GOAL_CONFIG_MAP,
} from './constants'

export const LongPaywallUserTarget: React.FC = () => {
  const { t } = useTranslation()
  const userGoal = useSelector(selectUserGoal)
  const language = useSelector(selectLanguage)
  const cohort = useSelector(selectCurrentVariantCohort)
  const userAnswers = useSelector(selectAnswers)
  const { isLuvlyIntroFastCohort, isSeniorCohort, isUnisexCohort } =
    useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()

  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  const isEsLocale = useMemo(() => language === Locale.SPANISH, [language])

  const isCancelOfferRoute = useIsCurrentRoute(
    PageId.PURCHASE_INTRO_CANCEL_OFFER,
  )

  const animationPath = useMemo(() => {
    if (isUnisexCohort) {
      return userAnswers?.[PageId.GENDER] === Gender.MALE
        ? `${CDN_FOLDER_LINK}${ANIMATION_PATH}_man.json`
        : `${CDN_FOLDER_LINK}${ANIMATION_PATH}_en.json`
    }

    if (cohort === Cohort.LUVLY_19) {
      return `${CDN_FOLDER_LINK}${ANIMATION_PATH}_india.json`
    }

    if (isEsLocale && isLuvlyIntroFastCohort) {
      return `${CDN_FOLDER_LINK}${ANIMATION_PATH}_${language}.json`
    }

    return `${CDN_FOLDER_LINK}${ANIMATION_PATH}_en.json`
  }, [
    cohort,
    isEsLocale,
    isLuvlyIntroFastCohort,
    isUnisexCohort,
    language,
    userAnswers,
  ])

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        path: animationPath,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [animationPath, isEsLocale, isLuvlyIntroFastCohort, language])

  const userGoalConfig = useMemo(
    () => USER_GOAL_CONFIG_MAP[userGoal || UserGoal.WRINKLE_FREE_SKIN],
    [userGoal],
  )

  return (
    <S.Root>
      {!isSeniorCohort ? (
        <S.AnimationContainer
          data-testid="paywall-target-img"
          ref={animationContainerRef}
          isCancelOfferRoute={isCancelOfferRoute}
        />
      ) : (
        <S.ImageContainer />
      )}
      <S.Wrapper>
        <S.FacialCondition>
          <S.Subtitle>{t('purchase7.facialCondition.nowLabel')}</S.Subtitle>
          <S.LevelContent>
            <S.UserGoal>
              <Trans
                i18nKey={userGoalConfig.goalText}
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
              />
            </S.UserGoal>
            <S.LevelIndicationWrapper>
              {LEVEL_INDICATION_BLANK.map((item) => (
                <S.LevelIndicationUnit
                  key={item}
                  type={
                    userGoalConfig.currentLevelIndicator >= item
                      ? LevelIndication.CURRENT
                      : LevelIndication.DEFAULT
                  }
                />
              ))}
            </S.LevelIndicationWrapper>
          </S.LevelContent>
        </S.FacialCondition>
        <S.FacialCondition>
          <S.Subtitle>{t('purchase7.facialCondition.goalLabel')}</S.Subtitle>
          <S.LevelContent>
            <S.UserGoal>
              <Trans
                i18nKey={userGoalConfig.goalText}
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
              />
            </S.UserGoal>
            <S.LevelIndicationWrapper>
              {LEVEL_INDICATION_BLANK.map((item) => (
                <S.LevelIndicationUnit
                  key={item}
                  type={
                    userGoalConfig.goalLevelIndicator >= item
                      ? LevelIndication.GOAL
                      : LevelIndication.DEFAULT
                  }
                />
              ))}
            </S.LevelIndicationWrapper>
          </S.LevelContent>
        </S.FacialCondition>
      </S.Wrapper>
    </S.Root>
  )
}
