import styled from 'styled-components'

import { Container } from 'components/Container'

import chevronRight from 'assets/images/chevron-right-gray.png'

import { Color } from 'root-constants'

export const StyledOccasion = {
  Root: styled(Container)`
    padding-bottom: 50px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    color: ${Color.DARK};
    margin-bottom: 16px;
    text-align: center;
  `,
  Description: styled.p`
    font-size: 17px;
    line-height: 22px;
    color: ${Color.LIGHT};
    margin-bottom: 16px;
    text-align: center;
  `,
  AnswerContent: styled.div`
    display: block;
    position: relative;
    width: 100%;

    &::after {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-image: url(${chevronRight});
      background-size: 24px 24px;
    }
  `,
}
