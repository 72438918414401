import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { PageId } from 'page-constants'

export const useIsCurrentRoute = (routeToCheck: PageId) => {
  const { pathname } = useLocation()

  const currentPageId = useMemo(
    () => getPageIdFromPathName(pathname),
    [pathname],
  )

  return useMemo(
    () => currentPageId === routeToCheck,
    [currentPageId, routeToCheck],
  )
}
