import { AnyAction } from 'redux'

import {
  RESET_EMAIL_ERROR_MESSAGE,
  RESET_PASSWORD_ERROR_MESSAGE,
  SET_EMAIL_ERROR_MESSAGE,
  SET_PASSWORD_ERROR_MESSAGE,
} from './actions'

export interface IUnsubscribeLoginState {
  emailErrorMessage: string | null
  passwordErrorMessage: string | null
}

const initialState: IUnsubscribeLoginState = {
  emailErrorMessage: null,
  passwordErrorMessage: null,
}

export function unsubscribeLoginReducer(
  state = initialState,
  { type, payload }: AnyAction,
): IUnsubscribeLoginState {
  switch (type) {
    case SET_EMAIL_ERROR_MESSAGE: {
      return { ...state, emailErrorMessage: payload }
    }
    case RESET_EMAIL_ERROR_MESSAGE: {
      return { ...state, emailErrorMessage: null }
    }

    case SET_PASSWORD_ERROR_MESSAGE: {
      return { ...state, passwordErrorMessage: payload }
    }
    case RESET_PASSWORD_ERROR_MESSAGE: {
      return { ...state, passwordErrorMessage: null }
    }

    default:
      return state
  }
}
