import { useEffect, useState } from 'react'

import { easeInOutQuart } from 'helpers/animationFunctions'

const frameDuration = 1000 / 60
export const useCounter = (
  isVisible: boolean,
  animation: {
    duration: number
    from?: number
    to?: number
    animationFunction?: (t: number) => number
    callback?: (data: string) => void
  },
): { counter: number; linearCounterValue: number; isFinished: boolean } => {
  const {
    from: countFrom = 0,
    to: countTo = 100,
    duration: animationDuration,
    animationFunction = easeInOutQuart,
    callback,
  } = animation

  const [counterValue, setCounterValue] = useState(countFrom)
  const [linearCounterValue, setLinearCounterValue] = useState(countFrom)

  useEffect(() => {
    if (!isVisible) return
    if (countFrom >= countTo && callback) {
      callback('')
    }

    let frame = 0
    const totalFrames = Math.round(animationDuration / frameDuration)

    const counter = setInterval(() => {
      frame += 1
      setCounterValue(
        Math.round(countTo * animationFunction(frame / totalFrames)),
      )
      setLinearCounterValue(Math.round(countTo * (frame / totalFrames)))
      if (frame !== totalFrames) return
      clearInterval(counter)
      callback && callback('')
    }, frameDuration)

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(counter)
    }
  }, [
    animationDuration,
    animationFunction,
    callback,
    countFrom,
    countTo,
    isVisible,
  ])

  return {
    counter: counterValue,
    linearCounterValue,
    isFinished: counterValue === countTo,
  }
}
