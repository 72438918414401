import styled from 'styled-components'

import {
  StickyButtonContainer as ButtonContainer,
  customBackgroundStyles,
} from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants'

export const StyledAgingTypeFeedback = {
  Root: styled.div`
    padding-top: 16px;
    position: relative;

    ${customBackgroundStyles}
  `,

  ImageContainer: styled.div`
    aspect-ratio: 328/238;
    margin-bottom: 16px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto 16px;
    }
  `,
  Image: styled.img`
    max-width: 100%;
    height: auto;
  `,
  Title: styled.h1`
    color: ${Color.DARK};
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    text-align: center;

    & strong {
      display: inline-block;
      font-weight: 500;
    }
    & strong::first-letter {
      text-transform: uppercase;
    }
  `,
  Description: styled.p`
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 15px;
    color: ${Color.BLUE_DARK};
  `,
  StickyButtonContainer: styled(ButtonContainer)`
    max-width: 320px;
    margin: 0 auto;
  `,
}
