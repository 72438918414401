import guashaImage from 'assets/images/guasha-item.png'
import guashaAndRollerImage from 'assets/images/guasha-roller-item.png'
import summaryGuashaAndRoller from 'assets/images/order-summary-guasha-roller.png'
import summaryGuasha from 'assets/images/order-summary-guasha.png'
import summaryRoller from 'assets/images/order-summary-roller.png'
import rollerImage from 'assets/images/roller-item.png'

export const IN_APP_UPSELL_BOTH_OPTIONS_DISCOUNT = 50
export const IN_APP_UPSELL_SINGLE_OPTION_DISCOUNT = 35
export const IN_APP_CANCEL_UPSELL_BOTH_OPTIONS_DISCOUNT = 60
export const IN_APP_CANCEL_UPSELL_SINGLE_OPTION_DISCOUNT = 45

export const IN_APP_PRODUCTS_DATA = {
  1: {
    image: guashaAndRollerImage,
    name: 'guashaUpsell.subscription.productName1',
    orderSummaryImage: summaryGuashaAndRoller,
    hasLabel: true,
    defaultDiscount: IN_APP_UPSELL_BOTH_OPTIONS_DISCOUNT,
    cancelOfferDiscount: IN_APP_CANCEL_UPSELL_BOTH_OPTIONS_DISCOUNT,
  },
  2: {
    image: guashaImage,
    name: 'guashaUpsell.subscription.productName2',
    orderSummaryImage: summaryGuasha,
    hasLabel: false,
    defaultDiscount: IN_APP_UPSELL_SINGLE_OPTION_DISCOUNT,
    cancelOfferDiscount: IN_APP_CANCEL_UPSELL_SINGLE_OPTION_DISCOUNT,
  },
  3: {
    image: rollerImage,
    name: 'guashaUpsell.subscription.productName3',
    orderSummaryImage: summaryRoller,
    hasLabel: false,
    defaultDiscount: IN_APP_UPSELL_SINGLE_OPTION_DISCOUNT,
    cancelOfferDiscount: IN_APP_CANCEL_UPSELL_SINGLE_OPTION_DISCOUNT,
  },
}
