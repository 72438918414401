import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { addDaysToCurrentDate } from 'helpers/date'

import { PageId } from 'page-constants'
import { CDN_FOLDER_LINK, DAYS_NUMBER } from 'root-constants'

import { StyledUserGoalGraphSenior as S } from './UserGoalGraphSenior.styles'
import { SENIOR_IMAGE_PATH } from './constants'

export const UserGoalGraphSenior: React.FC = () => {
  const language = useSelector(selectLanguage)

  const goalDate = useMemo(() => {
    const daysNumber = DAYS_NUMBER[PageId.SHORT_PROGRAM_DESCRIPTION]
    return addDaysToCurrentDate(daysNumber, language)
  }, [language])

  return (
    <>
      <S.GoalDateContainer>
        <S.Goal>
          <Trans
            i18nKey="onboarding.shortProgramDescription.goalDate_senior"
            values={{
              day: goalDate.day,
              month: goalDate.month,
            }}
            components={[<br />]}
          />
        </S.Goal>
      </S.GoalDateContainer>
      <S.GraphContainer>
        <S.Graph
          src={`${CDN_FOLDER_LINK}${SENIOR_IMAGE_PATH}_${language}.png`}
          alt="graph"
        />
      </S.GraphContainer>
    </>
  )
}
