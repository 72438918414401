export const QUESTION = 'Vegetables'

export const OPTION_VALUES = {
  EVERYDAY: 'everyday',
  OFTEN: 'often',
  SOMETIMES: 'sometimes',
  NEVER: 'never',
}

export const VEGETABLES_ANSWERS_MAP = {
  [OPTION_VALUES.EVERYDAY]: 'onboarding.vegetables.everyday',
  [OPTION_VALUES.OFTEN]: 'onboarding.vegetables.often',
  [OPTION_VALUES.SOMETIMES]: 'onboarding.vegetables.sometimes',
  [OPTION_VALUES.NEVER]: 'onboarding.vegetables.never',
}
