import styled from 'styled-components'

export const StyledFaceShape = {
  TitleContainer: styled.header`
    margin-bottom: 16px;
  `,
  OptionsContainer: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 16px;
  `,
}
