import styled from 'styled-components'

import { BottomButtonContainer as ButtonContainer } from 'common-styles'

export const StyledCareCosmetics = {
  TitleContainer: styled.header`
    margin-bottom: 16px;
  `,
  DescriptionContainer: styled.div`
    margin-bottom: 16px;
  `,
  OptionsContainer: styled.div``,
  BottomButtonContainer: styled(ButtonContainer)`
    padding: 40px 16px;
  `,
}
