import styled from 'styled-components'

import { Color } from 'root-constants'

type TIconProps = {
  iconSrc: string
}

export const StyledUpsellGuashaDescription = {
  Root: styled.div`
    padding-top: 32px;
    border-radius: 32px 32px 0px 0px;
    background-color: ${Color.DEFAULT_BACKGROUND};
    margin: -32px auto 32px;
    position: relative;
    z-index: 2;
  `,
  Title: styled.h3`
    color: ${Color.BLUE_DARK};
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    margin-bottom: 12px;
  `,
  Description: styled.p`
    color: ${Color.LIGHT};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    margin-bottom: 24px;
  `,
  FeaturesWrapper: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
  `,
  Feature: styled.div`
    min-width: 96px;
    max-width: 104px;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &:not(:last-of-type) {
      &::after {
        position: absolute;
        content: '';
        right: -8px;
        top: 10px;
        width: 1px;
        height: 48px;
        background-color: #cacaca;
      }
    }
  `,
  FeatureIcon: styled.div<TIconProps>`
    width: 32px;
    height: 32px;
    background-image: url(${({ iconSrc }) => iconSrc});
  `,
  FeatureText: styled.p`
    height: 28px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
  `,
}
