import styled, { FlattenSimpleInterpolation, css } from 'styled-components'

import {
  commonBackdropStyles,
  commonMenuCloseButtonStyles,
  commonMenuStyles,
} from 'common-styles'
import { Color } from 'root-constants'

import { IntroHeaderColor } from './constants'

export type TIntroHeaderProps = {
  colorTheme: IntroHeaderColor
}

const lightThemeSupportActionStyles = css`
  border: 1px solid ${Color.WHITE};
  color: ${Color.WHITE};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1.5px;
  font-family: 'Open Sans', sans-serif;
`
const darkThemeSupportActionStyles = css`
  font-size: 14px;
  border: 1px solid ${Color.DARK_SECONDARY};
  color: ${Color.DARK_SECONDARY};
`
const transparentThemeSupportActionStyles = css`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1.5px;
  border: 1px solid ${Color.BLUE_DARK};
  color: ${Color.BLUE_DARK};
  padding: 0;
`

const SUPPORT_ACTION_STYLES_MAP: Record<
  IntroHeaderColor,
  FlattenSimpleInterpolation
> = {
  [IntroHeaderColor.DARK]: darkThemeSupportActionStyles,
  [IntroHeaderColor.LIGHT]: lightThemeSupportActionStyles,
  [IntroHeaderColor.TRANSPARENT]: transparentThemeSupportActionStyles,
}

export const StyledIntroHeader = {
  Header: styled.header<TIntroHeaderProps>`
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    padding: 12px 20px;
    align-items: center;

    ${({ colorTheme }) =>
      colorTheme === IntroHeaderColor.DARK &&
      css`
        background-color: ${Color.WHITE};
        z-index: 2;
      `};

    ${({ colorTheme }) =>
      colorTheme === IntroHeaderColor.TRANSPARENT &&
      css`
        background: rgba(255, 255, 255, 0.15);
      `};
  `,
  Logo: styled.img`
    max-width: 58px;
    height: auto;
    vertical-align: top;
    margin: 0 auto 0 0;
  `,
  SupportAction: styled.button<TIntroHeaderProps>`
    background-color: transparent;
    border-radius: 8px;
    margin-right: 11px;
    cursor: pointer;
    text-transform: uppercase;

    a {
      color: inherit;
      padding: 5px 8px;
      line-height: 28px;
    }

    ${({ colorTheme }) => SUPPORT_ACTION_STYLES_MAP[colorTheme]};
  `,
  Burger: styled.button`
    width: 20px;
    height: 17px;
    background: transparent;
    border: 0;
    border-radius: 0;
    overflow: hidden;
    position: relative;
    padding: 0;
    cursor: pointer;
  `,
  BurgerLine: styled.span<TIntroHeaderProps>`
    display: block;
    width: 100%;
    border-radius: 14px;
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;

    &:nth-child(2),
    &:nth-child(3) {
      top: calc(50%);
    }

    ${({ colorTheme }) =>
      colorTheme === IntroHeaderColor.LIGHT
        ? css`
            background-color: ${Color.WHITE};
          `
        : css`
            background-color: ${Color.DARK_SECONDARY};
          `};

    &:last-child {
      top: auto;
      bottom: 0;
    }
  `,
  Backdrop: styled.div`
    ${commonBackdropStyles};
    z-index: 3;
  `,
  Menu: styled.nav`
    ${commonMenuStyles};
  `,
  MenuCloseButton: styled.button`
    ${commonMenuCloseButtonStyles};
  `,
  StyledMenuLinkContainer: styled.div`
    font-size: 20px;
    line-height: 24px;
    color: rgb(45, 50, 64);
    margin-bottom: 35px;

    a {
      color: inherit;
    }
  `,
}
