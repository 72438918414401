export const QUESTION = 'Face yoga: natural facial practice'

export const NATURAL_PRACTICE_OPTIONS = [
  {
    id: '1',
    text: 'onboarding.naturalFacialPractice.option1',
  },
  {
    id: '2',
    text: 'onboarding.naturalFacialPractice.option2',
  },
  {
    id: '3',
    text: 'onboarding.naturalFacialPractice.option3',
  },
]
