import React from 'react'

import spinnerSvg from 'assets/images/sprite/spinner.svg'

import { StyledSpinner as S } from './Spinner.styles'

type TProps = {
  isFullScreen?: boolean
  height?: number
}

export const Spinner: React.FC<TProps> = ({
  isFullScreen = true,
  height = 76,
}) =>
  isFullScreen ? (
    <S.Wrapper>
      <S.Circle svg={spinnerSvg} height={height} />
    </S.Wrapper>
  ) : (
    <S.Circle svg={spinnerSvg} height={height} />
  )
