import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { Input } from 'components/Input'

import { selectIsFetching } from 'root-redux/selects/common'

import { useEmailInputField } from 'hooks/useEmailInputField'
import { usePasswordInputField } from 'hooks/usePasswordInputField'

import { IAppState, TAppDispatchThunk } from 'models/store.model'

import { StyledLoginButton } from 'common-styles'

import {
  loginWithEmailFirebaseAction,
  resetEmailErrorMessageAction,
  resetPasswordErrorMessageAction,
  selectEmailErrorMessage,
  selectPasswordErrorMessage,
} from '../redux'
import { StyledEmailLogin as S } from './EmailLogin.styles'

type TProps = TStateProps & TDispatchProps

const EmailLoginComponent: React.FC<TProps> = ({
  isFetching,
  emailErrorMessage,
  passwordErrorMessage,
  loginWithEmailFirebase,
  resetPasswordErrorMessage,
  resetEmailErrorMessage,
}) => {
  const { t } = useTranslation()

  const [email, setEmail] = useEmailInputField(
    emailErrorMessage,
    resetEmailErrorMessage,
  )
  const [password, setPassword] = usePasswordInputField(
    passwordErrorMessage,
    resetPasswordErrorMessage,
  )

  const isComplete = useMemo(
    () =>
      email.isValid &&
      email.value !== '' &&
      password.isValid &&
      password.value !== '',
    [email.isValid, email.value, password.isValid, password.value],
  )

  return (
    <>
      <S.Inputs>
        <Input
          value={email.value}
          isValid={email.isValid}
          validationText={email.validationText}
          labelName={t`unsubscribe.login.emailPlaceholder`}
          onChange={(e) => {
            setEmail((prevState) => ({ ...prevState, value: e.target.value }))
          }}
        />
        <Input
          value={password.value}
          isValid={password.isValid}
          validationText={password.validationText}
          labelName={t`unsubscribe.login.passwordPlaceholder`}
          hasVisibilityControl
          onChange={(e) => {
            setPassword((prevState) => ({
              ...prevState,
              value: e.target.value,
            }))
          }}
        />
      </S.Inputs>

      <StyledLoginButton.Email
        disabled={!isComplete || isFetching}
        onClick={() => {
          loginWithEmailFirebase(email.value, password.value)
        }}
        data-icon="false"
      >
        {t`unsubscribe.login.buttonText`}
      </StyledLoginButton.Email>
    </>
  )
}

const mapStateToProps = (state: IAppState) => ({
  isFetching: selectIsFetching(state),
  emailErrorMessage: selectEmailErrorMessage(state),
  passwordErrorMessage: selectPasswordErrorMessage(state),
})
const mapDispatchToProps = (dispatch: TAppDispatchThunk<any>) => ({
  loginWithEmailFirebase: (email: string, password: string) =>
    dispatch(loginWithEmailFirebaseAction(email, password)),
  resetEmailErrorMessage: () => dispatch(resetEmailErrorMessageAction()),
  resetPasswordErrorMessage: () => dispatch(resetPasswordErrorMessageAction()),
})

export type TStateProps = ReturnType<typeof mapStateToProps>
export type TDispatchProps = ReturnType<typeof mapDispatchToProps>

export const EmailLogin = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailLoginComponent)
