import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledUpsellPaywallSwitchers = {
  Root: styled.div`
    position: relative;
    padding-bottom: 171px;
  `,
  TitleContainer: styled.div`
    background-color: ${Color.SANDY};
    padding: 24px 20px 18px;
    margin: -16px auto 24px;
    max-width: 360px;

    @media (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: ${MediaBreakpoint.MAX_PHONE}px;
    }
  `,
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px 60px;
  `,
  Title: styled.h1`
    margin: 0 -10px 16px -10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.DARK};
  `,
  Subtitle: styled.h2`
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: ${Color.LIGHT};
    margin-bottom: 22px;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 16px;
  `,
  Details: styled.div`
    position: relative;
    padding: 16px;
    min-height: 217px;
    background: #ffffff;
    border: 1px solid #e8c082;
    border-radius: 20px;
    overflow: hidden;
  `,
  DetailsFooter: styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    background: ${Color.SANDY};
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${Color.DARK};
  `,
  Option: styled.div`
    display: flex;
    margin: 16px 0;

    img {
      margin-right: 16px;
    }
  `,
  DetailedTitle: styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    text-align: center;
  `,
}
