import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { userApi } from 'api'

import {
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserConfigData,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { useCreatePaypalSubscription } from 'hooks/useCreatePaypalSubscription'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'
import { selectCurrency } from 'modules/purchase/redux/selects/common'

import { SNAPCHAT_QUERY_PARAM } from 'root-constants'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export function usePaypalSubscriptionAfterRedirect(): void {
  const uuid = useSelector(selectUUID)
  const currency = useSelector(selectCurrency)
  const email = useSelector(selectUserOnboardingEmail)
  const config = useSelector(selectUserConfigData)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)

  const { isPersonalDataAllowed } = useCookieConsentAnswer()

  const { search } = useLocation()

  const URLParams = new URLSearchParams(search)
  const isSnapchatUser = URLParams.has(SNAPCHAT_QUERY_PARAM)

  const { createSubscription, handleError, handleSuccess } =
    useCreatePaypalSubscription()

  useEffect(() => {
    if (!isSnapchatUser || !config || !uuid) return

    const createPaypalSubscription = async () => {
      const paymentInformation = {
        productId: config.paypalPlanId,
        price: config.subscriptionPrice,
        trialPrice: config.trialPrice,
        trialPeriodDays: config.trialPeriod,
        periodName: config.periodName,
        periodQuantity: config.periodQuantity,
        screenName: config.screenName,
        isTrialActive: !!config.trialPeriod,
        uuid,
        currency,
        email: isPersonalDataAllowed ? email : '',
        paymentMethod: PaymentMethod.PAYPAL,
        stripeAccountId,
        stripeAccountName,
        paymentSystem: PaymentSystem.PAYPAL,
      }

      const response = await createSubscription(paymentInformation.productId)

      if (response.status) {
        let predictedLtv
        const predictedLtvResponse = await userApi.getPredictedLtv({ uuid })
        if (
          !!predictedLtvResponse.success &&
          predictedLtvResponse.status === 200
        ) {
          predictedLtv = predictedLtvResponse.data.predicted_ltv
        }

        handleSuccess({ ...paymentInformation, ...predictedLtv })
      } else {
        handleError({
          ...paymentInformation,
          error: response.data?.error,
        })
      }
    }

    createPaypalSubscription()
  }, [
    config,
    createSubscription,
    currency,
    email,
    handleError,
    handleSuccess,
    isSnapchatUser,
    uuid,
    stripeAccountId,
    stripeAccountName,
    isPersonalDataAllowed,
  ])
}
