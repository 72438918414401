import styled from 'styled-components'

export const StyledSkinType = {
  TitleContainer: styled.header`
    margin-bottom: 16px;
  `,
  OptionsContainer: styled.div`
    & strong {
      font-weight: 500;
    }
  `,
  LinkContainer: styled.div`
    margin-top: 33px;
    text-align: center;
  `,
  Link: styled.a`
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    cursor: pointer;
  `,
}
