export const QUESTION = 'Prepare Plan'
export const REDIRECT_DELAY = 3000

export const SLIDES_INFO = [
  {
    id: '1',
    imagePath: '/images/prepare-plan-img-first',
    textPath: 'onboarding.preparePlan.slideTextFirst',
  },
  {
    id: '2',
    imagePath: '/images/prepare-plan-img-second',
    textPath: 'onboarding.preparePlan.slideTextSecond',
  },
  {
    id: '3',
    imagePath: '/images/prepare-plan-img-third',
    textPath: 'onboarding.preparePlan.slideTextThird',
  },
  {
    id: '4',
    imagePath: '/images/prepare-plan-img-fourth',
    textPath: 'onboarding.preparePlan.slideTextFourth',
  },
  {
    id: '5',
    imagePath: '/images/prepare-plan-img-fifth',
    textPath: 'onboarding.preparePlan.slideTextFifth',
  },
  {
    id: '6',
    imagePath: '/images/prepare-plan-img-sixth',
    textPath: 'onboarding.preparePlan.slideTextSixth',
  },
]
