import styled from 'styled-components'

import { Color } from 'root-constants'

type TWrapperProps = {
  background: string
}

export const StyledWellnessBundleAppGallery = {
  Wrapper: styled.div<TWrapperProps>`
    background: ${({ background }) => `url(${background})`};
    height: 756px;
    padding: 32px 0;
    border-radius: 32px;
    box-shadow: 0px 8px 20px 0px rgba(204, 207, 222, 0.8);
    margin-bottom: 24px;

    & .swiper {
      padding-bottom: 32px;
    }

    & .swiper-pagination-bullets {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background: ${Color.WHITE};
    }
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 24px;
  `,
  AppContainer: styled.div`
    border-radius: 32px;
    box-shadow: 0px 8px 20px 0px rgba(204, 207, 222, 0.8);
    height: 756px;
    padding: 32px 0;
  `,
  AppSubtitle: styled.p`
    color: ${Color.WHITE};
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 12px;
    padding: 0 20px;
  `,
  AppDescription: styled.p`
    color: ${Color.WHITE};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px;
    padding: 0 20px;
  `,
  LabelImage: styled.img`
    margin: 0 auto 32px;
    height: 80px;
    width: auto;
  `,
  ImageWrapper: styled.div`
    height: 380px;
    width: 200px;
  `,
  SlideImage: styled.img`
    display: block;
    width: 100%;
    height: 100%;
  `,
}
