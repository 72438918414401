import styled from 'styled-components'

export const StyledLikeChatExercises = {
  ExercisesLi: styled.li`
    counter-increment: item;
    padding-left: 20px;
    position: relative;
    &::before {
      content: counter(item) '.';
      display: inline-block;
      position: absolute;
      left: 4px;
    }
  `,
}
