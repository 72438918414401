import styled from 'styled-components'

export const StyledLikeChatGoal = {
  ContentContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 65px);
  `,
  AnswerWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  Icon: styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
  `,
}
