import faceScanning from 'assets/images/face-scanning.png'
import getInsights from 'assets/images/get-insights.png'
import personalizedSkincare from 'assets/images/personalized-skincare.png'

export const QUESTION = 'You’re on the right track now!'
export const CONTINUE_BUTTON_TEXT = 'Continue'

export const DIAGNOSIS_STEPS = [
  { id: '1', icon: faceScanning, text: 'skinDiagnosis.startFaceScanning' },
  { id: '2', icon: getInsights, text: 'skinDiagnosis.getInsights' },
  {
    id: '3',
    icon: personalizedSkincare,
    text: 'skinDiagnosis.personalizedSkinCare',
  },
]
