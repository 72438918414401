import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSkipWellnessBundleModal = {
  Root: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    flex-direction: column;
    background-color: rgba(107, 114, 128, 0.75);
  `,
  Content: styled.div`
    margin-top: 24px;
    padding-top: 24px;
    border-radius: 24px 24px 0 0;
    flex: 1;
    background-color: ${Color.WHITE};
    overflow-y: scroll;
  `,
  Title: styled.p`
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 12px;
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.LIGHT};
    text-align: center;
    margin-bottom: 24px;

    & > strong {
      font-weight: 600;
      color: ${Color.PRIMARY};
    }
  `,
  GraphContainer: styled.div`
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 8px 20px 0px #e8e8e8;
    margin-bottom: 16px;
  `,
  ChartWrapper: styled.div`
    position: relative;
    height: 189px;
  `,
  Chart: styled.img``,
  ChartText: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.BLUE_DARK};
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 98px;
  `,
  Note: styled.p`
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: ${Color.LIGHT};
    margin-top: 16px;
  `,
  DiscountContainer: styled.div`
    padding: 16px;
    border-radius: 16px;
    background-color: #f1f5f9;
    margin-bottom: 16px;
  `,
  FeelDiscount: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.LIGHT};
  `,
  ButtonContainer: styled.div`
    padding: 16px 16px;
    border-top: 1px solid ${Color.GRAY_LIGHT};
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    z-index: 2;
    background-color: ${Color.WHITE};
  `,
}
