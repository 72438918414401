import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

export const usePasswordInputField = (
  errorMessage: string | null,
  resetErrorMessage: () => void,
): TInputFieldHook => {
  const { t } = useTranslation()
  const [password, setPassword] = useState<IInputFieldState>({
    value: '',
    isValid: true,
    isFocused: false,
    validationText: '',
    firebaseError: '',
  })

  useEffect(() => {
    if (password.value.length < 8 && password.value !== '') {
      setPassword((prevState) => ({
        ...prevState,
        isValid: false,
        firebaseError: '',
      }))
      return
    }

    setPassword((prevState) => ({
      ...prevState,
      isValid: true,
      firebaseError: '',
    }))
  }, [password.value, t])

  useEffect(() => {
    setPassword((prevState) => ({
      ...prevState,
      validationText:
        !password.isValid && !password.isFocused ? t`login.shortPassword` : '',
    }))
  }, [password.isValid, password.isFocused, t])

  useEffect(() => {
    if (errorMessage) {
      setPassword((prevState) => ({
        ...prevState,
        isValid: false,
        firebaseError: errorMessage,
      }))

      resetErrorMessage()
    }
  }, [errorMessage, resetErrorMessage])

  return [password, setPassword]
}
