import React, { useCallback } from 'react'

export const useScrollToTarget = () => {
  const scrollToTarget = useCallback(
    (target: React.RefObject<HTMLDivElement>) => {
      if (target && target.current) {
        target.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    },
    [],
  )

  return { scrollToTarget }
}
