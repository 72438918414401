import checkIcon from 'assets/images/monochrome-check-circle.png'
import documentIcon from 'assets/images/monochrome-clipboard-document-check.png'
import smileIcon from 'assets/images/monochrome-face-smile.png'

export const WELLNESS_BUNDLE_BENEFITS = [
  {
    icon: checkIcon,
    benefit: 'wellnessBundleUpsell.benefits.benefit1',
    description: 'wellnessBundleUpsell.benefits.description1',
  },
  {
    icon: documentIcon,
    benefit: 'wellnessBundleUpsell.benefits.benefit2',
    description: 'wellnessBundleUpsell.benefits.description2',
  },
  {
    icon: smileIcon,
    benefit: 'wellnessBundleUpsell.benefits.benefit3',
    description: 'wellnessBundleUpsell.benefits.description3',
  },
]
