import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton, AnswerButtonHeight } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { selectAnswers } from 'root-redux/selects/common'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'
import { Gender, I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledSkinCareRoutineDescription as S } from './SkinCareRoutineDescription.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const SkinCareRoutineDescription: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const cohortToUse = useCohortToUse()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleContinue, pageId, handleChange, setIsAnswersDisabled],
  )

  const [gender, age] = useMemo(() => {
    const userGender =
      userAnswers?.[PageId.GENDER] === Gender.MALE
        ? t`commonComponents.males`
        : t`commonComponents.females`
    const userAge = userAnswers?.[PageId.AGE] || 'age'

    return [userGender, userAge]
  }, [t, userAnswers])

  return (
    <div>
      <Container>
        <S.TitleContainer>
          {t('onboarding.skinCareRoutineDescription.question', {
            gender,
            age,
          })}
        </S.TitleContainer>
        <S.OptionsContainer textAlign="center">
          <Option
            disabled={isAnswersDisabled}
            {...optionProps}
            value={OPTION_VALUES.IS_NOT_GREAT}
          >
            <AnswerButton height={AnswerButtonHeight.M}>
              {t`onboarding.skinCareRoutineDescription.isNotGreat`}
            </AnswerButton>
          </Option>
          <Option
            disabled={isAnswersDisabled}
            {...optionProps}
            value={OPTION_VALUES.CAN_BE_BETTER}
          >
            <AnswerButton height={AnswerButtonHeight.M}>
              {t`onboarding.skinCareRoutineDescription.canBeBetter`}
            </AnswerButton>
          </Option>
          <Option
            disabled={isAnswersDisabled}
            {...optionProps}
            value={OPTION_VALUES.PRACTICE_PROPER_SKIN_CARE_ROUTINE}
          >
            <AnswerButton height={AnswerButtonHeight.M}>
              <Trans
                i18nKey="onboarding.skinCareRoutineDescription.practiceProperSkinCareRoutine"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
                components={[<br />]}
              />
            </AnswerButton>
          </Option>
        </S.OptionsContainer>
      </Container>
    </div>
  )
}
