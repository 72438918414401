import React from 'react'
import { useTranslation } from 'react-i18next'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import starSvg from 'assets/images/sprite/star.svg'

import { StyledHolidaysCancelOfferTestimonials as S } from './HolidaysCancelOfferTestimonials.styles'
import { TESTIMONIALS } from './constants'

export const HolidaysCancelOfferTestimonials: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <Container>
        <S.Title>
          {t('purchase1.holidayCancelOffer.testimonials.title')}
        </S.Title>
        <S.Stores>
          <S.Store>
            <SvgImage svg={starSvg} />
            {t('purchase1.holidayCancelOffer.testimonials.stores.appStore')}
          </S.Store>
          <S.Store>
            <SvgImage svg={starSvg} />
            {t('purchase1.holidayCancelOffer.testimonials.stores.googlePlay')}
          </S.Store>
        </S.Stores>
      </Container>

      <Carousel
        slidesPerView={1}
        pagination
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop
      >
        {TESTIMONIALS.map((person) => (
          <SwiperSlide key={person.testimonial}>
            <S.Card>
              <S.CardCaption>
                <S.Name>{t(person.name)}</S.Name>
                <div>
                  <SvgImage svg={starSvg} />
                  <SvgImage svg={starSvg} />
                  <SvgImage svg={starSvg} />
                  <SvgImage svg={starSvg} />
                  <SvgImage svg={starSvg} />
                </div>
              </S.CardCaption>
              <S.Testimonial>{t(person.testimonial)}</S.Testimonial>
            </S.Card>
          </SwiperSlide>
        ))}
      </Carousel>
    </S.Root>
  )
}
