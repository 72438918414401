import React from 'react'

import { StyledDomainsContainer as S } from './DomainsContainer.styles'

type TProps = {
  children: React.ReactNode
  className?: string
}

export const DomainsContainer: React.FC<TProps> = ({ children, className }) => (
  <S.DomainsContainer className={className}>{children}</S.DomainsContainer>
)
