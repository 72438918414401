export const QUESTION = 'Face scanning'

export const enum AnalysisStepThreshold {
  FIRST = 0,
  SECOND = 30,
  THIRD = 70,
}

export const VIDEO_CONSTRAINTS = {
  width: 512,
  height: 512,
  facingMode: 'user',
}

export const ANIMATION_PATH = '/animations/ML-scanning'
