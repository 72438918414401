import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledEmail = {
  Container: styled.div`
    margin: 0 auto 16px;
    max-width: 360px;
    padding: 0 10px;
  `,
  TitleContainer: styled.header`
    margin-bottom: 16px;
  `,
  DescriptionContainer: styled.div`
    margin-bottom: 16px;
  `,
  InputContainer: styled.div`
    position: relative;
    margin: 40px 0 16px;
  `,
  Disclaimer: styled.div`
    padding: 0 15px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  ButtonContainer: styled.div`
    position: relative;
    padding: 40px 0;
  `,
}
