import styled from 'styled-components'

import { Color } from 'root-constants'

type TOfferProps = {
  isSelected: boolean
}

export const StyledUpsellSubscriptionItemSwitchers = {
  Offer: styled.div<TOfferProps>`
    display: flex;
    align-items: center;
    border: ${({ isSelected }) =>
      isSelected ? `1px solid ${Color.PRIMARY}` : '1px solid #c4c4c4'};
    color: ${({ isSelected }) => (isSelected ? Color.DARK : Color.LIGHT)};
    border-radius: 14px;
    padding: 19px 16px;
    margin-bottom: 16px;
  `,
  OfferCaption: styled.span`
    max-width: 98px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-left: 14px;
  `,
  OfferPrice: styled.span`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 0 auto;
  `,
}
