import { useMemo } from 'react'

import { Cohort } from 'root-constants'

import { useCohortToUse } from './useCohortToUse'

export const useActiveCohortIdentifier = () => {
  const cohortToUse = useCohortToUse()
  const {
    isCosmeticCohort,
    isLuvlyIntroFastCohort,
    isSeniorCohort,
    isLuvlyIntro2TCohort,
    isWellnessBundleCohort,
    isLuvly14Cohort,
    isStartPageVideoCohort,
    isChatCohort,
    isUpgradeBundleCohort,
    isDynamicDiscountCohort,
    isIntro3SaleCohort,
    isUnisexCohort,
    isRefundCohort,
    isLuvlyIntroSaleCohort,
    isLuvly12SaleCohort,
    isLuvlyCancelIntro1Cohort,
  } = useMemo(
    () => ({
      isLuvlyIntroFastCohort: cohortToUse === Cohort.LUVLY_INTRO_FAST,
      isCosmeticCohort: cohortToUse === Cohort.LUVLY_COSMETIC_SCANNER,
      isSeniorCohort:
        cohortToUse === Cohort.LUVLY_12 || cohortToUse === Cohort.LUVLY_12_SALE,
      isLuvlyIntro2TCohort: cohortToUse === Cohort.LUVLY_INTRO2T,
      isWellnessBundleCohort:
        cohortToUse === Cohort.LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE,
      isLuvly14Cohort: cohortToUse === Cohort.LUVLY_14,
      isStartPageVideoCohort:
        cohortToUse === Cohort.LUVLY_15 ||
        cohortToUse === Cohort.LUVLY_17 ||
        cohortToUse === Cohort.LUVLY_18,
      isChatCohort: cohortToUse === Cohort.LUVLY_CHAT,
      isUpgradeBundleCohort:
        cohortToUse === Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF,
      isDynamicDiscountCohort:
        cohortToUse === Cohort.LUVLY_INTRO_SALE ||
        cohortToUse === Cohort.LUVLY_12_SALE ||
        cohortToUse === Cohort.LUVLY_INTRO3_SALE,
      isLuvlyIntroSaleCohort: cohortToUse === Cohort.LUVLY_INTRO_SALE,
      isLuvly12SaleCohort: cohortToUse === Cohort.LUVLY_12_SALE,
      isIntro3SaleCohort: cohortToUse === Cohort.LUVLY_INTRO3_SALE,
      isUnisexCohort: cohortToUse === Cohort.LUVLY_20,
      isRefundCohort: cohortToUse === Cohort.LUVLY_REF,
      isLuvlyCancelIntro1Cohort: cohortToUse === Cohort.LUVLY_CANCEL_INTRO_1,
    }),
    [cohortToUse],
  )
  return {
    isCosmeticCohort,
    isLuvlyIntroFastCohort,
    isSeniorCohort,
    isLuvlyIntro2TCohort,
    isWellnessBundleCohort,
    isLuvly14Cohort,
    isStartPageVideoCohort,
    isChatCohort,
    isUpgradeBundleCohort,
    isDynamicDiscountCohort,
    isIntro3SaleCohort,
    isUnisexCohort,
    isRefundCohort,
    isLuvly12SaleCohort,
    isLuvlyIntroSaleCohort,
    isLuvlyCancelIntro1Cohort,
  }
}
