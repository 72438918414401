import saggerType from 'assets/images/sagger-type-feedback.png'
import sinkerType from 'assets/images/sinker-type-feedback.png'
import wrinklerType from 'assets/images/wrinkler-type-feedback.png'

import { OPTION_VALUES as userAnswers } from '../AgingType/constants'

export const QUESTION = 'Women with Sinker type'

export const FEEDBACK = {
  [userAnswers.SINKER]: sinkerType,
  [userAnswers.SAGGER]: saggerType,
  [userAnswers.WRINKLER]: wrinklerType,
}
