import styled, { css } from 'styled-components'

import { AnswerButtonMini as AnswerButton } from 'components/AnswerButtonMini'

import { Color } from 'root-constants'

type TStatementQuestionProps = {
  isLargeSize: boolean
}

export const StyledStatementQuestion = {
  TitleContainer: styled.header`
    margin-bottom: 40px;
  `,
  Title: styled.h1<TStatementQuestionProps>`
    font-weight: 500;
    text-align: center;

    ${({ isLargeSize }) =>
      isLargeSize
        ? css`
            font-size: 24px;
            line-height: 32px;
            color: ${Color.BLUE_DARK};
            margin-bottom: 28px;
          `
        : css`
            font-size: 19px;
            line-height: 22px;
            color: ${Color.DARK};
            margin-bottom: 25px;
          `}
  `,
  Question: styled.h2<TStatementQuestionProps>`
    text-align: center;
    font-weight: 400;

    ${({ isLargeSize }) =>
      isLargeSize
        ? css`
            font-size: 18px;
            line-height: 24px;
            color: ${Color.BLUE_DARK};
          `
        : css`
            font-size: 19px;
            line-height: 22px;
            color: ${Color.DARK};
          `}
  `,
  OptionsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  `,
  AnswerButtonMini: styled(AnswerButton)<TStatementQuestionProps>`
    ${({ isLargeSize }) =>
      isLargeSize &&
      css`
        color: ${Color.BLUE_DARK};
        font-weight: 500;
      `}
  `,
  Scale: styled.div<TStatementQuestionProps>`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    color: ${({ isLargeSize }) => (isLargeSize ? Color.GRAY : Color.DARK)};

    & span {
      max-width: 110px;
    }
  `,
}
