import React from 'react'

import styled from 'styled-components'

import paypalLogo from 'assets/images/paypal.png'

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isTouched: boolean
  buttonHeight: number
  buttonBorderRadius: number
}

type TButtonContainerProps = {
  buttonHeight: number
  buttonBorderRadius: number
}

export const StyledPayPalButton = {
  Root: styled.div`
    z-index: 100;
  `,
  ButtonsContainer: styled.div<TButtonContainerProps>`
    position: relative;
    z-index: 1;
    min-height: ${({ buttonHeight }) => `${buttonHeight}px`};

    & iframe {
      border-radius: ${({ buttonBorderRadius }) => `${buttonBorderRadius}px`};
      -webkit-tap-highlight-color: transparent;
      opacity: 0.0000000001 !important;
    }
  `,
  FakePaypalButton: styled.button<IButtonProps>`
    width: 100%;
    height: ${({ buttonHeight }) => `${buttonHeight}px`};
    line-height: ${({ buttonHeight }) => `${buttonHeight}px`};
    border-radius: ${({ buttonBorderRadius }) => `${buttonBorderRadius}px`};
    border: none;
    background: #f9c456 url(${paypalLogo}) no-repeat center;
    background-size: 102px 28px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    padding: 0;
    z-index: 1;
    filter: ${({ isTouched }) =>
      isTouched ? 'brightness(0.95)' : 'brightness(1)'};
  `,
}
