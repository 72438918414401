import React, { useEffect, useRef } from 'react'

import lottie from 'lottie-web/build/player/lottie_light'

import confettiAnimation from 'assets/animation/confettiAnimation.json'

import { StyledConfettiBackdrop as S } from './ConfettiBackdrop.styles'

export const ConfettiBackdrop = () => {
  const animationContainerRef = useRef(null)

  useEffect(() => {
    if (animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        animationData: confettiAnimation,
        loop: false,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      })
    }

    return () => lottie.destroy()
  }, [])
  return <S.Root ref={animationContainerRef} />
}
