import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import wonderfulStartImage from 'assets/images/wonderful-start-forever-young.png'

import { StyledWonderfulStart as S } from './WonderfulStart.styles'
import { QUESTION } from './constants'

export const WonderfulStart: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <Container>
      <S.Image src={wonderfulStartImage} alt="wonderful-image" />
      <S.PageTitle>{t`onboarding.wonderfulStart.question`}</S.PageTitle>
      <S.Description>{t`onboarding.wonderfulStart.description`}</S.Description>
      <S.ButtonContainer>
        <Button onClick={handleNextClick}>{t('actions.continue')}</Button>
      </S.ButtonContainer>
    </Container>
  )
}
