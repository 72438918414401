import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { getSupportFormLink } from 'helpers/getSupportFormLink'

import { eventLogger } from 'services/eventLogger.service'

import { StyledIntroHeader as S, TIntroHeaderProps } from './IntroHeader.styles'
import { LOGO_MAP } from './constants'

export const IntroHeader: React.FC<TIntroHeaderProps> = ({
  colorTheme,
}: TIntroHeaderProps) => {
  const { t } = useTranslation()
  const contactFormLink = getSupportFormLink()

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const toggleMenuVisibility = useCallback(() => {
    setIsMenuVisible(!isMenuVisible)
  }, [isMenuVisible])

  const handleHelpClick = useCallback(() => {
    eventLogger.logNeedHelpClicked()
  }, [])

  return (
    <>
      <S.Header colorTheme={colorTheme}>
        <S.Logo src={LOGO_MAP[colorTheme]} alt="" />
        <S.SupportAction colorTheme={colorTheme} onClick={handleHelpClick}>
          <a href={contactFormLink} target="_blank" rel="noopener noreferrer">
            {t`onboarding.intro.supportAction`}
          </a>
        </S.SupportAction>
        <S.Burger type="button" onClick={toggleMenuVisibility}>
          <S.BurgerLine colorTheme={colorTheme} />
          <S.BurgerLine colorTheme={colorTheme} />
          <S.BurgerLine colorTheme={colorTheme} />
          <S.BurgerLine colorTheme={colorTheme} />
        </S.Burger>
      </S.Header>
      <S.Backdrop
        isMenuVisible={isMenuVisible}
        onClick={toggleMenuVisibility}
      />
      <S.Menu isMenuVisible={isMenuVisible}>
        <S.MenuCloseButton type="button" onClick={toggleMenuVisibility} />
        <S.StyledMenuLinkContainer>
          <TermsOfUseLink />
        </S.StyledMenuLinkContainer>
        <S.StyledMenuLinkContainer>
          <PrivacyPolicyLink />
        </S.StyledMenuLinkContainer>
      </S.Menu>
    </>
  )
}
