import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { ErrorNotification } from 'components/ErrorNotification'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'

import { resetErrorAction } from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { Chat } from 'common-styles'

import { StyledLikeChatEmail as S } from './LikeChatEmail.styles'
import { useEmail } from './hooks/useEmail'

export const LikeChatEmail: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const {
    handlePrefilledEmail,
    handleBlur,
    handleFocus,
    handleChange,
    handleSubmit,
    errorText,
    isStatusFetching,
    isEmailValid,
    error,
    isErrorModalShown,
    isAutoCompleteVisible,
    email,
    domainsList,
    chatStep,
    setChatStep,
    setIsErrorModalShown,
  } = useEmail()

  return isStatusFetching ? (
    <Spinner />
  ) : (
    <S.CustomContainer fullHeight fields={0}>
      <Chat>
        <Container>
          <ChatMessage
            author={ChatAuthor.EXPERT}
            delayBeforeShow={DELAY_BEFORE_SHOW}
            hasJustNowLabel
          >
            <Trans
              i18nKey="onboarding.likeChatEmail.title"
              components={[<br />, <strong />]}
            />
          </ChatMessage>
          <ChatMessage
            author={ChatAuthor.EXPERT}
            delayBeforeShow={2000}
            onTransitionEnd={() => setChatStep(ChatStep.USER_ANSWER)}
            hasHeader={false}
          >
            {t('onboarding.likeChatEmail.secure')}
          </ChatMessage>
        </Container>
      </Chat>
      <S.BottomContainer
        isShown={chatStep === ChatStep.USER_ANSWER}
        pinedToBottom
      >
        <S.ErrorContainer>
          <ErrorNotification errorText={errorText} />
        </S.ErrorContainer>
        {isAutoCompleteVisible && (
          <S.Domains>
            {domainsList.map((item) => (
              <button
                type="button"
                key={item}
                value={item}
                onClick={handlePrefilledEmail}
              >
                {item}
              </button>
            ))}
          </S.Domains>
        )}

        <S.BottomContainerContent>
          <form onSubmit={handleSubmit}>
            <S.EmailInput
              value={email.value}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              required
            />
            <S.InputLabel>
              {t(
                email.isFocused
                  ? 'onboarding.likeChatEmail.input.label'
                  : 'onboarding.likeChatEmail.input.placeholder',
              )}
            </S.InputLabel>
            <S.ActionButtonWrapper>
              <S.SendEmailButton
                isValid={!!email.value && email.isValid}
                disabled={!email.value || !isEmailValid}
                type="submit"
              />
            </S.ActionButtonWrapper>
          </form>
        </S.BottomContainerContent>
      </S.BottomContainer>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
        error={error}
      />
    </S.CustomContainer>
  )
}
