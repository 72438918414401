import styled from 'styled-components'

type TTitleContainerProps = {
  hasLargeOffset?: boolean
}

export const StyledDailyWater = {
  TitleContainer: styled.header<TTitleContainerProps>`
    margin-bottom: ${({ hasLargeOffset }) =>
      hasLargeOffset ? '40px' : '16px'};
  `,
  DescriptionContainer: styled.div`
    margin-bottom: 16px;
  `,
  GlassesContainer: styled.div`
    margin: 24px 0 0;
  `,
}
