import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { Option } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PageId } from 'page-constants'
import { Gender, OptionType } from 'root-constants'

import { StyledGender as S } from './Gender.styles'
import { GENDER_IMAGE_OPTIONS, QUESTION } from './constants'

export const GenderAsIntro: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  useStartSession()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { headline, subtext } = useTitleFromUrl()

  const handleContinue = useNextStep({
    pageId: PageId.GENDER,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = useCallback(
    (value: string) => {
      eventLogger.logUserGenderSelected(value as Gender)
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, setIsAnswersDisabled],
  )

  const optionProps = useMemo(
    () => ({
      name: PageId.GENDER,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())

        window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        handleAnswerChange(value)
      },
    }),
    [handleAnswerChange, isAnswersDisabled, dispatch, uuid],
  )

  return (
    <S.Root>
      <IntroHeader colorTheme={IntroHeaderColor.DARK} />
      <Container>
        <S.Title>{headline || t`onboarding.genderAsIntro.title`}</S.Title>
        <S.Subtitle>
          {subtext || <Trans i18nKey="onboarding.genderAsIntro.subtitle" />}
        </S.Subtitle>
        <S.CallToAction>{t`onboarding.genderAsIntro.CTA`}</S.CallToAction>
        <S.Actions>
          {GENDER_IMAGE_OPTIONS.map(({ value, text, image }) => (
            <Option {...optionProps} value={value} key={value}>
              <S.Action>
                <S.Image src={image} alt="" />
                <S.Answer />
                <S.SkinType>
                  <S.SkinTypeValue>{t(text)}</S.SkinTypeValue>
                  <S.ChevronRight />
                </S.SkinType>
              </S.Action>
            </Option>
          ))}
        </S.Actions>
      </Container>
      <S.Terms>
        <p>{t`onboarding.intro.termsTitle`}</p>
        <TermsOfUseLink />
        <S.Separator />
        <PrivacyPolicyLink />
      </S.Terms>
    </S.Root>
  )
}
