import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  EventTypes,
  PaymentMethodType,
  PrimerHeadlessCheckout,
} from '@primer-io/checkout-web'

import { PRIMER_GOOGLE_PAY_ID, PaymentMethod } from 'modules/purchase/constants'

import { eventLogger } from 'services/eventLogger.service'

import { setPaymentMethodAction } from '../redux/actions/common'
import { usePrimerAnalytics } from './usePrimerAnalytics'

export const useInitPrimerGooglePay = ({
  primerRef,
}: {
  primerRef: React.RefObject<PrimerHeadlessCheckout | null>
}) => {
  const { logPaymentStarted } = usePrimerAnalytics()
  const dispatch = useDispatch()

  const initGooglePayButton = useCallback(
    async (callback?: () => void) => {
      const paymentMethodManager =
        await primerRef.current?.createPaymentMethodManager(
          PaymentMethodType.GOOGLE_PAY,
        )
      if (!paymentMethodManager) return

      const googlePayButton = paymentMethodManager.createButton()

      await googlePayButton.render(PRIMER_GOOGLE_PAY_ID, {
        style: {
          buttonType: 'short',
          buttonColor: 'white',
        },
      })

      googlePayButton.addEventListener(EventTypes.CLICK, () => {
        eventLogger.logPaymentMethodSelected(PaymentMethod.GOOGLE_PAY)
        dispatch(setPaymentMethodAction(PaymentMethod.GOOGLE_PAY))
        logPaymentStarted(PaymentMethod.GOOGLE_PAY)
        callback && callback()
      })
    },
    [primerRef, dispatch, logPaymentStarted],
  )

  return {
    initGooglePayButton,
  }
}
