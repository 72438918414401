import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { FIFTEEN_MINUTES_IN_SECONDS } from 'modules/purchase/constants'
import { useTimerForTarget } from 'modules/purchase/hooks'

import { StyledPurchaseTimerMarissa as S } from './PurchaseTimerMarissa.styles'

type TProps = {
  elemForComparisonRef: React.RefObject<HTMLDivElement>
}

export const PurchaseTimerMarissa: React.FC<TProps> = ({
  elemForComparisonRef,
}) => {
  const { t } = useTranslation()
  const isTargetisVisible = useTargetHiddenOnScroll(elemForComparisonRef)
  const timerElementRef = useRef<HTMLSpanElement>(null)

  useTimerForTarget(timerElementRef, FIFTEEN_MINUTES_IN_SECONDS)

  return (
    <S.Wrapper isVisible={isTargetisVisible}>
      <span>{t`purchase4.timer.timerText`}</span>
      <S.Timer ref={timerElementRef} />
    </S.Wrapper>
  )
}
