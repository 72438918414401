export const QUESTION = 'Biggest skincare challenges'
export const QUESTION_2 =
  'We will take your concerns and habits into consideration'

export const enum OPTION_VALUES {
  STICKING_ROUTINE = 'sunscreen',
  FIGURING_OUT_SKIN_NEEDS = 'figuring_out_skin_needs',
  BUILDING_ROUTINE = 'building_routine',
  EFFECTIVE_PRODUCTS = 'effective_products',
  SELECTING_SAFE_PRODUCTS = 'selecting_safe_products',
  KEEPING_PROGRESS = 'keeping_progress',
  OTHER = 'other',
}

export const CHALLENGES_OPTIONS = [
  {
    value: OPTION_VALUES.STICKING_ROUTINE,
    label: 'onboarding.likeChatChallenges1.stickingRoutine',
  },
  {
    value: OPTION_VALUES.FIGURING_OUT_SKIN_NEEDS,
    label: 'onboarding.likeChatChallenges1.figuringOutSkinNeeds',
  },
  {
    value: OPTION_VALUES.BUILDING_ROUTINE,
    label: 'onboarding.likeChatChallenges1.buildingRoutine',
  },
  {
    value: OPTION_VALUES.EFFECTIVE_PRODUCTS,
    label: 'onboarding.likeChatChallenges1.effectiveProducts',
  },
  {
    value: OPTION_VALUES.SELECTING_SAFE_PRODUCTS,
    label: 'onboarding.likeChatChallenges1.selectingProducts',
  },
  {
    value: OPTION_VALUES.KEEPING_PROGRESS,
    label: 'onboarding.likeChatChallenges1.keepingProgress',
  },
  {
    value: OPTION_VALUES.OTHER,
    label: 'onboarding.likeChatChallenges1.other',
  },
]

export const CHALLENGES_ANSWERS_MAP = {
  [OPTION_VALUES.STICKING_ROUTINE]:
    'onboarding.likeChatChallenges1.stickingRoutine',
  [OPTION_VALUES.FIGURING_OUT_SKIN_NEEDS]:
    'onboarding.likeChatChallenges1.figuringOutSkinNeeds',
  [OPTION_VALUES.BUILDING_ROUTINE]:
    'onboarding.likeChatChallenges1.buildingRoutine',
  [OPTION_VALUES.EFFECTIVE_PRODUCTS]:
    'onboarding.likeChatChallenges1.effectiveProducts',
  [OPTION_VALUES.SELECTING_SAFE_PRODUCTS]:
    'onboarding.likeChatChallenges1.selectingProducts',
  [OPTION_VALUES.KEEPING_PROGRESS]:
    'onboarding.likeChatChallenges1.keepingProgress',
  [OPTION_VALUES.OTHER]: 'onboarding.likeChatChallenges1.other',
}
