export const QUESTION = 'Sugar'

export const OPTION_VALUES = {
  EVERYDAY: 'everyday',
  OFTEN: 'often',
  SOMETIMES: 'sometimes',
  NEVER: 'never',
}

export const SUGAR_ANSWERS_MAP = {
  [OPTION_VALUES.EVERYDAY]: 'onboarding.sugar.everyday',
  [OPTION_VALUES.OFTEN]: 'onboarding.sugar.often',
  [OPTION_VALUES.SOMETIMES]: 'onboarding.sugar.sometimes',
  [OPTION_VALUES.NEVER]: 'onboarding.sugar.never',
}
