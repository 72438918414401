import styled, { css } from 'styled-components'

type TPreferredFoodProps = {
  isSeniorCohortStyles: boolean
}

type TOptionsContainerProps = {
  textAlign?: 'left' | 'center'
}

export const StyledPreferredFood = {
  TitleContainer: styled.header<TPreferredFoodProps>`
    margin-bottom: 16px;
    font-weight: 500;
    text-align: center;

    ${({ isSeniorCohortStyles }) =>
      isSeniorCohortStyles
        ? css`
            font-size: 24px;
            line-height: 32px;
          `
        : css`
            font-size: 17px;
            line-height: 26px;
          `}
  `,
  OptionsContainer: styled.div<TOptionsContainerProps>`
    text-align: ${({ textAlign = 'center' }) => textAlign};
  `,
}
