import styled from 'styled-components'

import fireWorksIcon from 'assets/images/fireworks-icon.png'

export const StyledLikeChatResult = {
  Emoji: styled.span`
    width: 24px;
    height: 24px;
    right: -12px;
    bottom: 8px;
    position: absolute;
    background: url(${fireWorksIcon}) no-repeat center;
    background-size: contain;
  `,
}
