import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { PageTitle } from 'components/PageTitle'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledWhatIsFaceYoga as S } from './WhatIsFaceYoga.styles'
import { QUESTION } from './constants'

export const WhatIsFaceYoga: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <Container>
      <S.TitleContainer>
        <PageTitle>
          <Trans
            i18nKey="onboarding.whatIsFaceYoga.question"
            components={[<strong />, <br />]}
          />
        </PageTitle>
      </S.TitleContainer>
      <S.Description>
        <Trans
          i18nKey="onboarding.whatIsFaceYoga.description"
          components={[<br />]}
        />
      </S.Description>
      <S.ListTile>{t`onboarding.whatIsFaceYoga.listTitle`}</S.ListTile>
      <ul>
        <S.ListItem>
          <Trans
            i18nKey="onboarding.whatIsFaceYoga.targetYourFace"
            components={[<strong />]}
          />
        </S.ListItem>
        <S.ListItem>
          <Trans
            i18nKey="onboarding.whatIsFaceYoga.massage"
            components={[<strong />]}
          />
          <ul>
            <S.SubListItem>
              {t`onboarding.whatIsFaceYoga.boostCirculation`}
            </S.SubListItem>
            <S.SubListItem>
              {t`onboarding.whatIsFaceYoga.improveDrainage`}
            </S.SubListItem>
            <S.SubListItem>
              {t`onboarding.whatIsFaceYoga.releaseTension`}
            </S.SubListItem>
          </ul>
        </S.ListItem>
        <S.ListItem>
          <Trans
            i18nKey="onboarding.whatIsFaceYoga.acupressureTechniques"
            components={[<strong />]}
          />
          <ul>
            <S.SubListItem>
              {t`onboarding.whatIsFaceYoga.preventingHeadaches`}
            </S.SubListItem>
            <S.SubListItem>
              {t`onboarding.whatIsFaceYoga.releasingSinuses`}
            </S.SubListItem>
            <S.SubListItem>
              {t`onboarding.whatIsFaceYoga.sleepQuality`}
            </S.SubListItem>
          </ul>
        </S.ListItem>
      </ul>
      <S.ButtonContainer>
        <Button onClick={handleNextClick}>{t`actions.gotIt`}</Button>
      </S.ButtonContainer>
    </Container>
  )
}
