import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledLikeChatDiet = {
  Root: styled.div`
    padding-bottom: 24px;
  `,
  OptionsContainer: styled.div`
    & > label {
      margin-bottom: 8px;
    }
  `,
  Text: styled.p`
    color: ${Color.LIGHT};
  `,
}
