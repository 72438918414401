import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { AnswerButton } from 'components/AnswerButton'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { PageId } from 'page-constants'
import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledSkinConcerns as S } from './SkinConcerns.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const SkinConcerns: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const includeNavigation = useIsNavigationOn()
  const cohortToUse = useCohortToUse()
  const { isCosmeticCohort, isSeniorCohort } = useActiveCohortIdentifier()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath:
      isCosmeticCohort || isSeniorCohort
        ? nextPagePath
        : `${PageId.MOTIVATION}${search}`,
  })

  const handleNextClick = useCallback(() => {
    const uniqueAnswers = [...new Set(answers)]
    handleContinue(uniqueAnswers)
  }, [answers, handleContinue])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        const isNothingFromAboveOptionTouched =
          value === OPTION_VALUES.NOTHING_FROM_THE_ABOVE

        if (isChecked && isNothingFromAboveOptionTouched) {
          setAnswers([value])

          return
        }

        if (isChecked) {
          setAnswers(
            [...answers, value].filter(
              (item) => item !== OPTION_VALUES.NOTHING_FROM_THE_ABOVE,
            ),
          )

          return
        }

        setAnswers(answers.filter((item) => item !== value))
      },
    }),
    [answers, pageId],
  )

  return (
    <div>
      <S.TitleContainer>
        <PageTitle>{t`onboarding.skinConcerns.question`}</PageTitle>
      </S.TitleContainer>
      <Container>
        <S.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.ACNE}
            checked={answers.includes(OPTION_VALUES.ACNE)}
          >
            <AnswerButton withCheckbox>
              {t`onboarding.skinConcerns.acne`}
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.REDNESS}
            checked={answers.includes(OPTION_VALUES.REDNESS)}
          >
            <AnswerButton withCheckbox>
              {t`onboarding.skinConcerns.redness`}
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.DULLNESS}
            checked={answers.includes(OPTION_VALUES.DULLNESS)}
          >
            <AnswerButton withCheckbox>
              {t`onboarding.skinConcerns.dullness`}
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.ROUGH_TEXTURE}
            checked={answers.includes(OPTION_VALUES.ROUGH_TEXTURE)}
          >
            <AnswerButton withCheckbox>
              {t`onboarding.skinConcerns.roughTexture`}
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.UNEVEN_SKIN_TONE}
            checked={answers.includes(OPTION_VALUES.UNEVEN_SKIN_TONE)}
          >
            <AnswerButton withCheckbox>
              {t`onboarding.skinConcerns.unevenSkinTone`}
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.PIGMENTATION_CHANGES}
            checked={answers.includes(OPTION_VALUES.PIGMENTATION_CHANGES)}
          >
            <AnswerButton withCheckbox>
              {t`onboarding.skinConcerns.pigmentationChanges`}
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NOTHING_FROM_THE_ABOVE}
            checked={answers.includes(OPTION_VALUES.NOTHING_FROM_THE_ABOVE)}
          >
            <AnswerButton withCheckbox>
              <Trans
                i18nKey="onboarding.skinConcerns.nothingFromTheAbove"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
              />
            </AnswerButton>
          </Option>
        </S.OptionsContainer>
      </Container>
      {includeNavigation ? (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answers.length}
        />
      ) : (
        <S.BottomButtonContainer>
          <Button onClick={handleNextClick} disabled={!answers.length}>
            {t`actions.continue`}
          </Button>
        </S.BottomButtonContainer>
      )}
    </div>
  )
}
