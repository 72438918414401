export const IMAGE_PATH = '/images/before-after-cancel-offer'

export const LONG_PAYWALL_APP_SLIDES = [
  {
    id: '1',
    imagePath: '/images/phone-app-img-first',
  },
  {
    id: '2',
    imagePath: '/images/phone-app-img-second',
  },
  {
    id: '3',
    imagePath: '/images/phone-app-img-third',
  },
]
