import styled from 'styled-components'

import { Color } from 'root-constants'

type TDescriptionProps = {
  isInProgress?: boolean
  isFinished?: boolean
}

export const StyledProgressAnalyzer = {
  Wrapper: styled.div`
    margin-bottom: 10px;
  `,
  ProgressWrapper: styled.div`
    margin-bottom: 24px;
    overflow: hidden;
    height: 10px;
    border-radius: 10px;
  `,
  Progress: styled.progress`
    width: 100%;
    height: 10px;
  `,
  Description: styled.p<TDescriptionProps>`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 5px;
    font-weight: ${({ isInProgress }) => (isInProgress ? 500 : 400)};
    color: ${({ isInProgress }) => (isInProgress ? Color.DARK : Color.LIGHT)};
    color: ${({ isFinished }) => isFinished && Color.LIGHT};
    transition: 0.25s;
  `,
  Text: styled.span`
    margin-right: 10px;
    text-align: left;
  `,
  Value: styled.span`
    min-width: 40px;
    flex-shrink: 0;
    text-align: right;
  `,
}
