import styled from 'styled-components'

import { BottomButtonContainer } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants'

export const StyledSocialProof = {
  Root: styled.div`
    padding-top: 24px;
  `,
  Title: styled.header`
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.MIDNIGHT_BLUE};
    text-align: center;
    margin-bottom: 8px;
  `,
  Description: styled.p`
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.LIGHT};
    text-align: center;
    margin-bottom: 24px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 328/308;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto 43px;
    }
  `,
  Image: styled.img`
    max-width: 100%;
    height: auto;
  `,
  ButtonContainer: styled(BottomButtonContainer)`
    max-width: 360px;
    padding: 40px 16px;
  `,
}
