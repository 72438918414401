import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledFaceScanPreview = {
  Root: styled.div`
    padding-top: 24px;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.DARK};
    margin-bottom: 22px;
  `,
  Description: styled.p`
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: ${Color.LIGHT};
    margin-bottom: 22px;
  `,
  HighlightedText: styled.p`
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: ${Color.PRIMARY};
    margin-bottom: 22px;
  `,
  IssuesContainer: styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
  `,
  Issue: styled.div`
    padding: 8px 16px;
    height: 30px;
    width: fit-content;
    text-transform: uppercase;
    border: 1px solid ${Color.PRIMARY};
    border-radius: 16px;
    margin-right: 8px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1.5px;
    color: ${Color.DARK};
  `,
  ButtonContainer: styled.div`
    position: sticky;
    bottom: 0;
    z-index: 2;
    width: 100%;
    padding: 38px 0 18px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    );

    button {
      max-width: 320px;
      margin: 0 auto;
    }
  `,
  AnimationContainer: styled.div`
    width: 100%;
    max-width: 375px;
    height: 244px;
    margin: 0 auto;
  `,
}
