import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Button } from 'components/Button'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { ConfettiBackdrop } from 'components/ConfettiBackdrop'
import { Container } from 'components/Container'
import { LinearProgressBar } from 'components/LinearProgressBar'

import { useCohortToUse } from 'hooks/useCohortToUse'

import { eventLogger } from 'services/eventLogger.service'

import likeChatResult1 from 'assets/images/like-chat-result-1.png'
import likeChatResult2 from 'assets/images/like-chat-result-2.png'

import { goTo } from 'browser-history'
import { DelayedBottomContainer } from 'common-styles'
import { PURCHASE_PAGES_MAP } from 'page-constants'

import { StyledLikeChatResult as S } from './LikeChatResult.styles'
import { QUESTION } from './constants'

export const LikeChatResult: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const cohortToUse = useCohortToUse()
  const [triggerAnimation, setTriggerAnimation] = useState(false)
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)

  const handleNextClick = useCallback(() => {
    eventLogger.logQuestion({
      question: QUESTION,
      answers: '',
    })
    goTo({ pathname: PURCHASE_PAGES_MAP[cohortToUse], search })
  }, [search, cohortToUse])

  return (
    <div>
      {triggerAnimation && <ConfettiBackdrop />}

      <LinearProgressBar progressStatus={29} />
      <Container>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          hasJustNowLabel
          onTransitionEnd={() => setTriggerAnimation(true)}
        >
          <Trans
            i18nKey="likeChatResult.congrats"
            components={{
              emoji: <S.Emoji />,
            }}
          />
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={2000}
          hasHeader={false}
        >
          {t('likeChatResult.skincareProgram')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={4000}
          isImage
          hasHeader={false}
        >
          <img src={likeChatResult1} alt="introduce-expert" />
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={5500}
          hasHeader={false}
        >
          {t('likeChatResult.otherMethods')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={7000}
          hasHeader={false}
          isImage
        >
          <img src={likeChatResult2} alt="introduce-expert" />
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={8500}
          hasHeader={false}
          onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
        >
          {t('likeChatResult.createdProgram')}
        </ChatMessage>
        <DelayedBottomContainer isShown={chatStep === ChatStep.FINISH}>
          <Button onClick={handleNextClick}>{t('actions.ready')}</Button>
        </DelayedBottomContainer>
      </Container>
    </div>
  )
}
