import styled from 'styled-components'

export const StyledLikeChatMentalHealth = {
  Answer: styled.p``,
  OptionsContainer: styled.div`
    & > label {
      margin-bottom: 8px;
    }
  `,
}
