import React from 'react'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { PROGRESS_STEPS_LENGTH_MAP } from 'root-constants'

import { StyledLinearProgressBar as S } from './LinearProgressBar.styles'

type TLinearProgressBarProps = {
  progressStatus?: number
}

export const LinearProgressBar: React.FC<TLinearProgressBarProps> = ({
  progressStatus,
}: TLinearProgressBarProps) => {
  const { progressValue } = useGetPageInfo()
  const cohortToUse = useCohortToUse()
  const { isChatCohort } = useActiveCohortIdentifier()

  return (
    <S.Wrapper isFullWidth={isChatCohort}>
      <S.Progress
        value={progressValue ?? progressStatus}
        isSlim={isChatCohort}
        max={PROGRESS_STEPS_LENGTH_MAP[cohortToUse] || 0}
      />
    </S.Wrapper>
  )
}
