import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellGuashaCancelOffer = {
  Content: styled.div`
    border-radius: 12px;
    background: rgba(255, 130, 118, 0.12);
    margin: 0 auto 24px;
    display: flex;
    gap: 16px;
    padding: 16px;
  `,
  GiftImage: styled.img`
    width: 40px;
    height: 40px;
  `,
  Discount: styled.p`
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.BLUE_DARK};

    & > span {
      text-decoration: line-through;
    }

    & > strong {
      color: ${Color.RED};
    }
  `,
}
