import styled from 'styled-components'

import checkIcon from 'assets/images/check.svg'

import { Color } from 'root-constants'

type TWrapperProps = {
  withCheckbox?: boolean
  isLargeSize?: boolean
}

type TIconSize = {
  iconWidth?: number
  iconHeight?: number
}

export enum AnswerButtonHeight {
  S = 's',
  M = 'm',
  L = 'l',
}

type TContentProps = {
  height: AnswerButtonHeight
}

const CONTENT_HEIGHT_MAP = {
  [AnswerButtonHeight.S]: '64px',
  [AnswerButtonHeight.M]: '74px',
  [AnswerButtonHeight.L]: '88px',
}

export const StyledAnswerButton = {
  Wrapper: styled.div<TWrapperProps>`
    width: 100%;
    max-width: ${({ isLargeSize }) => (isLargeSize ? '328px' : '320px')};
    box-shadow: 0 8px 20px #dfe3f3;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
    background-color: #fff;
    color: ${Color.DARK};
    padding: ${(props) => (props.withCheckbox ? '0 45px 0 20px' : '0 15px')};
    position: relative;

    &::after {
      display: ${(props) => (props.withCheckbox ? 'block' : 'none')};
      content: '';
      width: 24px;
      height: 24px;
      mix-blend-mode: normal;
      border: 1px solid #555965;
      border-radius: 50%;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }

    input:checked + & {
      background: #ff8276;
      color: #fff;
    }

    input:checked + &::after {
      opacity: 1;
      border-color: #fff;
      background: #fff url(${checkIcon}) no-repeat center;
      background-size: 10px 8.5px;
    }
  `,

  Content: styled.div<TContentProps>`
    height: ${({ height }) => CONTENT_HEIGHT_MAP[height]};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 20px;
    overflow-wrap: break-word;
  `,
  Text: styled.div`
    font-size: 17px;
    flex-grow: 1;
  `,
  Icon: styled.img<TIconSize>`
    width: ${({ iconWidth }) => (iconWidth ? `${iconWidth}px` : '24px')};
    height: ${({ iconHeight }) => (iconHeight ? `${iconHeight}px` : '24px')};
    margin-right: 10px;
  `,
}
