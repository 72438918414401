import brianna from 'assets/images/brianna-before-after-min.jpg'
import dalia from 'assets/images/dalia-before-after-min.png'
import linda from 'assets/images/linda-before-after-min.jpg'
import melissa from 'assets/images/melissa-before-after-min.png'

export const DEFAULT_REVIEWS = [
  {
    review: 'purchase1.holidayCancelOffer.reviews.melissa.review',
    name: 'purchase1.holidayCancelOffer.reviews.melissa.name',
    image: melissa,
  },
  {
    review: 'purchase1.holidayCancelOffer.reviews.brianna.review',
    name: 'purchase1.holidayCancelOffer.reviews.brianna.name',
    image: brianna,
  },
  {
    review: 'purchase1.holidayCancelOffer.reviews.dalia.review',
    name: 'purchase1.holidayCancelOffer.reviews.dalia.name',
    image: dalia,
  },
  {
    review: 'purchase1.holidayCancelOffer.reviews.linda.review',
    name: 'purchase1.holidayCancelOffer.reviews.linda.name',
    image: linda,
  },
]
