import React from 'react'

import { FeaturesReady } from '@growthbook/growthbook-react'

import { Spinner } from 'components/Spinner'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { TPageProps } from 'models/common.model'

import { AgeAsIntro } from './AgeAsIntro'
import { IntroSaleAgeAsIntro } from './IntroSaleAgeAsIntro'
import { IntroSaleStartPageVariant } from './IntroSaleAgeAsIntro/constants'

export const AgeAsIntroProvider: React.FC<TPageProps> = (props) => {
  const { introSaleStartPageVariant } = useABTest()
  const { isLuvlyIntroSaleCohort } = useActiveCohortIdentifier()

  return (
    <FeaturesReady fallback={<Spinner />}>
      {isLuvlyIntroSaleCohort &&
      introSaleStartPageVariant !== IntroSaleStartPageVariant.DEFAULT ? (
        <IntroSaleAgeAsIntro {...props} />
      ) : (
        <AgeAsIntro {...props} />
      )}
    </FeaturesReady>
  )
}
