import React from 'react'

import { Progress } from 'components/Progress'

import { StyledProgressAnalyzer as S } from './ProgressAnalyzer.styles'

type TProps = {
  value: number
  color: string
  isInProgress?: boolean
  isFinished?: boolean
  children?: React.ReactNode
}

export const ProgressAnalyzer: React.FC<TProps> = ({
  color,
  value,
  isInProgress,
  isFinished,
  children,
}) => (
  <S.Wrapper>
    <S.Description isInProgress={isInProgress} isFinished={isFinished}>
      <S.Text>{children}</S.Text>
      <S.Value>{value}%</S.Value>
    </S.Description>
    <S.ProgressWrapper>
      <Progress background="#eeeff6" color={color} value={value} max={100} />
    </S.ProgressWrapper>
  </S.Wrapper>
)
