import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Container } from 'components/Container'
import { InputWithHint as Input } from 'components/InputWithHint'
import { SelectWithFloatPlaceholder } from 'components/SelectWithFloatPlaceholder'

import { getUserStatusAction } from 'root-redux/actions/user'
import { selectIsFetching } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { GuashaOrderSummary } from 'modules/purchase/components/GuashaOrderSummary'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import {
  saveDeliveryAddressAction,
  setInAppPurchaseUserDataAction,
} from 'modules/purchase/redux/actions/upsell'
import { selectSubscription } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { IN_APP_PRODUCT_EVENT_NAMES_MAP, ScreenName } from 'root-constants'

import { StyledCheckoutGuasha as S } from './CheckoutGuasha.styles'
import {
  ONLY_DIGITS_REGEX,
  PHONE_REGEX,
  USER_FORM_FIELD_MAX_LENGTH,
  US_STATES_OPTIONS,
} from './constants'
import { getStateByCode } from './helpers'

interface ICustomerFormInput {
  country: string
  firstName: string
  lastName: string
  address: string
  optionalAddress?: string
  city: string
  zipCode: string
  phone: string
  state: { value: string; label: string } | null
}

type TCheckoutGuashaProps = {
  isCancelOffer: boolean
  onClose: () => void
}

export const CheckoutGuasha: React.FC<TCheckoutGuashaProps> = ({
  isCancelOffer,
  onClose,
}: TCheckoutGuashaProps) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch: TAppDispatch = useDispatch()

  const uuid = useSelector(selectUUID)
  const selectedSubscription = useSelector(selectSubscription)
  const isFetching = useSelector(selectIsFetching)

  const [isOptionalAddress, setIsOptionalAddress] = useState(false)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const {
    trigger,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<ICustomerFormInput>({
    defaultValues: {
      country: 'United States',
      firstName: '',
      lastName: '',
      address: '',
      optionalAddress: '',
      city: '',
      zipCode: '',
      phone: '',
      state: null,
    },
    mode: 'all',
    reValidateMode: 'onBlur',
  })

  useEffect(() => {
    document.body.scrollTo({ top: 0 })

    if (selectedSubscription) {
      eventLogger.logInAppSalePageShown({
        productId:
          IN_APP_PRODUCT_EVENT_NAMES_MAP[selectedSubscription?.product],
      })
    }
  }, [selectedSubscription])

  const goToNextPage = useCallback(() => {
    goTo({ pathname: PageId.FINISHING_TOUCHES, search })
  }, [search])

  const continueWithError = useCallback(async () => {
    dispatch(getUserStatusAction(uuid))
    goTo({ pathname: PageId.FINISHING_TOUCHES, search })
  }, [search, dispatch, uuid])

  const continueWithSuccess = useCallback(async () => {
    goTo({ pathname: PageId.FINISHING_TOUCHES, search })
  }, [search])

  const handleSubmit = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault()
      if (isValid) {
        dispatch(
          setInAppPurchaseUserDataAction({
            country: 'US',
            firstName: getValues('firstName'),
            lastName: getValues('lastName'),
            address: getValues('address'),
            city: getValues('city'),
            zipCode: getValues('zipCode'),
            phone: getValues('phone'),
            state: getValues('state.value'),
            optionalAddress: getValues('optionalAddress'),
          }),
        )
        await dispatch(
          saveDeliveryAddressAction(
            isCancelOffer
              ? ScreenName.UPSELL_GUASHA_CANCEL
              : ScreenName.UPSELL_GUASHA,
          ),
        )
      }
    },
    [dispatch, getValues, isCancelOffer, isValid],
  )

  return (
    <S.Wrapper>
      <Container>
        <S.CloseButton
          data-testid="close-btn"
          onClick={isCancelOffer ? goToNextPage : onClose}
        />
        {selectedSubscription && (
          <GuashaOrderSummary selectedSubscription={selectedSubscription} />
        )}

        <S.Subtitle>{t('checkoutGuasha.deliveryForm.subtitle')}</S.Subtitle>
        <S.Form>
          <Input
            labelName={t('checkoutGuasha.deliveryForm.country.label')}
            value={t('checkoutGuasha.deliveryForm.country.value')}
            disabled
          />
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                labelName={t('checkoutGuasha.deliveryForm.firstName.label')}
                isValid={!errors.firstName}
                {...(errors.firstName && {
                  validationText: t(
                    `checkoutGuasha.deliveryForm.firstName.error`,
                  ),
                })}
              />
            )}
            rules={{ required: true }}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <Input
                labelName={t('checkoutGuasha.deliveryForm.lastName.label')}
                isValid={!errors.lastName}
                {...(errors.lastName && {
                  validationText: t(
                    'checkoutGuasha.deliveryForm.lastName.error',
                  ),
                })}
                {...field}
              />
            )}
            rules={{ required: true }}
          />
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <Input
                labelName={t('checkoutGuasha.deliveryForm.address.label')}
                isValid={!errors.address}
                {...(errors.address && {
                  validationText:
                    getValues('address').length > USER_FORM_FIELD_MAX_LENGTH
                      ? t('checkoutGuasha.deliveryForm.address.error_length')
                      : t('checkoutGuasha.deliveryForm.address.error_default'),
                })}
                {...field}
              />
            )}
            rules={{
              required: true,
              validate: (value) => {
                return (
                  !!value.trim().length &&
                  value.length <= USER_FORM_FIELD_MAX_LENGTH
                )
              },
            }}
          />
          {isOptionalAddress ? (
            <Controller
              name="optionalAddress"
              control={control}
              render={({ field }) => (
                <Input
                  labelName={t(
                    'checkoutGuasha.deliveryForm.optionalAddress.label',
                  )}
                  {...field}
                />
              )}
            />
          ) : (
            <S.OptionalAddressActivator
              onClick={() => setIsOptionalAddress(true)}
            >
              {t('checkoutGuasha.deliveryForm.optionalAddress.activator')}
            </S.OptionalAddressActivator>
          )}
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                labelName={t('checkoutGuasha.deliveryForm.city.label')}
                isValid={!errors.city}
                {...(errors.city && {
                  validationText:
                    getValues('city').length > USER_FORM_FIELD_MAX_LENGTH
                      ? t('checkoutGuasha.deliveryForm.city.error_length')
                      : t('checkoutGuasha.deliveryForm.city.error_default'),
                })}
              />
            )}
            rules={{
              required: true,
              validate: (value) => {
                return (
                  !!value.trim().length &&
                  value.length <= USER_FORM_FIELD_MAX_LENGTH
                )
              },
            }}
          />
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <S.SelectContainer>
                <SelectWithFloatPlaceholder
                  {...field}
                  placeholder={t('checkoutGuasha.deliveryForm.state.label')}
                  options={US_STATES_OPTIONS}
                  isValid={!errors.state}
                  onBlur={() => {
                    if (getValues().state) {
                      trigger('zipCode')
                    }
                  }}
                  {...(errors.state && {
                    validationText: t(
                      'checkoutGuasha.deliveryForm.state.error',
                    ),
                  })}
                />
              </S.SelectContainer>
            )}
            rules={{ required: true }}
          />
          <Controller
            name="zipCode"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                labelName={t('checkoutGuasha.deliveryForm.zipCode.label')}
                isValid={!errors.zipCode}
                {...(errors.zipCode && {
                  validationText: getValues('state')
                    ? t('checkoutGuasha.deliveryForm.zipCode.error', {
                        state: getValues('state')?.label,
                      })
                    : t('checkoutGuasha.deliveryForm.zipCode.error_default'),
                })}
              />
            )}
            rules={{
              required: true,
              validate: (value) => {
                const state = getStateByCode(value)
                return (
                  state === getValues('state')?.value &&
                  ONLY_DIGITS_REGEX.test(value)
                )
              },
            }}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                labelName={t('checkoutGuasha.deliveryForm.phone.label')}
                isValid={!errors.phone}
                hintText={t('checkoutGuasha.deliveryForm.phone.hint')}
                {...(errors.phone && {
                  validationText:
                    getValues('phone') && !PHONE_REGEX.test(getValues('phone'))
                      ? t('checkoutGuasha.deliveryForm.phone.error')
                      : t('checkoutGuasha.deliveryForm.phone.error_default'),
                })}
              />
            )}
            rules={{
              required: true,
              validate: (value) => {
                return PHONE_REGEX.test(value) && value.trim() !== ''
              },
            }}
          />
        </S.Form>

        <S.ShippingInfoSubtitle>
          {t('checkoutGuasha.shipping.subtitle')}
        </S.ShippingInfoSubtitle>
        <S.ShippingInfoWrapper>
          <S.ShippingInfoIcon />
          <S.ShippingInfoContent>
            <S.ShippingInfoText>
              {t('checkoutGuasha.shipping.text')}
            </S.ShippingInfoText>
            <S.ShippingInfoDescription>
              {t('checkoutGuasha.shipping.description')}
            </S.ShippingInfoDescription>
          </S.ShippingInfoContent>
        </S.ShippingInfoWrapper>

        <S.SubmitButton
          type="button"
          onClick={handleSubmit}
          disabled={!isValid || isFetching}
        >
          {t('purchase2.checkout.confirmPayment')}
        </S.SubmitButton>
        <PaymentWaitingModal
          resetErrorCallback={continueWithError}
          successCallback={continueWithSuccess}
          successSubtitle="checkoutGuasha.payment.successModalSubtitle"
          isPaymentWaitingShown={isPaymentWaitingShown}
          setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          errorButtonText={t`guashaUpsell.modalAction`}
        />
      </Container>
    </S.Wrapper>
  )
}
