import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledLikeChatAnalyzingAnswers = {
  Root: styled.div`
    padding-bottom: 24px;
  `,
  ResetMargin: styled.div`
    & div:nth-child(6) {
      margin-bottom: 0;
    }
  `,
  Message: styled.span`
    display: flex;
    align-items: center;

    & img {
      max-width: 16px;
      margin-right: 12px;
    }
  `,
  RatingContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 8px;
  `,
  Rating: styled.div`
    display: flex;
    svg {
      margin: 0 1px;
      width: 20px;
      height: 18px;
    }
  `,
  Review: styled.div`
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px #dfe3f3;
    padding: 16px;
  `,
  Reviewer: styled.h3`
    text-align: center;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.DARK};
  `,
  ReviewText: styled.p`
    text-align: center;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: ${Color.LIGHT};
    line-height: 16px;
    margin-bottom: 8px;
  `,
}
