import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledLongPaywallUserGoalsGallery = {
  Container: styled.div`
    max-width: 360px;
    margin: 0 auto 32px;
    background-color: ${Color.WHITE};

    & .swiper-wrapper {
      padding-bottom: 48px;
    }

    & span.swiper-pagination-bullet {
      background-color: ${Color.CREAMY};
      opacity: 1;
    }

    & span.swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: ${Color.PRIMARY};
    }
  `,
  Subtitle: styled.h2`
    text-align: center;
    color: ${Color.DARK};
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
  `,
  ImageWrapper: styled.div`
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.5);
  `,
  SlideImage: styled.img`
    display: block;
    width: auto;
    height: 208px;
    border-radius: 16px 16px 0 0;
  `,
  User: styled.div`
    padding: 16px 0 16px;
    width: 100%;
    text-align: center;
    color: ${Color.BLUE_DARK};
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  `,
}
