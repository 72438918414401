import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

import { ChatAuthor } from './ChatMessage'

type TAuthorProps = {
  author: ChatAuthor
}

type TChatProps = {
  hasHeader: boolean
  isImage: boolean
  width?: string
} & TAuthorProps

type TRootProps = {
  isShown: boolean
} & TAuthorProps

const headerTextStyles = css`
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

export const StyledChatMessage = {
  Root: styled.div<TRootProps>`
    display: flex;
    gap: 8px;

    ${({ author }) =>
      author === ChatAuthor.EXPERT
        ? css`
            justify-content: flex-start;
            margin-bottom: 16px;
          `
        : css`
            justify-content: flex-end;
            margin-bottom: 24px;
          `};

    ${({ isShown }) =>
      isShown
        ? css`
            transition: all 0.5s ease;
            transform: scale(1);
          `
        : css`
            transform: scale(0);
            height: 0;
            visibility: hidden;
            margin-bottom: 0;
          `}
  `,
  ExpertPhoto: styled.img<TAuthorProps>`
    width: 36px;
    height: 36px;

    ${({ author }) => author === ChatAuthor.USER && 'display: none;'}
  `,
  ContentWrapper: styled.div``,
  Content: styled.div<TChatProps>`
    width: ${({ width }) => width || '252px'};
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    position: relative;

    ${({ hasHeader, author }) =>
      !hasHeader &&
      author === ChatAuthor.EXPERT &&
      css`
        margin-left: 44px;
      `}

    ${({ author }) =>
      author === ChatAuthor.USER
        ? css`
            padding: 16px;
            border-radius: 20px 4px 20px 20px;
            background-color: rgba(255, 130, 118, 0.24);
            color: ${Color.DARK};

            strong {
              font-weight: 600;
            }
          `
        : css`
            padding: 12px 16px 16px;
            border-radius: 4px 20px 20px 20px;
            background-color: ${Color.CORAL_GRAY};
            color: ${Color.GRAY};

            strong {
              font-weight: 500;
              line-height: 24px;
              color: ${Color.DARK};
            }
          `}

    ${({ isImage }) =>
      isImage &&
      css`
        padding: 0;
        border-radius: unset;
        background-color: transparent;
      `}
  `,
  MessageHeader: styled.div<TAuthorProps>`
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    align-items: center;
    justify-content: ${({ author }) =>
      author === ChatAuthor.USER ? 'flex-end' : 'space-between'};
  `,
  Author: styled.p`
    ${headerTextStyles}
    color: ${Color.DARK};
  `,
  Time: styled.p`
    ${headerTextStyles}
    color: ${Color.LIGHT};
  `,
}
