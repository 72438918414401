import React from 'react'
import { useTranslation } from 'react-i18next'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { StyledDisclaimer as S } from './Disclaimer.styles'

export const Disclaimer: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      {t`login.disclaimer`} <PrivacyPolicyLink /> {t`login.and`}{' '}
      <TermsOfUseLink />
    </S.Container>
  )
}
