import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useKeywordValue } from 'hooks/useKeywordValue'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PageId } from 'page-constants'
import { CDN_FOLDER_LINK, MediaBreakpoint, OptionType } from 'root-constants'

import { StyledIntroVariant1 as S } from './IntroVariant1.styles'
import {
  DESKTOP_CDN_PATH,
  MOBILE_CDN_PATH,
  OPTION_VALUES,
  QUESTION,
} from './constants'

export const IntroVariant4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const [answer, setAnswer] = useState<string>('')
  const cohortToUse = useCohortToUse()

  const keyword = useKeywordValue()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  useStartSession()

  const deviceCDNPath = useMemo(
    () =>
      document.body.clientWidth <= MediaBreakpoint.MAX_PHONE
        ? MOBILE_CDN_PATH
        : DESKTOP_CDN_PATH,
    [],
  )

  const handleContinue = useNextStep({
    pageId: PageId.INTRO,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())
        window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        handleChange(value, handleContinue)
        setAnswer(value)
        setIsAnswersDisabled(true)
      },
    }),
    [
      pageId,
      dispatch,
      uuid,
      handleChange,
      handleContinue,
      setIsAnswersDisabled,
    ],
  )

  return (
    <S.VideoCohortRoot>
      <IntroHeader colorTheme={IntroHeaderColor.LIGHT} />
      <S.BackgroundVideo
        id="background-video"
        autoPlay
        loop
        playsInline
        muted
        poster={`${CDN_FOLDER_LINK}/videos/${cohortToUse}_${deviceCDNPath}-poster.jpg`}
        src={`${CDN_FOLDER_LINK}/videos/${cohortToUse}_${deviceCDNPath}-start-bg-video.mp4`}
      />
      <S.Container>
        <div>
          <S.VideoCohortTitle>
            {keyword
              ? t(`onboarding.intro.title.${keyword}`)
              : t('onboarding.introV4.title')}
          </S.VideoCohortTitle>
          <S.ActionCall>{t`onboarding.intro.question`}</S.ActionCall>
          <S.Actions>
            <S.StyledOption
              {...optionProps}
              withoutMargin
              value={OPTION_VALUES.GET_RID}
              disabled={isAnswersDisabled}
              isChecked={answer === OPTION_VALUES.GET_RID}
            >
              <S.Action>
                {keyword
                  ? t(`onboarding.intro.firstAnswer.${keyword}`)
                  : t('onboarding.introV4.firstAnswer')}
              </S.Action>
            </S.StyledOption>
            <S.StyledOption
              {...optionProps}
              withoutMargin
              value={OPTION_VALUES.REDUCE_FAT}
              disabled={isAnswersDisabled}
              isChecked={answer === OPTION_VALUES.REDUCE_FAT}
            >
              <S.Action>
                {keyword
                  ? t(`onboarding.intro.secondAnswer.${keyword}`)
                  : t('onboarding.introV4.secondAnswer')}
              </S.Action>
            </S.StyledOption>
          </S.Actions>
          <S.RadioButton
            {...optionProps}
            value={OPTION_VALUES.BOTH_OPTIONS}
            disabled={isAnswersDisabled}
          >
            <S.RadioButtonLabel
              isChecked={answer === OPTION_VALUES.BOTH_OPTIONS}
            >
              {t`onboarding.intro.bothOptions`}
            </S.RadioButtonLabel>
          </S.RadioButton>
          <S.Terms>
            <p>{t`onboarding.intro.termsTitle`}</p>
            <TermsOfUseLink />
            <S.Separator />
            <PrivacyPolicyLink />
          </S.Terms>
          <S.Footnote>{t`onboarding.intro.footnote`}</S.Footnote>
        </div>
      </S.Container>
    </S.VideoCohortRoot>
  )
}
