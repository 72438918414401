import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import { useActiveCohortIdentifier } from './useActiveCohortIdentifier'

export const useGoaffproInitialization = (): void => {
  const userCountryCode = useSelector(selectUserCountryCode)
  const { isLuvlyIntroFastCohort } = useActiveCohortIdentifier()

  useLayoutEffect(() => {
    if (window.goaffproTrackConversion || !isLuvlyIntroFastCohort) {
      return
    }

    const script = document.createElement('script')
    script.src = 'https://api.goaffpro.com/loader.js?shop=bwhmshbsty'
    script.async = true
    document.head.appendChild(script)
  }, [isLuvlyIntroFastCohort, userCountryCode])
}
