import React from 'react'

import logoSvg from 'assets/images/sprite/logo.svg'

import { SvgImage } from '../SvgImage'
import { StyledHeader as S } from './Header.styles'

export const Header: React.FC = () => (
  <S.Wrapper>
    <SvgImage svg={logoSvg} />
  </S.Wrapper>
)
