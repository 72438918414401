import React, { InputHTMLAttributes, useMemo } from 'react'

import { OptionType } from 'root-constants'

import { StyledOption as S } from './Option.styles'

export type TProps = {
  type?: OptionType
  value: string
  name?: string
  checked?: boolean
  fullWidth?: boolean
  withoutMargin?: boolean
  disabled?: boolean
  onChange?: (value: string, isChecked: boolean) => void
  children?: React.ReactNode
}

export const Option: React.FC<TProps> = ({
  type,
  value,
  name,
  checked,
  disabled,
  onChange,
  children,
  ...rest
}) => {
  const inputProps = useMemo((): InputHTMLAttributes<HTMLInputElement> => {
    const props: InputHTMLAttributes<HTMLInputElement> = {
      value,
      type,
      onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) =>
        onChange && onChange(target.value, target.checked),
    }

    if (name) {
      props.name = name
    }

    if (checked !== undefined) {
      props.checked = checked
    }

    return props
  }, [checked, name, onChange, type, value])

  return (
    <S.OptionContainer {...rest}>
      <input disabled={disabled} {...inputProps} />
      {children}
    </S.OptionContainer>
  )
}
