import React, { useCallback, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'

import { AnswerButton, AnswerButtonHeight } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import invertedSmileEmoji from 'assets/images/inverted-smile-emoji.png'
import loveEmoji from 'assets/images/love-emoji.png'
import surprisedEmoji from 'assets/images/surprised-emoji.png'

import { goBack } from 'browser-history'
import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledSatisfyWithSkinCondition as S } from './SatisfyWithSkinCondition.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const SatisfyWithSkinCondition: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const includeNavigation = useIsNavigationOn()
  const cohortToUse = useCohortToUse()
  const { isSeniorCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(answer),
    [answer, handleContinue],
  )

  const handleAnswerChange = useCallback(
    (value: string) => {
      if (includeNavigation) {
        setAnswer(value)
        return
      }

      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, includeNavigation, setIsAnswersDisabled],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: handleAnswerChange,
    }),
    [handleAnswerChange, pageId],
  )

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <PageTitle>
            <Trans
              i18nKey="onboarding.satisfyWithSkinCondition.question"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </PageTitle>
        </S.TitleContainer>

        <S.OptionsContainer>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.LIKE_IT}
          >
            <AnswerButton iconSrc={loveEmoji}>
              <Trans
                i18nKey="onboarding.satisfyWithSkinCondition.likeIt"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
                components={[<br />]}
              />
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.NOT_SURE}
          >
            <AnswerButton
              iconSrc={invertedSmileEmoji}
              {...(isSeniorCohort && {
                height: AnswerButtonHeight.L,
              })}
            >
              <Trans
                i18nKey="onboarding.satisfyWithSkinCondition.notSure"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
              />
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.HAVE_PROBLEMS}
          >
            <AnswerButton
              iconSrc={surprisedEmoji}
              {...(isSeniorCohort && {
                height: AnswerButtonHeight.L,
              })}
            >
              <Trans
                i18nKey="onboarding.satisfyWithSkinCondition.haveProblems"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
                components={[<br />]}
              />
            </AnswerButton>
          </Option>
        </S.OptionsContainer>
      </Container>
      {includeNavigation && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
