import styled from 'styled-components'

import optionIconUrl from 'assets/images/check-icon.svg'
import bgWhatYouGetUrl from 'assets/images/luvly-benefits-bg.png'

export const StyledLuvlyBenefits = {
  Wrapper: styled.div`
    margin: 0 -20px;
    padding: 25px 15px 75px 35px;
    background-image: url(${bgWhatYouGetUrl});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  `,
  Title: styled.h2`
    text-align: center;
    padding: 0 15px 20px 0;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  ListItem: styled.li`
    position: relative;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
    padding: 0 5px 0 36px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url(${optionIconUrl}) center no-repeat;
      background-size: contain;
    }
  `,
}
