import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import luvlyUsers from 'assets/images/happy-users.png'

import { StyledSocialProof as S } from './SocialProof.styles'
import { QUESTION } from './constants'

export const SocialProof: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.Root>
      <S.Title>{t('onboarding.socialProof.question')}</S.Title>
      <S.Description>{t('onboarding.socialProof.description')}</S.Description>
      <S.ImageContainer>
        <S.Image src={luvlyUsers} alt="" />
      </S.ImageContainer>
      <S.ButtonContainer>
        <Button onClick={handleNextClick}>{t('actions.continue')}</Button>
      </S.ButtonContainer>
    </S.Root>
  )
}
