import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSkinDiagnosisIntro = {
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.DARK};
    margin: 24px 0;
  `,
  Description: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    padding: 0 16px;
    color: ${Color.LIGHT};
    margin-bottom: 24px;
  `,
}
