import airplaneEmoji from 'assets/images/airplane-emoji.png'
import beachEmoji from 'assets/images/beach-emoji.png'
import partyEmoji from 'assets/images/party-emoji.png'
import sweetsEmoji from 'assets/images/sweets-emoji.png'
import thinkEmoji from 'assets/images/think-emoji.png'
import weddingEmoji from 'assets/images/wedding-emoji.png'

export const QUESTION = 'Do you have any important event coming up?'

export const OPTION_VALUES = {
  WEDDING: 'wedding',
  VACATION: 'vacation',
  BEACH: 'beach_trip',
  PARTY: 'party',
  OTHER: 'other',
  NO_OCCASION: 'no',
}

export const OCCASION_OPTIONS = [
  {
    value: OPTION_VALUES.WEDDING,
    text: 'onboarding.occasion.wedding',
    icon: weddingEmoji,
  },
  {
    value: OPTION_VALUES.VACATION,
    text: 'onboarding.occasion.vacation',
    icon: airplaneEmoji,
  },
  {
    value: OPTION_VALUES.BEACH,
    text: 'onboarding.occasion.beachTrip',
    icon: beachEmoji,
  },
  {
    value: OPTION_VALUES.PARTY,
    text: 'onboarding.occasion.party',
    icon: partyEmoji,
  },
  {
    value: OPTION_VALUES.OTHER,
    text: 'onboarding.occasion.other',
    icon: sweetsEmoji,
  },
  {
    value: OPTION_VALUES.NO_OCCASION,
    text: 'onboarding.occasion.noOccasion',
    icon: thinkEmoji,
  },
]
