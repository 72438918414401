import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { getSupportFormLink } from 'helpers/getSupportFormLink'

import datePickerIcon from 'assets/images/datepicker-with-heart.png'
import moneyBackIcon from 'assets/images/moneyback.png'
import notebookIcon from 'assets/images/notebook-open.png'

import { StyledPurchaseRefundInstructions as S } from './PurchaseRefundInstructions.styles'

type TPurchaseRefundInstructionsProps = {
  handleOpenCheckout: () => void
}

export const PurchaseRefundInstructions: React.FC<
  TPurchaseRefundInstructionsProps
> = ({ handleOpenCheckout }: TPurchaseRefundInstructionsProps) => {
  const { t } = useTranslation()
  const contactFormLink = getSupportFormLink()

  return (
    <S.Wrapper>
      <Container>
        <S.Banner>{t('purchaseRefund.instructions.banner')}</S.Banner>
        <S.Title>{t('purchaseRefund.instructions.title')}</S.Title>
        <S.Subtitle>
          <Trans
            i18nKey="purchaseRefund.instructions.subtitle"
            components={[<strong />]}
          />
        </S.Subtitle>
        <S.Steps>
          <S.StepWrapper>
            <S.StepImage src={datePickerIcon} />
            <S.StepContent>
              <S.StepTitle>
                {t('purchaseRefund.instructions.step1.title')}
              </S.StepTitle>
              <S.StepDescription>
                {t('purchaseRefund.instructions.step1.description')}
              </S.StepDescription>
            </S.StepContent>
          </S.StepWrapper>
          <S.StepWrapper>
            <S.StepImage src={notebookIcon} />
            <S.StepContent>
              <S.StepTitle>
                {t('purchaseRefund.instructions.step2.title')}
              </S.StepTitle>
              <S.StepDescription>
                {t('purchaseRefund.instructions.step2.descriptionStart')}
                <a
                  href={contactFormLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('purchaseRefund.instructions.step2.linkText')}
                </a>
                <Trans
                  i18nKey="purchaseRefund.instructions.step2.descriptionEnd"
                  components={[<strong />]}
                />
              </S.StepDescription>
            </S.StepContent>
          </S.StepWrapper>
          <S.StepWrapper>
            <S.StepImage src={moneyBackIcon} />
            <S.StepContent>
              <S.StepTitle>
                {t('purchaseRefund.instructions.step3.title')}
              </S.StepTitle>
              <S.StepDescription>
                {t('purchaseRefund.instructions.step3.description')}
              </S.StepDescription>
            </S.StepContent>
          </S.StepWrapper>
        </S.Steps>
        <S.Disclaimer>
          {t('purchaseRefund.instructions.disclaimer')}
          <TermsOfUseLink />
        </S.Disclaimer>
        <Button onClick={handleOpenCheckout}>{t`actions.getMyPlan`}</Button>
      </Container>
    </S.Wrapper>
  )
}
