import activeIngredientsIcon from 'assets/images/active-ingredients-products.png'
import appropriateProductsIcon from 'assets/images/appropriate-products.png'
import coldCompressIcon from 'assets/images/cold-compress.png'
import maskIcon from 'assets/images/mask.png'
import naturalRemediesIcon from 'assets/images/natural-remedies.png'
import regularMassageIcon from 'assets/images/regular-massage.png'
import skincareProductsIcon from 'assets/images/skincare-products.png'
import sleepWellIcon from 'assets/images/sleep-well.png'

export const QUESTION = 'Our recommendations based on your facial scan results'

export const enum Diagnosis {
  ACNE = 'acne',
  BLACK_ROUND = 'black_round',
  WRINKLES = 'wrinkles',
}

export const ACNE_RECOMMENDATIONS = {
  title: 'skinDiagnosis.recommendations.acneTitle',
  tips: [
    {
      id: '1',
      icon: skincareProductsIcon,
      text: 'skinDiagnosis.recommendations.acneTip1',
    },
    {
      id: '2',
      icon: maskIcon,
      text: 'skinDiagnosis.recommendations.acneTip2',
    },
    {
      id: '3',
      icon: activeIngredientsIcon,
      text: 'skinDiagnosis.recommendations.acneTip3',
    },
  ],
}

export const DARK_CIRCLES_RECOMMENDATIONS = {
  title: 'skinDiagnosis.recommendations.darkCirclesTitle',
  tips: [
    {
      id: '1',
      icon: appropriateProductsIcon,
      text: 'skinDiagnosis.recommendations.darkCirclesTip1',
    },
    {
      id: '2',
      icon: regularMassageIcon,
      text: 'skinDiagnosis.recommendations.darkCirclesTip2',
    },
    {
      id: '3',
      icon: coldCompressIcon,
      text: 'skinDiagnosis.recommendations.darkCirclesTip3',
    },
    {
      id: '4',
      icon: sleepWellIcon,
      text: 'skinDiagnosis.recommendations.darkCirclesTip4',
    },
  ],
}

export const WRINKLES_RECOMMENDATIONS = {
  title: 'skinDiagnosis.recommendations.wrinklesTitle',
  tips: [
    {
      id: '1',
      icon: naturalRemediesIcon,
      text: 'skinDiagnosis.recommendations.wrinklesTip1',
    },
    {
      id: '2',
      icon: regularMassageIcon,
      text: 'skinDiagnosis.recommendations.wrinklesTip2',
    },
  ],
}

export const DIAGNOSIS_RECOMMENDATIONS_MAP = {
  [Diagnosis.ACNE]: ACNE_RECOMMENDATIONS,
  [Diagnosis.BLACK_ROUND]: DARK_CIRCLES_RECOMMENDATIONS,
  [Diagnosis.WRINKLES]: WRINKLES_RECOMMENDATIONS,
}
