import { useMemo } from 'react'
import { useLocation } from 'react-router'

import { convertFromSnakeCaseToText } from 'helpers/convertFromSnakCaseToText'

import { LANG_QUERY_PARAM } from 'root-constants'

export const enum DynamicTitles {
  HEADLINE = 'headline',
  SUBTEXT = 'subtext',
}

export const useTitleFromUrl = (): { headline: string; subtext: string } => {
  const { search } = useLocation()

  return useMemo(() => {
    const URLParams = new URLSearchParams(search)

    if (!URLParams.get(LANG_QUERY_PARAM)) {
      return {
        headline: '',
        subtext: '',
      }
    }

    const headlineFromUrl = URLParams.get(DynamicTitles.HEADLINE)
    const subtextFromUrl = URLParams.get(DynamicTitles.SUBTEXT)

    return {
      headline: convertFromSnakeCaseToText(headlineFromUrl),
      subtext: convertFromSnakeCaseToText(subtextFromUrl),
    }
  }, [search])
}
