import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'

import {
  sendUserAnswersAction,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import { EMAIL_QUERY_PARAM } from 'root-constants'

export const ChatBotEmail: React.FC = () => {
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  useEffect(() => {
    const chatBotEmail = sessionStorage.getItem(EMAIL_QUERY_PARAM) || ''
    if (!chatBotEmail) return

    dispatch(sendUserEmailAction({ email: chatBotEmail }))
    dispatch(sendUserAnswersAction())
    eventLogger.logEmailPageCompleted({ email: chatBotEmail })
    window.fbq('track', 'Lead', {}, { eventID: uuid })
  }, [dispatch, uuid])

  return <Spinner />
}
