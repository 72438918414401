import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { PageTitle } from 'components/PageTitle'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledCosmetologistVsLuvly as S } from './CosmetologistVsLuvly.styles'
import { IMAGE_PATH, QUESTION } from './constants'

export const CosmetologistVsLuvly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const imageLink = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH}_${language}.png`,
    [language],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <div>
      <S.TitleContainer>
        <PageTitle>{t('onboarding.cosmetologistVsLuvly.question')}</PageTitle>
      </S.TitleContainer>
      <Container>
        <S.Description>
          <Trans
            i18nKey="onboarding.cosmetologistVsLuvly.description"
            components={[<strong />, <br />]}
          />
        </S.Description>
        <S.ImageContainer>
          <S.Graph src={imageLink} alt="" />
        </S.ImageContainer>
        <S.Description>
          <Trans
            i18nKey="onboarding.cosmetologistVsLuvly.extraDescription"
            components={[<strong />, <br />]}
          />
        </S.Description>
      </Container>

      <NavigationButtons onBackClick={goBack} onNextClick={handleNextClick} />
    </div>
  )
}
