import styled, { keyframes } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

const spinKeyframes = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`

type TSlideTextProps = {
  isLargeSize?: boolean
}

export const StyledPreparePlan = {
  SpinnerContainer: styled.div`
    margin: 25px 0 23px;
    text-align: center;
  `,
  Text: styled.p`
    margin-top: -10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
  `,
  Circle: styled(SvgImage)`
    animation-name: ${spinKeyframes};
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 320/320;
  `,
  Image: styled.img`
    max-width: 100%;
  `,
  SlideText: styled.p<TSlideTextProps>`
    margin-top: 30px;
    padding: ${({ isLargeSize }) => (isLargeSize ? '0 0 40px' : '0 15px 40px')};
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  `,
}
