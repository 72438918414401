import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellGuashaFacts = {
  Root: styled.div`
    margin-bottom: 40px;
  `,
  Content: styled.div`
    position: relative;
    padding: 36px 24px 24px;
    background: linear-gradient(318deg, #d4b3ff 0%, #606bc3 99.89%),
      linear-gradient(135deg, #8aa679 -28.4%, #83bab3 100%), #fff;
    border-radius: 32px;
    margin-bottom: 24px;
  `,
  List: styled.ul``,
  Item: styled.li`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
    color: ${Color.WHITE};

    & svg {
      flex-shrink: 0;
    }
  `,
  ImageContainer: styled.div`
    position: absolute;
    width: 136px;
    right: 24px;
    bottom: 24px;
  `,
}
