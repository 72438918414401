import styled from 'styled-components'

import leftArrow from 'assets/images/swiper-left-arrow.svg'
import rightArrow from 'assets/images/swiper-right-arrow.svg'

import { Color } from 'root-constants'

export const StyledWellnessBundleBeforeAfterGallery = {
  Wrapper: styled.div`
    margin-bottom: 40px;
    border-radius: 20px;
    box-shadow: 0px 8px 20px 0px rgba(204, 207, 222, 0.8);
    padding: 24px 0;

    & .swiper {
      padding-bottom: 32px;
    }

    & .swiper-pagination-bullets {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background: ${Color.PRIMARY};
    }

    .swiper-button-prev,
    .swiper-button-next {
      height: 32px;
      width: 32px;
      border-radius: 22px;
      background-color: ${Color.WHITE};
      filter: drop-shadow(0px 8px 20px rgba(85, 85, 85, 0.43));
      top: 210px;
      margin: 0;
      z-index: 2;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
    }
    .swiper-button-prev {
      left: 0;
      &:after {
        content: url(${leftArrow});
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 0;
      }
    }
    .swiper-button-next {
      right: 0;
      &:after {
        content: url(${rightArrow});
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 0;
      }
    }
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    color: ${Color.BLUE_DARK};
  `,
  LabelImage: styled.img`
    margin: 0 auto 16px;
    height: 32px;
    width: auto;
  `,
  Image: styled.img`
    padding: 0 10px;
    margin-bottom: 16px;
  `,
  AppName: styled.p`
    padding: 0 10px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    color: ${Color.PRIMARY};
    margin-bottom: 16px;
  `,
  Author: styled.div`
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.DARK_SECONDARY};
    padding-bottom: 8px;
    border-bottom: 1px solid #e3e4e8;
    margin-bottom: 8px;
  `,
  Review: styled.p`
    padding: 0 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.LIGHT};
  `,
}
