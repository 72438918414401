import styled from 'styled-components'

import greenCheckbox from 'assets/images/green-checkbox.svg'
import selectedPriceBadgeUrl from 'assets/images/selected-price-badge.svg'

import { Color } from 'root-constants'

export const StyledCancelOfferSubscriptionItem = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
    border-radius: 10px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px #d1d6eb;
  `,
  Content: styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
    border-radius: 0 0 10px 10px;

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      mix-blend-mode: normal;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      transition: opacity 0.5s ease-out;
      background-image: url(${greenCheckbox});
    }
  `,
  MostPopularBadge: styled.div`
    height: 24px;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(97.03deg, #70cd1c 3.15%, #a2ea35 88.83%);
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
  PlanPeriod: styled.span`
    padding-left: 56px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.DARK};
  `,
  PriceContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  PreviousPrice: styled.span`
    margin-top: 18px;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    text-decoration: line-through;
    text-decoration-color: #e65c3e;
    color: ${Color.DARK};
  `,
  CurrentPrice: styled.p`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 90px;
    padding: 21px 0;
    object-fit: contain;
    transition: background 0.5s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${selectedPriceBadgeUrl});
    color: ${Color.WHITE};
  `,
  PriceValue: styled.span`
    padding-right: 15px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  `,
  Period: styled.span`
    padding-right: 15px;
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
  `,
}
