import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const CenteredImage = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 188px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
    mix-blend-mode: normal;
    opacity: 0.5;
  }
`

export const FaceScanStyles = {
  Img: styled.img`
    ${CenteredImage};
    object-fit: cover;
  `,
  AnimationContainer: styled.div`
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      scale: 2;
    }
  `,
  ImagesContainer: styled.div`
    ${CenteredImage};

    video {
      height: 100%;
    }
  `,
  Button: styled.button`
    position: absolute;
    bottom: 2%;
    background: transparent;
    left: 50%;
    transform: translate(-50%, 0);
    border: none;
  `,
  FaceContour: styled.img`
    height: 365px;
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(transparent 154px, rgba(0, 0, 0, 0.5) 150px);
    box-shadow: 0 0 0 999em rgba(0, 0, 0, 0.5);
  `,
  ProgressWrapper: styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    bottom: 11%;
    height: 82px;

    progress {
      margin: 0 auto;
      max-width: 320px;
      border-radius: 10px;
      height: 4px;
      overflow: hidden;
    }
  `,
  AnalysisText: styled.p`
    margin: 0 auto;
    width: 288px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: ${Color.WHITE};
    text-align: center;
  `,
  SkipBtn: styled.button`
    position: absolute;
    z-index: 1;
    bottom: calc(1% + 40px);
    left: 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: none;
    background-color: transparent;
    color: ${Color.WHITE};
  `,
}
