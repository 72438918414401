import { useEffect } from 'react'

import { createProductId } from 'helpers/createProductId'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import { ScreenName } from 'root-constants'

type TUseLogSalePageShownProps = {
  subscriptions: ISubscription[]
  screenName?: ScreenName
}

export const useLogSalePageShown = ({
  subscriptions,
  screenName,
}: TUseLogSalePageShownProps): void => {
  useEffect(() => {
    if (!subscriptions.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptions.map(({ mainPrices }) => {
        const { fullPrice, periodName, periodQuantity } = mainPrices

        return createProductId({
          periodName,
          periodQuantity,
          price: fullPrice,
        })
      }),
      screenName,
    })
  }, [subscriptions, screenName])
}
