import blushEmoji from 'assets/images/blush-emoji.png'
import CustomerReview1 from 'assets/images/customer-photo-1.png'
import CustomerReview2 from 'assets/images/customer-photo-2.png'
import CustomerReview3 from 'assets/images/customer-photo-3.png'

import { PageId } from 'page-constants'
import { Cohort } from 'root-constants'

export const QUESTIONS = {
  [PageId.SHORT_PROGRAM_DESCRIPTION]:
    'The only tighten skin program you will ever need',
  [PageId.SHORT_PROGRAM_DESCRIPTION_SECOND]:
    'The only tighten skin program you will ever need 2',
  [PageId.SHORT_PROGRAM_DESCRIPTION_AS_RESULT]:
    'The ultimate skin-tightening program for you',
}

export const SHORT_PROGRAM_DESCRIPTION_DEFAULT_TITLE_TEXT =
  'onboarding.shortProgramDescription.question.default'

export const SHORT_PROGRAM_DESCRIPTION_CONTEXT_TITLE_MAP = {
  [Cohort.LUVLY_COSMETIC_SCANNER]:
    'onboarding.shortProgramDescription.question.default_cosmetic',
}

export const SENIOR_CUSTOMER_REVIEWS = [
  {
    name: 'onboarding.shortProgramDescription.secondUserName',
    icon: CustomerReview1,
    review: 'onboarding.shortProgramDescription.firstUserReview',
    emoji: [blushEmoji],
  },
  {
    name: 'onboarding.shortProgramDescription.firstUserName',
    icon: CustomerReview2,
    review: 'onboarding.shortProgramDescription.secondUserReview',
  },
  {
    icon: CustomerReview3,
    name: 'onboarding.shortProgramDescription.thirdUserName',
    review: 'onboarding.shortProgramDescription.thirdUserReview',
  },
]
