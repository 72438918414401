import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledSeparator as S } from './Separator.styles'

export const Separator: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.Line />
      <S.TextBlock>{t`purchase2.checkout.or`}</S.TextBlock>
      <S.Line />
    </S.Container>
  )
}
