import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledHeader = {
  Wrapper: styled.header`
    position: relative;
    padding: 13px 0;
    width: 100%;
    height: 50px;
    margin: 0 auto 15px;
    display: flex;
    justify-content: center;
    box-shadow: 0 5px 20px #dfe3f3;
    background-color: ${Color.WHITE};
    z-index: 3;
  `,
}
