import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { setDeletedSubscriptionIdAction } from 'root-redux/actions/user'

import { goTo } from 'browser-history'

import { StyledHowToUnsubscribe as S } from './HowToUnsubscribe.styles'

export const HowToUnsubscribe: React.FC<{ subscriptionId?: string }> = ({
  subscriptionId = '',
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const [isCollapsed, setIsCollapsed] = useState(true)
  const contentElementRef = useRef<HTMLDivElement>(null)
  const contentElementHeight = useMemo(
    () => contentElementRef?.current?.getBoundingClientRect().height || 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCollapsed],
  )

  const handleClick = useCallback(() => {
    dispatch(setDeletedSubscriptionIdAction(subscriptionId))
    goTo({ pathname: 'reasons', search })
  }, [dispatch, subscriptionId, search])

  return (
    <S.Wrapper isCollapsed={isCollapsed} contentHeight={contentElementHeight}>
      <S.Button
        onClick={() => {
          setIsCollapsed((currentIsCollapsed) => !currentIsCollapsed)
        }}
      >
        <S.Plus />
        <S.Text>{t`unsubscribe.howToUnsubscribe.title`}</S.Text>
      </S.Button>
      <S.CollapseContent>
        <S.Text ref={contentElementRef}>
          {t`unsubscribe.howToUnsubscribe.text`}
          <S.Link onClick={handleClick}>
            {t`unsubscribe.howToUnsubscribe.linkText`}
          </S.Link>
        </S.Text>
      </S.CollapseContent>
    </S.Wrapper>
  )
}
