import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'

import { selectFirebaseConfig } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

export const useAuthObserver = (callback: (token: string) => void): void => {
  const firebaseConfig = useSelector(selectFirebaseConfig)
  const uuid = useSelector(selectUUID)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (firebaseConfig) {
      const unregisterAuthObserver = firebase
        .auth()
        .onAuthStateChanged(async (user) => {
          if (!user) return

          try {
            const token = await user.getIdToken()
            callback(token)
          } catch (err) {
            Sentry.captureException(
              `User ${uuid} NOT created firebase account with error using email sign up method. Error: ${err}`,
            )
          }
        })

      return () => {
        unregisterAuthObserver()
      }
    }
  }, [callback, firebaseConfig, uuid])
}
