import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TWrapperProps = {
  hasNavigation: boolean
  hasProgressBar: boolean
  hasShadow?: boolean
}

type TContainerProps = {
  isVisible: boolean
}

export const StyledHeaderMinimalistic = {
  Wrapper: styled.header<TWrapperProps>`
    width: 100%;
    background-color: ${Color.WHITE};
    padding: 10px 16px 0;
    display: flex;
    position: relative;
    z-index: 3;
    justify-content: ${({ hasNavigation }) =>
      hasNavigation ? 'space-between' : 'center'};
    margin-bottom: ${({ hasProgressBar }) => (hasProgressBar ? '8px' : '16px')};
    ${({ hasShadow }) =>
      hasShadow
        ? css`
            box-shadow: 0 5px 20px #dfe3f3;
            height: 50px;
            align-items: center;
            margin: 0 auto 16px;
            padding: 13px 16px;
          `
        : css`
            height: 40px;
            align-items: flex-end;
          `}
  `,
  ArrowIconContainer: styled.div<TContainerProps>`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    min-width: 55px;
    height: 24px;
  `,
  PageNumberContainer: styled.div<TContainerProps>`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    min-width: 55px;
    color: ${Color.LIGHT};
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: right;

    & > span:not(:last-of-type) {
      margin-right: 4px;
    }
  `,
}
