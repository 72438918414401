import { IRequestOptions, IResponseResult } from 'models/api.model'

import { ApiService } from 'services/api.service'

import { RequestMethod } from 'root-constants'

interface IFaceScanResponse {
  // eslint-disable-next-line camelcase
  task_id: string
}

interface IFaceScanResults {
  [problemArea: string]: string
}

export class FaceScanApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  sendCameraImage(image: Blob): Promise<IResponseResult<IFaceScanResponse>> {
    const file = new File([image], 'fs.png', {
      type: 'image/png',
    })

    const data = new FormData()
    data.append('file', file)

    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: data,
    }

    return this.api.makeRequestWithFormDate<IFaceScanResponse>(
      `parse_transparent/`,
      options,
    )
  }

  getAnalysisResultData(
    id: string,
  ): Promise<IResponseResult<IFaceScanResults>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IFaceScanResults>(
      `parse_result_data/${id}`,
      options,
    )
  }

  getAnalysisResultImage(id: string): Promise<IResponseResult<Blob>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<Blob>(`parse_result/${id}`, options, true)
  }
}
