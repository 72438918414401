import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectFacebookPixelIds } from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { COHORT_QUERY_PARAM, FBConsent } from 'root-constants'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export const useFacebookPixelInitialization = (): void => {
  const { search } = document.location
  const uuid = useSelector(selectUUID)
  const facebookPixelIds = useSelector(selectFacebookPixelIds)
  const userCountryCode = useSelector(selectUserCountryCode)

  const URLParams = new URLSearchParams(search)
  const cohort = URLParams.get(COHORT_QUERY_PARAM)

  const { hasAdvertizingAndMarketingCookie, isEUUser } =
    useCookieConsentAnswer()

  useLayoutEffect(() => {
    if (!window.fbq || !facebookPixelIds.length || !uuid || !userCountryCode) {
      return
    }

    facebookPixelIds.forEach((facebookPixelId) => {
      window.fbq('init', facebookPixelId, { external_id: uuid })
    })

    window.fbq('track', 'PageView')
  }, [cohort, facebookPixelIds, uuid, userCountryCode])

  useLayoutEffect(() => {
    if (!window.fbq || !isEUUser) return

    window.fbq(
      'consent',
      hasAdvertizingAndMarketingCookie ? FBConsent.GRANT : FBConsent.REVOKE,
    )
  }, [userCountryCode, hasAdvertizingAndMarketingCookie, isEUUser])
}
