import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'

import { StyledTopRatedWellnessApps as S } from './TopRatedWellnessApps.styles'
import { TOP_WELLNESS_APPS } from './constants'

export const TopRatedWellnessApps: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <Container fields={16}>
        <S.Title>
          <Trans
            i18nKey="wellnessBundleUpsell.topRatedApps.title"
            components={[<strong />]}
          />
        </S.Title>
        <S.AppsContainer>
          {TOP_WELLNESS_APPS.map(({ name, image, description }) => (
            <S.AppContainer key={name}>
              <S.AppLogo src={image} />
              <S.AppName>{t(name)}</S.AppName>
              <S.AppDescription>{t(description)}</S.AppDescription>
            </S.AppContainer>
          ))}
        </S.AppsContainer>
      </Container>
    </S.Root>
  )
}
