import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OptionType } from 'root-constants'

import { StyledFaceShapeChange as S } from './FaceShapeChange.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const FaceShapeChange: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <S.TitleContainer>
        <PageTitle>
          <Trans
            i18nKey="onboarding.faceShapeChange.question"
            components={[<br />]}
          />
        </PageTitle>
      </S.TitleContainer>

      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.YES}
      >
        <AnswerButton>{t`onboarding.faceShapeChange.yes`}</AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.NO}
      >
        <AnswerButton>{t`onboarding.faceShapeChange.no`}</AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.NOT_SURE}
      >
        <AnswerButton>{t`onboarding.faceShapeChange.notSure`}</AnswerButton>
      </Option>
    </Container>
  )
}
