import React, { useCallback, useMemo } from 'react'

import { StyledGlasses as S } from './Glasses.styles'

type TPageProps = {
  selectedQuantity: string
  setSelectedQuantity: (value: string) => void
  quantity?: number
}

export const Glasses: React.FC<TPageProps> = ({
  selectedQuantity,
  setSelectedQuantity,
  quantity = 10,
}) => {
  const glassesList = useMemo(
    () => Array.from({ length: quantity }, (_, index) => index + 1),
    [quantity],
  )

  const handleClick = useCallback(
    (event) => {
      setSelectedQuantity(event.target.value)
    },
    [setSelectedQuantity],
  )

  return (
    <S.Root>
      {glassesList.map((item, index) => (
        <S.Button
          isActive={!!selectedQuantity && item <= +selectedQuantity}
          key={item}
          type="button"
          value={item}
          onClick={handleClick}
          index={index}
        />
      ))}
    </S.Root>
  )
}
