import femaleImage from 'assets/images/female-gender.png'
import maleImage from 'assets/images/male-gender.png'

import { ALTERNATIVE_GENDER, Gender } from 'root-constants'

export const QUESTION = 'Gender'

export const OPTION_VALUES = {
  MALE: Gender.MALE,
  FEMALE: Gender.FEMALE,
  OTHER: ALTERNATIVE_GENDER,
}

export const GENDER_IMAGE_OPTIONS = [
  {
    value: OPTION_VALUES.MALE,
    text: 'onboarding.gender.male',
    image: maleImage,
  },
  {
    value: OPTION_VALUES.FEMALE,
    text: 'onboarding.gender.female',
    image: femaleImage,
  },
]
