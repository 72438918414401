import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledScannerFeedback = {
  Root: styled.div`
    padding-top: 25px;
  `,
  Title: styled.header`
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    margin-bottom: 16px;
  `,
  Description: styled.div`
    margin-bottom: 64px;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: ${Color.LIGHT};
    text-align: center;
    text-wrap: pretty;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 320/270;
    margin-bottom: 43px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto 43px;
    }
  `,
  Image: styled.img`
    max-width: 100%;
    height: auto;
  `,
}
