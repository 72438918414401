import styled from 'styled-components'

import { IProps as IButtonProps } from 'components/Button/Button'

import separator from 'assets/images/dashed-separator.svg'
import arrow from 'assets/images/dialog-arrow.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledFaceScanPreview = {
  Hero: styled.div`
    position: relative;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;
    width: 100%;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }

    video {
      min-height: 380px;
    }
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: ${Color.DARK};
    margin: 24px 0;
  `,
  SafetyMessage: styled.div`
    display: flex;
    align-items: center;
    position: relative;
    max-width: 327px;
    background: ${Color.WHITE};
    border-radius: 16px;
    padding: 16px;
    filter: drop-shadow(0px 8px 20px #dfe3f3);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${Color.BLUE_DARK};
    margin: 0 auto;

    &::after {
      content: '';
      position: absolute;
      bottom: -14px;
      width: 36px;
      height: 14px;
      left: calc(50% - 18px);
      background-image: url(${arrow});
    }

    svg {
      max-width: 24px;
      margin-right: 8px;
    }

    span {
      color: ${Color.RED};
    }
  `,
  StepsContainer: styled.div``,
  Step: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 34px;

    img {
      filter: drop-shadow(0px 8px 20px #dfe3f3);
      margin-right: 16px;
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 40px;
      bottom: -36px;
      left: 20px;
      background-image: url(${separator});
    }
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: ${Color.DARK};

    strong {
      font-weight: 600;
    }
  `,
  SkipButton: styled.button<IButtonProps>`
    width: 100%;
    height: 50px;
    display: block;
    padding: 0 15px;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
    text-align: center;
    background: transparent;
    color: #a9a9a9;
  `,
  NavigationButtons: styled.div`
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 2;
    padding: 38px 0 10px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    );

    button {
      max-width: 320px;
      margin: 0 auto;
    }
  `,
}
