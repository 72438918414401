import { CurrentEnvironment, SUPPORT_FORM_LINKS_MAP } from 'root-constants'

import { getCurrentEnv } from './getCurrentEnv'

export const getSupportFormLink = (): string => {
  const currentEnv = getCurrentEnv()

  switch (true) {
    case currentEnv === CurrentEnvironment.PROD:
      return SUPPORT_FORM_LINKS_MAP[CurrentEnvironment.PROD]

    case currentEnv === CurrentEnvironment.STAGE:
      return SUPPORT_FORM_LINKS_MAP[CurrentEnvironment.STAGE]

    case currentEnv === CurrentEnvironment.DEV:
      return SUPPORT_FORM_LINKS_MAP[CurrentEnvironment.DEV]

    default:
      return SUPPORT_FORM_LINKS_MAP[CurrentEnvironment.DEV]
  }
}
