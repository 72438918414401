import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Container } from 'components/Container'

import { StyledHolidaysCancelOfferReviews as S } from './HolidaysCancelOfferReviews.styles'
import { DEFAULT_REVIEWS } from './constants'

type TReview = {
  review?: string
  name: string
  image: string
}

type THolidaysCancelOfferReviewsProps = {
  reviews?: TReview[]
  hasTitle?: boolean
  className?: string
}

export const HolidaysCancelOfferReviews: React.FC<
  THolidaysCancelOfferReviewsProps
> = ({ className = '', hasTitle = true, reviews = DEFAULT_REVIEWS }) => {
  const { t } = useTranslation()

  return (
    <S.Root className={className}>
      {hasTitle && (
        <Container>
          <S.Title>
            <Trans
              i18nKey="purchase1.holidayCancelOffer.reviews.title"
              components={[<br />]}
            />
          </S.Title>
        </Container>
      )}
      <Carousel
        slidesPerView={1}
        pagination
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop
      >
        {reviews.map((item: TReview) => (
          <SwiperSlide key={item.image}>
            <S.Card>
              <S.Image src={item.image} alt={t(item.name)} />
              <S.Description>{t(item.review || '')}</S.Description>
              <S.Reviewer>{t(item.name)}</S.Reviewer>
            </S.Card>
          </SwiperSlide>
        ))}
      </Carousel>
    </S.Root>
  )
}
