import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellGuashaReviews = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;

    & .swiper-wrapper {
      padding-bottom: 40px;
    }

    & span.swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #8abb5d;
    }
  `,
  CarouselTitle: styled.h3`
    color: ${Color.BLUE_DARK};
    margin-bottom: 16px;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
  `,
  Card: styled.div`
    height: 250px;
    background: ${Color.WHITE};
    border-radius: 20px;
    padding: 16px;
    margin: 0 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 8px 20px 0px #f2e6e1;
  `,
  CardRating: styled.img`
    margin-bottom: 16px;
  `,
  CardReview: styled.p`
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;
    color: ${Color.GRAY};
    flex: 1;
  `,
  CardReviewer: styled.h3`
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: ${Color.BLUE_DARK};
  `,
}
