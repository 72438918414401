import React, { InputHTMLAttributes } from 'react'

import checkIconSvg from 'assets/images/sprite/check-icon-green.svg'
import crossIconSvg from 'assets/images/sprite/cross-icon.svg'

import { StyledInputWithFloatPlaceholder as S } from './InputWithFloatPlaceholder.styles'

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  value?: string
  labelName: string
  isValid?: boolean
  validationText?: string
  type?: string
  marginBottom?: number
  inputHeight?: number
  hasValidationIcon?: boolean
  successIconSrc?: string
  errorIconSrc?: string
  iconSize?: number
  className?: string
}

export const InputWithFloatPlaceholder: React.FC<TProps> = ({
  value,
  labelName,
  isValid = true,
  validationText = '',
  type = 'text',
  marginBottom = 0,
  inputHeight = 60,
  hasValidationIcon = false,
  successIconSrc = checkIconSvg,
  errorIconSrc = crossIconSvg,
  iconSize = 20,
  className,
  ...props
}) => (
  <S.Wrapper
    data-valid={isValid}
    data-validation-text={validationText}
    marginBottom={marginBottom}
    className={className}
  >
    <S.Input
      required
      type={type}
      inputHeight={inputHeight}
      value={value}
      {...props}
    />
    <S.Label>{labelName}</S.Label>

    {hasValidationIcon && (
      <>
        {value && isValid && (
          <S.SvgIcon svg={successIconSrc} width={iconSize} />
        )}
        {value && !isValid && <S.SvgIcon svg={errorIconSrc} width={iconSize} />}
      </>
    )}
  </S.Wrapper>
)
