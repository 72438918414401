import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'

import { StyledUpsellGuashaUsers as S } from './UpsellGuashaUsers.styles'
import { UPSELL_GUASHA_USERS } from './constants'

export const UpsellGuashaUsers: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <S.Title>
        <Trans
          i18nKey="guashaUpsell.users.title"
          components={[<br />, <strong />]}
        />
      </S.Title>
      <Container fields={16}>
        {UPSELL_GUASHA_USERS.map(
          ({ title, subtitle, image, bgColor, borderColor }) => (
            <S.Container
              key={title}
              bgColor={bgColor}
              borderColor={borderColor}
            >
              <S.ContainerTitle>{t(title)}</S.ContainerTitle>
              <S.ContainerSubtitle>
                <Trans i18nKey={subtitle} components={[<br />]} />
              </S.ContainerSubtitle>
              <S.Image src={image} alt="guasha-user" />
            </S.Container>
          ),
        )}
      </Container>
    </S.Root>
  )
}
