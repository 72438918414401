import React, { FC, useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import safetyBadge from 'assets/images/sprite/safety-badge.svg'

import { replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { FaceScanSkipScreen } from 'root-constants'

import { StyledFaceScanPreview as S } from './FaceScanPreview.styles'
import { CONTINUE_BUTTON_TEXT, DIAGNOSIS_STEPS, QUESTION } from './constants'

export const FaceScanPreview: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  useEffect(() => eventLogger.logFaceSetupScreenShown(), [])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(CONTINUE_BUTTON_TEXT),
    [handleContinue],
  )

  const handleSkipClick = useCallback(() => {
    eventLogger.logFaceScanSkipped(FaceScanSkipScreen.INFO_SCREEN)
    replaceHistory({ pathname: PageId.MOTIVATION, search })
  }, [search])

  return (
    <>
      <S.Hero>
        <video
          id="background-video"
          width="100%"
          autoPlay
          loop
          playsInline
          muted
          poster="https://cdn-prod-unified-content.gismart.xyz/34486912bbc40fd28752c59e42098ae09101436d76566d91d767d95811286b02/videos/ml-feature-poster.jpg"
          src="https://cdn-prod-unified-content.gismart.xyz/34486912bbc40fd28752c59e42098ae09101436d76566d91d767d95811286b02/videos/ml-feature.mp4"
        />
      </S.Hero>
      <Container>
        <S.Title>{t('skinDiagnosis.rightTrack')}</S.Title>
        <S.StepsContainer>
          {DIAGNOSIS_STEPS.map(({ id, text, icon }) => (
            <S.Step key={id}>
              <img src={icon} alt="step-icon" height={40} />
              <S.Text>
                <Trans i18nKey={text} components={[<strong />]} />
              </S.Text>
            </S.Step>
          ))}
        </S.StepsContainer>
        <S.SafetyMessage>
          <SvgImage svg={safetyBadge} />
          <p>
            <Trans
              i18nKey="skinDiagnosis.safetyMessage"
              components={{ highlighted: <span /> }}
            />
          </p>
        </S.SafetyMessage>
      </Container>
      <S.NavigationButtons>
        <Button onClick={handleNextClick}>{t('actions.continue')}</Button>
        <S.SkipButton onClick={handleSkipClick}>
          {t('skinDiagnosis.skip')}
        </S.SkipButton>
      </S.NavigationButtons>
    </>
  )
}
