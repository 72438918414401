import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import { PageId } from 'page-constants'

export const useManualAnalyticsSending = (): ((
  question: string,
  answer: string,
  pageId: PageId,
) => void) => {
  const dispatch = useDispatch()
  return useCallback(
    (question, answer, pageId) => {
      dispatch(
        setAnswersAction({
          answers: answer,
          pageId,
        }),
      )
      eventLogger.logQuestion({ question, answers: answer })
    },
    [dispatch],
  )
}
