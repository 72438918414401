import styled from 'styled-components'

export const StyledReverseYourWrinkles = {
  TitleContainer: styled.div`
    margin: 0 auto 16px;
    max-width: 360px;
    padding: 0 10px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 320/181;
    margin-bottom: 39px;
  `,
  Graph: styled.img`
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: top;
  `,
  Card: styled.div`
    display: flex;
    margin-bottom: 36px;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  CardIcon: styled.img`
    min-width: 64px;
    height: auto;
    margin-right: 14px;
  `,
  CardText: styled.p`
    flex-grow: 1;
    font-size: 14px;
    line-height: 16px;
  `,
}
