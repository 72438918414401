/* eslint-disable max-lines */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  PaymentFlow,
  PaymentMethodInfo,
  PaymentMethodType,
  Primer,
  PrimerHeadlessCheckout,
} from '@primer-io/checkout-web'

import {
  resetErrorAction,
  setErrorAction,
  setIsPrimerRetryProcessing,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { sendUserConfigAction } from 'root-redux/actions/user'
import {
  selectError,
  selectIsFetching,
  selectIsPrimerRetryProcessing,
} from 'root-redux/selects/common'

import {
  CARDHOLDER_NAME_MIN_LENGTH,
  CARDHOLDER_NAME_REGEX,
  INITIAL_PRIMER_CONFIG,
  PRIMER_CARD_CVC_ID,
  PRIMER_CARD_EXPIRY_ID,
  PRIMER_CARD_NUMBER_ID,
  PRIMER_PAYMENT_ERRORS,
  PRIMER_PAYMENT_METHODS_MAP,
  PRIMER_PAYPAL_ID,
  PaymentMethod,
  PaymentSystem,
} from 'modules/purchase/constants'
import { useCancelPrimerDiscount } from 'modules/purchase/hooks/useCancelPrimerDiscount'
import { useInitPrimerCardForm } from 'modules/purchase/hooks/useInitPrimerCardForm'
import { useInitPrimerPayPal } from 'modules/purchase/hooks/useInitPrimerPayPal'
import { usePrimerAnalytics } from 'modules/purchase/hooks/usePrimerAnalytics'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  CHECK_PAYPAL_REQUEST_BUTTON,
  PURCHASE,
  getPrimerClientSessionTokenAction,
  primerPurchaseAction,
  primerResumePurchaseAction,
  setBackupPrimerSubscriptionConfigAction,
  setIsFirstPaymentRetryPassedAction,
  setPaymentMethodAction,
  setPrimerClientSessionTokenAction,
  setPrimerPaymentId,
} from 'modules/purchase/redux/actions/common'
import {
  selectCurrency,
  selectIsFirstPaymentRetryPassed,
  selectPaymentMethod,
  selectPrimerClientSessionToken,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import walletIcon from 'assets/images/wallet.png'

import { CardPaymentFieldName } from 'root-constants'

import { StyledPrimerPaymentForm as S } from './PrimerPaymentForm.styles'

type TPrimerPaymentFormProps = {
  hasPayPalButton?: boolean
  selectedPaymentMethod: PaymentMethod
  setAlternativePaymentMethodsCallback: Dispatch<
    SetStateAction<PaymentMethod[]>
  >
}

export const PrimerPaymentFormV2: React.FC<TPrimerPaymentFormProps> = ({
  hasPayPalButton = true,
  selectedPaymentMethod,
  setAlternativePaymentMethodsCallback,
}: TPrimerPaymentFormProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const primerRef = useRef<PrimerHeadlessCheckout | null>(null)
  const primerFormRef = useRef<HTMLFormElement>(null)
  const bottomButtonRef = useRef(null)
  const isFetching = useSelector(selectIsFetching)
  const priceLookupKey = useSelector(selectSubscriptionLookupKey)
  const primerClientSessionToken = useSelector(selectPrimerClientSessionToken)
  const isPrimerRetryProcessing = useSelector(selectIsPrimerRetryProcessing)
  const paymentMethod = useSelector(selectPaymentMethod)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const trialPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const currency = useSelector(selectCurrency)
  const error = useSelector(selectError)
  const isFirstPaymentRetryPassed = useSelector(selectIsFirstPaymentRetryPassed)

  const [isFocused, setIsFocused] = useState(false)
  const [isPayPalVisible, setIsPayPalVisible] = useState(false)

  const [isBottomButtonPinned, setIsBottomButtonPinned] = useState(true)

  const { logPaypalPaymentStarted, logPaymentStarted } = usePrimerAnalytics()
  const { initCardForm, cardManagerRef, errors, isFormValid, handleChange } =
    useInitPrimerCardForm({
      primerRef,
      shouldAutofocus: false,
    })
  const { initPayPalButton } = useInitPrimerPayPal({ primerRef })
  const { cancelDiscount } = useCancelPrimerDiscount()

  const saveBackupPrimerConfig = useCallback(() => {
    dispatch(
      setBackupPrimerSubscriptionConfigAction({
        paymentCurrency: currency,
        paymentMethod,
        subscriptionPrice: currentPrice,
        subscriptionDuration: `${periodQuantity}${periodName}`,
        priceId: priceLookupKey,
        trialPrice,
        trialPeriod: `${trialPeriodDays}`,
      }),
    )
  }, [
    currency,
    currentPrice,
    dispatch,
    paymentMethod,
    periodName,
    periodQuantity,
    priceLookupKey,
    trialPeriodDays,
    trialPrice,
  ])

  useEffect(() => {
    dispatch(getPrimerClientSessionTokenAction())

    return () => {
      dispatch(setPrimerClientSessionTokenAction(''))
      dispatch(setPrimerPaymentId(''))
    }
  }, [dispatch])

  useEffect(() => {
    const cachedRef = bottomButtonRef.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBottomButtonPinned(entry.intersectionRatio < 1)
      },
      { threshold: [1] },
    )

    if (cachedRef) {
      observer.observe(cachedRef)
    }

    return () => {
      if (cachedRef) {
        observer.unobserve(cachedRef)
      }
    }
  }, [])

  const retryPaymentCallback = useCallback(() => {
    if (!isFirstPaymentRetryPassed) {
      dispatch(setIsFirstPaymentRetryPassedAction(true))
      const submit = new Event('submit', {
        cancelable: true,
        bubbles: true,
      })

      primerFormRef.current?.dispatchEvent(submit)

      return
    }

    dispatch(setErrorAction(error || t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
    dispatch(setIsPrimerRetryProcessing(false))
    dispatch(stopFetching(PURCHASE))
  }, [dispatch, error, isFirstPaymentRetryPassed, t])

  const initPrimer = useCallback(async () => {
    const headless = await Primer.createHeadless(primerClientSessionToken)
    primerRef.current = headless

    headless.configure({
      ...INITIAL_PRIMER_CONFIG,
      paymentHandling: 'MANUAL',
      paypal: {
        paymentFlow: PaymentFlow.PREFER_VAULT,
      },

      onAvailablePaymentMethodsLoad(paymentMethods: PaymentMethodInfo[]) {
        paymentMethods.forEach(({ type }) => {
          if (type === PaymentMethodType.PAYMENT_CARD) {
            initCardForm()
          }

          if (type === PaymentMethodType.PAYPAL && hasPayPalButton) {
            initPayPalButton()
            setIsPayPalVisible(true)
            setAlternativePaymentMethodsCallback((prevValues) => [
              ...prevValues,
              PaymentMethod.PAYPAL,
            ])
          }
        })
        dispatch(stopFetching(CHECK_PAYMENT_REQUEST_BUTTON))
        dispatch(stopFetching(CHECK_PAYPAL_REQUEST_BUTTON))
      },
      onTokenizeSuccess(token, handler) {
        dispatch(startFetching(PURCHASE))

        const currentMethod =
          PRIMER_PAYMENT_METHODS_MAP[token.paymentInstrumentType]

        currentMethod === PaymentMethod.PAYPAL && logPaypalPaymentStarted()

        dispatch(
          sendUserConfigAction({
            payment_system: PaymentSystem.PRIMER,
          }),
        )
        saveBackupPrimerConfig()

        dispatch(
          primerPurchaseAction(token.token, handler, retryPaymentCallback),
        )
      },
      onTokenizeStart() {
        dispatch(startFetching(PURCHASE))
      },
      onTokenizeError() {
        dispatch(stopFetching(PURCHASE))
      },
      onResumeSuccess({ paymentId, resumeToken }, handler) {
        dispatch(
          primerResumePurchaseAction(
            paymentId,
            resumeToken,
            handler,
            retryPaymentCallback,
          ),
        )
      },
    })

    await headless.start()
  }, [
    primerClientSessionToken,
    dispatch,
    initCardForm,
    saveBackupPrimerConfig,
    retryPaymentCallback,
    logPaypalPaymentStarted,
    initPayPalButton,
    hasPayPalButton,
    setAlternativePaymentMethodsCallback,
  ])

  useEffect(() => {
    if (!primerClientSessionToken || primerRef.current) return
    initPrimer()
  }, [initPrimer, primerClientSessionToken])

  const handleSubmitClick = useCallback(() => {
    eventLogger.logPaymentMethodSelected(PaymentMethod.CREDIT_CARD)
    dispatch(setPaymentMethodAction(PaymentMethod.CREDIT_CARD))
    logPaymentStarted(PaymentMethod.CREDIT_CARD)
  }, [dispatch, logPaymentStarted])

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      await cancelDiscount()
      dispatch(resetErrorAction())

      if (!isFormValid || isFetching || !cardManagerRef.current) {
        return
      }

      const { valid } = await cardManagerRef.current.validate()

      if (valid) {
        await cardManagerRef.current.submit()
      }
    },
    [cancelDiscount, dispatch, isFormValid, isFetching, cardManagerRef],
  )

  return (
    <S.Wrapper isDefaultStyles={false}>
      <S.Form
        ref={primerFormRef}
        onSubmit={handleSubmit}
        isVisible={selectedPaymentMethod === PaymentMethod.CREDIT_CARD}
      >
        <S.Label>{t('purchase1.paymentForm.cardNumber')}</S.Label>
        <S.InputContainer
          isInvalid={
            !!errors[CardPaymentFieldName.NUMBER].error ||
            !errors[CardPaymentFieldName.NUMBER].isFocused
          }
          isDefaultStyles
          id={PRIMER_CARD_NUMBER_ID}
        />

        <S.CvvExpiryInputContainer>
          <S.CardExpiryContainer>
            <S.Label>{t('purchase1.paymentForm.cardExpiry')}</S.Label>
            <S.InputContainer
              isInvalid={
                !!errors[CardPaymentFieldName.EXPIRY].error ||
                !errors[CardPaymentFieldName.EXPIRY].isFocused
              }
              isDefaultStyles
              id={PRIMER_CARD_EXPIRY_ID}
            />
          </S.CardExpiryContainer>

          <S.CardCvcContainer>
            <S.Label>{t('purchase1.paymentForm.securityNumber')}</S.Label>
            <S.InputContainer
              isInvalid={
                !!errors[CardPaymentFieldName.CVC].error ||
                !errors[CardPaymentFieldName.CVC].isFocused
              }
              isDefaultStyles
              id={PRIMER_CARD_CVC_ID}
            />
            <S.CardCvcElementIcon
              isDefaultStyles
              src={walletIcon}
              alt="wallet"
            />
          </S.CardCvcContainer>
        </S.CvvExpiryInputContainer>

        <S.Label>{t('purchase1.paymentForm.cardholderName')}</S.Label>
        <S.CardHolderInputContainer
          isInvalid={!!errors[CardPaymentFieldName.NAME].error && !isFocused}
        >
          <S.CardHolderInput
            data-testid="cardholder-name-input"
            isDefaultStyles
            type="text"
            placeholder={t('purchase1.paymentForm.cardholderNamePlaceholder')}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => {
              const value = e.target.value.trim()
              const isNameValid =
                CARDHOLDER_NAME_REGEX.test(value) &&
                value.length > CARDHOLDER_NAME_MIN_LENGTH
              cardManagerRef.current?.setCardholderName(value)

              handleChange({
                fieldName: CardPaymentFieldName.NAME,
                hasError: !!value && !isNameValid,
                isComplete: !value || (!!value && isNameValid),
                isFocused,
              })
            }}
          />
        </S.CardHolderInputContainer>

        <S.ButtonContainer
          ref={bottomButtonRef}
          hasShadow={isBottomButtonPinned}
          hasPaypalButton={hasPayPalButton}
        >
          <S.SubmitButton
            data-testid="checkout-btn"
            type="submit"
            disabled={!isFormValid || isFetching || isPrimerRetryProcessing}
            isMaximized={!isBottomButtonPinned}
            onClick={handleSubmitClick}
          >
            {t('purchase2.checkout.confirmPayment')}
          </S.SubmitButton>
        </S.ButtonContainer>
      </S.Form>

      {hasPayPalButton && (
        <S.PayPalButton
          id={PRIMER_PAYPAL_ID}
          isVisible={
            isPayPalVisible && selectedPaymentMethod === PaymentMethod.PAYPAL
          }
        />
      )}
    </S.Wrapper>
  )
}
