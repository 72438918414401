import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.DARK,
    fontFamily: 'Rubik,  sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '55px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: Color.LIGHT,
      fontWeight: 400,
    },
  },
  invalid: {
    color: Color.ERROR,
  },
}

export const commonInputStyles = css`
  color: ${Color.DARK};
  height: 55px;
  background-color: ${Color.WHITE};
  line-height: 55px;
  border-radius: 10px;
  border: 1px solid ${Color.LIGHT};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  padding-left: 15px;
  transition: border-color 0.2s ease-out;

  &[data-invalid='true'],
  &.StripeElement--invalid {
    border-color: ${Color.ERROR};
  }
`

export const StyledCardPaymentFormSeparateCheckout = {
  Wrapper: styled.div``,
  Form: styled.form``,
  InputContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Label: styled.p`
    padding-bottom: 8px;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    text-align: left;
  `,
  CvvExpiryInputContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CardExpiryContainer: styled.div`
    width: calc(50% - 5px);
  `,
  CardCvcContainer: styled.div`
    position: relative;
    width: calc(50% - 5px);
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles};
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles};
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles};
  `,
  CardCvcElementIcon: styled.img`
    position: absolute;
    top: 40px;
    right: 15px;
    max-width: 20px;
  `,
  CardholderInput: styled.input`
    ${commonInputStyles};
    width: 100%;
    font-family: 'Rubik', sans-serif;
    outline: none;
    box-shadow: none;
    font-weight: 500;

    ::placeholder {
      color: ${Color.LIGHT};
      font-weight: 400;
    }
  `,
  Button: styled(Button)`
    height: 55px;
    margin: 15px 0 0;
  `,
}
