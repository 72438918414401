import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AGE_ANSWERS_MAP } from 'pages/questions/Age/constants'

import {
  selectAnswers,
  selectLanguage,
  selectUserGoal,
} from 'root-redux/selects/common'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'

import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
} from 'modules/purchase/constants'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import smallAvatar from 'assets/images/small-avatar.png'

import { PageId } from 'page-constants'
import {
  DynamicDiscountTheme,
  DynamicDiscountType,
  I18N_CONTEXT_COHORT_MAP,
  Locale,
  UserGoal,
} from 'root-constants'

import { StyledLongPaywallShortUserProfile as S } from './LongPaywallShortUserProfile.styles'
import { USER_GOAL_TITLES_MAP, USER_LEVELS_MAP, UserLevel } from './constants'

type TLongPaywallShortUserProfileProps = {
  timerRef: React.RefObject<HTMLElement>
  title?: string
}

export const LongPaywallShortUserProfile: React.FC<
  TLongPaywallShortUserProfileProps
> = ({ title, timerRef }: TLongPaywallShortUserProfileProps) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const userGoal = useSelector(selectUserGoal)
  const language = useSelector(selectLanguage)
  const cohortToUse = useCohortToUse()
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const userLevel = userAnswers?.[PageId.HAVE_YOU_HEARD] || UserLevel.NOT_SURE

  const isEnLocale = useMemo(() => language === Locale.ENGLISH, [language])

  const {
    isDynamicDiscountCohort,
    isRefundCohort,
    isLuvlyIntroSaleCohort,
    isLuvly12SaleCohort,
    isIntro3SaleCohort,
  } = useActiveCohortIdentifier()
  const { isStaticBannerActive } = useABTest()

  const isCancelOfferRoute = useIsCurrentRoute(
    PageId.PURCHASE_INTRO_CANCEL_OFFER,
  )

  const isDynamicDiscountType = useMemo(
    () => dynamicDiscount?.type !== DynamicDiscountType.STATIC,
    [dynamicDiscount?.type],
  )

  const hasTimer = useMemo(() => {
    return (
      !isDynamicDiscountCohort ||
      (isDynamicDiscountType && (isLuvly12SaleCohort || isIntro3SaleCohort)) ||
      (isLuvlyIntroSaleCohort && isDynamicDiscountType && !isStaticBannerActive)
    )
  }, [
    isDynamicDiscountCohort,
    isLuvly12SaleCohort,
    isDynamicDiscountType,
    isIntro3SaleCohort,
    isLuvlyIntroSaleCohort,
    isStaticBannerActive,
  ])

  const isStaticBannerVisible = useMemo(() => {
    return (
      (!isDynamicDiscountType && isDynamicDiscountCohort) ||
      (isDynamicDiscountType && isLuvlyIntroSaleCohort && isStaticBannerActive)
    )
  }, [
    isDynamicDiscountType,
    isDynamicDiscountCohort,
    isLuvlyIntroSaleCohort,
    isStaticBannerActive,
  ])

  const staticBannerDiscount = useMemo(() => {
    const discount = dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT

    return isCancelOfferRoute
      ? discount + CANCEL_OFFER_ADDITIONAL_DISCOUNT
      : discount
  }, [dynamicDiscount?.amount, isCancelOfferRoute])

  return (
    <S.Wrapper>
      <S.Title>
        {t(
          title || USER_GOAL_TITLES_MAP[userGoal || UserGoal.WRINKLE_FREE_SKIN],
          { context: I18N_CONTEXT_COHORT_MAP[cohortToUse] || '' },
        )}
      </S.Title>
      <S.ContentWrapper isEnLocaleStyle={isEnLocale}>
        <S.ContentBlock isEnLocaleStyle={isEnLocale}>
          <S.ContentImage src={smallAvatar} alt="small-avatar" />
          <S.LevelContentWrapper>
            <S.ContentSubtitle isEnLocaleStyle={isEnLocale}>
              {t('purchase7.userProfile.age')}
            </S.ContentSubtitle>
            <S.UserProfileText isEnLocaleStyle={isEnLocale}>
              {isRefundCohort ? (
                AGE_ANSWERS_MAP[userAnswers?.[PageId.AGE] || '20']
              ) : (
                <Trans
                  i18nKey="purchase7.userProfile.ageValue"
                  values={{
                    age: userAnswers?.[PageId.AGE] || '20',
                  }}
                />
              )}
            </S.UserProfileText>
          </S.LevelContentWrapper>
        </S.ContentBlock>
        <S.ContentBlock isEnLocaleStyle={isEnLocale}>
          <S.ContentImage
            src={USER_LEVELS_MAP[userLevel].image}
            alt="small-avatar"
          />
          <S.LevelContentWrapper>
            <S.ContentSubtitle isEnLocaleStyle={isEnLocale}>
              {t('purchase7.userProfile.faceYogaLevel')}
            </S.ContentSubtitle>
            <S.UserProfileText isEnLocaleStyle={isEnLocale}>
              {t(USER_LEVELS_MAP[userLevel].text)}
            </S.UserProfileText>
          </S.LevelContentWrapper>
        </S.ContentBlock>
      </S.ContentWrapper>
      {hasTimer && (
        <S.TimerContainer
          theme={
            !isDynamicDiscountCohort
              ? DynamicDiscountTheme.CORAL
              : dynamicDiscount?.theme
          }
        >
          <S.TimerContainerText isEnLocaleStyle={isEnLocale}>
            <Trans i18nKey="purchase7.duplicateTimer" components={[<br />]} />{' '}
            <strong ref={timerRef} /> {t('purchase7.min')}
          </S.TimerContainerText>
        </S.TimerContainer>
      )}
      {isStaticBannerVisible && (
        <S.Discount theme={dynamicDiscount?.theme}>
          <S.DiscountLabel theme={dynamicDiscount?.theme}>
            <Trans
              i18nKey="purchase7.off"
              values={{ value: staticBannerDiscount }}
            />
          </S.DiscountLabel>
          <S.DiscountText theme={dynamicDiscount?.theme}>
            {t('purchase7.limitedDeal')}
          </S.DiscountText>
        </S.Discount>
      )}
    </S.Wrapper>
  )
}
