import styled, { css, keyframes } from 'styled-components'

const blink = keyframes`
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
`

const fadeIn = keyframes` 
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `

export const baseTitleStyles = css`
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
`

export const StyledBlinkingPageTitle = {
  Title: styled.h1`
    ${baseTitleStyles};
    margin-top: 0;
    margin-bottom: 40px;
    min-height: 64px;
    animation-name: ${fadeIn};
    animation-duration: 0.4s;
  `,
  Dot: styled.span`
    animation-name: ${blink};
    animation-duration: 1s;
    animation-iteration-count: infinite;

    &:nth-child(2) {
      animation-delay: 0.15s;
    }
    &:nth-child(3) {
      animation-delay: 0.3s;
    }
  `,
}
