import invertedSmileEmoji from 'assets/images/inverted-smile-emoji.png'
import loveEmoji from 'assets/images/love-emoji.png'
import surprisedEmoji from 'assets/images/surprised-emoji.png'

export const QUESTION =
  'Are you generally satisfied with your skin condition today?'

export const OPTION_VALUES = {
  LIKE_IT: 'like_it',
  NOT_SURE: 'not_sure',
  HAVE_PROBLEMS: 'have_problems',
}

export const SKIN_QUALITY_ANSWERS_MAP = {
  [OPTION_VALUES.LIKE_IT]: {
    text: 'onboarding.likeChatSkin.happy',
    icon: loveEmoji,
    expression: 'onboarding.likeChatSkin.expression1',
  },
  [OPTION_VALUES.NOT_SURE]: {
    text: 'onboarding.likeChatSkin.notReally',
    icon: invertedSmileEmoji,
    expression: 'onboarding.likeChatSkin.expression2',
  },
  [OPTION_VALUES.HAVE_PROBLEMS]: {
    text: 'onboarding.likeChatSkin.problematicSkin',
    icon: surprisedEmoji,
    expression: 'onboarding.likeChatSkin.expression2',
  },
}
