import styled from 'styled-components'

import { customBackgroundStyles } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants'

export const StyledTrustFeedback = {
  Root: styled.div`
    padding-top: 25px;
    position: relative;

    ${customBackgroundStyles}
  `,
  Title: styled.header`
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    margin-bottom: 16px;
  `,
  Description: styled.div`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.DARK};
    text-align: center;
    text-wrap: pretty;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 360/330;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto;
    }
  `,
  Image: styled.img`
    max-width: 100%;
    height: auto;
  `,
}
