import styled from 'styled-components'

export const StyledFixedButton = {
  Button: styled.button`
    outline: none;
    cursor: pointer;
    border: 0;
    border-radius: 12px;

    &[data-fixed] {
      position: fixed;
      z-index: 2;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto 24px;
    }

    &[data-visible='false'] {
      visibility: hidden;
    }

    &[data-visible='true'] {
      visibility: visible;
    }
  `,
}
