import styled from 'styled-components'

export const StyledSeparator = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  `,
  TextBlock: styled.p`
    padding: 0 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
  `,
  Line: styled.div`
    flex-grow: 1;
    height: 1px;
    background-color: #e3e4e8;
  `,
}
