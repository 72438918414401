import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledDisclaimer = {
  Container: styled.p`
    position: relative;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: ${Color.LIGHT};

    a {
      color: ${Color.PRIMARY};
      text-decoration: underline;
    }
  `,
}
