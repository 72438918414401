import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { CustomerReviews } from 'components/CustomerReviews'
import { DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS } from 'components/CustomerReviews/constants'
import { PageTitleDescription } from 'components/PageTitleDescription'
import { UserGoalGraphSenior } from 'components/UserGoalGraphSenior'

import { useCohortToUse } from 'hooks/useCohortToUse'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { BottomButtonContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { I18N_CONTEXT_COHORT_MAP } from 'root-constants'

import { StyledShortProgramDescription as S } from './ShortProgramDescription.styles'
import { QUESTIONS, SENIOR_CUSTOMER_REVIEWS } from './constants'

export const ShortProgramDescriptionAsResult: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const cohortToUse = useCohortToUse()

  const handleNextClick = useCallback(() => {
    eventLogger.logQuestion({
      question: QUESTIONS[PageId.SHORT_PROGRAM_DESCRIPTION_AS_RESULT],
      answers: '',
    })

    goTo({
      pathname: PageId.PURCHASE_INTRO_OFFER,
      search,
    })
  }, [search])

  return (
    <div>
      <Container>
        <S.TitleContainerSenior>
          {t(`onboarding.shortProgramDescription.question.default`, {
            context: I18N_CONTEXT_COHORT_MAP[cohortToUse] || '',
          })}
        </S.TitleContainerSenior>
        <S.DescriptionContainerSenior>
          <PageTitleDescription>
            <Trans
              i18nKey="onboarding.shortProgramDescription.description"
              components={[<br />]}
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </PageTitleDescription>
        </S.DescriptionContainerSenior>
        <UserGoalGraphSenior />
        <CustomerReviews
          customerReviews={SENIOR_CUSTOMER_REVIEWS}
          viewMoreCustomerReviews={DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS}
        />
      </Container>
      <BottomButtonContainer>
        <Button onClick={handleNextClick}>
          {t`onboarding.shortProgramDescription.goToNextButtonText`}
        </Button>
      </BottomButtonContainer>
    </div>
  )
}
