import React, { useRef } from 'react'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { StyledFixedButton as S } from './FixedButton.styles'

type TScrollParams = {
  rootMargin?: string
  threshold?: number
}

type TFixedButtonProps = {
  onClick: () => void
  children: React.ReactNode
  scrollParams?: TScrollParams
  className?: string
}

export const FixedButton: React.FC<TFixedButtonProps> = ({
  onClick,
  children,
  scrollParams,
  className,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const isButtonHidden = useTargetHiddenOnScroll(buttonRef, scrollParams)

  return (
    <>
      <S.Button className={className} ref={buttonRef} onClick={onClick}>
        {children}
      </S.Button>
      <S.Button
        className={className}
        data-fixed
        data-visible={isButtonHidden}
        onClick={onClick}
      >
        {children}
      </S.Button>
    </>
  )
}
