import styled from 'styled-components'

import { Color } from 'root-constants'

interface IStatusProps {
  color: string
}
export const StyledSubscriptionDetails = {
  Title: styled.h1`
    margin-bottom: 13px;
    font-size: 26px;
    line-height: 1;
    font-weight: 500;
    text-align: center;
  `,
  Text: styled.p`
    margin-bottom: 30px;
    opacity: 0.8;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  `,
  PlanInfo: styled.div`
    width: 100%;
    min-height: 240px;
    margin-bottom: 40px;
    padding: 16px 16px 30px;
    border-radius: 10px;
    box-shadow: 0 8px 20px 0 #dfe3f3;
    background-color: ${Color.WHITE};
  `,
  PlanTitle: styled.h3`
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
  `,
  PlanStatus: styled.h2<IStatusProps>`
    margin-bottom: 19px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    color: ${({ color }) => color};
  `,
  PlanList: styled.dl`
    padding: 10px 0;
    border-bottom: 1px solid rgba(129, 132, 140, 0.5);
    border-top: 1px solid rgba(129, 132, 140, 0.5);
  `,
  PlanItem: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 36px;
    font-size: 14px;

    dt {
      color: ${Color.LIGHT};
      text-transform: capitalize;
    }
    dd {
      font-weight: 500;
    }
  `,
  UnsubscribeLinkContainer: styled.div`
    & > div {
      padding: 30px 0 0;
    }
  `,
}
