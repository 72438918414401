import styled from 'styled-components'

import { Color } from 'root-constants'

type THiddenProps = {
  isVisible: boolean
}

export const StyledPurchaseTimerMarissa = {
  Wrapper: styled.div<THiddenProps>`
    width: 100%;
    position: ${({ isVisible }) => (isVisible ? 'fixed' : 'static')};
    top: 0;
    left: 0;
    z-index: 10;
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: ${Color.WHITE};
    background-color: ${Color.LIGHT_PINK};
    padding: 12px;
  `,
  Timer: styled.span`
    width: 56px;
  `,
}
