import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { Sale } from 'components/Sale'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { Ticker } from 'components/Ticker'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectLanguage } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useABTest } from 'hooks/useABTest'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import girl2 from 'assets/images/introsale2-center-image-mobile.png'
import girl from 'assets/images/introsale2-center-image.png'
import star from 'assets/images/star-green-bg.png'

import { PageId } from 'page-constants'
import { DynamicDiscountType, Locale, OptionType } from 'root-constants'

import { AGE_OPTIONS, AGE_OPTION_VALUES_MAP, QUESTION } from '../constants'
import { StyledIntroSaleAgeAsIntro as S } from './IntroSaleAgeAsIntro.styles'
import { DEFAULT_INTRO_HEADLINE, IntroSaleStartPageVariant } from './constants'

export const IntroSaleAgeAsIntro: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const { introSaleStartPageVariant } = useABTest()

  useStartSession()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const { headline } = useTitleFromUrl()

  const handleContinue = useNextStep({
    pageId: PageId.AGE,
    question: QUESTION,
    nextPagePath,
  })

  const isEnLocale = useMemo(() => language === Locale.ENGLISH, [language])

  const handleAnswerChange = useCallback(
    (value: string) => {
      eventLogger.logUserAgeSelected(AGE_OPTION_VALUES_MAP[value])
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, setIsAnswersDisabled],
  )

  const optionProps = useMemo(
    () => ({
      name: PageId.AGE,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())

        window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        handleAnswerChange(value)
      },
    }),
    [handleAnswerChange, isAnswersDisabled, dispatch, uuid],
  )

  const isMinimalisticStylesVariant = useMemo(
    () =>
      introSaleStartPageVariant === IntroSaleStartPageVariant.VARIANT_B ||
      introSaleStartPageVariant === IntroSaleStartPageVariant.VARIANT_C,
    [introSaleStartPageVariant],
  )

  return (
    <S.Root variant={introSaleStartPageVariant as IntroSaleStartPageVariant}>
      <IntroHeader colorTheme={IntroHeaderColor.DARK} />
      {dynamicDiscount?.type === DynamicDiscountType.STATIC ? (
        <Sale />
      ) : (
        <Ticker />
      )}
      <S.Container>
        {isMinimalisticStylesVariant && (
          <S.ImageContainer>
            <S.FlowersImage src={girl2} alt="girl" />
            <S.GirlImage src={girl} alt="girl" />
          </S.ImageContainer>
        )}
        <S.Title
          hasMinimalisticStyles={isMinimalisticStylesVariant}
          isEnLocaleStyles={isEnLocale}
        >
          {headline || DEFAULT_INTRO_HEADLINE}
        </S.Title>
        {introSaleStartPageVariant !== IntroSaleStartPageVariant.VARIANT_C && (
          <S.Subtitle hasMinimalisticStyles={isMinimalisticStylesVariant}>
            {t('onboarding.introSaleAgeAsIntro.description')}
          </S.Subtitle>
        )}
        {introSaleStartPageVariant === IntroSaleStartPageVariant.VARIANT_C && (
          <S.DescriptionContainer>
            <S.Description isEnLocaleStyles={isEnLocale}>
              <S.Star src={star} isEnLocaleStyles={isEnLocale} />
              <Trans
                i18nKey="onboarding.introSaleAgeAsIntro.rating"
                components={[<br />]}
              />
              <S.DescriptionSeparator isEnLocaleStyles={isEnLocale} />
              <Trans
                i18nKey="onboarding.introSaleAgeAsIntro.userQty"
                components={[<br />]}
              />
            </S.Description>
            <S.Description isEnLocaleStyles={isEnLocale}>
              <Trans
                i18nKey="onboarding.introSaleAgeAsIntro.quizDuration"
                components={[<br />]}
              />
            </S.Description>
          </S.DescriptionContainer>
        )}
        <S.Actions>
          {AGE_OPTIONS.map(({ value, text }) => (
            <S.StyledOption
              {...optionProps}
              value={value}
              key={value}
              hasMinimalisticStyles={isMinimalisticStylesVariant}
            >
              <S.Action>
                <S.AnswerWrapper
                  hasMinimalisticStyles={isMinimalisticStylesVariant}
                >
                  <S.AnswerValue>{text}</S.AnswerValue>
                  <S.ChevronRight
                    hasMinimalisticStyles={isMinimalisticStylesVariant}
                  />
                </S.AnswerWrapper>
              </S.Action>
            </S.StyledOption>
          ))}
        </S.Actions>
        <S.Terms hasMinimalisticStyles={isMinimalisticStylesVariant}>
          <p>{t`onboarding.intro.termsTitle`}</p>
          <TermsOfUseLink />
          <S.Separator hasMinimalisticStyles={isMinimalisticStylesVariant} />
          <PrivacyPolicyLink />
        </S.Terms>
      </S.Container>
    </S.Root>
  )
}
