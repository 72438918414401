import { Cohort } from 'root-constants'

export const QUESTION = 'Sun safety'

export const OPTION_VALUES = {
  ALWAYS: 'always',
  IN_SUNNY_WEATHER: 'in_sunny_weather',
  SOMETIMES: 'sometimes',
  RARELY: 'rarely',
  NEVER: 'never',
}

export const SUN_SAFETY_DEFAULT_TEXT = {
  question: 'onboarding.sunSafety.question',
  description: 'onboarding.sunSafety.description',
  always: 'onboarding.sunSafety.always',
  onlyInSunnyWeather: 'onboarding.sunSafety.onlyInSunnyWeather',
  sometimes: 'onboarding.sunSafety.sometimes',
  rarely: 'onboarding.sunSafety.rarely',
  never: 'onboarding.sunSafety.never',
}

export const SUN_SAFETY_CONTEXT_TEXT_MAP = {
  [Cohort.LUVLY_COSMETIC_SCANNER]: {
    question: 'onboarding.sunSafety.question_cosmetic',
    always: 'onboarding.sunSafety.always',
    onlyInSunnyWeather: 'onboarding.sunSafety.onlyInSunnyWeather',
    sometimes: 'onboarding.sunSafety.sometimes',
    rarely: 'onboarding.sunSafety.rarely',
    never: 'onboarding.sunSafety.never',
  },
}
