import dancebitLogo from 'assets/images/dancebit-logo.jpg'
import fitmeLogo from 'assets/images/fitme-logo.jpg'
import nutrimateLogo from 'assets/images/nutrimate-logo.jpg'

import { WellnessBundleApp } from 'root-constants'

export const TOP_WELLNESS_APPS = [
  {
    name: WellnessBundleApp.FITME,
    description: 'wellnessBundleUpsell.topRatedApps.fitMeDescription',
    image: fitmeLogo,
  },
  {
    name: WellnessBundleApp.DANCEBIT,
    description: 'wellnessBundleUpsell.topRatedApps.dancebitDescription',
    image: dancebitLogo,
  },
  {
    name: WellnessBundleApp.NUTRIMATE,
    description: 'wellnessBundleUpsell.topRatedApps.nutriMateDescription',
    image: nutrimateLogo,
  },
]
