import styled from 'styled-components'

import { Color } from 'root-constants'

type TWrapperProps = {
  withCheckbox?: boolean
  isLargeSize?: boolean
}

type TContentProps = {
  withDetailedAnswer?: boolean
}

export const StyledCompositeAnswerButton = {
  Root: styled.div<TWrapperProps>`
    width: 100%;
    max-width: ${({ isLargeSize }) => (isLargeSize ? '328px' : '320px')};
    box-shadow: 0 8px 20px #dfe3f3;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
    background-color: #fff;
    color: ${Color.DARK};
    padding: 8px 20px;
    position: relative;

    input:checked + & {
      background: #ff8276;
      color: ${Color.WHITE};
    }

    input:checked + & p {
      color: ${Color.WHITE};
    }
  `,

  Content: styled.div<TContentProps>`
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    line-height: 20px;
    overflow-wrap: break-word;

    & p {
      color: ${Color.LIGHT};
    }
  `,
  Title: styled.h3`
    width: 100%;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
  `,
  Text: styled.p`
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  `,
}
