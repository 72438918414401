import { Cohort } from 'root-constants'

export const QUESTION = 'Care cosmetics'

export const enum OPTION_VALUES {
  SUNSCREEN = 'sunscreen',
  CLEANSER = 'cleanser',
  TONER = 'toner',
  SERUM = 'serum',
  MOISTURIZER = 'moisturizer',
  EYE_CREAM = 'eye_cream',
  FACE_MASK = 'face_mask',
  EXFOLIATORS = 'exfoliators',
  LIP_TREATMENT = 'lip_treatment',
  NONE = 'none_of_these',
}

export const CARE_COSMETICS_MAP = {
  [OPTION_VALUES.SUNSCREEN]: {
    value: OPTION_VALUES.SUNSCREEN,
    text: 'onboarding.careCosmetics.sunscreen',
  },
  [OPTION_VALUES.CLEANSER]: {
    value: OPTION_VALUES.CLEANSER,
    text: 'onboarding.careCosmetics.cleanser',
  },
  [OPTION_VALUES.TONER]: {
    value: OPTION_VALUES.TONER,
    text: 'onboarding.careCosmetics.toner',
  },
  [OPTION_VALUES.SERUM]: {
    value: OPTION_VALUES.SERUM,
    text: 'onboarding.careCosmetics.serum',
  },
  [OPTION_VALUES.MOISTURIZER]: {
    value: OPTION_VALUES.MOISTURIZER,
    text: 'onboarding.careCosmetics.moisturizer',
  },
  [OPTION_VALUES.EYE_CREAM]: {
    value: OPTION_VALUES.EYE_CREAM,
    text: 'onboarding.careCosmetics.eyeCream',
  },
  [OPTION_VALUES.FACE_MASK]: {
    value: OPTION_VALUES.FACE_MASK,
    text: 'onboarding.careCosmetics.faceMask',
  },
  [OPTION_VALUES.EXFOLIATORS]: {
    value: OPTION_VALUES.EXFOLIATORS,
    text: 'onboarding.careCosmetics.exfoliators',
  },
  [OPTION_VALUES.LIP_TREATMENT]: {
    value: OPTION_VALUES.LIP_TREATMENT,
    text: 'onboarding.careCosmetics.lipTreatment',
  },
  [OPTION_VALUES.NONE]: {
    value: OPTION_VALUES.NONE,
    text: 'onboarding.careCosmetics.none',
  },
}

export const DEFAULT_CARE_COSMETICS_OPTIONS = [
  CARE_COSMETICS_MAP[OPTION_VALUES.CLEANSER],
  CARE_COSMETICS_MAP[OPTION_VALUES.TONER],
  CARE_COSMETICS_MAP[OPTION_VALUES.SERUM],
  CARE_COSMETICS_MAP[OPTION_VALUES.MOISTURIZER],
  CARE_COSMETICS_MAP[OPTION_VALUES.EYE_CREAM],
  CARE_COSMETICS_MAP[OPTION_VALUES.FACE_MASK],
  CARE_COSMETICS_MAP[OPTION_VALUES.EXFOLIATORS],
  CARE_COSMETICS_MAP[OPTION_VALUES.LIP_TREATMENT],
  CARE_COSMETICS_MAP[OPTION_VALUES.NONE],
]

export const COSMETIC_SCANNER_OPTIONS = [
  CARE_COSMETICS_MAP[OPTION_VALUES.CLEANSER],
  CARE_COSMETICS_MAP[OPTION_VALUES.TONER],
  CARE_COSMETICS_MAP[OPTION_VALUES.SERUM],
  CARE_COSMETICS_MAP[OPTION_VALUES.MOISTURIZER],
  CARE_COSMETICS_MAP[OPTION_VALUES.EYE_CREAM],
  CARE_COSMETICS_MAP[OPTION_VALUES.FACE_MASK],
  CARE_COSMETICS_MAP[OPTION_VALUES.EXFOLIATORS],
  CARE_COSMETICS_MAP[OPTION_VALUES.NONE],
]

export const SENIOR_CARE_COSMETICS_OPTIONS = [
  CARE_COSMETICS_MAP[OPTION_VALUES.SUNSCREEN],
  CARE_COSMETICS_MAP[OPTION_VALUES.CLEANSER],
  CARE_COSMETICS_MAP[OPTION_VALUES.TONER],
  CARE_COSMETICS_MAP[OPTION_VALUES.SERUM],
  CARE_COSMETICS_MAP[OPTION_VALUES.MOISTURIZER],
  CARE_COSMETICS_MAP[OPTION_VALUES.EYE_CREAM],
  CARE_COSMETICS_MAP[OPTION_VALUES.FACE_MASK],
  CARE_COSMETICS_MAP[OPTION_VALUES.EXFOLIATORS],
  CARE_COSMETICS_MAP[OPTION_VALUES.NONE],
]

export const CARE_COSMETICS_OPTIONS_MAP = {
  [Cohort.LUVLY_COSMETIC_SCANNER]: COSMETIC_SCANNER_OPTIONS,
  [Cohort.LUVLY_12]: SENIOR_CARE_COSMETICS_OPTIONS,
  [Cohort.LUVLY_CHAT]: SENIOR_CARE_COSMETICS_OPTIONS,
  [Cohort.LUVLY_12_SALE]: SENIOR_CARE_COSMETICS_OPTIONS,
}
