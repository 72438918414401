import { createBrowserHistory } from 'history'

import { ILocationsState } from 'models/common.model'

export const browserHistory = createBrowserHistory()

export const goTo = (location: string | ILocationsState): void =>
  browserHistory.push(location)
export const goBack = (): void => browserHistory.back()
export const replaceHistory = (location: string | ILocationsState): void =>
  browserHistory.replace(location)
