import { CookieConsentOption } from 'root-constants'

export const ALL_COOKIE_CONSENT_TYPES = [
  CookieConsentOption.ADVERTIZING_AND_TARGETING,
  CookieConsentOption.ANALYTICS_AND_PERFORMANCE,
  CookieConsentOption.FUNCTIONAL_COOKIES,
  CookieConsentOption.STRICTLY_NECESSARY,
]

export const enum Source {
  BANNER = 'banner',
  SETTINGS = 'cookies_settings_screen',
}
