import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'

import { createProductId } from 'helpers/createProductId'

import { PaymentSystem } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import { setIsFirstPaymentRetryPassedAction } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenName } from 'root-constants'

import { Checkout } from '../Checkout'
import { CheckoutPrimer } from '../CheckoutPrimer'
import {
  CheckoutSeparateMethods,
  CheckoutSeparateMethodsMix,
} from '../CheckoutSeparateMethods'
import { CANCEL_OFFER_COHORTS, SEPARATED_CHECKOUT_COHORTS } from './constants'

type TCheckoutList = {
  setIsCheckoutShown: (isCheckoutShown: boolean) => void
  isCancelOffer: boolean
}
export const CheckoutList = ({
  setIsCheckoutShown,
  isCancelOffer,
}: TCheckoutList) => {
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()
  const cohortToUse = useCohortToUse()
  const { isPrimerActive, isPaypalPrimerButtonActive } = useABTest()

  const { isRefundCohort, isLuvlyIntroSaleCohort } = useActiveCohortIdentifier()

  const { cohort, hasCancelOffer, periodName, periodQuantity, price } =
    usePurchaseStore()

  const isSeparatedCheckout = useMemo(
    () => SEPARATED_CHECKOUT_COHORTS.includes(cohortToUse),
    [cohortToUse],
  )

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  const cancelOfferCohort = useMemo(
    () => CANCEL_OFFER_COHORTS.includes(cohortToUse),
    [cohortToUse],
  )

  const cancelOfferRoutePath = useMemo(
    () =>
      isRefundCohort
        ? PageId.PURCHASE_CANCEL_REFUND
        : PageId.PURCHASE_INTRO_CANCEL_OFFER,
    [isRefundCohort],
  )

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName: isCancelOffer
        ? ScreenName.CANCEL_OFFER_LONG
        : ScreenName.PURCHASE,
    })

    if (isPrimerActive) {
      dispatch(setIsFirstPaymentRetryPassedAction(false))
    }

    if (hasCancelOffer && cancelOfferCohort && !isCancelOffer) {
      dispatch(setSubscriptionListAction([]))
      goTo({ pathname: cancelOfferRoutePath, search })
    }
    setIsCheckoutShown(false)
  }, [
    cancelOfferCohort,
    cancelOfferRoutePath,
    cohort,
    dispatch,
    hasCancelOffer,
    isCancelOffer,
    isPrimerActive,
    pathname,
    productId,
    search,
    setIsCheckoutShown,
  ])

  return (
    <>
      {!isPrimerActive && !isSeparatedCheckout && !isLuvlyIntroSaleCohort && (
        <Checkout
          handleCloseCheckout={handleCloseCheckout}
          hasTotalAmount={false}
        />
      )}
      {isPrimerActive && !isSeparatedCheckout && !isLuvlyIntroSaleCohort && (
        <CheckoutPrimer
          handleCloseCheckout={handleCloseCheckout}
          hasTotalAmount={false}
        />
      )}
      {isSeparatedCheckout && !isLuvlyIntroSaleCohort && (
        <CheckoutSeparateMethods
          handleCloseCheckout={handleCloseCheckout}
          paymentSystem={
            isPrimerActive ? PaymentSystem.PRIMER : PaymentSystem.STRIPE
          }
        />
      )}
      {isLuvlyIntroSaleCohort && (
        <CheckoutSeparateMethodsMix
          hasPaypalButton={isPaypalPrimerButtonActive}
          handleCloseCheckout={handleCloseCheckout}
        />
      )}
    </>
  )
}
