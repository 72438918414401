import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import exercisesCheek from 'assets/images/exercises-cheek.png'
import exercisesEyes from 'assets/images/exercises-eyes.png'
import exercisesJawline from 'assets/images/exercises-jawline.png'

import { DelayedBottomContainer } from 'common-styles'
import { PageId } from 'page-constants'

import { StyledLikeChatExercises as S } from './LikeChatExercises.styles'
import { PROBLEMATIC_AREA_EXERCISES, QUESTION } from './constants'

export const LikeChatExercises: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  const { exercisesText, exercisesImage } = useMemo(() => {
    const areasAnswers = userAnswers?.[PageId.PROBLEMATIC_AREA]
    if (!areasAnswers) {
      return {
        exercisesText: 'onboarding.likeChatExercises.cheek',
        exercisesImage: exercisesCheek,
      }
    }

    if (
      areasAnswers.some(
        ({ label }) => label === PROBLEMATIC_AREA_EXERCISES.JAWLINE,
      )
    ) {
      return {
        exercisesText: 'onboarding.likeChatExercises.cheek',
        exercisesImage: exercisesCheek,
      }
    }
    if (
      areasAnswers.some(
        ({ label }) => label === PROBLEMATIC_AREA_EXERCISES.EYES,
      )
    ) {
      return {
        exercisesText: 'onboarding.likeChatExercises.eyes',
        exercisesImage: exercisesEyes,
      }
    }
    if (
      areasAnswers.some(
        ({ label }) => label === PROBLEMATIC_AREA_EXERCISES.NECK,
      )
    ) {
      return {
        exercisesText: 'onboarding.likeChatExercises.neck',
        exercisesImage: exercisesJawline,
      }
    }
    return {
      exercisesText: 'onboarding.likeChatExercises.cheek',
      exercisesImage: exercisesCheek,
    }
  }, [userAnswers])

  return (
    <Container fullHeight>
      <ChatMessage
        author={ChatAuthor.EXPERT}
        delayBeforeShow={DELAY_BEFORE_SHOW}
        hasJustNowLabel
      >
        <Trans
          i18nKey={exercisesText}
          components={{ ul: <ul />, li: <S.ExercisesLi />, br: <br /> }}
        />
      </ChatMessage>
      <ChatMessage
        author={ChatAuthor.EXPERT}
        delayBeforeShow={2000}
        isImage
        hasHeader={false}
        onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
      >
        <img src={exercisesImage} alt="exercises" />
      </ChatMessage>
      <DelayedBottomContainer
        isShown={chatStep === ChatStep.FINISH}
        pinedToBottom
      >
        <Button onClick={handleNextClick}>{t('actions.cool')}</Button>
      </DelayedBottomContainer>
    </Container>
  )
}
