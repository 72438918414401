import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpgradeSubscriptionsBlock = {
  Wrapper: styled.div`
    width: 100%;
    margin-bottom: 30px;
  `,
  PlanTitle: styled.h3`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 24px;
    color: ${Color.DARK};
    text-align: center;
    padding-top: 40px;
  `,
  AdditionalInfo: styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: ${Color.LIGHT};
    max-width: 277px;
    margin: 24px auto 0;
  `,
}
