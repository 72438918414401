import styled from 'styled-components'

import arrowRight from 'assets/images/arrow-forward-gray.png'
import thumbsUp from 'assets/images/thumbs-up.svg'

import { Color } from 'root-constants'

export const StyledUpsellHairCare = {
  Root: styled.div`
    & .swiper-wrapper {
      margin-bottom: 48px;
    }

    & span.swiper-pagination-bullet {
      background-color: ${Color.CREAMY};
      opacity: 1;
    }

    & span.swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: ${Color.PRIMARY};
    }
  `,
  Hero: styled.div`
    margin-bottom: 24px;
    aspect-ratio: 328 / 326;
    background-color: #e1dcfe;
    position: relative;
    border-radius: 24px;

    & img {
      position: absolute;
      object-fit: contain;
      inset: 0;
    }
  `,
  Title: styled.h1`
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    color: ${Color.DARK};
    margin-bottom: 12px;
    text-align: center;
  `,
  Subtitle: styled.p`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.LIGHT};
    text-align: center;
    margin-bottom: 16px;
  `,
  SubscriptionBlock: styled.div`
    display: flex;
    border-radius: 18px;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px rgba(204, 207, 222, 0.8);
    overflow: hidden;
    align-items: center;
    margin-bottom: 24px;

    & img {
      max-width: 80px;
      margin-right: 10px;
    }
  `,
  SubscriptionBlockTitle: styled.h3`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 4px;
  `,
  Prices: styled.div`
    display: flex;
    align-items: center;
  `,
  OldPrice: styled.span`
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    text-decoration: line-through;
    color: ${Color.GRAY};
    display: flex;
    align-items: center;
    position: relative;

    &:after {
      content: '';
      display: block;
      background-image: url(${arrowRight});
      width: 12px;
      height: 12px;
      background-size: 12px 12px;
      margin: 0 2px;
    }
  `,
  Price: styled.span`
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.RED};
    margin-right: 8px;
  `,
  Discount: styled.div`
    padding: 2px 6px;
    border-radius: 4px;
    border: 1px solid ${Color.RED};
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.RED};
  `,
  ApprovedBy: styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${Color.DARK};
    margin-bottom: 24px;
    padding-left: 48px;
    padding-right: 16px;
    background: url(${thumbsUp}) no-repeat 16px center;
    background-size: 20px;
  `,
  Deny: styled.button`
    all: unset;
    display: flex;
    justify-content: center;
    margin: 16px auto 80px;
    color: ${Color.LIGHT};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-decoration-line: underline;
    cursor: pointer;
  `,
  Guide: styled.div`
    border-radius: 32px;
    background: #f1f5f9;
    padding: 56px 16px 32px;
    position: relative;
    margin-bottom: 40px;
  `,
  GuideIcon: styled.div`
    width: 72px;
    height: 72px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    background: #83bab3;
    box-shadow: 0px 8px 20px 0px rgba(204, 207, 222, 0.5);
  `,
  GuideTitle: styled.h3`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    margin-bottom: 12px;
    color: ${Color.BLUE_DARK};
  `,
  GuideSubtitle: styled.p`
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.LIGHT};
    margin-bottom: 24px;
  `,
  Benefits: styled.div`
    margin-bottom: 40px;

    & button:last-of-type {
      margin-bottom: 0;
    }
  `,
  BenefitsTitle: styled.h3`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 24px;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  BenefitsBeforeAfter: styled.div`
    display: flex;
    border-radius: 24px;
    background-color: ${Color.CORAL_GRAY};
    margin-bottom: 24px;

    & img {
      margin-bottom: 12px;
    }
  `,
  BenefitsBefore: styled.div`
    flex-basis: 50%;
    padding: 16px 8px;
  `,
  BenefitsBeforeTitle: styled.h4`
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.LIGHT};
    max-width: 140px;
    margin: 0 auto 12px;
  `,
  BenefitsBeforeItem: styled.li`
    max-width: 140px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 auto 12px;
    color: ${Color.LIGHT};

    &:last-child {
      margin-bottom: 0;
    }

    & svg {
      flex-shrink: 0;
      margin-right: 6px;
    }
  `,
  BenefitsAfter: styled.div`
    flex-basis: 50%;
    padding: 16px 8px;
    background-color: #fff4f0;
  `,
  BenefitsAfterTitle: styled.h4`
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.PRIMARY};
    max-width: 140px;
    margin: 0 auto 12px;
  `,
  BenefitsAfterItem: styled.li`
    max-width: 140px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 auto 12px;
    color: ${Color.PRIMARY};

    &:last-child {
      margin-bottom: 0;
    }

    & svg {
      flex-shrink: 0;
      margin-right: 6px;
    }
  `,
  Stories: styled.div`
    padding-bottom: 32px;
    max-width: 360px;
    margin: 0 auto;

    & .swiper {
      padding: 16px 16px 0;
    }
  `,
  StoriesTitle: styled.h3`
    color: ${Color.DARK};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 16px;
  `,
  StoriesSubtitle: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    color: ${Color.DARK};

    & span {
      margin-left: 4px;
    }
  `,
  StoriesRating: styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2px;
    margin-left: 13px;
  `,
  Story: styled.div`
    padding: 20px;
    border-radius: 20px;
    background: ${Color.WHITE};
    box-shadow: 0px 2px 4px 0px rgba(7, 4, 146, 0.1),
      0px 24px 60px 0px rgba(6, 47, 125, 0.05),
      0px 12px 24px 0px rgba(27, 59, 119, 0.05);
  `,
  StoryCaption: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.GRAY};
  `,
  StoryTeller: styled.h4`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
    color: ${Color.GRAY};
  `,
  StoryTitle: styled.h3`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 8px;
    line-height: 26px;
    color: ${Color.DARK};
  `,
  StoryText: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.GRAY};
  `,
}
