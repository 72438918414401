import styled from 'styled-components'

import { Container } from 'components/Container'
import { Header } from 'components/Header'

import { Color } from 'root-constants'

export const StyledHolidaysPurchaseCancelOffer = {
  Root: styled.div`
    padding-bottom: 40px;
  `,
  Header: styled(Header)`
    box-shadow: none;
    margin: 0 auto;
  `,
  Hero: styled.section`
    background-color: #fef8f4;
    padding: 16px 0 40px 0;
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
    color: ${Color.DARK};
    text-align: center;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,

  TitleDescription: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: ${Color.LIGHT};
    margin-bottom: 40px;
    text-align: center;
  `,
  PromoCode: styled.p`
    background: #fef7e8;
    border: 1px solid #f6ca66;
    border-radius: 14px;
    padding: 16px 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: ${Color.DARK};
    margin-bottom: 40px;
    text-align: center;
    letter-spacing: -0.3px;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  ButtonContainer: styled(Container)`
    margin: 40px auto 24px;
  `,
}
