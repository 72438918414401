import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPhotoResult = {
  TitleContainer: styled.header`
    max-width: 360px;
    padding: 0 10px;
    margin: 0 auto 12px;
  `,
  ImageContainer: styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 17px;
    aspect-ratio: 320/184;
  `,
  Card: styled.div`
    min-width: 130px;
    padding: 10px;
    background-color: ${Color.WHITE};
    box-shadow: 0 8px 20px #dfe3f3;
    border-radius: 14px;
  `,
  Image: styled.img`
    max-width: 110px;
    image-rendering: -webkit-optimize-contrast;
  `,
  ReviewContainer: styled.div`
    padding: 21px 10px 12px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px #dfe3f3;
    border-radius: 20px;
    text-align: center;
  `,
  ReviewText: styled.p`
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 15px;
  `,
  ReviewAuthor: styled.p`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  `,
  ProgressbarContainer: styled.div`
    margin-top: 20px;
    padding-bottom: 40px;
  `,
}
