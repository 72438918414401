import brianna from 'assets/images/brianna-before-after-min.jpg'
import bannerMarissa from 'assets/images/intro-v2-banner-min.jpg'
import jennifer from 'assets/images/jennifer-before-after-min.jpg'
import linda from 'assets/images/linda-before-after-min.jpg'
import ambassadorMarissa from 'assets/images/marissa-ambassador.png'
import marissa from 'assets/images/marissa-before-after-min.jpg'

import { Cohort } from 'root-constants'

export const QUESTION_MARISSA = 'Save 50% with Marissa'

const MARISSA_INTRO_PAGE_ID = 'marissaIntro'

export const REVIEWS = [
  {
    name: 'onboarding.introV2.reviews.marissa',
    image: marissa,
  },
  {
    name: 'onboarding.introV2.reviews.brianna',
    image: brianna,
  },
  {
    name: 'onboarding.introV2.reviews.linda',
    image: linda,
  },
  {
    name: 'onboarding.introV2.reviews.jennifer',
    image: jennifer,
  },
]

export const STEPS = [
  {
    title: `onboarding.introV2.steps.first.stepTitle`,
    description: `onboarding.introV2.steps.first.stepDescription`,
  },
  {
    title: `onboarding.introV2.steps.second.stepTitle`,
    description: `onboarding.introV2.steps.second.stepDescription`,
  },
  {
    title: `onboarding.introV2.steps.third.stepTitle`,
    description: `onboarding.introV2.steps.third.stepDescription`,
  },
]

export enum OBSERVABLE_BLOCKS {
  REVIEWS_BLOCK = 'reviews',
  TERMS_BLOCK = 'terms',
}

export const intersectionOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 1,
}

export const scrolledValues = {
  [OBSERVABLE_BLOCKS.REVIEWS_BLOCK]: '66%',
  [OBSERVABLE_BLOCKS.TERMS_BLOCK]: '100%',
}

export const fixedButtonParams = {
  rootMargin: '-30px 0px 0px 0px',
  threshold: 0.4,
}

export const BLOGGERS_INTRO_PAGE_MAP = {
  [Cohort.LUVLY_MARISSA]: {
    pageId: MARISSA_INTRO_PAGE_ID,
    question: QUESTION_MARISSA,
    banner: bannerMarissa,
    avatar: ambassadorMarissa,
  },
}
