import dancebitBackground from 'assets/images/dancebit-app-bg.png'
import dancebitImage1 from 'assets/images/dancebit-app-image-1.png'
import dancebitImage2 from 'assets/images/dancebit-app-image-2.png'
import dancebitImage3 from 'assets/images/dancebit-app-image-3.png'
import dancebitLabel from 'assets/images/dancebit-label-vertical.png'
import fitMeBackground from 'assets/images/fitme-app-bg.png'
import fitMeImage1 from 'assets/images/fitme-app-image-1.png'
import fitMeImage2 from 'assets/images/fitme-app-image-2.png'
import fitMeImage3 from 'assets/images/fitme-app-image-3.png'
import fitMeLabel from 'assets/images/fitme-label-vertical.png'
import nutrimateBackground from 'assets/images/nutrimate-app-bg.png'
import nutrimateImage1 from 'assets/images/nutrimate-app-image-1.png'
import nutrimateImage2 from 'assets/images/nutrimate-app-image-2.png'
import nutrimateImage3 from 'assets/images/nutrimate-app-image-3.png'
import nutrimateLabel from 'assets/images/nutrimate-label-vertical.png'

export const WELLNESS_BUNDLE_APP_IMAGES = [
  {
    id: 1,
    images: [fitMeImage1, fitMeImage2, fitMeImage3],
    appLabel: fitMeLabel,
    subtitle: 'wellnessBundleUpsell.appsGallery.fitMe.subtitle',
    description: 'wellnessBundleUpsell.appsGallery.fitMe.description',
    background: fitMeBackground,
  },
  {
    id: 2,
    images: [dancebitImage1, dancebitImage2, dancebitImage3],
    appLabel: dancebitLabel,
    subtitle: 'wellnessBundleUpsell.appsGallery.dancebit.subtitle',
    description: 'wellnessBundleUpsell.appsGallery.dancebit.description',
    background: dancebitBackground,
  },
  {
    id: 3,
    images: [nutrimateImage1, nutrimateImage2, nutrimateImage3],
    appLabel: nutrimateLabel,
    subtitle: 'wellnessBundleUpsell.appsGallery.nutriMate.subtitle',
    description: 'wellnessBundleUpsell.appsGallery.nutriMate.description',
    background: nutrimateBackground,
  },
]
