import styled from 'styled-components'

import { Button } from 'components/Button'

import { PurchaseDisclaimer as Disclaimer } from 'modules/purchase/components/PurchaseDisclaimer'
import { SecurityInfo as Security } from 'modules/purchase/components/SecurityInfo'
import { TLocaleStylesProps } from 'modules/purchase/types'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseRefund = {
  Wrapper: styled.div`
    padding-bottom: 8px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 360px;
      margin: 0 auto;
    }
  `,
  TimerContainer: styled.div`
    width: 100%;
    background-color: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.75);
    position: sticky;
    margin: -15px auto 0;
    top: 0;
    z-index: 2;
  `,
  TimerContent: styled.div`
    margin: 0 auto;
    max-width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
  `,
  TimerText: styled.div`
    color: #252d48;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-right: 14px;
  `,
  CountdownTime: styled.p`
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    height: 28px;
  `,
  TopButton: styled(Button)`
    width: 50%;
  `,
  ContainerWrapper: styled.div<TLocaleStylesProps>`
    max-width: 360px;
    margin: 96px auto 0;
    padding: ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle ? '0 20px' : '0 12px'};
  `,
  SubscriptionsContainer: styled.div`
    margin-top: 24px;

    &:last-of-type {
      padding-bottom: 16px;
    }
  `,
  ButtonContainer: styled.div`
    margin: -6px 0 24px;
  `,
  NumberOneImage: styled.img`
    width: 288px;
    margin: 0 auto 24px;
  `,
  Subtitle: styled.h2`
    color: ${Color.DARK};
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
  `,
  SecurityInfo: styled(Security)`
    box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.5);
  `,
  PurchaseDisclaimer: styled(Disclaimer)`
    font-size: 14px;
    line-height: 20px;
  `,
}
