import React from 'react'
import { useTranslation } from 'react-i18next'

import upgradeBonus from 'assets/images/upgrade-bonus.png'

import { StyledPurchaseUpgradeBenefits as S } from './PurchaseUpgradeBenefits.styles'

export const PurchaseUpgradeBenefits: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.PurchaseUpgradeBenefitsBlock>
      <S.BenefitsTitle>
        {t('purchaseUpgrade.bonusBenefitsTitle')}:
      </S.BenefitsTitle>
      <S.BonusContainer>
        <S.ImageContainer>
          <S.Image src={upgradeBonus} alt="bonus" />
          <S.BonusLabel>{t('purchaseUpgrade.bonusTitle')}</S.BonusLabel>
        </S.ImageContainer>
        <S.BonusTitle>{t('purchaseUpgrade.bonusDescription')} 📖</S.BonusTitle>
        <S.BonusMessage>{t('purchaseUpgrade.bonusMessage')}</S.BonusMessage>
      </S.BonusContainer>
      <S.List>
        <S.ListItem>
          {t('purchaseUpgrade.bonusBenefits.removeDoubleChin')}
        </S.ListItem>
        <S.ListItem>
          {t('purchaseUpgrade.bonusBenefits.discoverGuaSha')}
        </S.ListItem>
        <S.ListItem>{t('purchaseUpgrade.bonusBenefits.facelift')}</S.ListItem>
        <S.ListItem>
          {t('purchaseUpgrade.bonusBenefits.settingRoutine')}
        </S.ListItem>
        <S.ListItem>{t('purchaseUpgrade.bonusBenefits.happierYOU')}</S.ListItem>
      </S.List>
    </S.PurchaseUpgradeBenefitsBlock>
  )
}
