import { useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendUserTTCLID } from 'root-redux/actions/user'
import { selectTiktokPixelId } from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserCountryCode,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export const useTiktokPixelInitialization = (): void => {
  const dispatch = useDispatch()
  const tiktokPixelId = useSelector(selectTiktokPixelId)
  const email = useSelector(selectUserOnboardingEmail)
  const uuid = useSelector(selectUUID)
  const userCountryCode = useSelector(selectUserCountryCode)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    hasFunctionalCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const shouldTiktokPixelBePaused = useMemo(() => {
    return (
      (isEUUser && !userCookieConsentAnswer?.length) ||
      (isEUUser &&
        !!userCookieConsentAnswer?.length &&
        !hasAdvertizingAndMarketingCookie)
    )
  }, [isEUUser, userCookieConsentAnswer, hasAdvertizingAndMarketingCookie])

  const isPersonalDataAllowed = useMemo(() => {
    return (isEUUser && hasFunctionalCookie) || !isEUUser
  }, [isEUUser, hasFunctionalCookie])

  useLayoutEffect(() => {
    if (!tiktokPixelId || !userCountryCode || shouldTiktokPixelBePaused) return
    window.ttq.load(tiktokPixelId)
    window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })
    window.ttq.page({ event_id: uuid })

    const searchParams = new URLSearchParams(document.location.search)
    const ttclid = searchParams.get('ttclid')

    if (ttclid && uuid) dispatch(sendUserTTCLID(ttclid))
  }, [
    dispatch,
    email,
    tiktokPixelId,
    uuid,
    userCountryCode,
    shouldTiktokPixelBePaused,
    isPersonalDataAllowed,
  ])
}
