import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import faceYogaPractice from 'assets/images/face-yoga-practice.png'

import { Chat, DelayedBottomContainer } from 'common-styles'
import { OptionType } from 'root-constants'

import { StyledLikeChatFaceYoga as S } from './LikeChatFaceYoga.styles'
import { OPTION_VALUES, PRACTICE_ANSWERS_MAP, QUESTION } from './constants'

export const LikeChatFaceYoga: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue(answer)
  }, [answer, handleContinue])

  const handleSetAnswer = useCallback((value) => {
    setAnswer(value)
  }, [])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      onChange: (value: string) => {
        setIsAnswersDisabled(true)
        handleChange(value, handleSetAnswer)
      },
    }),
    [
      handleChange,
      handleSetAnswer,
      isAnswersDisabled,
      pageId,
      setIsAnswersDisabled,
    ],
  )

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.USER_ANSWER)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_QUESTION ||
            (chatStep === ChatStep.USER_ANSWER && !answer)
          }
        >
          {t('onboarding.likeChatFaceYoga.title')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.USER}
          {...(!!answer && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.EXPERT_EXPRESSION)}
        >
          {answer && (
            <S.AnswerWrapper>
              <S.Icon src={PRACTICE_ANSWERS_MAP[answer].icon} alt="icon" />
              {t(PRACTICE_ANSWERS_MAP[answer].text)}
            </S.AnswerWrapper>
          )}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          {...(chatStep === ChatStep.EXPERT_EXPRESSION && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.IMAGE)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_EXPRESSION ||
            chatStep === ChatStep.IMAGE ||
            chatStep === ChatStep.FINISH
          }
        >
          {t('onboarding.likeChatFaceYoga.expression')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          isImage
          hasHeader={false}
          {...(chatStep === ChatStep.IMAGE && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
        >
          <img src={faceYogaPractice} alt="face-yoga-practice" />
        </ChatMessage>
      </Chat>
      <DelayedBottomContainer isShown={chatStep === ChatStep.FINISH}>
        <Button onClick={handleNextClick}>{t('actions.soundsGreat')}</Button>
      </DelayedBottomContainer>
      <DelayedBottomContainer
        isShown={!answer && chatStep === ChatStep.USER_ANSWER}
      >
        <Option {...optionProps} value={OPTION_VALUES.PRACTICE_YOGA}>
          <ChatAnswerButton
            iconSrc={PRACTICE_ANSWERS_MAP[OPTION_VALUES.PRACTICE_YOGA].icon}
          >
            {t('onboarding.likeChatFaceYoga.practiceFaceYoga')}
          </ChatAnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.TRIED_IT}>
          <ChatAnswerButton
            iconSrc={PRACTICE_ANSWERS_MAP[OPTION_VALUES.TRIED_IT].icon}
          >
            {t('onboarding.likeChatFaceYoga.triedFaceYoga')}
          </ChatAnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.NEVER_TRIED}>
          <ChatAnswerButton
            iconSrc={PRACTICE_ANSWERS_MAP[OPTION_VALUES.NEVER_TRIED].icon}
          >
            {t('onboarding.likeChatFaceYoga.neverTriedFaceYoga')}
          </ChatAnswerButton>
        </Option>
      </DelayedBottomContainer>
    </Container>
  )
}
