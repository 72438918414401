import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Chat, DelayedBottomContainer } from 'common-styles'
import {
  CHAT_PAGE_TRANSITION_DELAY,
  Cohort,
  I18N_CONTEXT_COHORT_MAP,
  OptionType,
} from 'root-constants'

import { OPTION_VALUES, QUESTION, VEGETABLES_ANSWERS_MAP } from './constants'

export const LikeChatVegetables: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)
  const { handleChange } = useDelayedContinue(CHAT_PAGE_TRANSITION_DELAY)
  const {
    isAnswersDisabled,
    handleChange: handleAnswerChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleSetAnswer = useCallback((value) => {
    setAnswer(value)
  }, [])

  useEffect(() => {
    if (chatStep === ChatStep.FINISH) {
      handleChange(answer, handleContinue)
    }
  }, [answer, chatStep, handleChange, handleContinue])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      onChange: (value: string) => {
        setIsAnswersDisabled(true)
        handleAnswerChange(value, handleSetAnswer)
      },
    }),
    [
      handleAnswerChange,
      handleSetAnswer,
      isAnswersDisabled,
      pageId,
      setIsAnswersDisabled,
    ],
  )

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.USER_ANSWER)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_QUESTION ||
            (chatStep === ChatStep.USER_ANSWER && !answer)
          }
        >
          {t('onboarding.likeChatVegetables.title')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.USER}
          {...(!!answer && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
        >
          {answer && (
            <Trans
              i18nKey={VEGETABLES_ANSWERS_MAP[answer]}
              context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
            />
          )}
        </ChatMessage>
      </Chat>
      <DelayedBottomContainer
        isShown={!answer && chatStep === ChatStep.USER_ANSWER}
      >
        <Option {...optionProps} value={OPTION_VALUES.EVERYDAY}>
          <ChatAnswerButton>
            {t('onboarding.vegetables.everyday')}
          </ChatAnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.OFTEN}>
          <ChatAnswerButton>
            <Trans
              i18nKey="onboarding.vegetables.often"
              context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
            />
          </ChatAnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.SOMETIMES}>
          <ChatAnswerButton>
            {t('onboarding.vegetables.sometimes')}
          </ChatAnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.NEVER}>
          <ChatAnswerButton>
            {t('onboarding.vegetables.never')}
          </ChatAnswerButton>
        </Option>
      </DelayedBottomContainer>
    </Container>
  )
}
