import React from 'react'
import { useTranslation } from 'react-i18next'

import { EffectCreative, Navigation, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Container } from 'components/Container'

import { StyledWellnessBundleAppGallery as S } from './WellnessBundleAppGallery.styles'
import { WELLNESS_BUNDLE_APP_IMAGES } from './constants'

export const WellnessBundleAppGallery: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container fields={16}>
      <S.Title>{t('wellnessBundleUpsell.appsGallery.title')}</S.Title>
      {WELLNESS_BUNDLE_APP_IMAGES.map(
        ({ id, images, appLabel, subtitle, description, background }) => (
          <S.Wrapper key={id} background={background}>
            <S.LabelImage src={appLabel} alt="app-label-image" />
            <S.AppSubtitle>{t(subtitle)}</S.AppSubtitle>
            <S.AppDescription>{t(description)}</S.AppDescription>
            <Carousel
              slidesPerView={1.6}
              loop
              centeredSlides
              modules={[Pagination, Navigation, EffectCreative]}
              navigation
              pagination={{
                clickable: true,
              }}
              effect="creative"
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              creativeEffect={{
                prev: {
                  scale: 0.9,
                  translate: ['-100%', 0, 0],
                },
                next: {
                  scale: 0.9,
                  translate: ['100%', 0, 0],
                },
              }}
            >
              {images.map((image) => (
                <SwiperSlide key={image}>
                  <S.ImageWrapper>
                    <S.SlideImage src={image} alt="wellness-app-image" />
                  </S.ImageWrapper>
                </SwiperSlide>
              ))}
            </Carousel>
          </S.Wrapper>
        ),
      )}
    </Container>
  )
}
