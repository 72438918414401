import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseCosmeticScanner = {
  Wrapper: styled.div`
    padding-bottom: 22px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 360px;
      margin: 0 auto;
    }
  `,
  Title: styled.h1`
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: ${Color.DARK};
    margin-bottom: 15px;
  `,
  GoalSubtitle: styled.h3`
    font-size: 17px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    color: ${Color.LIGHT};
    margin-bottom: 12px;
  `,
  SubscriptionsContainer: styled.div`
    margin-top: 24px;

    &:last-of-type {
      padding-bottom: 16px;
    }
  `,
  ButtonContainer: styled.div`
    margin: -6px 0 24px;
  `,
  Subtitle: styled.h2`
    text-align: center;
    color: ${Color.DARK};
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
  `,
  OfferSubtitle: styled.h2`
    text-align: center;
    color: ${Color.DARK};
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 40px;
  `,
  DailyScheduleImage: styled.img`
    margin-top: 24px;
    width: 100%;
    margin-bottom: 40px;
  `,
  StartPlanButtonContainer: styled.div`
    margin: 30px auto 40px;
  `,
}
