import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPurchaseSubtitle = {
  Subtitle: styled.h2`
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
}
