import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { IntroOptionWithImage } from 'components/IntroOptionWithImage'
import { Option } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { Sale } from 'components/Sale'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { Ticker } from 'components/Ticker'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectLanguage } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PageId } from 'page-constants'
import { DynamicDiscountType, Locale, OptionType } from 'root-constants'

import { StyledAge as S } from './Age.styles'
import {
  AGE_OPTIONS,
  AGE_OPTIONS_ES,
  AGE_OPTION_VALUES_MAP,
  QUESTION,
  SENIOR_AGE_OPTIONS,
} from './constants'

export const AgeAsIntro: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const { isDynamicDiscountCohort } = useActiveCohortIdentifier()

  useStartSession()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { isSeniorCohort, isLuvlyIntroFastCohort } = useActiveCohortIdentifier()
  const { headline, subtext } = useTitleFromUrl()

  const handleContinue = useNextStep({
    pageId: PageId.AGE,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = useCallback(
    (value: string) => {
      eventLogger.logUserAgeSelected(AGE_OPTION_VALUES_MAP[value])
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, setIsAnswersDisabled],
  )

  const isEsLocale = useMemo(() => language === Locale.SPANISH, [language])

  const pageData = useMemo(() => {
    if (isSeniorCohort) {
      return {
        options: SENIOR_AGE_OPTIONS,
        subtitle: 'onboarding.ageAsIntro.subtitle_senior',
      }
    }
    return {
      options:
        isEsLocale && isLuvlyIntroFastCohort ? AGE_OPTIONS_ES : AGE_OPTIONS,
      subtitle: 'onboarding.ageAsIntro.subtitle',
    }
  }, [isEsLocale, isLuvlyIntroFastCohort, isSeniorCohort])

  const optionProps = useMemo(
    () => ({
      name: PageId.AGE,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())

        window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        handleAnswerChange(value)
      },
    }),
    [handleAnswerChange, isAnswersDisabled, dispatch, uuid],
  )

  return (
    <S.Root>
      <IntroHeader colorTheme={IntroHeaderColor.DARK} />
      {isDynamicDiscountCohort &&
        dynamicDiscount?.type !== DynamicDiscountType.STATIC && <Ticker />}
      {isDynamicDiscountCohort &&
        dynamicDiscount?.type === DynamicDiscountType.STATIC && <Sale />}
      <Container>
        <S.Title>{headline || t`onboarding.ageAsIntro.title`}</S.Title>
        <S.Subtitle>
          {subtext || <Trans i18nKey={pageData.subtitle} />}
        </S.Subtitle>
        <S.CallToAction>{t`onboarding.ageAsIntro.CTA`}</S.CallToAction>
        <S.Actions>
          {pageData.options.map(({ value, text, image }) => (
            <Option {...optionProps} value={value} key={value}>
              <IntroOptionWithImage image={image} text={text} />
            </Option>
          ))}
        </S.Actions>
      </Container>
      <S.Terms>
        <p>{t`onboarding.intro.termsTitle`}</p>
        <TermsOfUseLink />
        <S.Separator />
        <PrivacyPolicyLink />
      </S.Terms>
    </S.Root>
  )
}
