import styled from 'styled-components'

import discountBackground from 'assets/images/cancel-offer-discount-bg.png'

import { Color } from 'root-constants'

export const StyledCancelOfferDiscountInfo = {
  Root: styled.div`
    max-width: 328px;
    padding: 16px 16px 16px 80px;
    background: url(${discountBackground}) no-repeat center,
      linear-gradient(
        263deg,
        rgba(123, 195, 0, 0.5) 44.68%,
        rgba(135, 213, 0, 0.5) 100%
      );
    background-size: contain;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #1c4f4a;
    margin: 16px auto;
    border-radius: 12px;

    & i {
      text-decoration: line-through;
      font-style: normal;
    }

    & strong {
      color: ${Color.RED};
    }
  `,
}
