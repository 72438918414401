import { DynamicTitles } from 'hooks/useTitleFromUrl'

import { KEYWORD_PARAM, LANG_QUERY_PARAM } from 'root-constants'

export const getPathFromPageId = ({
  pageId,
  cohort,
  uuid,
  language,
  currentSearch,
}: {
  pageId: string
  cohort: string | null
  uuid: string
  language: string
  currentSearch: string
}): string => {
  const keyword = new URLSearchParams(document.location.search).get(
    KEYWORD_PARAM,
  )

  const searchParams = new URLSearchParams(currentSearch)
  const headline = searchParams.get(DynamicTitles.HEADLINE)
  const subtext = searchParams.get(DynamicTitles.SUBTEXT)
  const lang = searchParams.get(LANG_QUERY_PARAM)

  if (!lang && (headline || subtext)) {
    searchParams.delete(DynamicTitles.HEADLINE)
    searchParams.delete(DynamicTitles.SUBTEXT)
  }

  searchParams.set('cohort', cohort || '')
  searchParams.set('uuid', uuid)
  searchParams.set('lang', language)

  keyword && searchParams.set(KEYWORD_PARAM, keyword)

  return `${pageId}?${searchParams.toString()}`
}
