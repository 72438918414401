import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { Button } from 'components/Button'
import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import animationConfig from 'assets/animation/heartAnimation.json'

import { Chat, DelayedBottomContainer } from 'common-styles'
import { OptionType } from 'root-constants'

import { StyledLikeChatSkinQuality as S } from './LikeChatSkinQuality.styles'
import { OPTION_VALUES, QUESTION, SKIN_QUALITY_ANSWERS_MAP } from './constants'

export const LikeChatSkinQuality: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)
  const animationRef = useRef<HTMLSpanElement | null>(null)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleSetAnswer = useCallback((value) => {
    setAnswer(value)
  }, [])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      onChange: (value: string) => {
        setIsAnswersDisabled(true)
        handleChange(value, handleSetAnswer)
      },
    }),
    [
      handleChange,
      handleSetAnswer,
      isAnswersDisabled,
      pageId,
      setIsAnswersDisabled,
    ],
  )

  useEffect(() => {
    return () => lottie.destroy()
  }, [])

  const triggerAnimation = useCallback(() => {
    if (animationRef.current?.firstChild) return
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        animationData: animationConfig,
        loop: false,
      })
    }
  }, [])

  const handleNextClick = useCallback(() => {
    handleContinue(answer)
  }, [answer, handleContinue])

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.USER_ANSWER)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_QUESTION ||
            (chatStep === ChatStep.USER_ANSWER && !answer)
          }
        >
          {t('onboarding.likeChatSkin.title')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.USER}
          {...(!!answer && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.EXPERT_EXPRESSION)}
        >
          {answer && (
            <S.AnswerWrapper>
              <S.Icon src={SKIN_QUALITY_ANSWERS_MAP[answer].icon} alt="icon" />
              {t(SKIN_QUALITY_ANSWERS_MAP[answer].text)}
            </S.AnswerWrapper>
          )}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          {...(chatStep === ChatStep.EXPERT_EXPRESSION && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => {
            setChatStep(ChatStep.FINISH)
            triggerAnimation()
          }}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_EXPRESSION ||
            chatStep === ChatStep.FINISH
          }
        >
          {answer && (
            <Trans
              i18nKey={SKIN_QUALITY_ANSWERS_MAP[answer].expression}
              components={{
                emoji: <S.Emoji />,
              }}
            />
          )}
        </ChatMessage>
      </Chat>
      <DelayedBottomContainer isShown={chatStep === ChatStep.FINISH}>
        <Button onClick={handleNextClick}>{t('actions.thankYou')}</Button>
      </DelayedBottomContainer>
      <DelayedBottomContainer
        isShown={!answer && chatStep === ChatStep.USER_ANSWER}
      >
        <Option {...optionProps} value={OPTION_VALUES.LIKE_IT}>
          <ChatAnswerButton
            iconSrc={SKIN_QUALITY_ANSWERS_MAP[OPTION_VALUES.LIKE_IT].icon}
          >
            {t('onboarding.likeChatSkin.happy')}
          </ChatAnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.NOT_SURE}>
          <ChatAnswerButton
            iconSrc={SKIN_QUALITY_ANSWERS_MAP[OPTION_VALUES.NOT_SURE].icon}
          >
            {t('onboarding.likeChatSkin.notReally')}
          </ChatAnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.HAVE_PROBLEMS}>
          <ChatAnswerButton
            iconSrc={SKIN_QUALITY_ANSWERS_MAP[OPTION_VALUES.HAVE_PROBLEMS].icon}
          >
            {t('onboarding.likeChatSkin.problematicSkin')}
          </ChatAnswerButton>
        </Option>
      </DelayedBottomContainer>
      <S.Animation ref={animationRef} />
    </Container>
  )
}
