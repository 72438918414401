import styled from 'styled-components'

export const StyledEmailLogin = {
  Inputs: styled.div`
    width: 100%;
    margin-bottom: 0;

    @media (min-height: 812px) {
      margin-bottom: 60px;
    }
  `,
}
