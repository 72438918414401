import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  sendUserAnswersAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectGeneralSubscriptionDuration,
  selectGeneralSubscriptionPrice,
  selectGeneralSubscriptionPriceId,
  selectGeneralSubscriptionTrialPeriod,
  selectGeneralSubscriptionTrialPrice,
  selectUserAccountLoginMethod,
  selectUserPaymentCurrency,
  selectUserPaymentMethod,
} from 'root-redux/selects/user'

import { getDeepLink } from 'helpers/getDeepLink'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import girl from 'assets/images/getting-app-girl.png'

import { CUSTOM_TOKEN_LOCAL_STORAGE_KEY } from 'root-constants'

import { StyledDownload as S } from './Download.styles'

export const Download: React.FC = () => {
  const { t } = useTranslation()
  const loginMethod = useSelector(selectUserAccountLoginMethod)
  const dispatch = useDispatch()
  const paymentCurrency = useSelector(selectUserPaymentCurrency)
  const paymentMethod = useSelector(selectUserPaymentMethod)
  const price = useSelector(selectGeneralSubscriptionPrice)
  const priceId = useSelector(selectGeneralSubscriptionPriceId)
  const subscriptionDuration = useSelector(selectGeneralSubscriptionDuration)
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)
  const trialPrice = useSelector(selectGeneralSubscriptionTrialPrice)

  const downloadAppLink = useMemo(() => {
    if (!loginMethod) return ''
    const customToken = localStorage.getItem(CUSTOM_TOKEN_LOCAL_STORAGE_KEY)

    return getDeepLink(customToken)
  }, [loginMethod])

  useEffect(() => {
    eventLogger.logGettingAppShown()
  }, [])

  useEffect(() => {
    dispatch(sendUserAnswersAction(null, true))
    dispatch(
      sendUserConfigAction({
        is_download_visited: true,
        payment_currency: paymentCurrency,
        payment_method: paymentMethod,
        subscription_price: `${price}`,
        subscription_duration: `${subscriptionDuration}`,
        price_id: `${priceId}`,
        trial_price: `${trialPrice}`,
        trial_period: `${trialPeriodDays}`,
      }),
    )
  }, [
    dispatch,
    paymentCurrency,
    paymentMethod,
    price,
    subscriptionDuration,
    priceId,
    trialPeriodDays,
    trialPrice,
  ])

  const handleContinue = useCallback(() => {
    window.location.href = downloadAppLink
  }, [downloadAppLink])

  const handleClick = useCallback(() => {
    googleAnalyticsLogger.logDownloadButtonPressed()
    eventLogger.logDownloadClicked(handleContinue)
  }, [handleContinue])

  if (!loginMethod) return null

  return (
    <S.Wrapper>
      <S.Title>
        <Trans i18nKey="gettingApp.downloadToContinue" components={[<br />]} />
      </S.Title>
      <S.ImageContainer>
        <S.Image src={girl} alt="girl" />
        <S.Description>{t`gettingApp.description`}</S.Description>
      </S.ImageContainer>
      <S.Button
        type="button"
        data-deep-link={downloadAppLink}
        onClick={handleClick}
      >
        {t`gettingApp.download`}
      </S.Button>
    </S.Wrapper>
  )
}
