import styled from 'styled-components'

import { StyledButtonBase } from 'common-styles'

import { IProps as IButtonProps } from './Button'

export const StyledButton = styled.button<IButtonProps>`
  ${StyledButtonBase};

  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`}

  &:disabled {
    background-color: ${({ disabledBackgroundColor }) =>
      disabledBackgroundColor || '#cdced1'};
  }
`
