import styled from 'styled-components'

import calendarIcon from 'assets/images/calendar-icon.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledCustomDatePicker = {
  WrapperDataField: styled.div`
    width: 100%;
    border-radius: 10px;
    border: 1px solid ${Color.LIGHT};
    background: ${Color.WHITE};
    padding: 18px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    height: 60px;
    display: flex;
    cursor: pointer;
  `,
  DateField: styled.div`
    user-select: none;
    margin-right: 7px;
  `,
  CalendarIcon: styled.div`
    position: absolute;
    width: 24px;
    height: 24px;
    right: 16px;
    bottom: 18px;
    background: url(${calendarIcon});

    path {
      display: none;
    }
  `,
  Wrapper: styled.div`
    position: relative;

    .react-datepicker__input-container {
      position: relative;
      display: flex;
      justify-content: center;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker {
      background: ${Color.WHITE};
      box-shadow: 0 8px 20px rgba(17, 30, 23, 0.1);
      border-radius: 12px;
      border: none;
      color: transparent;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }

    .react-datepicker__navigation {
      top: 16px;
    }

    .react-datepicker__current-month {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 10px;
    }

    .react-datepicker__day-name {
      width: 30px;
      font-weight: 400;
      font-size: 14px;
      color: #898e97;
      margin: 6px;
    }

    .react-datepicker__week {
      .react-datepicker__day--keyboard-selected {
        background-color: transparent;
      }

      .react-datepicker__day {
        width: 36px;
        height: 36px;
        font-weight: 400;
        font-size: 14px;
        line-height: 36px;
      }
    }

    .react-datepicker-popper[data-placement^='bottom'] {
      padding-top: 0;
      transform: translate3d(23px, 190px, 0px);
      z-index: 5;

      @media (min-width: ${MediaBreakpoint.SMALL_PHONE}px) {
        transform: translate3d(43px, 190px, 0px);
      }
    }

    .react-datepicker__header {
      padding-top: 16px;
      padding-bottom: 0;
      background-color: transparent;
      border: none;
    }

    .react-datepicker__month {
      margin: 8px 12px 16px;
    }

    .react-datepicker__month-container {
      width: 320px;

      .react-datepicker__input-container input {
        width: 153px;
        height: 54px;
        border: 2px solid ${Color.GRAY};
        border-radius: 12px;
        background: ${Color.WHITE};
        align-items: center;
        padding: 16px 16px 16px 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;

        &:focus {
          outline: none !important;
          border-color: ${Color.PRIMARY};
        }
      }

      .react-datepicker__day--outside-month {
        color: #898e97;
      }

      .react-datepicker__day--disabled {
        color: #d5d6d6;
      }
    }

    .react-datepicker__week {
      .react-datepicker__day--selected {
        background: ${Color.PRIMARY};
        font-weight: 500;
        border-radius: 12px;
        color: ${Color.WHITE};
        outline: none;
      }
    }
  `,
}
