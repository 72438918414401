import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledCustomerReviews = {
  TitleContainer: styled.div`
    width: 100%;
    margin-bottom: 6px;
    padding: 14px 11px;
    background-color: #f0f2f7;
  `,
  Title: styled.h2`
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    color: ${Color.LIGHT};
  `,
  ViewMoreLinkContainer: styled.div`
    width: 100%;
    border-bottom: 1px solid #c4c4c4;
    text-align: right;

    &.no-border {
      border-bottom: none;
    }
  `,
  ViewMoreLink: styled.span`
    padding-bottom: 8px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: #43598f;
    cursor: pointer;
  `,
}
