import React from 'react'

import { TComponentProps } from 'models/common.model'

import { StyledPageTitle as S } from './PageTitle.styles'

export const PageTitle: React.FC<TComponentProps> = ({
  children,
  className = '',
}) => <S.PageTitle className={className}>{children}</S.PageTitle>
