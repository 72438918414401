import styled from 'styled-components'

import emptyGlass from 'assets/images/empty-glass.png'
import fullGlass from 'assets/images/full-glass.png'

type TButtonProps = {
  isActive: boolean
  index: number
}

export const StyledGlasses = {
  Root: styled.div`
    width: 100%;
    max-width: 360px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  `,
  Button: styled.button<TButtonProps>`
    display: inline-flex;
    outline: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    height: auto;
    min-height: 80px;
    border: 0;
    transition: 0.1s;
    transition-delay: ${(props) => (props.index ? props.index / 50 : 0)}s;
    margin-bottom: 12px;
    padding: 0;
    cursor: pointer;
    position: relative;
    background: ${({ isActive }) =>
      isActive
        ? `url(${fullGlass}) no-repeat center`
        : `url(${emptyGlass}) no-repeat center`};

    background-size: contain;
  `,
}
