import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import luvlyUsers from 'assets/images/luvly-users.png'

import { StyledLuvlyUsers as S } from './LuvlyUsers.styles'
import { QUESTION } from './constants'

export const LuvlyUsers: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.Root>
      <Container>
        <S.Title>{t('onboarding.luvlyUsers.question')}</S.Title>
        <S.Description>
          <Trans
            i18nKey="onboarding.luvlyUsers.description"
            components={[<br />]}
          />
        </S.Description>
      </Container>
      <S.ImageContainer>
        <S.Image src={luvlyUsers} alt="" />
      </S.ImageContainer>
      <S.StickyButtonContainer customBackground="transparent">
        <Button onClick={handleNextClick}>{t('actions.next')}</Button>
      </S.StickyButtonContainer>
    </S.Root>
  )
}
