import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledChatAnswerButtonMini = {
  Root: styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 60px;
    width: 100%;
    height: 74px;
    background-color: ${Color.WHITE};
    transition: background-color 0.25s;
    border: 1px solid rgba(255, 130, 118, 0.5);
    border-radius: 10px;
    font-size: 18px;
    color: ${Color.DARK};

    input:checked + & {
      background: ${Color.PRIMARY};
      color: ${Color.WHITE};
    }
  `,
}
