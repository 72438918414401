import React from 'react'
import { useSelector } from 'react-redux'

import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { StyledSale as S } from './Sale.styles'

export const Sale = () => {
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  return (
    <S.Root theme={dynamicDiscount?.theme}>
      <S.Text>{dynamicDiscount?.name}</S.Text>
    </S.Root>
  )
}
