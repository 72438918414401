import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledAge = {
  Root: styled.div`
    padding-top: 56px;
  `,
  TitleContainer: styled.header`
    margin-bottom: 16px;
  `,
  DescriptionContainer: styled.div`
    margin-bottom: 16px;
  `,
  OptionsContainer: styled.div``,
  Title: styled.h1`
    color: ${Color.DARK};
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 24px;
  `,
  Subtitle: styled.h2`
    color: ${Color.DARK};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;
    text-transform: uppercase;
  `,
  CallToAction: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 24px;
  `,
  Actions: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin: 0 -4px;

    input:checked + div > div {
      border: 2px solid ${Color.PRIMARY};
      background: #ffe0de;
    }

    & label {
      flex-basis: calc(50% - 8px);
      margin-bottom: 0;
    }
  `,
  Terms: styled.div`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: ${Color.LIGHT};
    margin: 24px auto 0;

    a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.LIGHT};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
