import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

export const StyledPaymentModal = {
  Container: styled.div`
    width: 100%;
    max-width: 320px;
    min-height: 362px;
    max-height: 362px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: ${Color.WHITE};
    margin: 0 20px;
  `,
  ModalWrapper: styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
  `,
  LottieContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  `,
  PaymentStatus: styled.div`
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: ${Color.DARK};
    padding: 5px 0 9px;
    font-size: 24px;
    line-height: 28px;
  `,
  ErrorStatus: styled.div`
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: ${Color.DARK};
    padding: 10px 15px;
    font-size: 15px;
    line-height: 18px;
  `,
  ErrorInfo: styled.div`
    padding: 10px 7px 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  ErrorButton: styled(Button)`
    background-color: #ff6168;
    height: 50px;
  `,
  SuccessButton: styled(Button)`
    height: 50px;
    margin-top: 32px;
    background-color: #8fdf2c;
  `,
  Disclaimer: styled.p`
    padding: 16px 0 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
}
