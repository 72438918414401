import styled from 'styled-components'

import chevronRight from 'assets/images/chevron-right.svg'

import { Color } from 'root-constants'

export const StyledIntroOptionWithImage = {
  Action: styled.div`
    display: flex;
    min-height: 176px;
    position: relative;
    padding-top: 11px;
  `,
  Image: styled.img`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 140px;
    z-index: 1;
  `,
  Answer: styled.div`
    flex-basis: 100%;
    background-color: ${Color.WHITE};
    border: 2px solid transparent;
    border-radius: 12px;
    transition: background-color 0.1s ease-out;
    box-shadow: 0px 3px 13px 0px #d6dcf4;
  `,
  AnswerWrapper: styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    border-radius: 0 0 12px 12px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: ${Color.WHITE};
    position: absolute;
    bottom: 0;
    left: 0;
    height: 36px;
    width: 100%;
    padding: 8px 12px;
    z-index: 2;
    background: ${Color.PRIMARY};
    margin-right: 6px;
  `,
  AnswerValue: styled.span`
    margin-right: 6px;
  `,
  ChevronRight: styled.span`
    width: 6px;
    height: 12px;
    background: url(${chevronRight}) no-repeat center;
    background-size: contain;
  `,
}
