import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { Cohort } from 'root-constants'

import { ONBOARDING_STEPS_MAP } from '../../constants/progressSteps'
import { StyledOnboardingProgressBar as S } from './OnboardingProgressBar.styles'

export const OnboardingProgressBar: React.FC = () => {
  const { pathname } = useLocation()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  const currentVariantSteps = useMemo(
    () =>
      ONBOARDING_STEPS_MAP[(parentCohort as Cohort) || (cohort as Cohort)] ||
      [],
    [cohort, parentCohort],
  )

  const [currentSectionIndex, currentStepIndex] = useMemo(() => {
    const currentPageId = getPageIdFromPathName(pathname)

    const sectionIndex = currentVariantSteps.findIndex(
      (block) => !!block.find((step) => step.id === currentPageId),
    )
    const stepIndex = currentVariantSteps[sectionIndex]?.findIndex(
      (step) => step.id === currentPageId,
    )
    return [sectionIndex, stepIndex]
  }, [currentVariantSteps, pathname])

  const hasProgressBar = useMemo(
    () =>
      currentSectionIndex >= 0 &&
      currentVariantSteps[currentSectionIndex][currentStepIndex].hasProgressBar,
    [currentVariantSteps, currentSectionIndex, currentStepIndex],
  )

  return !hasProgressBar ? null : (
    <S.Wrapper>
      {currentVariantSteps.map((stepsBlock, index) => {
        const currentBlockProgressValue =
          currentSectionIndex === index ? currentStepIndex : 0
        const progressValue =
          currentSectionIndex > index
            ? stepsBlock.length
            : currentBlockProgressValue

        return (
          <S.StepBlock key={stepsBlock[0].id}>
            <S.StepBlockProgress
              data-first-progress={index === 0}
              data-active={currentSectionIndex > index}
              value={progressValue}
              max={stepsBlock.length}
            />
            <S.StepNumber data-active={currentSectionIndex > index}>
              {index + 1}
            </S.StepNumber>
          </S.StepBlock>
        )
      })}
      <S.StepBlock>
        <S.StepBlockProgress value={0} max={0} data-last-progress />
      </S.StepBlock>
    </S.Wrapper>
  )
}
