import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Chat, DelayedContainer } from 'common-styles'
import {
  CHAT_PAGE_TRANSITION_DELAY,
  Cohort,
  I18N_CONTEXT_COHORT_MAP,
  OptionType,
} from 'root-constants'

import { StyledLikeChatDiet as S } from './LikeChatDiet.styles'
import { DIET_ANSWERS_MAP, DIET_OPTIONS, QUESTION } from './constants'

export const LikeChatDiet: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)
  const { handleChange } = useDelayedContinue(CHAT_PAGE_TRANSITION_DELAY)
  const {
    isAnswersDisabled,
    handleChange: handleAnswerChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleSetAnswer = useCallback((value) => {
    setAnswer(value)
  }, [])

  useEffect(() => {
    if (chatStep === ChatStep.FINISH) {
      handleChange(answer, handleContinue)
    }
  }, [answer, chatStep, handleChange, handleContinue])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      onChange: (value: string) => {
        setIsAnswersDisabled(true)
        handleAnswerChange(value, handleSetAnswer)
      },
    }),
    [
      handleAnswerChange,
      handleSetAnswer,
      isAnswersDisabled,
      pageId,
      setIsAnswersDisabled,
    ],
  )

  return (
    <S.Root>
      <Container fullHeight justifyContent="flex-end">
        <Chat>
          <ChatMessage
            author={ChatAuthor.EXPERT}
            delayBeforeShow={DELAY_BEFORE_SHOW}
            onTransitionEnd={() => setChatStep(ChatStep.USER_ANSWER)}
            hasJustNowLabel={
              chatStep === ChatStep.EXPERT_QUESTION ||
              (chatStep === ChatStep.USER_ANSWER && !answer)
            }
          >
            {t('onboarding.likeChatDiet.title')}
          </ChatMessage>
          <ChatMessage
            author={ChatAuthor.USER}
            {...(!!answer && {
              delayBeforeShow: DELAY_BEFORE_SHOW,
            })}
            onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
          >
            {answer && (
              <Trans
                i18nKey={DIET_ANSWERS_MAP[answer]}
                context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
              />
            )}
          </ChatMessage>
        </Chat>
        <DelayedContainer
          isShown={!answer && chatStep === ChatStep.USER_ANSWER}
        >
          <S.OptionsContainer>
            {DIET_OPTIONS.map(({ value, text, description }) => (
              <Option {...optionProps} key={value} value={value} withoutMargin>
                <ChatAnswerButton>
                  <Trans
                    i18nKey={text}
                    context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
                  />
                  {description && (
                    <S.Text>
                      <Trans
                        i18nKey={description}
                        context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
                      />
                    </S.Text>
                  )}
                </ChatAnswerButton>
              </Option>
            ))}
          </S.OptionsContainer>
        </DelayedContainer>
      </Container>
    </S.Root>
  )
}
