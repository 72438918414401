import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledYouAreInGoodHands = {
  Title: styled.h1`
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.DARK};
    text-align: center;
    margin: 50px 0 24px 0;
  `,
  AnimationContainer: styled.div`
    width: 100%;
    height: 178px;
  `,
  Subtitle: styled.h2`
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.DARK};
    text-align: center;
  `,
}
