export const TESTIMONIALS = [
  {
    name: 'purchase1.holidayCancelOffer.testimonials.andrea.name',
    testimonial: 'purchase1.holidayCancelOffer.testimonials.andrea.testimonial',
  },
  {
    name: 'purchase1.holidayCancelOffer.testimonials.rosie.name',
    testimonial: 'purchase1.holidayCancelOffer.testimonials.rosie.testimonial',
  },
  {
    name: 'purchase1.holidayCancelOffer.testimonials.jin.name',
    testimonial: 'purchase1.holidayCancelOffer.testimonials.jin.testimonial',
  },
  {
    name: 'purchase1.holidayCancelOffer.testimonials.silverFox.name',
    testimonial:
      'purchase1.holidayCancelOffer.testimonials.silverFox.testimonial',
  },
]
