import { Cohort } from 'root-constants'

export const SEPARATED_CHECKOUT_COHORTS: Cohort[] = [
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_9,
  Cohort.LUVLY_14,
  Cohort.LUVLY_15,
  Cohort.LUVLY_17,
  Cohort.LUVLY_18,
  Cohort.LUVLY_CHAT,
  Cohort.LUVLY_INTRO3_SALE,
  Cohort.LUVLY_12_SALE,
  Cohort.LUVLY_20,
  Cohort.LUVLY_REF,
]

export const CANCEL_OFFER_COHORTS: Cohort[] = [
  Cohort.LUVLY_CHAT,
  Cohort.LUVLY_INTRO3_SALE,
  Cohort.LUVLY_REF,
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_INTRO_SALE,
  Cohort.LUVLY_9,
  Cohort.LUVLY_12,
  Cohort.LUVLY_12_SALE,
  Cohort.LUVLY_20,
]
