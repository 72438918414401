import styled, { css } from 'styled-components'

import blackBg from 'assets/images/static-sale-bg-black.jpg'
import blueBg from 'assets/images/static-sale-bg-blue.jpg'
import coralBg from 'assets/images/static-sale-bg-coral.jpg'
import violetBg from 'assets/images/static-sale-bg-purple.jpg'

import { Color, DynamicDiscountTheme } from 'root-constants'

type TTheme = {
  theme: DynamicDiscountTheme
}

export const StyledSale = {
  Root: styled.div<TTheme>`
    display: grid;
    place-content: center;
    height: 53px;

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: url(${coralBg}) no-repeat center;
            background-size: cover;
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: url(${violetBg}) no-repeat center;
            background-size: cover;
          `
        case DynamicDiscountTheme.BLUE:
          return css`
            background: url(${blueBg}) no-repeat center;
            background-size: cover;
          `
        case DynamicDiscountTheme.BLACK:
          return css`
            background: url(${blackBg}) no-repeat center;
            background-size: cover;
          `
        default:
          return css`
            background: url(${coralBg}) no-repeat center;
            background-size: cover;
          `
      }
    }}
  `,
  Text: styled.p`
    color: ${Color.WHITE};
    text-align: center;
    text-shadow: 0px 1px 4px rgba(37, 33, 120, 0.2);
    font-family: Rubik;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
  `,
}
