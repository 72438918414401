import styled, { css } from 'styled-components'

import { TLocaleStylesProps } from 'modules/purchase/types'

import { Color } from 'root-constants'

export const StyledLongPaywallCustomerReviews = {
  Wrapper: styled.div<TLocaleStylesProps>`
    max-width: 360px;
    margin: 0 auto 45px;
    padding: ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle ? '0 20px' : '0 16px'};
  `,
  Title: styled.h2<TLocaleStylesProps>`
    text-align: center;
    color: ${Color.DARK};
    font-weight: 700;
    margin-bottom: 24px;

    ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle
        ? css`
            font-size: 28px;
            line-height: 32px;
          `
        : css`
            font-size: 24px;
            line-height: 30px;
          `}
  `,
  Review: styled.div`
    width: 100%;
    padding: 12px 16px;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.5);
  `,
  ViewMoreLinkContainer: styled.div`
    width: 100%;
    text-align: right;
  `,
  ViewMoreLink: styled.span`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    color: ${Color.PRIMARY};
    cursor: pointer;
  `,
}
