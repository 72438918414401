import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import expertAvatar from 'assets/images/angela-rosoff-online.png'

import { StyledChatMessage as S } from './ChatMessage.styles'

export const DELAY_BEFORE_SHOW = 500

export enum ChatAuthor {
  EXPERT = 'expert',
  USER = 'user',
}

export enum ChatStep {
  EXPERT_QUESTION = 'expert_question',
  SHOW_OPTIONS = 'show_options',
  USER_ANSWER = 'user_answer',
  EXPERT_EXPRESSION = 'expert_expression',
  IMAGE = 'image',
  FINISH = 'finish',
}

type TExpertHeaderProps = {
  hasJustNowLabel?: boolean
}

type TExpertMessage = {
  author: ChatAuthor.EXPERT
  hasHeader?: boolean
}

type TUserMessage = {
  author: ChatAuthor.USER
  hasHeader?: true
}

export type TChatMessageProps = {
  children: ReactNode
  delayBeforeShow?: number
  isImage?: boolean
  className?: string
  onTransitionEnd?: () => void
  width?: string
} & TExpertHeaderProps &
  (TExpertMessage | TUserMessage)

const UserMessageHeader = () => {
  const { t } = useTranslation()

  return (
    <S.MessageHeader author={ChatAuthor.USER}>
      <S.Author>{t('commonComponents.chatMessage.userName')}</S.Author>
    </S.MessageHeader>
  )
}

const ExpertMessageHeader: React.FC<TExpertHeaderProps> = ({
  hasJustNowLabel,
}: TExpertHeaderProps) => {
  const { t } = useTranslation()

  return (
    <S.MessageHeader author={ChatAuthor.EXPERT}>
      <S.Author>{t('commonComponents.chatMessage.expertName')}</S.Author>
      {hasJustNowLabel && (
        <S.Time>{t('commonComponents.chatMessage.time')}</S.Time>
      )}
    </S.MessageHeader>
  )
}

export const ChatMessage: React.FC<TChatMessageProps> = ({
  author,
  hasHeader = true,
  children,
  delayBeforeShow,
  isImage = false,
  onTransitionEnd,
  className,
  hasJustNowLabel,
  width = '252px',
}: TChatMessageProps) => {
  const [isShown, setIsShown] = useState<boolean>(false)

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>

    if (delayBeforeShow) {
      timerId = setTimeout(() => {
        setIsShown(true)
      }, delayBeforeShow)
    }
    return () => clearTimeout(timerId)
  }, [delayBeforeShow])

  return (
    <S.Root
      author={author}
      isShown={isShown}
      className={className}
      onTransitionEnd={onTransitionEnd}
    >
      {hasHeader && (
        <S.ExpertPhoto src={expertAvatar} alt="expert-avatar" author={author} />
      )}
      <S.ContentWrapper>
        {hasHeader && author === ChatAuthor.EXPERT && (
          <ExpertMessageHeader hasJustNowLabel={hasJustNowLabel} />
        )}
        {author === ChatAuthor.USER && <UserMessageHeader />}

        <S.Content
          author={author}
          hasHeader={hasHeader}
          isImage={isImage}
          width={width}
        >
          {children}
        </S.Content>
      </S.ContentWrapper>
    </S.Root>
  )
}
