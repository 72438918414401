import styled from 'styled-components'

import { HolidaysCancelOfferReviews } from 'modules/purchase/components/HolidaysCancelOfferReviews'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'

import { MediaBreakpoint } from 'root-constants'

export const StyledPurchaseUpgradePDF = {
  Root: styled.div`
    padding: 0 0 40px 0;
  `,
  Hero: styled.img`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  ButtonContainer: styled.div`
    margin: 24px 0 40px 0;
  `,
  HolidaysCancelOfferReviews: styled(HolidaysCancelOfferReviews)`
    padding: 0;
  `,
  SecurityInfo: styled(SecurityInfo)`
    a {
      font-weight: 400;
      text-decoration: underline;
    }
  `,
}
