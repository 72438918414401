import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { LinearProgressBar } from 'components/LinearProgressBar'
import { Option } from 'components/Option'

import { sendUserEmailConsentAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { Chat, DelayedBottomContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { StyledLikeChatEmailConsent as S } from './LikeChatEmailConsent.styles'
import { QUESTION, USER_ANSWERS } from './constants'

export const LikeChatEmailConsent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { search } = useLocation()
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleClick = useCallback(
    async (value) => {
      eventLogger.logQuestion({
        question: QUESTION,
        answers: value,
      })

      dispatch(
        sendUserEmailConsentAction({ consented: value === USER_ANSWERS.YES }),
      )

      goTo({ pathname: PageId.OB_RESULT, search })
    },
    [dispatch, search],
  )

  const optionProps = useMemo(
    () => ({
      name: PageId.OB_EMAIL_CONSENT,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      onChange: (value: string) => {
        setIsAnswersDisabled(true)
        handleChange(value, handleClick)
      },
    }),
    [handleChange, handleClick, isAnswersDisabled, setIsAnswersDisabled],
  )

  return (
    <div>
      <LinearProgressBar progressStatus={28} />
      <Container fullHeight justifyContent="flex-end">
        <Chat>
          <ChatMessage
            author={ChatAuthor.EXPERT}
            delayBeforeShow={DELAY_BEFORE_SHOW}
            onTransitionEnd={() => setChatStep(ChatStep.USER_ANSWER)}
            hasJustNowLabel
          >
            {t('onboarding.likeChatEmailConsent.title')}
          </ChatMessage>
        </Chat>
        <DelayedBottomContainer isShown={chatStep === ChatStep.USER_ANSWER}>
          <S.OptionsContainer>
            <Option {...optionProps} value={USER_ANSWERS.YES} withoutMargin>
              <ChatAnswerButton>
                {t('onboarding.likeChatEmailConsent.yes')}
              </ChatAnswerButton>
            </Option>
            <Option {...optionProps} value={USER_ANSWERS.NO} withoutMargin>
              <ChatAnswerButton>
                {t('onboarding.likeChatEmailConsent.no')}
              </ChatAnswerButton>
            </Option>
          </S.OptionsContainer>
        </DelayedBottomContainer>
      </Container>
    </div>
  )
}
