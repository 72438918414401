import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'

import { StyledUpsellGuashaDescription as S } from './UpsellGuashaDescription.styles'

export const UpsellGuashaDescription: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <Container fields={16}>
        <S.Title>
          <Trans i18nKey="guashaUpsell.title" component={[<br />]} />
        </S.Title>
        <S.Description>{t('guashaUpsell.description')}</S.Description>
        <S.FeaturesWrapper />
      </Container>
    </S.Root>
  )
}
