import styled from 'styled-components'

import { Color } from 'root-constants'

type TContainerProps = {
  bgColor: string
  borderColor: string
}

export const StyledUpsellGuashaUsers = {
  Root: styled.div`
    margin-bottom: 40px;
  `,
  Title: styled.p`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 24px;

    & > strong {
      color: ${Color.PRIMARY};
    }
  `,
  Container: styled.div<TContainerProps>`
    padding: 24px 24px 0;
    border-radius: 32px;
    text-align: center;
    background-color: ${({ bgColor }) => bgColor};
    border: 1px solid ${({ borderColor }) => borderColor};

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  `,
  ContainerTitle: styled.p`
    color: ${Color.BLUE_DARK};
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 12px;
  `,
  ContainerSubtitle: styled.p`
    color: ${Color.LIGHT};
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 16px;
  `,
  Image: styled.img``,
}
