import styled from 'styled-components'

import { Button } from 'components/Button'

import PrimaryCheck from 'assets/images/accept-red-checkbox.png'
import СloseSvg from 'assets/images/close-icon.svg'

import { Color } from 'root-constants'

export const StyledCheckoutGuasha = {
  Wrapper: styled.div`
    padding-top: 20px;
    padding-bottom: 32px;
    display: flex;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 70px;
    z-index: 5;
    background-color: ${Color.DEFAULT_BACKGROUND};
  `,
  CloseButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 0;
    right: 15px;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 24px 24px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${СloseSvg});
  `,
  Subtitle: styled.h2`
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 16px;
    text-align: center;
  `,
  Form: styled.form``,
  OptionalAddressActivator: styled.button`
    color: ${Color.DARK};
    font-size: 15px;
    font-weight: 400;
    background: transparent;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 12px;
    display: block;
    line-height: normal;
    border: none;
    outline: none;
    cursor: pointer;
  `,
  ShippingInfoSubtitle: styled.h4`
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    margin: 16px auto 8px;
  `,
  ShippingInfoWrapper: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
    border-radius: 10px;
    padding: 6px 20px;
    box-shadow: 0px 8px 20px 0px #dfe3f3;
    margin-bottom: 32px;
  `,
  ShippingInfoIcon: styled.div`
    width: 20px;
    height: 20px;
    background: url(${PrimaryCheck}) no-repeat center;
    background-size: contain;
  `,
  ShippingInfoContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 12px;
  `,
  ShippingInfoText: styled.p`
    font-size: 15px;
    font-weight: 500;
  `,
  ShippingInfoDescription: styled.p`
    font-size: 13px;
    font-weight: 400;
  `,
  SelectContainer: styled.div`
    margin-bottom: 12px;
  `,
  SubmitButton: styled(Button)`
    max-width: 320px;
    margin-top: 5px;
  `,
}
