import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledChatStatementQuestion = {
  Statement: styled.p`
    color: ${Color.DARK};
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 4px;
  `,
  OptionsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  `,
  Scale: styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.GRAY};
    padding-bottom: 40px;

    & span {
      max-width: 110px;
    }
  `,
}
