import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useVatInfo } from 'hooks/useHasVatInfo'

import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { StyledSelectSubscriptionItem as S } from './SelectSubscriptionItem.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
  isOutOfStock?: boolean
  isCancelOffer?: boolean
}

export const SelectSubscriptionItem: React.FC<TProps> = ({
  subscription: { id, isDefault, currency, trialPrices, mainPrices },
  isSelected,
  isOutOfStock = false,
  isCancelOffer = false,
}) => {
  const { t } = useTranslation()
  const hasVatInfo = useVatInfo()

  const taxAmount = useSelector(selectTaxAmount)

  const getCalculatedPrice = (fullPrice: number) => {
    if (!hasVatInfo) return fullPrice.toFixed(2)

    return getCalculatedPriceWithoutVAT(fullPrice, taxAmount)
  }

  const trialPriceWithFractionalPart = useMemo(
    () => trialPrices.fullPrice.toFixed(2),
    [trialPrices],
  )

  return (
    <S.Wrapper isSelected={isSelected} key={id} isOutOfStock={isOutOfStock}>
      {isDefault && (
        <S.MostPopularBadge>{t`purchase1.subscription.mostPopularBadge`}</S.MostPopularBadge>
      )}
      {isOutOfStock && (
        <S.SoldOutBadge>{t`purchase1.subscription.soldOutBadge`}</S.SoldOutBadge>
      )}
      <S.Content
        isSelected={isSelected}
        isDefault={isDefault}
        isOutOfStock={isOutOfStock}
      >
        <S.PlanPeriod isSelected={isSelected}>
          <Trans
            i18nKey="purchase1.subscription.planPeriod"
            values={{
              count: mainPrices.periodQuantity,
              periodName: mainPrices.periodName,
            }}
            components={[<br />]}
          />
        </S.PlanPeriod>
        <S.PriceContainer>
          <S.PreviousPrice isSelected={isSelected}>
            {t('purchase1.subscription.price', {
              currency: CURRENCY_SYMBOLS[currency],
              price: getCalculatedPrice(
                isCancelOffer
                  ? mainPrices.fullPrice
                  : mainPrices.oldPrices.weekly,
              ),
            })}
          </S.PreviousPrice>
          <S.CurrentPrice isSelected={isSelected}>
            <S.PriceValue>
              {t('purchase1.subscription.price', {
                currency: CURRENCY_SYMBOLS[currency],
                price: getCalculatedPrice(
                  isCancelOffer
                    ? Number(trialPriceWithFractionalPart)
                    : mainPrices.weekly,
                ),
              })}
            </S.PriceValue>
            {!isCancelOffer && (
              <S.Period>
                {t`purchase1.subscription.per`}
                {t`purchase1.subscription.week`}
              </S.Period>
            )}
          </S.CurrentPrice>
        </S.PriceContainer>
      </S.Content>
    </S.Wrapper>
  )
}
