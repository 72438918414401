import styled from 'styled-components'

type TWrapperProps = {
  buttonHeight: number
  marginBottom: number
  borderRadius: number
}

export const StyledPaymentRequestButton = {
  Wrapper: styled.div<TWrapperProps>`
    height: ${({ buttonHeight }) => `${buttonHeight}px`};
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};

    & * {
      border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    }
  `,
}
