import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPageTitle = {
  PageTitle: styled.h1`
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.DARK};
  `,
}
