import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useVatInfo } from 'hooks/useHasVatInfo'

import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import { CURRENCY_SYMBOLS, Currency } from 'modules/purchase/constants'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { SubscriptionProduct } from 'root-constants'

import { StyledSelectSubscriptionItemV2 as S } from './SelectSubscriptionItemV2.styles'
import { WEEKLY_PLANS_DURATION_MAP } from './constants'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const SelectSubscriptionItemV2: React.FC<TProps> = ({
  subscription: { id, isDefault, mainPrices, trialPrices, currency },
  isSelected,
}) => {
  const { t } = useTranslation()

  const hasVatInfo = useVatInfo()

  const taxAmount = useSelector(selectTaxAmount)

  const isMxnCurrency = useMemo(() => currency === Currency.MXN, [currency])

  const getCalculatedPrice = (fullPrice: number) => {
    if (!hasVatInfo) return fullPrice

    return getCalculatedPriceWithoutVAT(fullPrice, taxAmount)
  }

  return (
    <S.Wrapper isSelected={isSelected} key={id}>
      {isDefault && (
        <S.MostPopularBadge isSelected={isSelected}>
          {t`purchaseIntroOffer.subscription.mostPopularBadge`}
        </S.MostPopularBadge>
      )}
      <S.Content isSelected={isSelected} isDefault={isDefault}>
        <S.PlanContainer>
          <S.PlanPeriod isSelected={isSelected}>
            {t(
              trialPrices.durationDays === SubscriptionProduct.SEVEN_DAY
                ? 'purchaseIntroOffer.weeklyPlan'
                : 'purchaseIntroOffer.multiWeekPlan',
              {
                count: WEEKLY_PLANS_DURATION_MAP[trialPrices.durationDays],
              },
            )}
          </S.PlanPeriod>
          <S.PricesComparisonWrapper isMxnCurrency={isMxnCurrency}>
            <S.PreviousPrice>
              <Trans
                i18nKey="purchaseIntroOffer.subscription.oldPrice"
                values={{
                  oldPrice:
                    trialPrices.durationDays === SubscriptionProduct.SEVEN_DAY
                      ? getCalculatedPrice(trialPrices.oldPrices.fullPrice)
                      : getCalculatedPrice(mainPrices.fullPrice),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.PreviousPrice>

            <S.CurrentPrice isSelected={isSelected}>
              <Trans
                i18nKey="purchaseIntroOffer.subscription.price"
                values={{
                  price: getCalculatedPrice(trialPrices.fullPrice),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.CurrentPrice>
          </S.PricesComparisonWrapper>
        </S.PlanContainer>
        <S.CustomPrice>
          <S.OldDailyPrice>
            <Trans
              i18nKey="purchaseIntroOffer.subscription.oldestPrice"
              values={{
                oldestPrice: getCalculatedPrice(trialPrices.oldPrices.daily),
                currency: CURRENCY_SYMBOLS[currency],
              }}
            />
          </S.OldDailyPrice>
          <S.PriceValue isSelected={isSelected} isMxnCurrency={isMxnCurrency}>
            {t('purchaseIntroOffer.subscription.price', {
              currency: CURRENCY_SYMBOLS[currency],
              price: getCalculatedPrice(trialPrices.daily),
            })}
          </S.PriceValue>
          <S.Period>
            {t('purchase1.subscription.per', { context: 'day' })}
            {t`purchase1.subscription.day`}
          </S.Period>
        </S.CustomPrice>
      </S.Content>
    </S.Wrapper>
  )
}
