export const QUESTION = 'Skin care routine'

export const OPTION_VALUES = {
  MORNING_AND_EVENING_ROUTINE: 'morning_and_evening_routine',
  MORNING_ROUTINE: 'morning_routine',
  EVENING_ROUTINE: 'evening_routine',
  NO_ROUTINE: 'no_routine',
}

export const SKIN_CARE_ROUTINE_ANSWERS_MAP = {
  [OPTION_VALUES.MORNING_AND_EVENING_ROUTINE]:
    'onboarding.skinCareRoutine.morningAndEveningRoutine',
  [OPTION_VALUES.MORNING_ROUTINE]: 'onboarding.skinCareRoutine.morningRoutine',
  [OPTION_VALUES.EVENING_ROUTINE]: 'onboarding.skinCareRoutine.eveningRoutine',
  [OPTION_VALUES.NO_ROUTINE]: 'onboarding.skinCareRoutine.noRoutine',
}
