import React from 'react'

import { StyledErrorNotification as S } from './ErrorNotification.styles'

type TProps = {
  errorText: string
}

export const ErrorNotification: React.FC<TProps> = ({ errorText }) => (
  <S.Wrapper errorText={errorText}>
    <S.Text>{errorText}</S.Text>
  </S.Wrapper>
)
