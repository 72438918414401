import React, { useCallback } from 'react'

import { SvgImage } from 'components/SvgImage'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import arrowSvg from 'assets/images/sprite/arrow-back-gray.svg'
import logoSvg from 'assets/images/sprite/logo.svg'

import { goBack } from 'browser-history'
import { PROGRESS_STEPS_LENGTH_MAP } from 'root-constants'

import { StyledHeaderMinimalistic as S } from './HeaderMinimalistic.styles'

export const HeaderMinimalistic: React.FC = () => {
  const {
    progressValue,
    hasNavigation,
    hasProgressBar,
    hasMinimalisticHeader,
  } = useGetPageInfo()
  const cohortToUse = useCohortToUse()

  const handleBack = useCallback(() => {
    goBack()
  }, [])

  return (
    <S.Wrapper
      hasNavigation={hasNavigation}
      hasProgressBar={hasProgressBar}
      hasShadow={!hasMinimalisticHeader}
    >
      <S.ArrowIconContainer isVisible={hasNavigation}>
        <SvgImage
          svg={arrowSvg}
          width={24}
          height={24}
          cursor="pointer"
          onClick={handleBack}
        />
      </S.ArrowIconContainer>
      <SvgImage svg={logoSvg} width={58} height={24} />
      <S.PageNumberContainer isVisible={hasNavigation}>
        <span>{progressValue}</span>
        <span>/</span>
        <span>{PROGRESS_STEPS_LENGTH_MAP[cohortToUse]}</span>
      </S.PageNumberContainer>
    </S.Wrapper>
  )
}
