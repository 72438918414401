import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OptionType } from 'root-constants'

import { StyledOccasion as S } from './Occasion.styles'
import { OCCASION_OPTIONS, QUESTION } from './constants'

export const Occasion: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleContinue, pageId, handleChange, setIsAnswersDisabled],
  )

  return (
    <S.Root>
      <S.Title>{t('onboarding.occasion.question')}</S.Title>
      <S.Description>{t('onboarding.occasion.description')}</S.Description>
      {OCCASION_OPTIONS.map(({ value, text, icon }) => (
        <Option
          {...optionProps}
          key={value}
          disabled={isAnswersDisabled}
          value={value}
        >
          <AnswerButton iconSrc={icon}>
            <S.AnswerContent>{t(text)}</S.AnswerContent>
          </AnswerButton>
        </Option>
      ))}
    </S.Root>
  )
}
