import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useVatInfo } from 'hooks/useHasVatInfo'

import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { selectTaxAmount } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { StyledCancelOfferSubscriptionItem as S } from './CancelOfferSubscriptionItem.styles'

type TProps = {
  subscription: ISubscription
}

export const CancelOfferSubscriptionItem: React.FC<TProps> = ({
  subscription: { mainPrices, currency },
}) => {
  const { t } = useTranslation()
  const hasVatInfo = useVatInfo()
  const taxAmount = useSelector(selectTaxAmount)

  const getCalculatedPrice = (fullPrice: number) => {
    if (!hasVatInfo) return fullPrice.toFixed(2)

    return getCalculatedPriceWithoutVAT(fullPrice, taxAmount)
  }

  return (
    <S.Wrapper>
      <S.MostPopularBadge>{t`purchase1.cancelOffer.badge`}</S.MostPopularBadge>
      <S.Content>
        <S.PlanPeriod>
          <Trans
            i18nKey="purchase1.subscription.planPeriod"
            values={{
              count: mainPrices.periodQuantity,
              periodName: mainPrices.periodName,
            }}
            components={[<br />]}
          />
        </S.PlanPeriod>
        <S.PriceContainer>
          <S.PreviousPrice>
            {t('purchase1.subscription.price', {
              currency: CURRENCY_SYMBOLS[currency],
              price: getCalculatedPrice(mainPrices.oldPrices.weekly),
            })}
          </S.PreviousPrice>
          <S.CurrentPrice>
            <S.PriceValue>
              {t('purchase1.subscription.price', {
                currency: CURRENCY_SYMBOLS[currency],
                price: getCalculatedPrice(mainPrices.weekly),
              })}
            </S.PriceValue>
            <S.Period>
              {t`purchase1.subscription.per`}
              {t(`purchase1.subscription.week}`)}
            </S.Period>
          </S.CurrentPrice>
        </S.PriceContainer>
      </S.Content>
    </S.Wrapper>
  )
}
