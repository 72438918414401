import styled from 'styled-components'

import optionIconUrl from 'assets/images/check-icon-full-green.svg'

import { customBackgroundStyles } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants'

export const StyledLuvlyGuide = {
  Root: styled.div`
    padding-top: 25px;
    position: relative;

    ${customBackgroundStyles}
  `,
  Title: styled.header`
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    margin-bottom: 16px;
  `,
  List: styled.ul`
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.8);
    padding: 16px 12px;
    margin-bottom: 32px;
  `,
  ListItem: styled.li`
    color: ${Color.DARK};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 8px;
    position: relative;
    padding-left: 32px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url(${optionIconUrl}) center no-repeat;
      background-size: contain;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
  ImageContainer: styled.div`
    margin: 0 auto 13px;
    aspect-ratio: 320/262;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto 13px;
    }
  `,
  Image: styled.img`
    max-width: 100%;
    height: auto;
  `,
}
