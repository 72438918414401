import React, { useRef } from 'react'
import ReactSelect, { ControlProps, components } from 'react-select'

import { TAnswer } from 'models/common.model'

import {
  StyledSelectWithFloatPlaceholder as S,
  customSelectStyles,
} from './SelectWithFloatPlaceholder.styles'

type TOption = {
  value: TAnswer
  label: string
}

type TSelectProps = {
  placeholder: string
  options: TOption[]
  defaultValue?: TOption | undefined
  isValid?: boolean
  validationText?: string
  onBlur?: (event: any) => void
  onChange?: (event: any) => void
}

export const SelectWithFloatPlaceholder = React.forwardRef<
  HTMLDivElement,
  TSelectProps
>(
  (
    {
      placeholder,
      options,
      defaultValue,
      validationText,
      isValid = true,
      onBlur,
      onChange,
    },
    ref,
  ) => {
    const styles = customSelectStyles(isValid)
    const rootRef = useRef<HTMLDivElement>(null)
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur(event)
    }

    const handleChange = (event) => {
      onChange && onChange(event)
    }

    const CustomOption = ({
      innerProps,
      isDisabled,
      innerRef,
      isSelected,
      children,
    }) =>
      !isDisabled ? (
        <S.Option {...innerProps} ref={innerRef}>
          {children}
          {isSelected ? <S.SelectedIcon /> : <S.CircleMarkIcon />}
        </S.Option>
      ) : null

    const Control = (props: ControlProps<TOption>) => {
      const { isFocused, hasValue } = props

      return (
        <>
          <S.FloatingLabel isFloating={isFocused || hasValue}>
            {placeholder}
          </S.FloatingLabel>
          <components.Control {...props} {...(ref && { innerRef: ref })} />
        </>
      )
    }

    const handleMenuOpen = () => {
      setTimeout(() => {
        if (rootRef.current) {
          rootRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'end',
          })
        }
      }, 0)
    }

    return (
      <S.Root ref={rootRef}>
        <ReactSelect
          defaultValue={defaultValue}
          options={options}
          hideSelectedOptions={false}
          styles={styles}
          isClearable={false}
          isSearchable={false}
          onBlur={handleBlur}
          closeMenuOnSelect
          blurInputOnSelect
          onChange={handleChange}
          placeholder=""
          onMenuOpen={handleMenuOpen}
          components={{
            Option: CustomOption,
            Control,
          }}
        />
        {validationText && !rootRef.current?.onfocus && (
          <S.ValidationText>{validationText}</S.ValidationText>
        )}
      </S.Root>
    )
  },
)
