import styled from 'styled-components'

export const StyledSkinTypeExplanation = {
  TitleContainer: styled.header`
    margin-bottom: 16px;
  `,
  OptionsContainer: styled.div``,
  LinkContainer: styled.div`
    margin-top: 48px;
    text-align: center;
    padding-bottom: 16px;
  `,
  Link: styled.a`
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    cursor: pointer;
  `,
}
