import styled from 'styled-components'

export const StyledUserGoalGraphSenior = {
  GoalDateContainer: styled.div`
    text-align: center;
    margin-bottom: 24px;
  `,
  Goal: styled.p`
    position: relative;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    background: linear-gradient(90deg, #e7e581 0%, #fb9679 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  GraphContainer: styled.div`
    margin-bottom: 24px;
    aspect-ratio: 328/212;
    border-radius: 16px;
    box-shadow: 0px 4px 14px 0px rgba(68, 85, 127, 0.19);
  `,
  Graph: styled.img`
    max-width: 100%;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
  `,
}
