import styled, { css } from 'styled-components'

const titleStyles = css`
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
`

export const StyledShortProgramDescription = {
  TitleContainer: styled.header`
    ${titleStyles}
    margin-bottom: 16px;
  `,
  DescriptionContainer: styled.div`
    margin-bottom: 24px;
  `,

  TitleContainerSenior: styled.header`
    ${titleStyles}
    margin-bottom: 8px;
  `,
  DescriptionContainerSenior: styled.div`
    margin-bottom: 8px;
  `,
}
