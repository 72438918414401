import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CURRENCY_SYMBOLS, MONTHS_IN_A_YEAR } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import { selectUpgradeSubscriptionPrice } from 'modules/purchase/redux/selects/common'

import {
  CENTS_IN_DOLLAR,
  FREE_BONUS_FOR_UPGRADE,
  TimeInterval,
} from 'root-constants'

import { StyledUpgradeSubscriptionItem as S } from './UpgradeSubscriptionItem.styles'

type TUpgradeSubscriptionItemProps = {
  hasBonus?: boolean
}

export const UpgradeSubscriptionItem: React.FC<
  TUpgradeSubscriptionItemProps
> = ({ hasBonus = false }) => {
  const { t } = useTranslation()
  const price = useSelector(selectUpgradeSubscriptionPrice)

  const { currency, periodQuantity, periodName } = usePurchaseStore()

  const getCalculatedPrice = useCallback((fullPrice: number) => {
    return fullPrice.toFixed(2)
  }, [])

  return (
    <>
      <S.UpgradePlanBlock>
        <S.PlanTitle>{t('purchaseUpgrade.planTitle')}</S.PlanTitle>
        <S.PriceTitle>{t('purchaseUpgrade.priceToday')}</S.PriceTitle>
        <S.PricesContainer>
          <S.Plan>
            {t('purchaseIntroOffer.monthlyPeriod', {
              count:
                periodName === TimeInterval.YEAR
                  ? Number(periodQuantity) * MONTHS_IN_A_YEAR
                  : Number(periodQuantity),
            })}
          </S.Plan>
          <S.Plan>
            {CURRENCY_SYMBOLS[currency]}
            {getCalculatedPrice(price / CENTS_IN_DOLLAR)}
          </S.Plan>
        </S.PricesContainer>
        <S.PricesContainer>
          <S.Discount>{t('purchaseUpgrade.discount')}</S.Discount>
          <S.DiscountPrice>
            {CURRENCY_SYMBOLS[currency]}
            {getCalculatedPrice((price * 2) / CENTS_IN_DOLLAR)}
          </S.DiscountPrice>
        </S.PricesContainer>
        {hasBonus ? (
          <S.BonusContainer>
            <S.BonusTitle>+ {t('purchaseUpgrade.bonusTitle')} 🎁</S.BonusTitle>
            <S.BonusDescription>
              {t('purchaseUpgrade.bonusDescription')}
            </S.BonusDescription>
            <S.BonusPrice>
              {CURRENCY_SYMBOLS[currency]}
              {FREE_BONUS_FOR_UPGRADE}
            </S.BonusPrice>
          </S.BonusContainer>
        ) : (
          <S.AdditionalInfo>
            {t('purchaseUpgrade.additionalInfo')}
          </S.AdditionalInfo>
        )}
      </S.UpgradePlanBlock>
      {hasBonus && (
        <S.AdditionalInfo>
          {t('purchaseUpgrade.additionalInfo')}
        </S.AdditionalInfo>
      )}
    </>
  )
}
