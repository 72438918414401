import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Header } from 'components/Header'
import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  resetErrorAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useVatInfo } from 'hooks/useHasVatInfo'

import { createProductId } from 'helpers/createProductId'

import { CancelOfferWhatYouGet } from 'modules/purchase/components/CancelOfferWhatYouGet'
import { HolidaysCancelOfferReviews } from 'modules/purchase/components/HolidaysCancelOfferReviews'
import { HolidaysCancelOfferTestimonials } from 'modules/purchase/components/HolidaysCancelOfferTestimonials'
import { HolidaysLuvlyBenefits } from 'modules/purchase/components/HolidaysLuvlyBenefits'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsBlock } from 'modules/purchase/components/SubscriptionsBlock'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  usePurchaseStore,
} from 'modules/purchase/hooks'
import { Checkout } from 'modules/purchase/pages'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  Locale,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { StyledHolidaysPurchaseCancelOffer as S } from './HolidaysPurchaseCancelOffer.styles'

export const HolidaysPurchaseCancelOffer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const hasVatInfo = useVatInfo()
  const { pathname } = useLocation()
  const { cohort, periodName, periodQuantity, price, language } =
    usePurchaseStore()
  const [isCheckoutShown, setIsCheckoutShown] = useState(false)

  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(
    ScreenName.CANCEL_OFFER_NY,
  )

  const { arePricesReady } = usePricesStatus()

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  const hasPaypalButton = useMemo(() => language === Locale.ENGLISH, [language])

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.CANCEL_OFFER_NY))

    const vatTag = hasVatInfo
      ? SubscriptionTagsType.TAX
      : SubscriptionTagsType.NO_TAX

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        `${SubscriptionTagsType.CANCEL_OFFER},${vatTag}`,
      ),
    )
    window.axon && window.axon('track', 'view_item')
  }, [hasVatInfo, dispatch])

  useDefaultSubscription()

  const handleShowCheckout = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }, [sendGeneralPurchaseEvents, dispatch])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName: ScreenName.CANCEL_OFFER_NY,
    })
    setIsCheckoutShown(false)
  }, [dispatch, pathname, cohort, productId])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isCheckoutShown && (
        <S.Root data-testid="cancel-offer-container">
          <S.Header />
          <S.Hero>
            <Container>
              <S.Title>
                <Trans
                  components={[<strong />]}
                  i18nKey="purchase1.holidayCancelOffer.title"
                />
              </S.Title>
              <S.TitleDescription>
                {t('purchase1.holidayCancelOffer.titleDescription')}
              </S.TitleDescription>
              <S.PromoCode>
                <Trans
                  components={[<strong />]}
                  i18nKey="purchase1.holidayCancelOffer.promoCode"
                />
              </S.PromoCode>
              <SubscriptionsBlock isCancelOffer />
              <Button
                data-testid="start-plan-btn"
                type="button"
                onClick={handleShowCheckout}
              >
                {t('purchase1.holidayCancelOffer.action')}
              </Button>
            </Container>
          </S.Hero>
          <HolidaysLuvlyBenefits />
          <CancelOfferWhatYouGet />
          <Container>
            <S.ButtonContainer>
              <Button type="button" onClick={handleShowCheckout}>
                {t('purchase1.holidayCancelOffer.action')}
              </Button>
            </S.ButtonContainer>
          </Container>
          <HolidaysCancelOfferReviews />
          <S.ButtonContainer>
            <Button type="button" onClick={handleShowCheckout}>
              {t('purchase1.holidayCancelOffer.action')}
            </Button>
          </S.ButtonContainer>

          <HolidaysCancelOfferTestimonials />
          <S.ButtonContainer>
            <Button type="button" onClick={handleShowCheckout}>
              {t('purchase1.holidayCancelOffer.action')}
            </Button>

            <SecurityInfo withPricing />
          </S.ButtonContainer>
        </S.Root>
      )}
      {isCheckoutShown && (
        <>
          <Header />
          <Checkout
            hasTotalAmount={false}
            hasPaypalButton={hasPaypalButton}
            handleCloseCheckout={handleCloseCheckout}
          />
        </>
      )}
    </>
  )
}
