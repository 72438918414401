import styled from 'styled-components'

import { Color } from 'root-constants'

import { Button } from '../Button'

type TStyledModalProps = {
  isShown: boolean
}

export const StyledModal = {
  Wrapper: styled.div<TStyledModalProps>`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isShown }) => (isShown ? 10 : -1)};
    width: max(100%, var(--min-screen-width));
    height: calc(var(--full-height) - calc(var(--full-height) - 100%));
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
  `,
  Content: styled.div`
    width: calc(100% - 40px);
    max-width: 360px;
    margin: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 10px;
    background-color: ${Color.WHITE};
  `,
  Button: styled(Button)`
    margin-top: 30px;
  `,
  Error: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 24px;
  `,
}
