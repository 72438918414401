import { PaymentIntentResult, StripeError } from '@stripe/stripe-js'

import { TProductId } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import {
  CardPaymentFieldName,
  Cohort,
  ScreenName,
  TimeInterval,
  UpsellProduct,
} from 'root-constants'

import {
  EMPTY_FIELD_ERROR,
  INTRO_SALES_COHORTS_DISCOUNT_TEXT_MAP,
  PaymentDeclineReason,
  PaymentMethod,
  PaymentSystem,
  STRIPE_SOFT_DECLINE_REASONS,
  StripeErrorCode,
  StripeSoftDeclineReason,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from '../constants'
import { TPaymentErrorState } from '../types'

export const getPaymentErrorStateBySubmitWithUntouchedFields = (
  errors: TPaymentErrorState,
): TPaymentErrorState =>
  Object.entries(errors).reduce(
    (result, error) => ({
      ...result,
      [error[0]]: {
        ...error[1],
        ...(!error[1].isTouched && {
          isTouched: true,
          error: EMPTY_FIELD_ERROR,
        }),
      },
    }),
    {} as TPaymentErrorState,
  )

export const getDefaultPaymentErrorsState = (): Record<
  CardPaymentFieldName,
  {
    error: string
    isTouched: boolean
    isShown: boolean
    isComplete: boolean
    isFocused: boolean
  }
> => ({
  [CardPaymentFieldName.NAME]: {
    error: '',
    isTouched: true,
    isShown: true,
    isComplete: true,
    isFocused: false,
  },
  [CardPaymentFieldName.NUMBER]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
    isFocused: false,
  },
  [CardPaymentFieldName.EXPIRY]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
    isFocused: false,
  },
  [CardPaymentFieldName.CVC]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
    isFocused: false,
  },
})

export const getRedirectUrl = (paymentPageId: string): string =>
  `${window.location.origin}/${paymentPageId}?${THREEDS_REDIRECT_SEARCH_PARAM}`

export const checkIsRetryAllowed = (
  confirmCardPaymentResponse: PaymentIntentResult,
): boolean => {
  const declineCode =
    confirmCardPaymentResponse?.error?.decline_code ||
    confirmCardPaymentResponse?.error?.code

  return STRIPE_SOFT_DECLINE_REASONS.includes(
    declineCode as StripeSoftDeclineReason,
  )
}

export const logSuccessfulPayment = ({
  productId,
  price,
  trialPrice,
  trialPeriodDays,
  subscriptionId,
  discountApplied,
  uuid,
  periodName,
  periodQuantity,
  paymentMethod,
  currency,
  email,
  screenName,
  stripeAccountName,
  utmSource,
  isUpgraded = false,
  amountToPay,
  stripeAccountId,
  paymentSystem,
  productName,
  predictedLtv,
  isPersonalDataAllowed,
}: {
  productId: TProductId
  price: number
  predictedLtv?: number
  trialPrice: number
  trialPeriodDays: number
  subscriptionId: string
  discountApplied?: string
  uuid: string
  periodName: TimeInterval | null
  periodQuantity: number | null
  paymentMethod?: PaymentMethod
  currency: string
  email: string
  screenName: ScreenName
  stripeAccountName: string
  utmSource?: string
  isUpgraded?: boolean
  amountToPay?: string
  stripeAccountId: string
  paymentSystem?: PaymentSystem
  productName?: UpsellProduct
  isPersonalDataAllowed?: boolean
}): void => {
  if (trialPeriodDays) {
    window.fbq(
      'track',
      'StartTrial',
      {
        value: trialPrice,
        currency,
        subscription_id: subscriptionId,
        // This field and similar ones below have been added to avoid FB pixel issue. https://github.com/facebook/facebook-nodejs-business-sdk/issues/164
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
  }
  window.fbq(
    'track',
    'Purchase',
    {
      value: predictedLtv || price,
      currency,
      subscription_id: subscriptionId,
      subscription_sid: subscriptionId,
    },
    { eventID: uuid },
  )

  window.fbq(
    'track',
    'Subscribe',
    {
      value: trialPrice || price,
      currency,
      subscription_id: subscriptionId,
      subscription_sid: subscriptionId,
    },
    { eventID: uuid },
  )

  window.ttq.identify({ email })
  window.ttq.track('CompletePayment', {
    value: trialPrice || price,
    currency,
    content_id: subscriptionId,
    event_id: uuid,
  })

  window.snaptr &&
    window.snaptr('track', 'PURCHASE', {
      price: trialPrice || price,
      currency,
      client_dedup_id: subscriptionId,
      transaction_id: subscriptionId,
      user_email: email,
    })

  window.obApi &&
    window.obApi('track', 'Purchase', {
      orderValue: trialPrice || price,
      currency: currency.toUpperCase(),
      orderId: subscriptionId,
    })

  window.axon &&
    window.axon('track', 'purchase', {
      value: trialPrice || price,
      currency,
      user_id: isPersonalDataAllowed ? uuid : '',
    })

  window._tfa &&
    window._tfa.push({
      notify: 'event',
      name: 'make_purchase',
      revenue: trialPrice || price,
      orderId: subscriptionId,
      currency: currency.toUpperCase(),
    })

  window.rdt &&
    window.rdt('track', 'Purchase', {
      value: trialPrice || price,
      currency,
      transactionId: subscriptionId,
      email,
      externalId: uuid,
    })

  eventLogger.logPurchaseCompleted({
    productId,
    priceDetails: {
      price,
      trial: !!trialPeriodDays,
      currency,
    },
    paymentMethod,
    screenName,
    discountApplied,
    stripeAccountName,
    utmSource,
    isUpgraded,
    amountToPay,
    paymentSystem,
    stripeAccountId,
    productName,
  })

  googleAnalyticsLogger.logPurchaseCompleted({
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency,
    screenName,
  })
}

export const logFailedPayment = ({
  productId,
  priceDetails,
  paymentResponse: { type, code, message, decline_code: declineCode },
  paymentMethod,
  screenName,
  stripeAccountId,
  stripeAccountName,
}: {
  productId: TProductId
  priceDetails: { price: number; trial: boolean; currency: string }
  paymentResponse: StripeError
  paymentMethod: PaymentMethod
  screenName: ScreenName
  stripeAccountId: string
  stripeAccountName: string
}): void => {
  const errorCode =
    code === StripeErrorCode.CARD_DECLINED ? `${code}:${declineCode}` : code

  eventLogger.logPurchaseFailed({
    productId,
    priceDetails,
    error: { type, code: errorCode, description: message },
    paymentMethod,
    screenName,
    stripeAccountId,
    stripeAccountName,
    paymentSystem: PaymentSystem.STRIPE,
  })
}

export const getDecoratedTimerValue = (valueInSeconds: number): string => {
  const minutes = Math.trunc(valueInSeconds / 60)
  const seconds = valueInSeconds - minutes * 60
  const get2DigitValue = (value: number): string =>
    value.toString().padStart(2, '0')

  return `${get2DigitValue(minutes)}:${get2DigitValue(seconds)}`
}

export const handleGoaffproRefPurchase = ({
  subscriptionId,
  purchaseAmount,
  currency,
  discount,
}: {
  subscriptionId: string
  purchaseAmount: number
  currency: string
  discount?: string
}) => {
  const appliedDiscount: string = discount ? discount.split('_')[0] : ''
  const total = appliedDiscount
    ? purchaseAmount - purchaseAmount * (Number(appliedDiscount) / 100)
    : purchaseAmount

  window.goaffpro_order = {
    number: subscriptionId,
    total,
    currency,
  }

  if (typeof window.goaffproTrackConversion !== 'undefined') {
    window.goaffproTrackConversion(window.goaffpro_order)
  }
}

export const getPaymentFailedError = (
  error?: StripeSoftDeclineReason | PaymentDeclineReason | string,
) => {
  switch (error) {
    case StripeSoftDeclineReason.INSUFFICIENT_FUNDS:
      return 'purchase1.insufficientFunds'
    case StripeSoftDeclineReason.CARD_VELOCITY_EXCEEDED:
      return 'purchase1.velocityExceeded'
    case PaymentDeclineReason.STOLEN_CARD:
    case PaymentDeclineReason.LOST_CARD:
    case PaymentDeclineReason.GENERIC_DECLINE:
      return 'purchase1.genericDecline'
    default:
      return 'login.somethingWentWrong'
  }
}

export const getDynamicDiscountText = ({
  discountAmount,
  isCancelOffer,
  discountText,
  cohort,
}: {
  discountAmount: number
  isCancelOffer: boolean
  cohort: Cohort
  discountText?: string
}) => {
  const initialDiscountText = discountText
    ? discountText.replace('٪', '%')
    : INTRO_SALES_COHORTS_DISCOUNT_TEXT_MAP[cohort] ||
      INTRO_SALES_COHORTS_DISCOUNT_TEXT_MAP[Cohort.LUVLY_INTRO_SALE]
  if (!isCancelOffer) return initialDiscountText

  return initialDiscountText.replace(/(\d{2})%/, `${discountAmount}%`)
}
