import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'

import { ISubscription } from 'models/subscriptions.model'

import { CENTS_IN_DOLLAR, FREE_BONUS_FOR_UPGRADE } from 'root-constants'

import { StyledSelectUpgradeItem as S } from './SelectUpgradeItem.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
  hasBonus?: boolean
}

export const SelectUpgradeItem: React.FC<TProps> = ({
  subscription: { id, currency, mainPrices, upgradeDiff },
  isSelected,
  hasBonus,
}) => {
  const { t } = useTranslation()

  const getCalculatedPrice = useCallback((fullPrice: number) => {
    return fullPrice.toFixed(2)
  }, [])

  return (
    <S.Wrapper isSelected={isSelected} key={id}>
      <S.Content isSelected={isSelected}>
        <S.PriceRow>
          <S.PlanPeriod isSelected={isSelected}>
            {t('purchaseUpgrade.yearly', {
              planPeriod: mainPrices.periodQuantity,
            })}
          </S.PlanPeriod>
          <S.CurrentPrice isSelected={isSelected}>
            <S.PriceValue>
              {CURRENCY_SYMBOLS[currency]}
              {getCalculatedPrice(
                Number(upgradeDiff?.amountToPay) / CENTS_IN_DOLLAR,
              )}
            </S.PriceValue>
          </S.CurrentPrice>
        </S.PriceRow>
        <S.PriceRow>
          <S.Discount>{t('purchaseUpgrade.discount')}</S.Discount>
          <S.PreviousPrice isSelected={isSelected}>
            {CURRENCY_SYMBOLS[currency]}
            {getCalculatedPrice(
              (Number(upgradeDiff?.amountToPay) * 2) / CENTS_IN_DOLLAR,
            )}
          </S.PreviousPrice>
        </S.PriceRow>
      </S.Content>
      {hasBonus && (
        <S.BonusContainer>
          <S.BonusTitle>+ {t('purchaseUpgrade.bonusTitle')} 🎁</S.BonusTitle>
          <S.BonusDescription>
            {t('purchaseUpgrade.bonusDescription')}
          </S.BonusDescription>
          <S.BonusPrice>
            {CURRENCY_SYMBOLS[currency]}
            {FREE_BONUS_FOR_UPGRADE}
          </S.BonusPrice>
        </S.BonusContainer>
      )}
    </S.Wrapper>
  )
}
