import styled from 'styled-components'

import { Color } from 'root-constants'

type TButtonsProps = {
  disabled?: boolean
  buttonHeight?: number
}

export const StyledNavigationButtons = {
  Root: styled.div`
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 2;
    padding: 40px 20px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    );
  `,
  ButtonContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 154px));
    justify-content: center;
    gap: 12px;
  `,
  BackButton: styled.button<TButtonsProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ buttonHeight }) => `${buttonHeight}px`};
    background: ${(props) =>
      props.disabled ? '#c4c4c4' : `${Color.CORAL_GRAY}`};
    border-radius: 12px;
    text-decoration: none;
    color: ${Color.DARK};
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    border: 0;
    outline: none;
    cursor: pointer;
  `,
  NextButton: styled.button<TButtonsProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ buttonHeight }) => `${buttonHeight}px`};
    background: ${(props) => (props.disabled ? '#c4c4c4' : '#ff8276')};
    border-radius: 12px;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    border: 0;
    outline: none;
    cursor: pointer;
  `,
}
