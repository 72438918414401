import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledExpertLoader = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 65px);
  `,
  ProgressContainer: styled.div`
    margin: 58px auto 16px;
  `,
  Title: styled.h2`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    font-family: Rubik;
    color: ${Color.GRAY};
    margin-bottom: 32px;
  `,
  Experts: styled.div`
    max-width: 360px;
    margin: 0 auto;
    min-height: 320px;
  `,
}
