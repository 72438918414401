import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants'

type TPropsGraphContainer = {
  userGoal: string
  isWrinkleFreeSkinSelected: boolean
}

export const StyledPreliminaryProgress = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: calc(var(--full-height) - 65px);
  `,
  ProgressbarContainer: styled.div`
    min-width: 314px;
    max-width: 360px;
    margin: 100px auto 0;

    & p {
      max-width: 210px;
      margin: 0 auto;
    }
  `,
  GraphContainer: styled.div<TPropsGraphContainer>`
    position: relative;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;

    &:before {
      content: '${({ userGoal }) => userGoal}';
      position: absolute;
      top: 22px;
      left: ${({ isWrinkleFreeSkinSelected }) =>
        isWrinkleFreeSkinSelected ? '78px' : '109px'};
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: #c893a1;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
}
