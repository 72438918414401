import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { OPTION_VALUES, QUESTION } from '../constants'
import { StyledIntroVariantRefund as S } from './IntroVariantRefund.styles'

export const IntroVariantRefund: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  useStartSession()
  const { headline } = useTitleFromUrl()

  const handleContinue = useNextStep({
    pageId: PageId.INTRO,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = useCallback(
    (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, setIsAnswersDisabled],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())

        window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        handleAnswerChange(value)
      },
    }),
    [pageId, isAnswersDisabled, dispatch, uuid, handleAnswerChange],
  )

  return (
    <S.Root>
      <IntroHeader colorTheme={IntroHeaderColor.TRANSPARENT} />
      <S.Container>
        <div>
          <S.Title>
            {headline || (
              <Trans
                i18nKey="onboarding.introRefund.title"
                components={[<strong />]}
              />
            )}
          </S.Title>
          <S.Subtitle>{t`onboarding.introRefund.subtitle`}</S.Subtitle>
          <S.Actions>
            <S.StyledOption
              {...optionProps}
              withoutMargin
              value={OPTION_VALUES.GET_RID}
            >
              <S.Action>{t('onboarding.goal.getRid2')}</S.Action>
            </S.StyledOption>
            <S.StyledOption
              {...optionProps}
              withoutMargin
              value={OPTION_VALUES.REDUCE_FAT}
            >
              <S.Action>{t('onboarding.goal.reduceFat')}</S.Action>
            </S.StyledOption>
            <S.StyledOption
              {...optionProps}
              withoutMargin
              value={OPTION_VALUES.BOTH_OPTIONS}
            >
              <S.Action>{t('onboarding.goal.bothOptions')}</S.Action>
            </S.StyledOption>
          </S.Actions>
          <S.Terms>
            <p>{t`onboarding.intro.termsTitle`}</p>
            <TermsOfUseLink />
            <S.Separator />
            <PrivacyPolicyLink />
          </S.Terms>
        </div>
      </S.Container>
    </S.Root>
  )
}
