import React from 'react'
import { Trans } from 'react-i18next'

import { Container } from 'components/Container'

import gift from 'assets/images/gift-red.png'

import { StyledUpsellGuashaCancelOffer as S } from './UpsellGuashaCancelOffer.styles'

export const UpsellGuashaCancelOffer: React.FC = () => {
  return (
    <Container>
      <S.Content>
        <S.GiftImage src={gift} alt="gift " />
        <S.Discount>
          <Trans
            i18nKey="guashaUpsell.discount"
            components={{
              br: <br />,
              strong: <strong />,
              strikethrough: <span />,
            }}
          />
        </S.Discount>
      </S.Content>
    </Container>
  )
}
