import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'

import { getSupportFormLink } from 'helpers/getSupportFormLink'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenName } from 'root-constants'

import { StyledWellnessBundlePurchaseBlock as S } from './WellnessBundlePurchaseBlock.styles'
import { SKIP_BUTTONS_MAP } from './constants'

type TWellnessBundlePurchaseBlockProps = {
  openSkipModal: () => void
  purchaseBundle: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
  buttonOrder: number
  position: 'top' | 'bottom'
}

export const WellnessBundlePurchaseBlock: React.FC<
  TWellnessBundlePurchaseBlockProps
> = ({
  openSkipModal,
  purchaseBundle,
  buttonOrder,
  position,
}: TWellnessBundlePurchaseBlockProps) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const subscription = useSelector(selectSubscription)
  const currency = useSelector(selectCurrency)
  const contactFormLink = getSupportFormLink()

  const isCancelOfferRoute = useIsCurrentRoute(
    PageId.WELLNESS_BUNDLE_CANCEL_PAYWALL,
  )

  const skipWellnessBundle = useCallback(() => {
    eventLogger.logPlansPageClose(
      ScreenName.LIVECHAT_WELLNESS_BUNDLE_CANCEL_OFFER,
    )
    goTo({ pathname: PageId.DOWNLOAD, search })
  }, [search])

  const skipWellnessBundleButton = useMemo(() => {
    if (isCancelOfferRoute) {
      return {
        buttonText: t`wellnessBundleUpsell.purchase.skipFinally`,
        handler: skipWellnessBundle,
      }
    }

    return {
      buttonText: t(SKIP_BUTTONS_MAP[position]),
      handler: openSkipModal,
    }
  }, [isCancelOfferRoute, openSkipModal, position, skipWellnessBundle, t])

  return (
    <S.Root>
      <Container fields={16}>
        <S.Title>
          <Trans
            i18nKey={
              isCancelOfferRoute || position === 'bottom'
                ? 'wellnessBundleUpsell.purchase.title2'
                : 'wellnessBundleUpsell.purchase.title1'
            }
            values={{
              discount: subscription?.introDiff?.discountPercentage || 0,
            }}
          />
        </S.Title>
        <S.Offer>
          <S.OfferText>
            {t('wellnessBundleUpsell.purchase.welcomeOffer')}
          </S.OfferText>
          <S.Prices>
            <S.DiscountPrice>
              {CURRENCY_SYMBOLS[currency]}
              {subscription?.trialPrices.fullPrice}
            </S.DiscountPrice>
            <S.OldPrice>
              {CURRENCY_SYMBOLS[currency]}
              {subscription?.mainPrices.fullPrice}
            </S.OldPrice>
          </S.Prices>
        </S.Offer>
        <S.Offer>
          <S.OfferText>
            {t('wellnessBundleUpsell.purchase.weightLossGuide')}
          </S.OfferText>
          <S.Prices>
            <S.DiscountPrice>
              <S.FreeLabel>
                {t('wellnessBundleUpsell.purchase.freeLabel')}
              </S.FreeLabel>
            </S.DiscountPrice>
            <S.OldPrice>$69.99</S.OldPrice>
          </S.Prices>
        </S.Offer>
        <Button type="button" data-order={buttonOrder} onClick={purchaseBundle}>
          {t('wellnessBundleUpsell.purchase.CTA')}
        </Button>
        <S.LinkContainer>
          <S.Link onClick={skipWellnessBundleButton.handler}>
            {skipWellnessBundleButton.buttonText}
          </S.Link>
        </S.LinkContainer>
        <S.Disclaimer>
          <Trans
            i18nKey="wellnessBundleUpsell.purchase.disclaimer"
            values={{
              price: subscription?.trialPrices.fullPrice,
              oldPrice: subscription?.mainPrices.fullPrice,
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
          <TermsOfUseLink /> {t`commonComponents.or`}{' '}
          <a target="_blank" rel="noopener noreferrer" href={contactFormLink}>
            {t`commonComponents.contactSupportDisclaimer`}
          </a>
        </S.Disclaimer>
      </Container>
    </S.Root>
  )
}
