import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Chat, DelayedBottomContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { Cohort, I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledLikeChatProblematicAreas as S } from './LikeChatProblematicAreas.styles'
import {
  LIKE_CHAT_PROBLEMATIC_AREAS_OPTIONS_MAP,
  PROBLEMATIC_AREAS_ANSWERS_MAP,
  PROBLEMATIC_AREAS_COSMETIC_SCANNER_OPTIONS,
  QUESTION,
} from './constants'

export const LikeChatProblematicAreas: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)

  const handleContinue = useNextStep({
    pageId: PageId.PROBLEMATIC_AREA,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    const uniqueAnswers = answers.map(
      (answer) => LIKE_CHAT_PROBLEMATIC_AREAS_OPTIONS_MAP[answer],
    )

    const analyticsAnswers = answers.map((answer) =>
      t(PROBLEMATIC_AREAS_ANSWERS_MAP[answer]),
    )

    handleContinue(uniqueAnswers, analyticsAnswers)
  }, [answers, handleContinue, t])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [answers, pageId],
  )

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.SHOW_OPTIONS)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_QUESTION ||
            chatStep === ChatStep.SHOW_OPTIONS
          }
        >
          {t('onboarding.likeChatProblematicAreas.title')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.USER}
          {...(chatStep === ChatStep.USER_ANSWER && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.EXPERT_EXPRESSION)}
        >
          {answers.length &&
            answers.map((area, i) => (
              <span key={area}>
                {i ? ', ' : ''}
                <Trans
                  i18nKey={PROBLEMATIC_AREAS_ANSWERS_MAP[area]}
                  context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
                />
              </span>
            ))}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          {...(chatStep === ChatStep.EXPERT_EXPRESSION && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_EXPRESSION ||
            chatStep === ChatStep.FINISH
          }
        >
          {t('onboarding.likeChatProblematicAreas.expression')}
        </ChatMessage>
      </Chat>

      <DelayedBottomContainer isShown={chatStep === ChatStep.FINISH}>
        <Button onClick={handleNextClick}>{t('actions.nice')}</Button>
      </DelayedBottomContainer>

      <DelayedBottomContainer isShown={chatStep === ChatStep.SHOW_OPTIONS}>
        <S.OptionsWrapper>
          {PROBLEMATIC_AREAS_COSMETIC_SCANNER_OPTIONS.map(
            ({ value, text, image }) => (
              <Option {...optionProps} value={value} key={value} withoutMargin>
                <S.ShortenedChatAnswerButton withCheckbox>
                  <S.OptionContentWrapper>
                    <S.OptionImage src={image} />
                    <Trans
                      i18nKey={text}
                      context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
                    />
                  </S.OptionContentWrapper>
                </S.ShortenedChatAnswerButton>
              </Option>
            ),
          )}
        </S.OptionsWrapper>
        <Button
          onClick={() => setChatStep(ChatStep.USER_ANSWER)}
          disabled={!answers.length}
        >
          {t('actions.send')}
        </Button>
      </DelayedBottomContainer>
    </Container>
  )
}
