import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledCancelOfferQuestion = {
  Wrapper: styled.div`
    margin: 0 auto;
    padding-bottom: 30px;
  `,
  Container: styled.div`
    max-width: 360px;
    margin: 0 auto;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.DARK};
    text-align: center;
    margin: 0 0 16px 0;
  `,
  TitleDescription: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 16px;
    padding: 0 9px;

    & i {
      font-weight: 700;
      font-size: 24px;
      color: ${Color.PRIMARY};
      font-style: normal;
    }

    & strong {
      font-weight: 500;
      color: ${Color.DARK};
    }
  `,
  PictureContainer: styled.div`
    max-width: 428px;
    margin: 0 auto 16px;

    @media screen and (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 360px;
    }
  `,
  Picture: styled.img`
    margin-bottom: 8px;
  `,
  Footnote: styled.figcaption`
    max-width: 280px;
    margin: 0 auto;
    color: ${Color.LIGHT};
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.DARK};
    margin-bottom: 24px;
    text-align: center;
    padding: 0 20px;

    & strong {
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
    }
  `,
  Action: styled.div`
    padding: 0 20px;
  `,
}
