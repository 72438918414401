export const MAIN_GOAL = {
  GET_RID: 'get_rid_of_wrinkles',
  REDUCE_FAT: 'reduce_face_fat',
  BOTH_OPTIONS: 'both_options',
}

export const TITLE = {
  [MAIN_GOAL.GET_RID]: 'purchase4.title',
  [MAIN_GOAL.REDUCE_FAT]: 'purchase4.title',
  [MAIN_GOAL.BOTH_OPTIONS]: 'purchase4.titleForBoth',
}

export const SUBTITLE = {
  [MAIN_GOAL.GET_RID]: 'purchase4.subtitle.wrinkles',
  [MAIN_GOAL.REDUCE_FAT]: 'purchase4.subtitle.faceFat',
  [MAIN_GOAL.BOTH_OPTIONS]: 'purchase4.subtitle.both',
}
