import styled from 'styled-components'

import selectedIcon from 'assets/images/selected-option-icon.svg'

import { Color } from 'root-constants'

const getBorderStyles = (isValid: boolean, isOpen: boolean) => {
  if (!isValid) return `1px solid ${Color.ERROR_SECONDARY}`
  if (isOpen) return '1px solid #000000'
  return '1px solid transparent'
}

export const customSelectStyles = (isValid: boolean) => ({
  menu: () => ({
    border: ' 1px solid #000000',
    borderRadius: '10px',
    padding: '7px 0',
    marginTop: '8px',
  }),
  control: (_, state) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: getBorderStyles(isValid, state.selectProps.menuIsOpen),
    borderRadius: '10px',
    padding: '6px 16px 6px 20px',
    minHeight: '54px',
    boxShadow:
      !state.selectProps.menuIsOpen && isValid
        ? '0px 8px 20px #DFE3F3'
        : 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    overflow: 'visible',
    position: 'relative',
    alignSelf: 'flex-end',
    fontWeight: 500,
    fontSize: 15,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: Color.BLUE_DARK,
    padding: 0,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
})

export const StyledSelectWithFloatPlaceholder = {
  Root: styled.div`
    position: relative;
  `,
  Option: styled.div`
    display: flex;
    align-items: center;
    padding: 0 51px 0 21px;
    min-height: 40px;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: ${Color.DARK};
    position: relative;
    border-bottom: 1px solid ${Color.SILVER_GRAY};

    &:last-child {
      border-bottom: 0;
    }
  `,
  SelectedIcon: styled.span`
    width: 20px;
    height: 20px;
    background: url(${selectedIcon}) no-repeat center;
    background-size: contain;
    position: absolute;
    right: 21px;
    top: calc(50% - 10px);
  `,
  CircleMarkIcon: styled.span`
    width: 20px;
    height: 20px;
    opacity: 0.5;
    border: 1px solid #555965;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
  `,
  FloatingLabel: styled.label<{ isFloating?: boolean }>`
    pointer-events: none;
    font-size: 13px;
    position: absolute;
    font-weight: 400;
    transition: 0.2s ease all;
    color: ${Color.DARK};
    left: 20px;
    top: ${({ isFloating }) => (isFloating ? '6px' : '17px')};
  `,
  ValidationText: styled.p`
    margin-top: 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    color: ${Color.ERROR_SECONDARY};
  `,
}
