import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledAnalyzingAnswers = {
  ProgressContainer: styled.div`
    margin-bottom: 40px;
  `,
  CarouselTitle: styled.h2`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${Color.DARK};
    margin-bottom: 24px;
    text-align: center;
  `,
  Card: styled.div`
    background: #ffffff;
    box-shadow: 0 8px 20px #dfe3f3;
    border-radius: 20px;
    padding: 15px 14px 12px;
  `,
  CardRating: styled.img`
    display: block;
    max-width: 90px;
    min-height: 15px;
    margin: 0 auto 6px;
  `,
  CardReview: styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT};
    margin-bottom: 6px;
  `,
  CardReviewer: styled.h3`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${Color.DARK};
  `,
}
