import dancebitBeforeAfter from 'assets/images/dancebit-before-after.png'
import dancebitLabel from 'assets/images/dancebit-label.png'
import fitmeBeforeAfter from 'assets/images/fitme-before-after.png'
import fitmeLabel from 'assets/images/fitme-label.png'
import nutrimateBeforeAfter from 'assets/images/nutrimate-before-after.png'
import nutrimateLabel from 'assets/images/nutrimate-label.png'

import { WellnessBundleApp } from 'root-constants'

export const WELLNESS_BUNDLE_BEFORE_AFTER = [
  {
    app: WellnessBundleApp.FITME,
    appLabel: fitmeLabel,
    author: 'wellnessBundleUpsell.beforeAfterGallery.fitMe.author',
    review: 'wellnessBundleUpsell.beforeAfterGallery.fitMe.review',
    lostWeight: 'wellnessBundleUpsell.beforeAfterGallery.fitMe.lostWeight',
    image: fitmeBeforeAfter,
  },
  {
    app: WellnessBundleApp.NUTRIMATE,
    appLabel: nutrimateLabel,
    author: 'wellnessBundleUpsell.beforeAfterGallery.nutriMate.author',
    review: 'wellnessBundleUpsell.beforeAfterGallery.nutriMate.review',
    lostWeight: 'wellnessBundleUpsell.beforeAfterGallery.nutriMate.lostWeight',
    image: nutrimateBeforeAfter,
  },
  {
    app: WellnessBundleApp.DANCEBIT,
    appLabel: dancebitLabel,
    author: 'wellnessBundleUpsell.beforeAfterGallery.dancebit.author',
    review: 'wellnessBundleUpsell.beforeAfterGallery.dancebit.review',
    lostWeight: 'wellnessBundleUpsell.beforeAfterGallery.dancebit.lostWeight',
    image: dancebitBeforeAfter,
  },
]
