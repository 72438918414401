import styled from 'styled-components'

import earthIcon from 'assets/images/customer-review-earth-icon.png'
import messageIcon from 'assets/images/customer-review-icon.png'

import { Color } from 'root-constants'

export const StyledCustomerReviews = {
  ReviewContainer: styled.div`
    margin-bottom: 8px;
    padding: 10px 5px 7px 11px;
    background: ${Color.WHITE};
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  CustomerInfoContainer: styled.div`
    position: relative;
    padding-left: 55px;
  `,
  CustomerImg: styled.img`
    position: absolute;
    top: 0;
    left: 0;
    max-width: 42px;
  `,
  CustomerName: styled.p`
    position: relative;
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #43598f;

    &:before {
      content: '';
      position: absolute;
      top: 3px;
      right: -20px;
      width: 13px;
      height: 13px;
      background-image: url(${messageIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }
  `,
  CustomerRecommendation: styled.p`
    position: relative;
    padding: 0 0 7px 16px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #8e8e93;

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 12px;
      height: 12px;
      background-image: url(${earthIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }
  `,
  CustomerReview: styled.p`
    font-size: 13px;
    line-height: 17px;
  `,
  EmojiContainer: styled.span`
    margin-left: 2px;
    vertical-align: middle;
  `,
  Emoji: styled.img`
    display: inline-block;
    width: 16px;
    height: 16px;
  `,
}
