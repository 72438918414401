import styled from 'styled-components'

export const StyledSugar = {
  TitleContainer: styled.header`
    margin: 0 auto 16px;
  `,
  DescriptionContainer: styled.div`
    max-width: 360px;
    padding: 0 10px;
    margin: 0 auto 16px;
  `,
  OptionsContainer: styled.div``,
}
