import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Navigation } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Container } from 'components/Container'

import { StyledWellnessBundleBeforeAfterGallery as S } from './WellnessBundleBeforeAfterGallery.styles'
import { WELLNESS_BUNDLE_BEFORE_AFTER } from './constants'

export const WellnessBundleBeforeAfterGallery: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Container fields={16}>
      <S.Wrapper>
        <S.Title>
          <Trans
            i18nKey="wellnessBundleUpsell.beforeAfterGallery.title"
            components={[<strong />]}
          />
        </S.Title>
        <Carousel
          slidesPerView={1}
          modules={[Navigation]}
          pagination
          navigation
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          spaceBetween={12}
          loop
        >
          {WELLNESS_BUNDLE_BEFORE_AFTER.map(
            ({ app, author, review, lostWeight, image, appLabel }) => (
              <SwiperSlide key={app}>
                <S.AppName>{app}</S.AppName>
                <S.LabelImage src={appLabel} alt="app-label" />
                <S.Image src={image} alt="before-after-image" />
                <S.Author>
                  <span>{t(author)}</span>
                  <span>{t(lostWeight)}</span>
                </S.Author>
                <S.Review>{t(review)}</S.Review>
              </SwiperSlide>
            ),
          )}
        </Carousel>
      </S.Wrapper>
    </Container>
  )
}
