import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/check.svg'
import whitePlaneIcon from 'assets/images/paper-plane-white.svg'
import planeIcon from 'assets/images/paper-plane.svg'

import { Color } from 'root-constants'

type TWrapperProps = {
  withCheckbox?: boolean
}

type TIconSize = {
  iconWidth?: number
  iconHeight?: number
}

export const StyledChatAnswerButton = {
  Wrapper: styled.div<TWrapperProps>`
    width: 100%;
    max-width: 328px;
    min-height: 56px;
    height: fit-content;
    border-radius: 12px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(255, 130, 118, 0.5);
    cursor: pointer;
    transition: background-color 0.2s ease-out;
    background-color: ${Color.WHITE};
    color: ${Color.DARK};
    padding: ${({ withCheckbox }) =>
      withCheckbox ? '8px 45px 8px 16px' : '8px 60px 8px 16px'};
    position: relative;

    ${({ withCheckbox }) =>
      withCheckbox
        ? css`
            &::after {
              display: block;
              content: '';
              width: 20px;
              height: 20px;
              mix-blend-mode: normal;
              border: 1px solid #aaacb2;
              border-radius: 50%;
              opacity: 0.5;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 20px;
            }

            input[type='checkbox']:checked + & {
              background: ${Color.PRIMARY};
              color: ${Color.WHITE};
            }

            input[type='checkbox']:checked + &::after {
              opacity: 1;
              border-color: ${Color.WHITE};
              background: #fff url(${checkIcon}) no-repeat center;
              background-size: 10px 8.5px;
            }
          `
        : css`
            &::after {
              content: '';
              display: flex;
              width: 40px;
              height: 40px;
              padding: 8px;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              background-color: rgba(255, 130, 118, 0.16);
              background: rgba(255, 130, 118, 0.16) url(${planeIcon}) no-repeat
                center;
              mix-blend-mode: normal;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 8px;
            }

            input[type='radio']:checked + & {
              background-color: ${Color.PRIMARY};
              color: ${Color.WHITE};
            }

            input[type='radio']:checked + & p {
              color: ${Color.WHITE};
            }

            input[type='radio']:checked + &::after {
              background: ${Color.PRIMARY} url(${whitePlaneIcon}) no-repeat
                center;
            }
          `}
  `,

  Content: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 20px;
    overflow-wrap: break-word;
  `,
  Text: styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    flex-grow: 1;
  `,
  Icon: styled.img<TIconSize>`
    width: ${({ iconWidth }) => (iconWidth ? `${iconWidth}px` : '24px')};
    height: ${({ iconHeight }) => (iconHeight ? `${iconHeight}px` : '24px')};
    margin-right: 10px;
  `,
}
