import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledCareCosmetics as S } from './CareCosmetics.styles'
import {
  CARE_COSMETICS_OPTIONS_MAP,
  DEFAULT_CARE_COSMETICS_OPTIONS,
  OPTION_VALUES,
  QUESTION,
} from './constants'

export const CareCosmetics: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const includeNavigation = useIsNavigationOn()
  const cohortToUse = useCohortToUse()
  const { isCosmeticCohort, isSeniorCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(answers),
    [answers, handleContinue],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        const isNoneOptionTouched = value === OPTION_VALUES.NONE
        if (isChecked && isNoneOptionTouched) {
          setAnswers([value])

          return
        }

        if (isChecked) {
          setAnswers(
            [...answers, value].filter((item) => item !== OPTION_VALUES.NONE),
          )

          return
        }

        setAnswers(answers.filter((item) => item !== value))
      },
    }),
    [pageId, answers],
  )

  const hasDescription = useMemo(
    () => !isCosmeticCohort && !isSeniorCohort,
    [isCosmeticCohort, isSeniorCohort],
  )

  const careCosmeticsOptions: {
    value: OPTION_VALUES
    text: string
  }[] = useMemo(() => {
    return (
      CARE_COSMETICS_OPTIONS_MAP[cohortToUse] || DEFAULT_CARE_COSMETICS_OPTIONS
    )
  }, [cohortToUse])

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <PageTitle>
            <Trans
              i18nKey="onboarding.careCosmetics.question"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </PageTitle>
        </S.TitleContainer>
        {hasDescription && (
          <S.DescriptionContainer>
            <PageTitleDescription>
              <Trans
                i18nKey="onboarding.careCosmetics.description"
                components={[<br />]}
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
              />
            </PageTitleDescription>
          </S.DescriptionContainer>
        )}
        <S.OptionsContainer>
          {careCosmeticsOptions.map(({ value, text }) => (
            <Option
              key={value}
              {...optionProps}
              checked={answers.includes(value)}
              value={value}
            >
              <AnswerButton withCheckbox>
                <Trans
                  i18nKey={text}
                  context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
                />
              </AnswerButton>
            </Option>
          ))}
        </S.OptionsContainer>
      </Container>
      {includeNavigation ? (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answers.length}
        />
      ) : (
        <S.BottomButtonContainer>
          <Button onClick={handleNextClick} disabled={!answers.length}>
            {t`actions.continue`}
          </Button>
        </S.BottomButtonContainer>
      )}
    </div>
  )
}
