import styled from 'styled-components'

export const StyledSunSafety = {
  TitleContainer: styled.header`
    margin-bottom: 16px;
  `,
  DescriptionContainer: styled.div`
    margin-bottom: 16px;
  `,
  OptionsContainer: styled.div``,
}
