export const QUESTION = 'You’ve got it made!'

export const GRAPH_PATH = '/animations/ml-graph'

export const DIAGNOSIS_MAP = {
  acne: 'skinDiagnosis.results.acne',
  wrinkles: 'skinDiagnosis.results.wrinkles',
  black_round: 'skinDiagnosis.results.darkCircles',
}

export const PERCENTAGE = {
  1: '56',
  2: '46',
  3: '36',
}
