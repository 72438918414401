import styled from 'styled-components'

import { Option } from 'components/Option'

import introDesktopBg from 'assets/images/intro-ref-desktop-bg.jpg'
import introBg from 'assets/images/intro-ref-mobile-bg.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledIntroVariantRefund = {
  Root: styled.div`
    position: relative;
    background: url(${introBg}) lightgray 50% no-repeat;
    background-position: top center;
    background-size: cover;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background: url(${introDesktopBg}) lightgray 50% no-repeat;
      background-position: top center;
      background-size: cover;
    }
  `,
  Container: styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    min-height: var(--full-height);
    height: 100%;
    padding: 0 16px;
  `,
  Title: styled.h1`
    color: ${Color.WHITE};
    text-align: center;
    font-family: Rubik;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 28px;

    & > strong {
      color: #e1496e;
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin: 0 auto 16px;
      max-width: 440px;
    }
  `,
  Subtitle: styled.p`
    color: ${Color.WHITE};
    text-align: center;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 14px;
  `,
  Actions: styled.div`
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 440px;
      margin: 0 auto 16px auto;
    }
  `,
  StyledOption: styled(Option)`
    border-radius: 40px;
    color: #e1496e;
    background: rgba(255, 255, 255, 0.75);

    input:checked + div {
      color: ${Color.WHITE};
      background: #e1496e;
    }
  `,
  Action: styled.div`
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.75);
    height: 52px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
  `,
  Terms: styled.div`
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.5);
    margin: 24px auto 16px;
    text-align: center;

    & > span,
    p,
    a {
      font-family: 'Open Sans';
    }

    a {
      color: rgba(255, 255, 255, 0.5);
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 5px;
    vertical-align: middle;
  `,
}
