import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerButton, AnswerButtonHeight } from 'components/AnswerButton'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { BottomButtonContainer } from 'common-styles'
import { OptionType } from 'root-constants'

import { StyledSkincareChallenges as S } from './SkincareChallenges.styles'
import {
  QUESTION,
  SKINCARE_CHALLENGES_OPTIONS,
  SkincareChallenge,
} from './constants'

export const SkincareChallenges: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<{ value: string; text: string }[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    const uniqueAnswers = answers.map((answer) => answer.value)
    handleContinue(answers, uniqueAnswers)
  }, [answers, handleContinue])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked: boolean) => {
        if (isChecked) {
          const userAnswer = SKINCARE_CHALLENGES_OPTIONS.find(
            (item) => item.value === value,
          )
          userAnswer && setAnswers([...answers, userAnswer])
        } else {
          setAnswers(answers.filter((item) => item.value !== value))
        }
      },
    }),
    [answers, pageId],
  )

  return (
    <div>
      <S.PageTitle>{t`onboarding.skincareChallenges.question`}</S.PageTitle>
      <Container>
        {SKINCARE_CHALLENGES_OPTIONS.map(({ value, text }) => (
          <Option {...optionProps} value={value} key={value}>
            <AnswerButton
              withCheckbox
              {...(value !== SkincareChallenge.OTHER && {
                height: AnswerButtonHeight.L,
              })}
            >
              <Trans i18nKey={text} components={[<br />]} />
            </AnswerButton>
          </Option>
        ))}
      </Container>
      <BottomButtonContainer>
        <Button onClick={handleNextClick} disabled={!answers.length}>
          {t`actions.continue`}
        </Button>
      </BottomButtonContainer>
    </div>
  )
}
