import styled from 'styled-components'

import denyIcon from 'assets/images/deny.svg'
import greenCheckbox from 'assets/images/green-checkbox.svg'
import priceBadgeUrl from 'assets/images/price-badge.svg'
import selectedPriceBadgeUrl from 'assets/images/selected-price-badge.svg'

import { Color } from 'root-constants'

type TProps = {
  isSelected: boolean
  isDefault?: boolean
  isOutOfStock?: boolean
}

export const StyledSelectSubscriptionItem = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
    border-radius: 10px;
    background: ${Color.WHITE};
    box-shadow: ${({ isSelected }) =>
      isSelected ? `0 8px 20px #dfe3f3` : `none`};
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
    min-height: 70px;
    border-radius: ${({ isDefault, isOutOfStock }) =>
      isDefault || isOutOfStock ? `0 0 10px 10px` : `10px`};
    border: ${({ isSelected }) =>
      isSelected ? `1px solid #89cc45` : `1px solid #c4c4c4`};
    border-top: ${({ isDefault, isOutOfStock }) =>
      (isDefault || isOutOfStock) && `none`};

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      mix-blend-mode: normal;
      border: 1px solid #555965;
      border-radius: 50%;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      transition: opacity 0.5s ease-out;
      ${({ isSelected }) =>
        isSelected &&
        `
          background-image: url(${greenCheckbox});
          opacity: 1;
          border: none;
        `}
      ${({ isOutOfStock }) =>
        isOutOfStock &&
        ` 
          background-image: url(${denyIcon});
          opacity: 1;
          border: none;
        `}
    }
  `,
  MostPopularBadge: styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(97.03deg, #70cd1c 3.15%, #a2ea35 88.83%);
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
  SoldOutBadge: styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      96.89deg,
      #c4c4c4 3.05%,
      #b6b6b6 50.58%,
      #c4c4c4 94.69%
    );
    height: 24px;
    border-radius: 10px 10px 0 0;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${Color.WHITE};
  `,
  PlanPeriod: styled.span<TProps>`
    padding-left: 56px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${({ isSelected }) => (isSelected ? Color.DARK : Color.LIGHT)};
  `,
  PriceContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  PreviousPrice: styled.span<TProps>`
    margin-top: 18px;
    padding-right: 6px;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    text-decoration: line-through;
    text-decoration-color: #e65c3e;
    color: ${({ isSelected }) => (isSelected ? Color.DARK : Color.LIGHT)};
  `,
  CurrentPrice: styled.p<TProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 98px;
    min-height: 48px;
    padding: 10px 0;
    object-fit: contain;
    color: ${Color.LIGHT};
    transition: background 0.5s ease-out;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${priceBadgeUrl});

    ${({ isSelected }) =>
      isSelected &&
      `
        background-image: url(${selectedPriceBadgeUrl});
        color: #ffffff;
    `};
  `,
  PriceValue: styled.span`
    padding-right: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  `,
  Period: styled.span`
    padding-right: 8px;
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
  `,
}
