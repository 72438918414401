import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Chat, DelayedBottomContainer, DelayedContainer } from 'common-styles'
import { CHAT_PAGE_TRANSITION_DELAY, OptionType } from 'root-constants'

import { StyledLikeChatChallenges as S } from './Challenges.styles'
import {
  CHALLENGES_ANSWERS_MAP,
  CHALLENGES_OPTIONS,
  QUESTION,
} from './constants'

export const LikeChatChallenges1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)
  const { handleChange } = useDelayedContinue(CHAT_PAGE_TRANSITION_DELAY)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useEffect(() => {
    if (chatStep === ChatStep.FINISH) {
      const uniqueAnswers = [...new Set(answers)]
      handleChange(uniqueAnswers, handleContinue)
    }
  }, [answers, chatStep, handleChange, handleContinue])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.SHOW_OPTIONS)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_QUESTION ||
            chatStep === ChatStep.SHOW_OPTIONS
          }
        >
          {t('onboarding.likeChatChallenges1.title')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.USER}
          {...(chatStep === ChatStep.USER_ANSWER && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
        >
          {answers.length &&
            answers.map((item, i) => (
              <S.Answer key={item}>
                <Trans i18nKey={CHALLENGES_ANSWERS_MAP[item]} />
                <span>{i !== answers.length - 1 ? ', ' : ''}</span>
              </S.Answer>
            ))}
        </ChatMessage>
      </Chat>
      <DelayedContainer isShown={chatStep === ChatStep.SHOW_OPTIONS}>
        <S.OptionsContainer>
          {CHALLENGES_OPTIONS.map(({ value, label }) => (
            <Option {...optionProps} key={value} value={value} withoutMargin>
              <ChatAnswerButton withCheckbox>{t(label)}</ChatAnswerButton>
            </Option>
          ))}
        </S.OptionsContainer>
      </DelayedContainer>
      <DelayedBottomContainer isShown={chatStep === ChatStep.SHOW_OPTIONS}>
        <Button
          onClick={() => setChatStep(ChatStep.USER_ANSWER)}
          disabled={!answers.length}
        >
          {t('actions.send')}
        </Button>
      </DelayedBottomContainer>
    </Container>
  )
}
