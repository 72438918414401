import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPurchaseVariant4 = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px 40px;
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: ${Color.DARK};
    margin: 0 -10px 16px;
    padding: 0 20px 0 20px;
  `,
  Subtitle: styled.h2`
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: ${Color.LIGHT};
    margin: 0 0 16px 0;
    padding: 0 20px 0 20px;
  `,
  DiscountBlock: styled.div`
    margin: 0 0 12px 0;
    padding: 16px 18px;
    background: #fef7e8;
    border: 1px solid #f6ca66;
    box-sizing: border-box;
    border-radius: 14px;
  `,
  Discount: styled.p`
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: ${Color.DARK};

    strong {
      color: ${Color.RED};
    }
  `,
  ButtonContainer: styled.div`
    margin: 30px 0;
  `,
  PaymentContainer: styled.div`
    &[data-is-visible='false'] {
      display: none;
    }
  `,
}
