import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'
import { MediaBreakpoint } from 'root-constants'

export const StyledUnsubscribeLogin = {
  Wrapper: styled.div`
    ${basePageStyles}
  `,
  Column: styled.div`
    ${baseColumnStyles};
    width: 100%;
    max-width: 340px;
    padding: 20px 10px 40px;

    @media (min-height: 569px) {
      padding-top: 30px;
    }
  `,
  Title: styled.h1`
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;

    @media (min-height: 569px) {
      margin-bottom: 40px;
    }
  `,
  Text: styled.p`
    margin-bottom: 14px;
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
  `,
  Buttons: styled.div`
    padding-top: 20px;
    margin-top: auto;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-top: 200px;
    }
  `,
}
