import React from 'react'

import { usePaypalSubscriptionAfterRedirect } from 'hooks/usePaypalSubscriptionAfterRedirect'

import { PaymentStatusModal } from 'modules/purchase/components/PaymentStatusModal'

export const PaypalProxy = () => {
  usePaypalSubscriptionAfterRedirect()

  return <PaymentStatusModal />
}
