import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { StyledInputWithHint as S } from './InputWithHint.styles'

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  labelName: string
  isValid?: boolean
  hintText?: string
  validationText?: string
}

export const InputWithHint: React.FC<TProps> = React.forwardRef<
  HTMLDivElement,
  TProps
>(
  (
    { labelName, isValid = true, hintText, validationText = '', ...props },
    ref,
  ) => {
    const [isHintVisible, setIsHintVisible] = useState<boolean>(false)
    const hintRef = useRef<HTMLDivElement>(null)

    const handleClickOutside = useCallback((event: Event) => {
      const path = event.composedPath()

      if (hintRef.current && !path.includes(hintRef.current)) {
        setIsHintVisible(false)
      }
    }, [])

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true)

      return () => {
        document.removeEventListener('click', handleClickOutside, true)
      }
    }, [handleClickOutside])

    return (
      <S.Wrapper ref={ref}>
        <S.Input
          isValid={isValid}
          hasValue={Boolean(props.value)}
          type="text"
          {...props}
        />
        <S.Label>{labelName}</S.Label>
        {hintText && (
          <S.HintButton
            type="button"
            onClick={() => setIsHintVisible((prevValue) => !prevValue)}
          />
        )}
        {isHintVisible && hintText && <S.Hint ref={hintRef}>{hintText}</S.Hint>}
        {!isValid && validationText && (
          <S.ValidationText>{validationText}</S.ValidationText>
        )}
      </S.Wrapper>
    )
  },
)
