import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { selectAnswers } from 'root-redux/selects/common'

import { PageId } from 'page-constants'
import {
  MAX_VISIBLE_PROBLEM_AREAS_NUMBER,
  TARGET_AREA_OPTIONS,
} from 'root-constants'

import { StyledHolidaysLuvlyBenefits as S } from './HolidaysLuvlyBenefits.styles'
import { BENEFITS_LIST } from './constants'

export const HolidaysLuvlyBenefits: React.FC = () => {
  const { t } = useTranslation()
  const userAnswersCollection = useSelector(selectAnswers)

  const problematicAreas = useMemo(
    () => userAnswersCollection?.[PageId.PROBLEMATIC_AREA] || [],
    [userAnswersCollection],
  )

  const [firstProblematicArea, secondProblematicArea] = useMemo(
    () =>
      TARGET_AREA_OPTIONS.filter(
        ({ key }) => !!problematicAreas.find(({ label }) => label === key),
      ).map(({ label }) => t(label)),
    [t, problematicAreas],
  )

  return (
    <S.Root>
      <Carousel
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop
      >
        {BENEFITS_LIST.map(({ title, options }) => (
          <SwiperSlide key={title}>
            <S.Container>
              <S.Benefits>
                <S.Title>{t(title)}</S.Title>
                <S.List>
                  {options.map(({ dictionaryPath, hasAdditionalText }) => (
                    <S.Item key={dictionaryPath}>
                      {t(dictionaryPath, {
                        count: problematicAreas.length,
                        firstProblematicArea:
                          firstProblematicArea?.toLowerCase(),
                        secondProblematicArea:
                          secondProblematicArea?.toLowerCase(),
                      })}
                      {hasAdditionalText &&
                      problematicAreas.length > MAX_VISIBLE_PROBLEM_AREAS_NUMBER
                        ? t`purchase1.holidayCancelOffer.benefits.other`
                        : ''}
                    </S.Item>
                  ))}
                  <S.WaveShapeItem>
                    <S.WaveShapeSlice />
                    <S.WaveShapeSlice />
                    <S.WaveShapeSlice />
                    <S.WaveShapeSlice />
                    <S.WaveShapeSlice />
                    <S.WaveShapeSlice />
                  </S.WaveShapeItem>
                </S.List>
              </S.Benefits>
            </S.Container>
          </SwiperSlide>
        ))}
      </Carousel>
    </S.Root>
  )
}
