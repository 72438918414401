import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'
import { Typing } from 'components/Typing'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import starSvg from 'assets/images/sprite/star.svg'
import checkIcon from 'assets/images/square-green-check-icon.png'

import { Chat, DelayedContainer } from 'common-styles'

import { StyledLikeChatAnalyzingAnswers as S } from './LikeChatAnalyzingAnswers.styles'
import {
  ANALYZING_MESSAGES,
  MESSAGE_APPEARANCE_DELAY,
  QUESTION,
} from './constants'

export const LikeChatAnalyzingAnswers: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useEffect(() => {
    document.body.scrollTo({
      top: document.body.clientHeight,
      behavior: 'smooth',
    })
  }, [chatStep])

  const handleNext = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  useEffect(() => {
    if (chatStep === ChatStep.FINISH) {
      setTimeout(() => {
        handleNext()
      }, MESSAGE_APPEARANCE_DELAY)
    }
  }, [chatStep, handleContinue, handleNext])

  return (
    <S.Root>
      <Container fullHeight>
        <Chat>
          <S.ResetMargin>
            <ChatMessage
              author={ChatAuthor.EXPERT}
              delayBeforeShow={DELAY_BEFORE_SHOW}
              hasJustNowLabel={chatStep === ChatStep.EXPERT_QUESTION}
            >
              {t('onboarding.likeChatAnalyzingAnswers.title')}
            </ChatMessage>

            {ANALYZING_MESSAGES.map((element: number, index) => {
              const isLastIteration = index === ANALYZING_MESSAGES.length - 1
              return (
                <ChatMessage
                  author={ChatAuthor.EXPERT}
                  delayBeforeShow={MESSAGE_APPEARANCE_DELAY * element}
                  hasHeader={false}
                  key={element}
                  onTransitionEnd={() => {
                    isLastIteration && setChatStep(ChatStep.EXPERT_EXPRESSION)
                  }}
                >
                  <S.Message>
                    <img src={checkIcon} alt="" />
                    {t(
                      `onboarding.likeChatAnalyzingAnswers.program_${element}`,
                    )}
                  </S.Message>
                </ChatMessage>
              )
            })}
            {chatStep === ChatStep.EXPERT_QUESTION && (
              <ChatMessage
                author={ChatAuthor.EXPERT}
                delayBeforeShow={DELAY_BEFORE_SHOW * 2}
                hasHeader={false}
                width="87px"
              >
                <Typing>
                  {t('onboarding.likeChatAnalyzingAnswers.typing')}
                </Typing>
              </ChatMessage>
            )}
            {(chatStep === ChatStep.EXPERT_EXPRESSION ||
              chatStep === ChatStep.FINISH) && (
              <ChatMessage
                author={ChatAuthor.EXPERT}
                delayBeforeShow={DELAY_BEFORE_SHOW}
                hasHeader={false}
                onTransitionEnd={() => {
                  setChatStep(ChatStep.FINISH)
                }}
              >
                {t('onboarding.likeChatAnalyzingAnswers.cta')}
              </ChatMessage>
            )}

            <DelayedContainer isShown={chatStep === ChatStep.FINISH}>
              <S.Review>
                <S.RatingContainer>
                  <S.Rating>
                    <SvgImage svg={starSvg} />
                    <SvgImage svg={starSvg} />
                    <SvgImage svg={starSvg} />
                    <SvgImage svg={starSvg} />
                    <SvgImage svg={starSvg} />
                  </S.Rating>
                </S.RatingContainer>
                <S.ReviewText>
                  {t('onboarding.likeChatAnalyzingAnswers.review.text')}
                </S.ReviewText>
                <S.Reviewer>
                  {t('onboarding.likeChatAnalyzingAnswers.review.name')}
                </S.Reviewer>
              </S.Review>
            </DelayedContainer>
          </S.ResetMargin>
        </Chat>
      </Container>
    </S.Root>
  )
}
