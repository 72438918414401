export function createIntroOfferProductId({
  priceId,
  trialPriceId,
  trialPeriodQuantity,
}: {
  priceId: string
  trialPriceId: string
  trialPeriodQuantity: number
}): string {
  return `${priceId};${trialPriceId};${trialPeriodQuantity}`
}
