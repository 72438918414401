import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Button } from 'components/Button'

import { selectLanguage } from 'root-redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledCancelOfferQuestion as S } from './CancelOfferQuestion.styles'
import { IMAGE_PATH } from './constants'

export const CancelOfferQuestion: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const language = useSelector(selectLanguage)

  const imageLink = useMemo(
    () => `${CDN_FOLDER_LINK}${IMAGE_PATH}_${language}.png`,
    [language],
  )

  useEffect(() => {
    eventLogger.logCancelOfferPageShown()
  }, [])

  const handleClick = useCallback(() => {
    eventLogger.logCancelOfferPageClosed()
    goTo({ pathname: PageId.PURCHASE_CANCEL_OFFER, search })
  }, [search])

  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>{t`purchase1.cancelOffer.modal.title`} </S.Title>
        <S.TitleDescription>
          <Trans
            components={[<strong />, <i />]}
            i18nKey="purchase1.cancelOffer.modal.description"
          />
        </S.TitleDescription>
      </S.Container>
      <S.PictureContainer>
        <figure>
          <S.Picture src={imageLink} alt="" />
          <S.Footnote>{t`purchase1.cancelOffer.modal.footnote`}</S.Footnote>
        </figure>
      </S.PictureContainer>
      <S.Container>
        <S.Text>
          <Trans
            components={[<strong />, <br />]}
            i18nKey="purchase1.cancelOffer.modal.text"
          />
        </S.Text>
        <S.Action>
          <Button type="button" onClick={handleClick}>
            {t`purchase1.cancelOffer.modal.action`}
          </Button>
        </S.Action>
      </S.Container>
    </S.Wrapper>
  )
}
