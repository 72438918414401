import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerButton, AnswerButtonHeight } from 'components/AnswerButton'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { InputWithFloatPlaceholder } from 'components/InputWithFloatPlaceholder'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { BottomButtonContainer } from 'common-styles'
import {
  I18N_CONTEXT_COHORT_MAP,
  ONBOARDING_INPUT_MIN_LENGTH,
  OptionType,
} from 'root-constants'

import { StyledMotivation as S } from './Motivation.styles'
import {
  COHORT_QUESTIONS_MAP,
  DEFAULT_QUESTION,
  MOTIVATION_DEFAULT_OPTIONS,
  MOTIVATION_OPTIONS_MAP,
  OPTION_VALUES,
} from './constants'

export const Motivation: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const [motivation, setMotivation] = useState({ value: '', isValid: false })
  const cohortToUse = useCohortToUse()
  const includeNavigation = useIsNavigationOn()
  const { isSeniorCohort, isCosmeticCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: COHORT_QUESTIONS_MAP[cohortToUse] || DEFAULT_QUESTION,
    nextPagePath,
  })

  const inputLabelText = useMemo(() => t`onboarding.motivation.other`, [t])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          const newValues = answers.filter((item) => item !== motivation.value)
          setAnswers([...newValues, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
        setMotivation({ value: '', isValid: false })
      },
    }),
    [pageId, answers, motivation],
  )

  const handleNextClick = useCallback(
    () => handleContinue(answers),
    [answers, handleContinue],
  )

  const handleInputChange = useCallback(({ target: { value } }) => {
    const isInputValueValid = value.length >= ONBOARDING_INPUT_MIN_LENGTH

    setMotivation({ value, isValid: isInputValueValid })
    setAnswers(isInputValueValid ? [value] : [])
  }, [])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      if (!motivation.isValid) return

      handleNextClick()
    },
    [motivation.isValid, handleNextClick],
  )

  const motivationOptions: {
    value: OPTION_VALUES
    text: string
    height?: string
  }[] = useMemo(() => {
    return MOTIVATION_OPTIONS_MAP[cohortToUse] || MOTIVATION_DEFAULT_OPTIONS
  }, [cohortToUse])

  return (
    <div>
      <S.TitleContainer>
        <Trans
          i18nKey="onboarding.motivation.question"
          components={[<br />]}
          context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
        />
      </S.TitleContainer>
      {!isCosmeticCohort && (
        <S.DescriptionContainer>
          <PageTitleDescription>
            <Trans
              i18nKey="onboarding.motivation.description"
              components={[<br />]}
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </PageTitleDescription>
        </S.DescriptionContainer>
      )}
      <Container>
        <S.OptionsContainer>
          {motivationOptions.map(({ value, text, height }) => (
            <Option
              key={value}
              {...optionProps}
              value={value}
              checked={answers.includes(value)}
            >
              <AnswerButton
                withCheckbox
                {...(isSeniorCohort &&
                  height && { height: AnswerButtonHeight.L })}
              >
                <Trans
                  i18nKey={text}
                  components={[<br />]}
                  context={I18N_CONTEXT_COHORT_MAP[cohortToUse || '']}
                />
              </AnswerButton>
            </Option>
          ))}
        </S.OptionsContainer>
        <form onSubmit={handleSubmit}>
          <InputWithFloatPlaceholder
            value={motivation.value}
            labelName={inputLabelText}
            onChange={handleInputChange}
          />
        </form>
      </Container>
      {includeNavigation ? (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answers.length}
        />
      ) : (
        <BottomButtonContainer>
          <Button onClick={handleNextClick} disabled={!answers.length}>
            {t`actions.continue`}
          </Button>
        </BottomButtonContainer>
      )}
    </div>
  )
}
