import React from 'react'

import { PARTNERS } from 'components/PartnersContainer/constants'

import { StyledPartnerContainer as S } from './PartnersContainer.styles'

export const PartnersContainer: React.FC = () => {
  return (
    <S.PartnersContainer>
      {PARTNERS.map((item) => {
        return (
          <S.Partner key={item.partner} src={item.image} alt={item.partner} />
        )
      })}
    </S.PartnersContainer>
  )
}
