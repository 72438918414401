import styled from 'styled-components'

import { dotsAnimation } from 'common-styles'
import { Color } from 'root-constants'

import { ILoadingButtonProps, LoadingButtonTheme } from './LoadingButton'

export const StyledLoadingButton = {
  LoadingButton: styled.button<ILoadingButtonProps>`
    width: 90px;
    height: 29px;
    display: block;
    padding: 0 8px;
    margin-right: 11px;
    cursor: pointer;
    border: ${({ theme }) =>
      theme === LoadingButtonTheme.LIGHT
        ? `1px solid ${Color.WHITE}`
        : `1px solid ${Color.DARK_SECONDARY}`};
    outline: none;
    background-color: transparent;
    border-radius: 4px;
    font-size: 14px;
    line-height: 26px;
    text-decoration: none;
    text-align: center;
    color: ${({ theme }) =>
      theme === LoadingButtonTheme.LIGHT
        ? `${Color.WHITE}`
        : `${Color.DARK_SECONDARY}`};
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    & > div {
      display: inline-block;
      height: 8px;
      width: 8px;
      margin: 0 2px;
      animation: ${dotsAnimation} 1.5s infinite ease-in-out;
      background-color: ${({ theme }) =>
        theme === LoadingButtonTheme.LIGHT
          ? `${Color.WHITE}`
          : `${Color.DARK_SECONDARY}`};
      border-radius: 50%;

      &:nth-child(2) {
        animation-delay: 0.5s;
      }

      &:nth-child(3) {
        animation-delay: 1s;
      }
    }
  `,
}
