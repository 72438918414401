import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import ellipseChart from 'assets/images/bundle-ellipse-chart.png'

import { StyledSkipWellnessBundleModal as S } from './SkipWellnessBundleModal.styles'

type TSkipWellnessBundleModalProps = {
  closeModal: () => void
}

export const SkipWellnessBundleModal: React.FC<
  TSkipWellnessBundleModalProps
> = ({ closeModal }: TSkipWellnessBundleModalProps) => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <S.Content>
        <Container fields={16}>
          <S.Title>{t('wellnessBundleUpsell.skipModal.title')}</S.Title>
          <S.Subtitle>
            <Trans i18nKey="wellnessBundleUpsell.skipModal.subtitle" />
          </S.Subtitle>
          <S.GraphContainer>
            <S.ChartWrapper>
              <S.Chart src={ellipseChart} alt="ellipse-image" />
              <S.ChartText>
                {t('wellnessBundleUpsell.skipModal.usersNotice')}
              </S.ChartText>
            </S.ChartWrapper>
            <S.Note>{t('wellnessBundleUpsell.skipModal.note')}</S.Note>
          </S.GraphContainer>
          <S.DiscountContainer>
            <S.FeelDiscount>
              {t('wellnessBundleUpsell.skipModal.feelDiscount')}
            </S.FeelDiscount>
          </S.DiscountContainer>
        </Container>
        <S.ButtonContainer>
          <Button onClick={closeModal}>
            {t('guashaUpsell.skipModal.action')}
          </Button>
        </S.ButtonContainer>
      </S.Content>
    </S.Root>
  )
}
