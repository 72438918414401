import React, {
  FC,
  ImgHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Webcam from 'react-webcam'

import lottie from 'lottie-web/build/player/lottie_light'

import { Progress } from 'components/Progress'

import { setAnswersAction } from 'root-redux/actions/common'
import {
  analyseFacePictureAction,
  sendUserAnswersAction,
} from 'root-redux/actions/user'
import { selectFaceScanBlobImage } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useProgressAnimation } from 'hooks/useProgressAnimation'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import cameraButton from 'assets/images/camera-button.png'
import face from 'assets/images/face-contour.png'

import { goTo, replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  CameraAccess,
  Color,
  FaceScanSkipScreen,
} from 'root-constants'

import { FaceScanStyles as S } from './FaceScan.styles'
import {
  ANIMATION_PATH,
  AnalysisStepThreshold,
  VIDEO_CONSTRAINTS,
} from './constants'

export const FaceScan: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const maskBlob = useSelector(selectFaceScanBlobImage)

  const camera = useRef<Webcam>(null)

  const dispatch: TAppDispatch = useDispatch()

  const [image, setImage] = useState<ImgHTMLAttributes<HTMLImageElement>>()
  const [isCameraVisible, setIsCameraVisible] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const mask = useMemo(
    () =>
      maskBlob && {
        src: URL.createObjectURL(maskBlob),
      },
    [maskBlob],
  )

  const handleNextClick = useCallback(() => {
    dispatch(
      setAnswersAction({
        answers: '',
        pageId,
      }),
    )
    dispatch(sendUserAnswersAction())
    goTo(nextPagePath)
  }, [dispatch, pageId, nextPagePath])

  const { counter, progressAnimationPlayback } = useProgressAnimation({
    duration: 7000,
    callback: handleNextClick,
  })

  const analysisText = useMemo(() => {
    if (counter > AnalysisStepThreshold.THIRD) {
      return 'skinDiagnosis.analyzingResults'
    }
    if (counter > AnalysisStepThreshold.SECOND) {
      return 'skinDiagnosis.youAreBeautiful'
    }
    if (counter > AnalysisStepThreshold.FIRST) {
      return 'skinDiagnosis.analyzingParameters'
    }
    return 'skinDiagnosis.tapButton'
  }, [counter])

  const animationContainerRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node && !isCameraVisible) {
        lottie.loadAnimation({
          container: node,
          path: `${CDN_FOLDER_LINK}${ANIMATION_PATH}.json`,
          loop: true,
          name: 'faceScanner',
        })
      }
    },
    [isCameraVisible],
  )

  useEffect(() => () => lottie.destroy('faceScanner'), [])

  const handleAllowCamera = useCallback(() => {
    setIsLoading(false)
    eventLogger.logCameraAccess(CameraAccess.ALLOW)
  }, [])

  const handleDenyCamera = useCallback(() => {
    eventLogger.logCameraAccess(CameraAccess.DENY)
    eventLogger.logFaceScanSkipped(FaceScanSkipScreen.MAIN_SCREEN)
    replaceHistory({ pathname: PageId.MOTIVATION, search })
  }, [search])

  const handleSkipPage = useCallback(() => {
    eventLogger.logFaceScanSkipped(FaceScanSkipScreen.MAIN_SCREEN)
    replaceHistory({ pathname: PageId.MOTIVATION, search })
  }, [search])

  const toggleCamera = useCallback(async () => {
    const img = camera.current?.getScreenshot()
    if (!img) return

    const imgResponse = await fetch(img)
    const blob = await imgResponse.blob()

    setImage({ src: URL.createObjectURL(blob) })

    dispatch(analyseFacePictureAction(blob))
    eventLogger.logPhotoMade()

    setIsCameraVisible(false)
    progressAnimationPlayback()
  }, [progressAnimationPlayback, dispatch])

  return (
    <>
      {isCameraVisible ? (
        <S.ImagesContainer>
          <Webcam
            audio={false}
            ref={camera}
            mirrored
            muted
            playsInline
            forceScreenshotSourceSize
            screenshotFormat="image/png"
            videoConstraints={VIDEO_CONSTRAINTS}
            onUserMedia={handleAllowCamera}
            onUserMediaError={handleDenyCamera}
          />
          {!isLoading && <S.FaceContour src={face} />}
        </S.ImagesContainer>
      ) : (
        <>
          <S.Img src={image?.src} />
          {counter < AnalysisStepThreshold.THIRD ? (
            <>
              <S.FaceContour src={face} height={512} />
              <S.AnimationContainer ref={animationContainerRef} />
            </>
          ) : (
            <S.Img src={mask?.src} style={{ zIndex: 2 }} />
          )}
        </>
      )}

      {isCameraVisible && (
        <>
          <S.SkipBtn onClick={handleSkipPage}>
            {t('skinDiagnosis.skip')}
          </S.SkipBtn>
          <S.Button onClick={toggleCamera}>
            <img src={cameraButton} width={81} alt="button" />
          </S.Button>
        </>
      )}

      <S.ProgressWrapper>
        <S.AnalysisText>
          <Trans i18nKey={analysisText} components={[<br />]} />
        </S.AnalysisText>
        {!!counter && (
          <Progress
            background={Color.WHITE}
            color={Color.PRIMARY}
            value={counter}
            max={100}
          />
        )}
      </S.ProgressWrapper>
    </>
  )
}
