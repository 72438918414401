export const QUESTION = 'What are your biggest skincare challenges?'

export const enum SkincareChallenge {
  STICKING_SKINCARE_ROUTINE = 'sticking_skincare_routine',
  FIGURING_SKIN_NEEDS = 'figuring_skin needs',
  BUILDING_WORKING_ROUTINE = 'building_working_skincare_routine',
  FINDING_EFFECTIVE_PRODUCTS = 'finding_effective_products',
  SELECTING_SAFE_PRODUCTS = 'selecting_safe_products',
  KEEPING_TRACK_PROGRESS = 'keeping_track_progress',
  OTHER = 'other',
}

export const SKINCARE_CHALLENGES_OPTIONS = [
  {
    value: SkincareChallenge.STICKING_SKINCARE_ROUTINE,
    text: 'onboarding.skincareChallenges.stickingRoutine',
  },
  {
    value: SkincareChallenge.FIGURING_SKIN_NEEDS,
    text: 'onboarding.skincareChallenges.figuringNeeds',
  },
  {
    value: SkincareChallenge.BUILDING_WORKING_ROUTINE,
    text: 'onboarding.skincareChallenges.buildingRoutine',
  },
  {
    value: SkincareChallenge.FINDING_EFFECTIVE_PRODUCTS,
    text: 'onboarding.skincareChallenges.findingProducts',
  },
  {
    value: SkincareChallenge.SELECTING_SAFE_PRODUCTS,
    text: 'onboarding.skincareChallenges.selectingSafeProducts',
  },
  {
    value: SkincareChallenge.KEEPING_TRACK_PROGRESS,
    text: 'onboarding.skincareChallenges.keepingProgress',
  },
  {
    value: SkincareChallenge.OTHER,
    text: 'onboarding.skincareChallenges.other',
  },
]
