import { useEffect } from 'react'
import { useLocation } from 'react-router'

export const useScrollToTop = (): void => {
  const { pathname } = useLocation()

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  }, [pathname])
}
