import React, { InputHTMLAttributes, useCallback, useState } from 'react'

import { StyledInput as S } from './Input.styles'

type TProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'required'> & {
  labelName: string
  hasVisibilityControl?: boolean
  isValid?: boolean
  validationText?: string
  type?: string
}

export const Input: React.FC<TProps> = ({
  labelName,
  hasVisibilityControl = false,
  isValid = true,
  validationText = '',
  type = 'text',
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const getInputType = useCallback(() => {
    if (!hasVisibilityControl) return type

    return isVisible ? 'text' : 'password'
  }, [hasVisibilityControl, isVisible, type])

  return (
    <S.Wrapper data-valid={isValid} data-validation-text={validationText}>
      <S.Input required type={getInputType()} {...props} />
      <S.Label>{labelName}</S.Label>
      {hasVisibilityControl && (
        <S.VisibilityButton
          type="button"
          onClick={() => setIsVisible((prevValue) => !prevValue)}
          data-visible={isVisible}
        />
      )}
    </S.Wrapper>
  )
}
