import styled from 'styled-components'

import { Container } from 'components/Container'
import { DomainsContainer } from 'components/DomainsContainer'

import whitePlaneIcon from 'assets/images/paper-plane-white.svg'

import { DelayedBottomContainer } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants'

type TSendEmailButtonProps = {
  isValid?: boolean
}

export const StyledLikeChatEmail = {
  CustomContainer: styled(Container)`
    @media (max-width: ${MediaBreakpoint.TABLET}px) {
      max-width: ${MediaBreakpoint.TABLET}px;
    }
  `,
  ErrorContainer: styled.div`
    position: relative;
    padding: 4px;
    margin-left: 16px;
    margin-right: 16px;
  `,
  Domains: styled(DomainsContainer)`
    width: calc(100% - 32px);
    top: unset;
    bottom: 74px;
    left: 50%;
    transform: translateX(-50%);
  `,
  BottomContainer: styled(DelayedBottomContainer)`
    padding: 0;

    @media (max-width: ${MediaBreakpoint.TABLET}px) {
      max-width: ${MediaBreakpoint.TABLET}px;
    }
  `,
  BottomContainerContent: styled.div`
    width: 100%;
    border: 1px solid ${Color.SILVER_GRAY};
    padding: 12px 72px 12px 24px;
    position: relative;

    @media (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: ${MediaBreakpoint.MAX_PHONE}px;
    }
  `,
  EmailInput: styled.input`
    width: 100%;
    outline: none;
    border: none;
    height: 40px;
    padding: 0;
    background-color: ${Color.WHITE};
    color: ${Color.BLUE_DARK};
    font-size: 15px;
    font-weight: 500;
    transition: border 0.2s ease-out;

    &:valid,
    &:focus {
      padding-top: 10px;

      & + span {
        top: 15px;
        color: ${Color.LIGHT};
        font-size: 12px;
        line-height: 16px;
      }
    }
  `,
  InputLabel: styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 24px;
    color: ${Color.GRAY_SECONDARY};
    font-size: 16px;
    line-height: 24px;
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
    z-index: 1;
  `,
  ActionButtonWrapper: styled.div`
    position: absolute;
    top: 12px;
    right: 16px;
  `,
  SendEmailButton: styled.button<TSendEmailButtonProps>`
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    display: flex;
    border-radius: 8px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    background: ${({ isValid }) => (isValid ? Color.PRIMARY : '#C4C4C4')}
      url(${whitePlaneIcon}) no-repeat center;

    ${({ isValid }) =>
      isValid && 'box-shadow: 0px 4px 8px 0px rgba(208, 63, 49, 0.3);'}
  `,
}
