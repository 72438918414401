export const COSMETIC_SCANNER_LUVLY_BENEFITS = [
  {
    id: 1,
    text: 'purchase1.luvlyBenefits.personalChecker',
  },
  {
    id: 2,
    text: 'purchase1.luvlyBenefits.topProducts',
  },
  {
    id: 3,
    text: 'purchase1.luvlyBenefits.noMoney',
  },
  {
    id: 4,
    text: 'purchase1.luvlyBenefits.personalizedPlan',
  },
  {
    id: 5,
    text: 'purchase1.luvlyBenefits.personalInsights',
  },
  {
    id: 6,
    text: 'purchase1.luvlyBenefits.moreConfident',
  },
]
