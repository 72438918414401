import React from 'react'

import { TComponentProps } from 'models/common.model'

import { StyledChatAnswerButtonMini as S } from './ChatAnswerButtonMini.styles'

export const ChatAnswerButtonMini: React.FC<TComponentProps> = ({
  children,
  className,
}) => <S.Root className={className}>{children}</S.Root>
