import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPersonalizedPlan = {
  Wrapper: styled.div`
    margin-bottom: 16px;
    padding: 20px 15px 20px 20px;
    background: #fef7e8;
    border: 1px solid #f6ca66;
    box-sizing: border-box;
    border-radius: 14px;
  `,
  PlanDescription: styled.p`
    padding-bottom: 12px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
  `,
  Text: styled.p`
    line-height: 24px;
  `,
  TimerContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 12px;
    padding: 5px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.RED};

    &[data-is-target-hidden='true'] {
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      min-height: 50px;
      background-color: ${Color.LIGHT_PINK};
      color: ${Color.WHITE};
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      z-index: 99;
    }
  `,
  TimerText: styled.span`
    padding-right: 5px;

    & + strong {
      display: inline-block;
      min-width: 55px;
      text-align: left;
    }
  `,
  Disclaimer: styled.p`
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  `,
}
