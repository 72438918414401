import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledNavigationButtons as S } from './NavigationButtons.styles'

type TNavigationButtonsProps = {
  onBackClick?: () => void
  onNextClick?: () => void
  disabled?: boolean
  goToNextButtonText?: string
  buttonHeight?: number
  children?: React.ReactNode
}

export const NavigationButtons: React.FC<TNavigationButtonsProps> = ({
  onBackClick,
  onNextClick,
  disabled,
  goToNextButtonText,
  buttonHeight = 50,
  children,
}: TNavigationButtonsProps) => {
  const { t } = useTranslation()

  return (
    <S.Root>
      {children}
      <S.ButtonContainer>
        <S.BackButton
          type="button"
          buttonHeight={buttonHeight}
          onClick={onBackClick}
        >
          {t`actions.back`}
        </S.BackButton>
        <S.NextButton
          type="button"
          buttonHeight={buttonHeight}
          onClick={onNextClick}
          disabled={disabled}
        >
          {goToNextButtonText || t`actions.next`}
        </S.NextButton>
      </S.ButtonContainer>
    </S.Root>
  )
}
