import React, { useEffect } from 'react'

import { getDecoratedTimerValue } from 'modules/purchase/helpers/rootHelpers'

export const useTimerForTarget = (
  timerElementRef: React.RefObject<HTMLElement>,
  startTimeValueInSeconds: number,
  duplicateTimerRefs?: React.RefObject<HTMLElement>[],
): void => {
  useEffect(() => {
    let intervalID: number

    const setIntervalCallback = () => {
      if (!timerElementRef?.current) return

      const timerElement = timerElementRef.current
      const currentTimerValue = +(
        timerElement.dataset.value || startTimeValueInSeconds
      )

      if (currentTimerValue <= 0) {
        clearInterval(intervalID)
        return
      }

      const nextTimerValue = currentTimerValue - 1

      timerElement.setAttribute('data-value', `${nextTimerValue}`)
      timerElement.innerHTML = getDecoratedTimerValue(nextTimerValue)

      if (duplicateTimerRefs && duplicateTimerRefs.length) {
        duplicateTimerRefs.forEach((ref) => {
          if (ref && ref.current) {
            ref.current.setAttribute('data-value', `${nextTimerValue}`)
            ref.current.innerHTML = getDecoratedTimerValue(nextTimerValue)
          }
          return ref
        })
      }
    }

    intervalID = setInterval(setIntervalCallback, 1000) as unknown as number

    const leavePageCallback = () => {
      if (document.hidden) {
        clearInterval(intervalID)
        return
      }

      intervalID = setInterval(setIntervalCallback, 1000) as unknown as number
    }

    window.addEventListener('visibilitychange', leavePageCallback)

    return () => {
      clearInterval(intervalID)
      window.removeEventListener('visibilitychange', leavePageCallback)
    }
  }, [duplicateTimerRefs, startTimeValueInSeconds, timerElementRef])
}
