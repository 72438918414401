import { SubscriptionProduct } from 'root-constants'

export const DISCLAIMERS = {
  [SubscriptionProduct.ONE_MONTH]:
    'purchase1.holidayCancelOffer.disclaimerMonthly',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchase1.holidayCancelOffer.disclaimerQuarterly',
  [SubscriptionProduct.SIX_MONTHS]:
    'purchase1.holidayCancelOffer.disclaimerSemiAnnually',
}

export const DISCLAIMERS_WITHOUT_TAXES = {
  [SubscriptionProduct.ONE_MONTH]:
    'purchase1.holidayCancelOffer.disclaimerMonthlyWithoutTax',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchase1.holidayCancelOffer.disclaimerQuarterlyWithoutTax',
  [SubscriptionProduct.SIX_MONTHS]:
    'purchase1.holidayCancelOffer.disclaimerSemiAnnuallyWithoutTax',
}
