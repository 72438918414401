import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  EventTypes,
  PaymentMethodType,
  PrimerHeadlessCheckout,
} from '@primer-io/checkout-web'

import { PRIMER_PAYPAL_ID, PaymentMethod } from 'modules/purchase/constants'
import { usePrimerAnalytics } from 'modules/purchase/hooks/usePrimerAnalytics'
import { setPaymentMethodAction } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

export const useInitPrimerPayPal = ({
  primerRef,
}: {
  primerRef: React.RefObject<PrimerHeadlessCheckout | null>
}) => {
  const dispatch = useDispatch()
  const { logPaymentStarted } = usePrimerAnalytics()

  const initPayPalButton = useCallback(
    async (callback?: () => void) => {
      const paymentMethodManager =
        await primerRef.current?.createPaymentMethodManager(
          PaymentMethodType.PAYPAL,
        )

      if (!paymentMethodManager) return

      const payPalButton = paymentMethodManager.createButton()

      await payPalButton.render(PRIMER_PAYPAL_ID, {
        style: {
          buttonType: 'short',
          buttonColor: 'gold',
          buttonHeight: 55,
        },
      })

      payPalButton.addEventListener(EventTypes.CLICK, () => {
        callback && callback()
        eventLogger.logPaymentMethodSelected(PaymentMethod.PAYPAL)
        dispatch(setPaymentMethodAction(PaymentMethod.PAYPAL))
        logPaymentStarted(PaymentMethod.PAYPAL)
      })
    },
    [dispatch, logPaymentStarted, primerRef],
  )

  return {
    initPayPalButton,
  }
}
