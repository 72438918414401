import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { eventLogger } from 'services/eventLogger.service'

import guashaChart from 'assets/images/guasha-chart.png'

import { StyledSkipUpsellGuashaModal as S } from './SkipUpsellGuashaModal.styles'

type TSkipUpsellGuashaModalProps = {
  closeModal: () => void
}

export const SkipUpsellGuashaModal: React.FC<TSkipUpsellGuashaModalProps> = ({
  closeModal,
}: TSkipUpsellGuashaModalProps) => {
  const { t } = useTranslation()

  const closeSkipModal = useCallback(() => {
    eventLogger.logInAppModalClosed()
    closeModal()
  }, [closeModal])

  return (
    <S.Root>
      <S.Content>
        <Container fields={16}>
          <S.Title>{t('guashaUpsell.skipModal.title')}</S.Title>
          <S.Subtitle>
            <Trans i18nKey="guashaUpsell.skipModal.subtitle" />
          </S.Subtitle>
          <S.Container>
            <S.Chart src={guashaChart} alt="guasha-chart" />
            <S.Note>{t('guashaUpsell.skipModal.note')}</S.Note>
          </S.Container>
          <S.Container>
            <S.FeelDiscount>
              {t('guashaUpsell.skipModal.feelDiscount')}
            </S.FeelDiscount>
          </S.Container>
        </Container>
        <S.ButtonContainer>
          <Button onClick={closeSkipModal}>
            {t('guashaUpsell.skipModal.action')}
          </Button>
        </S.ButtonContainer>
      </S.Content>
    </S.Root>
  )
}
