import React, { useMemo } from 'react'

import { useCohortToUse } from 'hooks/useCohortToUse'

import { TComponentProps } from 'models/common.model'

import { StyledContainer as S } from './Container.styles'
import { LARGE_CONTAINER_COHORTS } from './constants'

export type TContainer = {
  fields?: number
  fullHeight?: boolean
  justifyContent?: string
}

export const Container: React.FC<TComponentProps & TContainer> = ({
  children,
  className = '',
  fields,
  fullHeight,
  justifyContent,
}) => {
  const cohortToUse = useCohortToUse()

  const containerFields = useMemo(() => {
    const defaultFields = LARGE_CONTAINER_COHORTS.includes(cohortToUse)
      ? 16
      : 20
    return fields ?? defaultFields
  }, [cohortToUse, fields])

  return (
    <S.Container
      className={className}
      fields={containerFields}
      fullHeight={fullHeight}
      justifyContent={justifyContent}
    >
      {children}
    </S.Container>
  )
}
