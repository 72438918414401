import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import trustFeedback from 'assets/images/trust-feedback.png'

import { StickyButtonContainer } from 'common-styles'

import { StyledTrustFeedback as S } from './TrustFeedback.styles'
import { QUESTION } from './constants'

export const TrustFeedback: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.Root>
      <S.ImageContainer>
        <S.Image src={trustFeedback} alt="" />
      </S.ImageContainer>
      <Container>
        <S.Title>
          <Trans
            i18nKey="onboarding.trustFeedback.question"
            components={[<br />]}
          />
        </S.Title>
        <S.Description>
          <Trans
            i18nKey="onboarding.trustFeedback.description"
            components={[<br />]}
          />
        </S.Description>
        <StickyButtonContainer customBackground="transparent">
          <Button onClick={handleNextClick}>{t('actions.next')}</Button>
        </StickyButtonContainer>
      </Container>
    </S.Root>
  )
}
