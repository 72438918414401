import styled from 'styled-components'

import { Button } from 'components/Button'

import discountBadge from 'assets/images/upsell-discount-badge.png'

import { Color } from 'root-constants'

export const StyledUpsellSubscriptionBlockSwitchers = {
  Discount: styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 16px;
    padding: 12px 20px 12px 100px;
    background: #ffeeed;
    border: 1px solid ${Color.PRIMARY};
    border-radius: 14px;
    min-height: 68px;
  `,
  DiscountDescription: styled.div`
    margin: 16px 0 24px;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: ${Color.GRAY};

    span {
      color: ${Color.PRIMARY};
    }
  `,
  DiscountBadge: styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 24px;
    top: -8px;
    max-width: 52px;
    min-height: 70px;
    padding: 7px 3px 19px;
    background-image: url(${discountBadge});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: ${Color.WHITE};

    div {
      font-size: 12px;
    }
  `,
  DiscountText: styled.p`
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    color: ${Color.GRAY};
  `,
  ButtonContainer: styled.div`
    margin-bottom: 16px;
  `,
  SecondaryButton: styled(Button)`
    background: #c4c4c4;
  `,
  LinkContainer: styled.div`
    text-align: center;
  `,
  Link: styled.a`
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    text-decoration: underline;
    color: ${Color.LIGHT};
    cursor: pointer;
  `,
  BottomActions: styled.div`
    position: absolute;
    max-width: 320px;
    z-index: 2;
    bottom: 88px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  `,
}
