import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellGuashaPersonalBonus = {
  Root: styled.div`
    padding-top: 36px;
    position: relative;
  `,
  Container: styled.div`
    padding: 56px 16px 32px;
    border-radius: 32px;
    background: #f1f5f9;
    margin: 0 auto 40px;
    max-width: 328px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 12px;
  `,
  Description: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    color: ${Color.LIGHT};
    margin-bottom: 24px;
  `,
  Icon: styled.div<{ backgroundColor: string }>`
    width: 72px;
    height: 72px;
    display: grid;
    place-content: center;
    border-radius: 16px;
    box-shadow: 0px 8px 20px 0px #cccfde;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: ${({ backgroundColor }) => backgroundColor || '#9687E0'};
  `,
}
