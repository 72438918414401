import React, { FC, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import lottie from 'lottie-web/build/player/lottie_light'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { selectFaceScanResults } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledFaceScanPreview as S } from './FaceScanResults.styles'
import { DIAGNOSIS_MAP, GRAPH_PATH, PERCENTAGE, QUESTION } from './constants'

export const FaceScanResults: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const results = useSelector(selectFaceScanResults)
  const animationContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        path: `${CDN_FOLDER_LINK}${GRAPH_PATH}.json`,
        loop: false,
        autoplay: true,
      })
    }

    return () => lottie.destroy()
  }, [])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    results.length
      ? handleContinue(results)
      : replaceHistory({ pathname: PageId.MOTIVATION, search })
  }, [handleContinue, results, search])

  return (
    <S.Root>
      <Container>
        <S.Title>{t('skinDiagnosis.results.title')}</S.Title>
        <S.Description>
          {t('skinDiagnosis.results.description', {
            percentage: PERCENTAGE[results.length] || PERCENTAGE['1'],
          })}
        </S.Description>
        {!!results.length && (
          <>
            <S.HighlightedText>
              {t('skinDiagnosis.results.followingIssues')}
            </S.HighlightedText>
            <S.IssuesContainer>
              {results.map((issue) => (
                <S.Issue key={issue}>{t(DIAGNOSIS_MAP[issue])}</S.Issue>
              ))}
            </S.IssuesContainer>
          </>
        )}
      </Container>

      <S.AnimationContainer ref={animationContainerRef} />

      <S.ButtonContainer>
        <Button onClick={handleNextClick}>{t('actions.continue')}</Button>
      </S.ButtonContainer>
    </S.Root>
  )
}
