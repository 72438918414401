import styled from 'styled-components'

import chevronRight from 'assets/images/chevron-right.svg'

import {
  commonBackdropStyles,
  commonMenuCloseButtonStyles,
  commonMenuStyles,
} from 'common-styles'
import { Color } from 'root-constants'

export const StyledIntroVariant3 = {
  Root: styled.div`
    padding-top: 50px;
    padding-bottom: 24px;

    & .swiper-wrapper {
      padding-bottom: 48px;
    }

    & .swiper-pagination {
      bottom: 0;
    }

    & span.swiper-pagination-bullet {
      background-color: ${Color.CREAMY};
      opacity: 1;
    }

    & span.swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: ${Color.PRIMARY};
    }
  `,
  Hero: styled.div`
    padding-top: 32px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 61.31%,
        #fff 95.07%
      ),
      linear-gradient(246deg, #d1acff 2.95%, #606bc3 88.94%);
    margin-bottom: 24px;
  `,
  Title: styled.h1`
    color: ${Color.WHITE};
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
    text-align: center;
    font-family: Rubik;
    text-transform: uppercase;
  `,
  Subtitle: styled.h2`
    color: ${Color.WHITE};
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-bottom: 12px;
    text-transform: uppercase;

    & strong {
      color: ${Color.CREAMY};
    }
  `,
  CallToAction: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${Color.WHITE};
    text-align: center;
    margin-bottom: 24px;
  `,
  Actions: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 8px;
    margin: 0 0 24px;

    input:checked + div > div {
      border: 2px solid ${Color.PRIMARY};
      background: #ffe0de;
    }

    & label {
      flex-basis: calc(50% - 8px);
      margin-bottom: 0;
    }
  `,
  Action: styled.div`
    display: flex;
    min-height: 176px;
    position: relative;
    padding-top: 11px;
  `,

  Answer: styled.div`
    flex-basis: 100%;
    background-color: ${Color.WHITE};
    border: 2px solid transparent;
    border-radius: 12px;
    transition: background-color 0.1s ease-out;
  `,
  Range: styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    border-radius: 0 0 12px 12px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: ${Color.WHITE};
    position: absolute;
    bottom: 0;
    left: 0;
    height: 36px;
    width: 100%;
    padding: 8px 16px;
    z-index: 1;
    background: ${Color.PRIMARY};
    margin-right: 6px;
  `,
  RangeValue: styled.span`
    margin-right: 6px;
  `,
  ChevronRight: styled.span`
    width: 6px;
    height: 12px;
    background: url(${chevronRight}) no-repeat center;
    background-size: contain;
  `,
  Image: styled.img`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 140px;
    z-index: 1;
  `,

  Terms: styled.div`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: ${Color.LIGHT};
    margin: 0 auto;

    a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.LIGHT};
    margin: 0 5px;
    vertical-align: middle;
  `,
  Header: styled.header`
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    padding: 12px 20px;
    align-items: center;
    background-color: ${Color.WHITE};
    z-index: 2;
  `,
  Logo: styled.img`
    max-width: 58px;
    height: auto;
    vertical-align: top;
    margin: 0 auto 0 0;
  `,
  SupportAction: styled.button`
    border: 1px solid ${Color.DARK_SECONDARY};
    background-color: transparent;
    border-radius: 8px;
    padding: 5px 8px;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.DARK_SECONDARY};
    margin-right: 11px;
    cursor: pointer;
    text-transform: uppercase;
  `,
  Burger: styled.button`
    width: 20px;
    height: 17px;
    background: transparent;
    border: 0;
    border-radius: 0;
    overflow: hidden;
    position: relative;
    padding: 0;
    cursor: pointer;
  `,
  BurgerLine: styled.span`
    display: block;
    width: 100%;
    height: 1px;
    border-radius: 14px;
    background-color: ${Color.DARK_SECONDARY};
    position: absolute;
    left: 0;
    top: 0;

    &:nth-child(2),
    &:nth-child(3) {
      top: calc(50%);
    }

    &:last-child {
      top: auto;
      bottom: 0;
    }
  `,
  Backdrop: styled.div`
    ${commonBackdropStyles};
    z-index: 3;
  `,
  Menu: styled.nav`
    ${commonMenuStyles};
  `,
  MenuCloseButton: styled.button`
    ${commonMenuCloseButtonStyles};
  `,
  StyledMenuLinkContainer: styled.div`
    font-size: 20px;
    line-height: 24px;
    color: rgb(45, 50, 64);
    margin-bottom: 35px;

    a {
      color: inherit;
    }
  `,
  ScrollDown: styled.div`
    width: 48px;
    height: 48px;
    margin: 0 auto 24px;
  `,
  Roadmap: styled.ul`
    margin-bottom: 48px;
  `,
  RoadmapItem: styled.li`
    margin-bottom: 48px;
    &:last-child {
      margin-bottom: 0;
    }
  `,
  RoadmapTitle: styled.h3`
    color: ${Color.BLUE_DARK};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    margin-bottom: 12px;
  `,
  RoadmapSubtitle: styled.p`
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 24px;
    color: ${Color.LIGHT};
  `,
  RoadmapImage: styled.img`
    max-width: 100%;
    width: 296px;
    height: auto;
    margin: 0 auto;
  `,
  NumberOneApp: styled.div`
    padding: 0 16px;
    margin-bottom: 32px;
  `,
  ButtonContainer: styled.div`
    margin-bottom: 48px;
  `,
  Brands: styled.div`
    margin-bottom: 48px;
  `,
  Creators: styled.div`
    padding: 48px 0 20px;
    background: linear-gradient(246deg, #d1acff 2.95%, #606bc3 88.94%),
      linear-gradient(
        5deg,
        rgba(197, 209, 255, 0.6) 0%,
        rgba(236, 197, 255, 0.6) 100%
      ),
      #fff;
    margin-bottom: 48px;
  `,
  CreatorsList: styled.ul``,
  CreatorsTitle: styled.h3`
    color: ${Color.WHITE};
    text-align: center;
    font-family: Rubik;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
  `,
  CreatorsSubtitle: styled.p`
    color: ${Color.WHITE};
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin: 0 -2px 24px;
  `,
  CreatorsItem: styled.li`
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.05))
      drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1));
  `,
  CreatorsImageContainer: styled.div`
    display: grid;
    place-content: center;
    width: 164px;
    height: 164px;
    border-radius: 50%;
    margin: 0 auto;
    border: 16px solid #f6f8ff;
    background-color: #ccbaff;
    position: relative;
    z-index: 1;
  `,
  CreatorsImage: styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
  `,
  CreatorsReview: styled.div`
    font-family: Rubik;
    border-radius: 16px;
    background: ${Color.WHITE};
    padding: 32px 16px 20px;
    transform: translateY(-28px);
    position: relative;
    z-index: 0;
    text-align: center;
  `,
  CreatorsName: styled.h4`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 4px;
  `,
  CreatorsText: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.LIGHT};
  `,
  Reviews: styled.div`
    margin-bottom: 32px;
  `,
  ReviewsTitle: styled.h3`
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
    color: ${Color.BLUE_DARK};
    text-align: center;
  `,
  Review: styled.div`
    background: ${Color.WHITE};
  `,
  ReviewContainer: styled.div`
    max-width: 328px;
    margin: 0 auto;
    box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.5);
    border-radius: 16px;
    padding: 16px 16px 20px;
    overflow: hidden;
  `,
  ReviewerImage: styled.img`
    display: block;
    width: auto;
    height: 208px;
    border-radius: 16px 16px 0 0;
  `,
  ReviewerName: styled.div`
    padding: 16px 0 16px;
    width: 100%;
    text-align: center;
    color: ${Color.BLUE_DARK};
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  `,
  ReviewText: styled.p`
    text-align: center;
    min-height: 80px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.LIGHT};
  `,
  Footnote: styled.p`
    margin-top: 16px;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    color: ${Color.LIGHT};
  `,
}
