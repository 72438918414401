import styled from 'styled-components'
import { Swiper } from 'swiper/react'

import cardBg from 'assets/images/results-card-bg.png'

import { Color } from 'root-constants'

export const StyledRecommendations = {
  Wrapper: styled.div`
    overflow-x: hidden;
  `,
  Title: styled.h2`
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-top: 24px;

    strong {
      color: #d56037;
      font-weight: 500;
      text-decoration: underline;
    }
  `,
  Subtitle: styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;

    strong {
      color: #d56037;
      font-weight: 500;
    }
  `,
  Tip: styled.div`
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
  `,
  Icon: styled.img`
    margin-right: 8px;
  `,
  CarouselContainer: styled.div`
    max-width: 360px;
    margin: 0 auto;
  `,
  Carousel: styled(Swiper)`
    padding-bottom: 24px;
    padding-top: 24px;

    .swiper-slide {
      height: auto;
    }

    .swiper-pagination-bullets {
      bottom: 34px;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      margin: 0 6px;
      background: ${Color.PRIMARY};
      opacity: 0.4;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      opacity: 1;
    }
  `,
  Card: styled.div`
    height: 100%;
    color: ${Color.DEFAULT_BACKGROUND};
    box-shadow: 0 8px 20px #e8e8e8;
    border-radius: 20px;
    padding: 24px 18px;
  `,
  ButtonContainer: styled.div`
    max-width: 360px;
    margin: 0 auto;
    position: sticky;
    bottom: 0;
    z-index: 2;
    padding: 38px 20px 18px 20px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    );

    button {
      max-width: 320px;
      margin: 0 auto;
    }
  `,
  HighlightedText: styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Color.WHITE};
    margin-bottom: 16px;
  `,
  IssuesContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    min-height: 76px;
    align-items: center;
  `,
  Issue: styled.div`
    padding: 8px 16px;
    height: 30px;
    width: fit-content;
    text-transform: uppercase;
    background: ${Color.WHITE};
    border-radius: 16px;
    margin-right: 8px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1.5px;
    color: #d56037;
  `,
  ResultsContainer: styled.div`
    padding: 19px 16px 13px;
    margin-top: 24px;
    border-radius: 20px;
    background: url(${cardBg}) top center no-repeat;
    background-size: cover;
  `,
  Divider: styled.div`
    height: 1px;
    width: 100%;
    margin-top: 24px;
    background: #d9d9d9;
  `,
}
