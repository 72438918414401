import briannaImg from 'assets/images/brianna-before-after-min.jpg'
import jenniferImg from 'assets/images/jennifer-before-after-min.jpg'
import lindaImg from 'assets/images/linda-before-after-min.jpg'
import melissaImg from 'assets/images/melissa-before-after-min.png'

export const USERS_GALLERY = [
  {
    name: 'purchase7.userGoalsGallery.user1',
    img: melissaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user2',
    img: briannaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user3',
    img: lindaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user4',
    img: jenniferImg,
  },
]
