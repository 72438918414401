import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { selectAnswers } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Chat, DelayedBottomContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import {
  OPTION_VALUES,
  PRACTICE_DURATION_ANSWERS_MAP,
  QUESTION,
} from './constants'

export const LikeChatPracticeDuration: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)
  const userAnswers = useSelector(selectAnswers)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    handleContinue(answer)
  }, [answer, handleContinue])

  const handleSetAnswer = useCallback((value) => {
    setAnswer(value)
  }, [])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      onChange: (value: string) => {
        setIsAnswersDisabled(true)
        handleChange(value, handleSetAnswer)
      },
    }),
    [
      handleChange,
      handleSetAnswer,
      isAnswersDisabled,
      pageId,
      setIsAnswersDisabled,
    ],
  )

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.USER_ANSWER)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_QUESTION ||
            (chatStep === ChatStep.USER_ANSWER && !answer)
          }
        >
          <Trans
            i18nKey="onboarding.likeChatPracticeDuration.title"
            component={[<strong />, <br />]}
            values={{
              age: userAnswers?.[PageId.AGE] || '20',
            }}
          />
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.USER}
          {...(!!answer && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.EXPERT_EXPRESSION)}
        >
          {answer && t(PRACTICE_DURATION_ANSWERS_MAP[answer])}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          {...(chatStep === ChatStep.EXPERT_EXPRESSION && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_EXPRESSION ||
            chatStep === ChatStep.FINISH
          }
        >
          <Trans
            i18nKey="onboarding.likeChatPracticeDuration.expression"
            component={[<strong />, <br />]}
            values={{
              age: userAnswers?.[PageId.AGE] || '20',
            }}
          />
        </ChatMessage>
      </Chat>
      <DelayedBottomContainer isShown={chatStep === ChatStep.FINISH}>
        <Button onClick={handleNextClick}>{t('actions.next')}</Button>
      </DelayedBottomContainer>
      <DelayedBottomContainer
        isShown={!answer && chatStep === ChatStep.USER_ANSWER}
      >
        <Option {...optionProps} value={OPTION_VALUES.UP_TO_TEN_MINUTES}>
          <ChatAnswerButton>
            {t('onboarding.practiceDuration.upToTenMinutes')}
          </ChatAnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.UP_TO_FIFTEEN_MINUTES}>
          <ChatAnswerButton>
            {t('onboarding.practiceDuration.upToFifteenMinutes')}
          </ChatAnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.UP_TO_THIRTY_MINUTES}>
          <ChatAnswerButton>
            {t('onboarding.practiceDuration.upToThirtyMinutes')}
          </ChatAnswerButton>
        </Option>
      </DelayedBottomContainer>
    </Container>
  )
}
