import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledTopRatedWellnessApps = {
  Root: styled.div`
    margin: 40px 0;
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};

    & > strong {
      color: #b499fb;
      font-weight: 700;
    }
  `,
  AppsContainer: styled.div`
    display: flex;
    gap: 16px;
    margin-top: 24px;
  `,
  AppContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &:first-of-type,
    &:last-of-type {
      min-width: 90px;
      width: 90px;
    }

    &:not(:first-of-type) {
      &::before {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        left: -8px;
        top: 0;
        background: linear-gradient(
          180deg,
          #a9a9a900 0%,
          #dedede 50%,
          #a9a9a900 100%
        );
      }
    }
  `,
  AppLogo: styled.img`
    width: 48px;
    height: 48px;
  `,
  AppName: styled.p`
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    color: ${Color.BLUE_DARK};
    margin-top: 8px;
    text-align: center;
  `,
  AppDescription: styled.p`
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: ${Color.GRAY};
    text-align: center;
  `,
}
