import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Outlet } from 'react-router-dom'

import { Spinner } from 'components/Spinner'

import { setTestEnvironmentQueryParamAction } from 'root-redux/actions/user'
import { selectLanguage } from 'root-redux/selects/common'

import { useEventLoggerInitialization } from 'hooks/useEventLoggerInitialization'
import { useLanguage } from 'hooks/useLanguage'

import { TEST_ENV_QUERY_PARAM } from 'root-constants'

import { StyledUnsubscribe as S } from './Unsubscribe.styles'

export const Unsubscribe: React.FC = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { i18n } = useTranslation()
  const language = useSelector(selectLanguage)

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const hasTestEnvQueryParam = URLParams.has(TEST_ENV_QUERY_PARAM)
    const testEnvQueryParamFromUrl = hasTestEnvQueryParam
      ? `?${TEST_ENV_QUERY_PARAM}`
      : ''

    dispatch(setTestEnvironmentQueryParamAction(testEnvQueryParamFromUrl))
  }, [dispatch, search])

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  useEventLoggerInitialization()
  useLanguage(true)

  return !language ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Column>
        <Outlet />
      </S.Column>
    </S.Wrapper>
  )
}
