import React from 'react'

import { StyledBlinkingPageTitle as S } from './BlinkingPageTitle.styles'

type TProps = {
  children?: React.ReactNode
}

export const BlinkingPageTitle: React.FC<TProps> = ({ children }) => (
  <S.Title>
    {children}&nbsp;<S.Dot>.</S.Dot>
    <S.Dot>.</S.Dot>
    <S.Dot>.</S.Dot>
  </S.Title>
)
