import styled from 'styled-components'

import fireIcon from 'assets/images/monochrome-fire.png'

import { Color } from 'root-constants'

export const StyledWellnessBundlePurchaseBlock = {
  Root: styled.div`
    margin: 40px auto;
  `,
  Title: styled.h2`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 24px;
    color: ${Color.BLUE_DARK};
  `,
  Offer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  `,
  OfferText: styled.span`
    color: ${Color.BLUE_GRAY};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
  Prices: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  DiscountPrice: styled.span`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.PRIMARY};
  `,
  OldPrice: styled.span`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration: line-through;
    color: ${Color.LIGHT};
  `,
  FreeLabel: styled.div`
    display: flex;
    padding: 4px 6px;
    align-items: center;
    border-radius: 4px;
    background: rgba(255, 130, 118, 0.1);

    &::before {
      content: '';
      width: 12px;
      height: 12px;
      margin-right: 4px;
      background-size: 12px 12px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(${fireIcon});
    }
  `,
  LinkContainer: styled.div`
    text-align: center;
    margin: 24px auto;
  `,
  Link: styled.a`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    color: ${Color.LIGHT};
    cursor: pointer;
  `,
  Disclaimer: styled.p`
    font-family: Rubik;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    color: ${Color.LIGHT};

    a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
}
