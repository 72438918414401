import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'

import { TProductId } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import { ScreenName } from 'root-constants'

export const logFailedPayPalPayment = ({
  productId,
  price,
  error,
  paymentMethod,
  currency,
  screenName,
  isTrialActive,
  stripeAccountId,
  stripeAccountName,
}: {
  productId: TProductId
  price: number
  error?: Record<string, undefined>
  paymentMethod: PaymentMethod
  currency: string
  screenName: ScreenName
  isTrialActive?: boolean
  stripeAccountId: string
  stripeAccountName: string
}): void => {
  eventLogger.logPurchaseFailed({
    productId,
    priceDetails: { price, currency, trial: isTrialActive },
    error: {
      type: error?.name || '',
      code: error?.debug_id,
      description: error?.message,
    },
    paymentMethod,
    screenName,
    stripeAccountId,
    stripeAccountName,
    paymentSystem: PaymentSystem.PAYPAL,
  })
}
