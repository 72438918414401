import styled from 'styled-components'

import optionIconUrl from 'assets/images/check-icon.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseUpgradeBenefits = {
  PurchaseUpgradeBenefitsBlock: styled.div`
    margin: 0 0 40px 0;
    background: ${Color.WHITE};
    padding: 16px 12px;
    box-shadow: 0 8px 20px 0 #f2e6e1;
    border-radius: 20px;
  `,
  BenefitsTitle: styled.p`
    margin: 0 0 16px 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  `,
  BonusContainer: styled.div`
    margin: 0 auto 16px;
    padding: 14px;
    border-radius: 20px;
    border: 2px solid #a569bd;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      padding: 16px;
    }
  `,
  ImageContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 16px 0;
  `,
  Image: styled.img`
    width: auto;
    max-height: 136px;
  `,
  BonusLabel: styled.span`
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 12px;
    padding: 2px 4px;
    background-color: ${Color.PRIMARY};
    color: ${Color.WHITE};
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      padding: 4px 8px;
    }
  `,
  BonusTitle: styled.p`
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 8px 0;
  `,
  BonusMessage: styled.p`
    text-align: center;
    color: #898e97;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
  `,
  ListItem: styled.li`
    position: relative;
    padding: 0 5px 0 36px;
    margin: 0 0 15px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url(${optionIconUrl}) center no-repeat;
      background-size: contain;
    }
  `,
}
