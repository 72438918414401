import heartDirection from 'assets/images/heart-direction-min.png'
import stars from 'assets/images/modern-stars-min.png'
import roseFlower from 'assets/images/rose-flower-min.png'

export const RECEIVED_ITEMS = [
  {
    title: 'purchase1.holidayCancelOffer.whatYouGet.title1',
    description: 'purchase1.holidayCancelOffer.whatYouGet.description1',
    icon: roseFlower,
  },
  {
    title: 'purchase1.holidayCancelOffer.whatYouGet.title2',
    description: 'purchase1.holidayCancelOffer.whatYouGet.description2',
    icon: stars,
  },
  {
    title: 'purchase1.holidayCancelOffer.whatYouGet.title3',
    description: 'purchase1.holidayCancelOffer.whatYouGet.description3',
    icon: heartDirection,
  },
]
