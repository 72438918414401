import styled from 'styled-components'

import { baseColumnStyles, basePageStyles } from 'common-styles'

export const StyledUnsubscribe = {
  Wrapper: styled.div`
    ${basePageStyles}
  `,
  Column: styled.div`
    width: 100%;
    max-width: 340px;
    padding: 30px 10px;
    ${baseColumnStyles}
  `,
}
