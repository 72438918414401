import React from 'react'
import { useTranslation } from 'react-i18next'

import { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import RatingImage from 'assets/images/stars.png'

import { StyledUpsellGuashaReviews as S } from './UpsellGuashaReviews.styles'
import { REVIEWS } from './constants'

export const UpsellGuashaReviews: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.CarouselTitle>{t`guashaUpsell.reviews.subtitle`}</S.CarouselTitle>
      <Carousel
        slidesPerView={1}
        loop
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {REVIEWS.map(({ author, review }) => (
          <SwiperSlide key={author}>
            <S.Card>
              <S.CardRating src={RatingImage} alt="" />
              <S.CardReview>{t(review)}</S.CardReview>
              <S.CardReviewer>{t(author)}</S.CardReviewer>
            </S.Card>
          </SwiperSlide>
        ))}
      </Carousel>
    </S.Wrapper>
  )
}
