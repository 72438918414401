import React, { useEffect, useState } from 'react'

type TObserverOptions = {
  rootMargin?: string
  threshold?: number
}

const defaultObserverOptions = {
  rootMargin: '-100px 0px 0px 0px',
  threshold: 0.4,
}

export const useTargetHiddenOnScroll = (
  targetScrollElementRef: React.RefObject<HTMLElement>,
  observerOptions: TObserverOptions = defaultObserverOptions,
): boolean => {
  const [isTargetHidden, setIsTargetHidden] = useState<boolean>(false)

  useEffect(() => {
    const scrollTargetCallback = (entries) => {
      entries.forEach((entry) => {
        const rootTopOffset = entry?.rootBounds?.top
        const targetTopOffset = entry?.boundingClientRect?.top

        const isUpperBorderCrossed = rootTopOffset >= targetTopOffset
        const isTargetInViewport = entry?.isIntersecting

        setIsTargetHidden(!isTargetInViewport && isUpperBorderCrossed)
      })
    }

    const observer = new IntersectionObserver(
      scrollTargetCallback,
      observerOptions,
    )
    const target = targetScrollElementRef.current

    target && observer.observe(target)

    return () => {
      observer.disconnect()
    }
  }, [targetScrollElementRef, observerOptions])

  return isTargetHidden
}
