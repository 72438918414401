import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledHeardAboutYoga as S } from './HeardAboutYoga.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const HeardAboutYoga: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const cohortToUse = useCohortToUse()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, setIsAnswersDisabled, handleContinue],
  )

  return (
    <Container>
      <S.TitleContainer>
        <PageTitle>
          <Trans
            i18nKey="onboarding.heardAbout.question"
            components={[<br />]}
          />
        </PageTitle>
      </S.TitleContainer>

      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.NOT_SURE}
      >
        <AnswerButton>
          <Trans
            i18nKey="onboarding.heardAbout.notSure"
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.KNOW_FEW_THINGS}
      >
        <AnswerButton>
          <Trans
            i18nKey="onboarding.heardAbout.knowFewThings"
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </AnswerButton>
      </Option>
      <Option
        {...optionProps}
        disabled={isAnswersDisabled}
        value={OPTION_VALUES.KNOW_EVERYTHING}
      >
        <AnswerButton>
          <Trans
            i18nKey="onboarding.heardAbout.knowEverything"
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </AnswerButton>
      </Option>
    </Container>
  )
}
