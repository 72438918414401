import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledModal as S } from './Modal.styles'

type TProps = {
  error?: string
  isShown: boolean
  onClose: () => void
  children?: React.ReactNode
}

export const Modal: React.FC<TProps> = ({
  error,
  isShown,
  onClose,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper isShown={isShown}>
      <S.Content>
        {error ? <S.Error>{error}</S.Error> : children}
        <S.Button type="button" onClick={onClose}>
          {t`actions.ok`}
        </S.Button>
      </S.Content>
    </S.Wrapper>
  )
}
