import guashaUser1 from 'assets/images/guasha-user-1.png'
import guashaUser2 from 'assets/images/guasha-user-2.png'
import guashaUser3 from 'assets/images/guasha-user-3.png'
import guashaUser4 from 'assets/images/guasha-user-4.png'

export const UPSELL_GUASHA_USERS = [
  {
    title: 'guashaUpsell.users.user1.title',
    subtitle: 'guashaUpsell.users.user1.subtitle',
    image: guashaUser1,
    bgColor: '#fdf1f2',
    borderColor: 'rgba(255, 130, 118, 0.16)',
  },
  {
    title: 'guashaUpsell.users.user2.title',
    subtitle: 'guashaUpsell.users.user2.subtitle',
    image: guashaUser2,
    bgColor: '#fff9ec',
    borderColor: 'rgba(252, 217, 3, 0.24)',
  },
  {
    title: 'guashaUpsell.users.user3.title',
    subtitle: 'guashaUpsell.users.user3.subtitle',
    image: guashaUser3,
    bgColor: '#f0edff',
    borderColor: 'rgba(165, 105, 189, 0.16)',
  },
  {
    title: 'guashaUpsell.users.user4.title',
    subtitle: 'guashaUpsell.users.user4.subtitle',
    image: guashaUser4,
    bgColor: '#f4f9f1',
    borderColor: 'rgba(205, 218, 116, 0.32)',
  },
]
