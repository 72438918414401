import styled, { css } from 'styled-components'

import { Option } from 'components/Option'

import chevronRightCoral from 'assets/images/chevron-right-coral.png'
import chevronRightWhite from 'assets/images/chevron-right-white.png'
import variant2DesktopBg from 'assets/images/introsale2-intro-desktop.jpg'
import variant2MobileBg from 'assets/images/introsale2-intro-mobile.jpg'
import variant4DesktopBg from 'assets/images/introsale4-intro-desktop.jpg'
import variant4MobileBg from 'assets/images/introsale4-intro-mobile.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

import { IntroSaleStartPageVariant } from './constants'

type TIntroSaleStartPageProps = {
  hasMinimalisticStyles: boolean
}

type TIntroSaleStartRootProps = {
  variant: IntroSaleStartPageVariant
}

type TLocaleProps = {
  isEnLocaleStyles: boolean
}

const INTRO_SALE_BACKGROUND_IMAGES_MAP = {
  [IntroSaleStartPageVariant.VARIANT_B]: {
    desktop: variant2DesktopBg,
    mobile: variant2MobileBg,
    backgroundPosition: 'top 96px center',
  },
  [IntroSaleStartPageVariant.VARIANT_C]: {
    desktop: variant2DesktopBg,
    mobile: variant2MobileBg,
    backgroundPosition: 'top 96px center',
  },
  [IntroSaleStartPageVariant.VARIANT_D]: {
    desktop: variant4DesktopBg,
    mobile: variant4MobileBg,
    backgroundPosition: 'top center',
  },
}

export const StyledIntroSaleAgeAsIntro = {
  Root: styled.div<TIntroSaleStartRootProps>`
    position: relative;
    padding-top: 56px;
    display: flex;
    flex-direction: column;

    ${({ variant }) =>
      variant !== IntroSaleStartPageVariant.DEFAULT &&
      css`
        background-image: url(${INTRO_SALE_BACKGROUND_IMAGES_MAP[variant]
          .mobile});
        background-position: ${INTRO_SALE_BACKGROUND_IMAGES_MAP[variant]
          .backgroundPosition};
      `}

    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      ${({ variant }) =>
        variant !== IntroSaleStartPageVariant.DEFAULT &&
        css`
          background-image: url(${INTRO_SALE_BACKGROUND_IMAGES_MAP[variant]
            .desktop});
          background-position: top center;
        `}
    }

    ${({ variant }) =>
      variant === IntroSaleStartPageVariant.VARIANT_D &&
      css`
        @media (min-aspect-ratio: 8/5) and (min-width: ${MediaBreakpoint.TABLET}px) {
          background-position: center top -80px;
        }
      `}
  `,
  ImageContainer: styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    max-height: calc(var(--full-height) - 446px);
  `,
  Container: styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    min-height: calc(var(--full-height) - 96px);
    padding: 0 12px;
  `,
  Title: styled.h1<TIntroSaleStartPageProps & TLocaleProps>`
    color: ${({ hasMinimalisticStyles }) =>
      hasMinimalisticStyles ? Color.DARK : Color.WHITE};
    text-align: center;
    font-family: Rubik;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin: 0 auto 28px;
    max-width: 300px;

    margin: ${({ isEnLocaleStyles, hasMinimalisticStyles }) =>
      !isEnLocaleStyles && hasMinimalisticStyles && css`0 auto 16px`};

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin: 0 auto 16px;
      max-width: 520px;
    }
  `,
  Subtitle: styled.p<TIntroSaleStartPageProps>`
    color: ${({ hasMinimalisticStyles }) =>
      hasMinimalisticStyles ? Color.LIGHT : Color.WHITE};
    text-align: center;
    font-family: 'Open Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
  GirlImage: styled.img`
    display: none;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      display: block;
      max-height: 331px;
      width: auto;
      object-fit: contain;
    }
  `,
  FlowersImage: styled.img`
    display: block;
    height: auto;
    max-width: 100%;
    object-fit: contain;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      display: none;
    }
  `,
  DescriptionContainer: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 4px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 440px;
      margin: 0 auto 16px auto;
    }
  `,
  Description: styled.div<TLocaleProps>`
    border-radius: 12px;
    background-color: ${Color.WHITE};
    padding: ${({ isEnLocaleStyles }) =>
      isEnLocaleStyles ? '8px' : '8px 16px'};
    box-shadow: 0px 8px 20px 0px rgba(232, 232, 232, 0.6);
    color: ${Color.GRAY};
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Open Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;
    max-height: 56px;

    ${({ isEnLocaleStyles }) =>
      !isEnLocaleStyles &&
      css`
        &:last-of-type {
          font-size: 14px;
        }
      `}

    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      padding: 8px 10px;
      font-size: 14px;
    }
  `,
  Star: styled.img<TLocaleProps>`
    width: 20px;
    height: 20px;
    margin-right: ${({ isEnLocaleStyles }) =>
      isEnLocaleStyles ? '8px' : '16px'};
  `,
  DescriptionSeparator: styled.span<TLocaleProps>`
    display: inline-flex;
    height: 20px;
    width: 1px;
    background-color: ${Color.SILVER_GRAY};
    margin: ${({ isEnLocaleStyles }) =>
      isEnLocaleStyles ? '0 8px' : '0 16px'};
    vertical-align: middle;
  `,
  Actions: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 48px 48px;
    gap: 16px;
    margin-top: 24px;

    & label {
      flex-basis: calc(50% - 8px);
      margin-bottom: 0;
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      width: 360px;
      margin: 16px auto 0;
    }
  `,
  Action: styled.div`
    display: flex;
  `,
  AnswerWrapper: styled.div<TIntroSaleStartPageProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s;
    border-radius: 16px;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    font-weight: 600;
    font-family: 'Open Sans';
    height: 48px;
    width: 100%;
    padding: 12px;

    ${({ hasMinimalisticStyles }) =>
      hasMinimalisticStyles
        ? css`
            color: ${Color.WHITE};
            background-color: ${Color.PRIMARY};
          `
        : css`
            color: ${Color.RED};
            background-color: ${Color.WHITE};
          `}
  `,
  AnswerValue: styled.span``,
  ChevronRight: styled.div<TIntroSaleStartPageProps>`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    background-color: ${({ hasMinimalisticStyles }) =>
      hasMinimalisticStyles ? Color.WHITE : Color.RED};

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      background-image: ${({ hasMinimalisticStyles }) =>
        hasMinimalisticStyles
          ? css`url(${chevronRightCoral})`
          : css`url(${chevronRightWhite})`};
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  `,
  StyledOption: styled(Option)<TIntroSaleStartPageProps>`
    border-radius: 16px;

    input:checked + div > div {
      background: ${Color.RED};

      ${({ hasMinimalisticStyles }) =>
        !hasMinimalisticStyles &&
        css`
          color: ${Color.WHITE};

          & > div {
            background: ${Color.WHITE};

            &::before {
              background-image: url(${chevronRightCoral});
            }
          }
        `}
    }
  `,
  Terms: styled.div<TIntroSaleStartPageProps>`
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: ${({ hasMinimalisticStyles }) =>
      hasMinimalisticStyles ? Color.GRAY_SECONDARY : Color.CORAL_GRAY};
    margin: 24px auto 16px;
    text-align: center;

    a {
      color: ${({ hasMinimalisticStyles }) =>
        hasMinimalisticStyles ? Color.GRAY_SECONDARY : Color.CORAL_GRAY};
      text-decoration: underline;
    }
  `,
  Separator: styled.span<TIntroSaleStartPageProps>`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${({ hasMinimalisticStyles }) =>
      hasMinimalisticStyles ? Color.GRAY_SECONDARY : Color.CORAL_GRAY};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
