import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledOccasionDate = {
  Title: styled.h1`
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    color: ${Color.DARK};
    margin-bottom: 16px;
    text-align: center;
  `,
  Description: styled.p`
    font-size: 17px;
    line-height: 22px;
    color: ${Color.LIGHT};
    margin-bottom: 24px;
    text-align: center;
  `,
  DatePickerContainer: styled.div`
    height: 330px;
  `,
  LinkContainer: styled.div`
    text-align: center;
    margin: 16px auto 8px;
  `,
  Link: styled.a`
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    text-decoration: underline;
    color: ${Color.LIGHT};
    cursor: pointer;
  `,
}
