import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

export const useABTest = () => {
  const isPrimerActive = useFeatureIsOn('lv_27_primer_integration')
  const areAgeHighPricesActive = useFeatureIsOn('lv_242_age_test')
  const isPaypalPrimerButtonActive = useFeatureIsOn('lv_758_primer_paypal_test')
  const isWeeklyProductCardsActive = useFeatureIsOn(
    'lv_362_new_product_cards_test',
  )
  const isStaticBannerActive = useFeatureIsOn('lv_770_static_banner_test')
  const introSaleStartPageVariant = useFeatureValue(
    'lv_816_intro_sale_start_page_test',
    'variant_a',
  )
  const isHairCareGuideActive = useFeatureIsOn('lv_823_hair_guide_test')

  return {
    isPrimerActive,
    areAgeHighPricesActive,
    isPaypalPrimerButtonActive,
    isWeeklyProductCardsActive,
    isStaticBannerActive,
    introSaleStartPageVariant,
    isHairCareGuideActive,
  }
}
