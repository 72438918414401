import styled from 'styled-components'

import { Color } from 'root-constants'

const commonTitleStyles = `
   line-height: 24px;
   letter-spacing: 0.2px;
   color: ${Color.DARK_SECONDARY};
`

export const StyledUpgradeSubscriptionItem = {
  UpgradePlanBlock: styled.div`
    margin: 16px auto 24px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px #d1d6eb;
    border-radius: 20px;
    padding: 16px;
  `,
  PricesContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    &:nth-of-type(2) {
      padding-bottom: 16px;
      border-bottom: 1px solid #e6e9f6;
    }
  `,
  BonusContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: ${Color.PRIMARY};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    > :not(:last-of-type) {
      margin: 0 4px 0 0;
    }
  `,
  BonusTitle: styled.p`
    font-weight: 600;
  `,
  BonusDescription: styled.p`
    max-width: 144px;
    font-size: 12px;
    line-height: 16px;
  `,
  BonusPrice: styled.p`
    align-self: center;
  `,
  Discount: styled.div`
    padding: 4px 16px;
    background: linear-gradient(97.03deg, #70cd1c 3.15%, #a2ea35 88.83%);
    border-radius: 35px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: ${Color.WHITE};
  `,
  PlanTitle: styled.p`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 16px;
  `,
  AdditionalInfo: styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: ${Color.LIGHT};
    padding: 0 10px 0;
  `,
  PriceTitle: styled.p`
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
    ${commonTitleStyles}
  `,
  Plan: styled.p`
    font-weight: 600;
    font-size: 18px;
    ${commonTitleStyles}
  `,
  DiscountPrice: styled.p`
    font-weight: 600;
    font-size: 18px;
    ${commonTitleStyles};
    color: ${Color.ERROR_SECONDARY};
    text-decoration: line-through;
  `,
}
