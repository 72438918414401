import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { EffectCreative, Navigation, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { selectLanguage } from 'root-redux/selects/common'

import { useCohortToUse } from 'hooks/useCohortToUse'

import { CDN_FOLDER_LINK, I18N_CONTEXT_COHORT_MAP } from 'root-constants'

import { StyledLongPaywallAppGallery as S } from './LongPaywallAppGallery.styles'
import { LONG_PAYWALL_APP_SLIDES } from './constants'

export const LongPaywallAppGallery: React.FC = () => {
  const language = useSelector(selectLanguage)
  const cohortToUse = useCohortToUse()

  return (
    <S.Container>
      <S.Subtitle>
        <Trans
          i18nKey="purchase7.appScreensGallerySubtitle"
          context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
        />
      </S.Subtitle>
      <Carousel
        slidesPerView={2}
        loop
        centeredSlides
        modules={[Pagination, Navigation, EffectCreative]}
        navigation
        pagination={{
          clickable: true,
        }}
        effect="creative"
        creativeEffect={{
          prev: {
            scale: 0.8,
            translate: ['-100%', 0, 0],
          },
          next: {
            scale: 0.8,
            translate: ['100%', 0, 0],
          },
        }}
      >
        {LONG_PAYWALL_APP_SLIDES.map(({ id, imagePath }) => (
          <SwiperSlide key={id}>
            <S.ImageWrapper>
              <S.SlideImage
                src={`${CDN_FOLDER_LINK}${imagePath}_${language}.png`}
                alt="phone-app-image"
              />
            </S.ImageWrapper>
          </SwiperSlide>
        ))}
      </Carousel>
    </S.Container>
  )
}
