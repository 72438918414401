import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { Cohort } from 'root-constants'

export const useCohortToUse = () => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)

  return useMemo(
    () => (parentCohort || cohort) as Cohort,
    [cohort, parentCohort],
  )
}
