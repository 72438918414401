import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import dayjs from 'dayjs'

import { Spinner } from 'components/Spinner'

import {
  getCustomerInfoAction,
  getUserSubscriptionsInfoAction,
  setAuthTokenAction,
} from 'root-redux/actions/user'
import {
  selectUserContactEmail,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { Color } from 'root-constants'

import { HowToUnsubscribe } from '../components'
import { StyledSubscriptionDetails as S } from './SubscriptionDetails.styles'

export const SubscriptionListDetails: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch: TAppDispatch = useDispatch()
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)
  const userContactEmail = useSelector(selectUserContactEmail)

  const isOnTrial = useMemo(
    () => dayjs().isBefore(userSubscriptionInfo?.trialEndDate, 'day'),
    [userSubscriptionInfo],
  )

  const getAndSaveAuthToken = useCallback(() => {
    const query = new URLSearchParams(search)
    const authToken = query.get('token')

    if (authToken) {
      dispatch(setAuthTokenAction(authToken))
    }
  }, [dispatch, search])

  useEffect(() => {
    getAndSaveAuthToken()
    dispatch(getCustomerInfoAction())
    dispatch(getUserSubscriptionsInfoAction())
  }, [dispatch, getAndSaveAuthToken])

  return !userSubscriptionInfo || !userContactEmail ? (
    <Spinner />
  ) : (
    <>
      <S.Title>{t`unsubscribe.subscriptionDetails.title`}</S.Title>
      <S.Text>
        {t`unsubscribe.subscriptionDetails.title`}
        <br /> ({userContactEmail})
      </S.Text>
      <S.PlanInfo>
        <S.PlanTitle>{t`unsubscribe.subscriptionDetails.subscriptionStatus`}</S.PlanTitle>
        <S.PlanStatus
          color={
            userSubscriptionInfo.isActiveOrTrial ? Color.SUCCESS : Color.PRIMARY
          }
        >
          {userSubscriptionInfo.status}
        </S.PlanStatus>

        <S.PlanList>
          <S.PlanItem>
            <dt>{t`unsubscribe.subscriptionDetails.startDate`}</dt>
            <dd>
              {isOnTrial
                ? userSubscriptionInfo.trialStartDate
                : userSubscriptionInfo.startDate}
            </dd>
          </S.PlanItem>

          <S.PlanItem>
            <dt>{t`unsubscribe.subscriptionDetails.billingCycle`}</dt>
            <dd>
              {isOnTrial && userSubscriptionInfo.trialBillingCycle
                ? userSubscriptionInfo.trialBillingCycle
                : userSubscriptionInfo.billingCycle}
            </dd>
          </S.PlanItem>

          {userSubscriptionInfo.isSubscriptionEndVisible && (
            <S.PlanItem>
              <dt>{t`unsubscribe.subscriptionDetails.endDate`}</dt>
              <dd>
                {isOnTrial
                  ? userSubscriptionInfo.trialEndDate
                  : userSubscriptionInfo.endDate}
              </dd>
            </S.PlanItem>
          )}
        </S.PlanList>
        {userSubscriptionInfo.isActiveOrTrial && (
          <S.UnsubscribeLinkContainer>
            <HowToUnsubscribe
              subscriptionId={userSubscriptionInfo.subscriptionId}
            />
          </S.UnsubscribeLinkContainer>
        )}
      </S.PlanInfo>
    </>
  )
}
