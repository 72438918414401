import styled from 'styled-components'

import { Color } from 'root-constants'

type TGuashaImageWrapperProps = {
  image: string
}

export const StyledGuashaOrderSummary = {
  Title: styled.h2`
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.DARK};
    text-align: center;
  `,
  GuashaProductWrapper: styled.div`
    display: flex;
    align-items: center;
    border-radius: 14px;
    padding: 8px 16px 8px 6px;
    border: 1px solid ${Color.PRIMARY};
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.DARK};
    margin-bottom: 16px;
  `,
  GuashaImageWrapper: styled.div<TGuashaImageWrapperProps>`
    position: relative;
    width: 100%;
    border-radius: 8px;
    width: 70px;
    height: 70px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px 70px;
    background-image: url(${({ image }) => image});

    &::after {
      content: '1';
      position: absolute;
      width: 20px;
      height: 20px;
      top: -9px;
      right: -9px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${Color.WHITE};
      background-color: ${Color.PRIMARY};
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 1.5px;
    }
  `,
  GuashaProductText: styled.p`
    flex: 1;
    margin: 0 16px;
  `,
  GuashaPrice: styled.p``,
  SubtotalWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-style: normal;
    line-height: 18px;
    color: ${Color.DARK};
    margin-bottom: 12px;
  `,
  SubtotalLabel: styled.span``,
  SubtotalValue: styled.span`
    font-weight: 600;
  `,
  TotalWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  `,
  TotalAmount: styled.div``,
  TotalText: styled.span`
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
  `,
  Currency: styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
  ShippingWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.DARK};
    margin-bottom: 12px;
  `,
  ShippingText: styled.span``,
}
