import React from 'react'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { StyledCompositeAnswerButton as S } from './CompositeAnswerButton.styles'

type TProps = {
  children?: React.ReactNode
  title: string
}

export const CompositeAnswerButton: React.FC<TProps> = ({
  children,
  title,
}) => {
  const { isSeniorCohort } = useActiveCohortIdentifier()

  return (
    <S.Root isLargeSize={isSeniorCohort}>
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.Text>{children}</S.Text>
      </S.Content>
    </S.Root>
  )
}
