import React from 'react'
import { useTranslation } from 'react-i18next'

import { Option } from 'components/Option'

import { UserAnswer } from 'root-constants'

import { StyledAnalyzingAnswersModal as S } from './AnalyzingAnswersModal.styles'

type TAnalyzingAnswersModal = {
  isModalVisible: boolean
  disabled?: boolean
  onChange?: (event?: any) => void
  answers?: string[]
  setAnswers?: () => void
  children?: React.ReactNode
}

export const AnalyzingAnswersModal: React.FC<TAnalyzingAnswersModal> = ({
  isModalVisible,
  disabled,
  onChange,
  children,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <S.Modal isVisible={isModalVisible}>
      <S.ModalContent>
        <S.ModalTitle>{children}</S.ModalTitle>
        <Option
          value={UserAnswer.YES}
          {...rest}
          disabled={disabled}
          onChange={() => onChange && onChange(UserAnswer.YES)}
        >
          <S.ModalButton disabled={disabled}>
            {t`onboarding.analyzingAnswers.yes`}
          </S.ModalButton>
        </Option>
        <Option
          value={UserAnswer.NO}
          {...rest}
          disabled={disabled}
          onChange={() => onChange && onChange(UserAnswer.NO)}
        >
          <S.ModalButton disabled={disabled}>
            {t`onboarding.analyzingAnswers.no`}
          </S.ModalButton>
        </Option>
      </S.ModalContent>
    </S.Modal>
  )
}
