import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import 'root-redux/actions/user'
import { selectGoogleAnalyticsId } from 'root-redux/selects/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import {
  CookieConsentOption,
  EU_COUNTRY_CODES,
  GAConsent,
} from 'root-constants'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export const useGoogleAnalytics = (): void => {
  const googleAnalyticsId = useSelector(selectGoogleAnalyticsId)
  const userCountryCode = useSelector(selectUserCountryCode)

  const {
    isEUUser,
    hasAnalyticsAndPerformanceCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const isAnalyticsStorageAllowed = useMemo(() => {
    return hasAnalyticsAndPerformanceCookie
  }, [hasAnalyticsAndPerformanceCookie])

  const shouldGABePaused = useMemo(() => {
    return (
      (isEUUser && !userCookieConsentAnswer?.length) ||
      (isEUUser &&
        !!userCookieConsentAnswer?.length &&
        !isAnalyticsStorageAllowed)
    )
  }, [isEUUser, userCookieConsentAnswer, isAnalyticsStorageAllowed])

  useEffect(() => {
    if (!userCountryCode || shouldGABePaused) return

    if (googleAnalyticsId) {
      const initGtag = () => {
        const script = document.createElement('script')
        script.text = `window.dataLayer = window.dataLayer || [];
        gtag = function () { dataLayer.push(arguments); }
        gtag('js', new Date());`
        script.async = true
        document.head.appendChild(script)
      }

      const setUpGTM = () => {
        const script = document.createElement('script')
        script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
        script.async = true
        document.head.appendChild(script)
      }

      initGtag()
      setUpGTM()

      !!window.gtag &&
        window.gtag('consent', 'default', {
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          analytics_storage: 'granted',
          region: EU_COUNTRY_CODES,
        })

      if (userCookieConsentAnswer?.length) {
        window.gtag &&
          window.gtag('consent', 'update', {
            ad_user_data: userCookieConsentAnswer.includes(
              CookieConsentOption.ADVERTIZING_AND_TARGETING,
            )
              ? GAConsent.GRANTED
              : GAConsent.DENIED,
            ad_personalization:
              userCookieConsentAnswer.includes(
                CookieConsentOption.ADVERTIZING_AND_TARGETING,
              ) &&
              userCookieConsentAnswer.includes(
                CookieConsentOption.FUNCTIONAL_COOKIES,
              )
                ? GAConsent.GRANTED
                : GAConsent.DENIED,
            ad_storage:
              userCookieConsentAnswer.includes(
                CookieConsentOption.ADVERTIZING_AND_TARGETING,
              ) &&
              userCookieConsentAnswer.includes(
                CookieConsentOption.FUNCTIONAL_COOKIES,
              )
                ? GAConsent.GRANTED
                : GAConsent.DENIED,
            region: EU_COUNTRY_CODES,
          })
      }

      !!window.gtag &&
        window.gtag('config', googleAnalyticsId, {
          send_page_view: false,
        })
    }
  }, [
    googleAnalyticsId,
    userCountryCode,
    shouldGABePaused,
    userCookieConsentAnswer,
  ])
}
