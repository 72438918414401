import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { PageId } from 'page-constants'
import { UserGoal } from 'root-constants'

import { StyledLuvlyBenefits as S } from './LuvlyBenefitsV2.styles'

export const LuvlyBenefitsV2: React.FC = () => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)

  const isWrinkleFreeSkinSelected = useMemo(() => {
    const goal = userAnswers?.[PageId.INTRO]

    return !goal || goal === UserGoal.WRINKLE_FREE_SKIN
  }, [userAnswers])

  return (
    <S.Wrapper>
      <S.Title>{t`purchase1.luvlyBenefits.title`}</S.Title>
      <S.List>
        <S.ListItem>
          {isWrinkleFreeSkinSelected
            ? t`purchase1.luvlyBenefits.wrinkleRemover`
            : t`purchase1.luvlyBenefits.fatRemover`}
        </S.ListItem>
        <S.ListItem>{t`purchase2.luvlyBenefits.preventAging`}</S.ListItem>
        <S.ListItem>{t`purchase2.luvlyBenefits.supportYourSuccess`}</S.ListItem>
        <S.ListItem>{t`purchase2.luvlyBenefits.betterHealth`}</S.ListItem>
        <S.ListItem>{t`purchase1.luvlyBenefits.personalInsights`}</S.ListItem>
        <S.ListItem>{t`purchase1.luvlyBenefits.moreConfident`}</S.ListItem>
      </S.List>
    </S.Wrapper>
  )
}
