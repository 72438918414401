export const DEFAULT_REFUND_CUSTOMER_REVIEWS = [
  {
    name: 'purchaseRefund.reviews.firstReviewer.name',
    review: 'purchaseRefund.reviews.firstReviewer.review',
  },
  {
    name: 'purchaseRefund.reviews.secondReviewer.name',
    review: 'purchaseRefund.reviews.secondReviewer.review',
  },
  {
    name: 'purchaseRefund.reviews.thirdReviewer.name',
    review: 'purchaseRefund.reviews.thirdReviewer.review',
  },
]
