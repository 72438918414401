import styled, { css, keyframes } from 'styled-components'

import { Color } from 'root-constants'

const blink = keyframes`
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
`

const fadeIn = keyframes` 
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `

export const baseStyles = css`
  font-family: Rubik;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  color: ${Color.GRAY_SECONDARY};
`

export const StyledTyping = {
  Text: styled.h1`
    ${baseStyles};
    animation-name: ${fadeIn};
    animation-duration: 0.4s;
  `,
  Dot: styled.span`
    animation-name: ${blink};
    animation-duration: 1s;
    animation-iteration-count: infinite;

    &:nth-child(2) {
      animation-delay: 0.15s;
    }
    &:nth-child(3) {
      animation-delay: 0.3s;
    }
  `,
}
