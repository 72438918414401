import { IRequestOptions, IResponseResult } from 'models/api.model'
import { IRawSubscription } from 'models/subscriptions.model'

import { ApiService } from 'services/api.service'

import { APP_NAME, RequestMethod, SubscriptionListType } from 'root-constants'

export class SubscriptionsApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getSubscriptionList({
    cohort,
    type,
    uuid,
    tags = '',
  }: {
    cohort: string
    type: SubscriptionListType
    uuid: string
    tags?: string
  }): Promise<IResponseResult<{ plans: IRawSubscription }>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { plan_type: type, uuid, tags },
    }

    return this.api.makeRequest<{ plans: IRawSubscription }>(
      `v2/plans/${APP_NAME}/${cohort}`,
      options,
    )
  }
}
