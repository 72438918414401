import { createProductId } from 'helpers/createProductId'

import { ISubscription } from 'models/subscriptions.model'

import { ScreenName, TimeInterval } from 'root-constants'

export const enum GoogleAnalyticsAction {
  EVENT = 'event',
}

export const enum GoogleAnalyticsEvent {
  PAGE_VIEW = 'page_view',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_TO_CART = 'add_to_cart',
  PURCHASE = 'purchase',
  FIRST_PAGE_COMPLETED = 'first_page_completed',
  ACCOUNT_CREATED = 'account_created',
  DOWNLOAD_BUTTON_PRESSED = 'download_button_press',
  LIVE_CHAT_OPENED = 'live_chat_opened',
  LIVE_CHAT_CLOSED = 'live_chat_closed',
}

export const enum GoogleAnalyticsEventCategory {
  TRANSITION = 'transition',
}

class GoogleAnalyticsService {
  logPageView(location: string): void {
    !!window.gtag &&
      window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.PAGE_VIEW, {
        page_path: `/${location}`,
      })
  }

  logCheckoutStarted(subscriptions: ISubscription[]): void {
    !!window.gtag &&
      window.gtag(
        GoogleAnalyticsAction.EVENT,
        GoogleAnalyticsEvent.BEGIN_CHECKOUT,
        {
          items: subscriptions.map(({ mainPrices }) => {
            const { fullPrice, periodName, periodQuantity } = mainPrices

            return {
              id: createProductId({
                periodName,
                periodQuantity,
                price: fullPrice,
              }),
              price: fullPrice,
            }
          }),
        },
      )
  }

  logAddingToCart({ mainPrices }: ISubscription): void {
    const { fullPrice, periodName, periodQuantity } = mainPrices

    !!window.gtag &&
      window.gtag(
        GoogleAnalyticsAction.EVENT,
        GoogleAnalyticsEvent.ADD_TO_CART,
        {
          items: [
            {
              id: createProductId({
                periodName,
                periodQuantity,
                price: fullPrice,
              }),
              price: fullPrice,
            },
          ],
        },
      )
  }

  logPurchaseCompleted({
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency,
    screenName,
  }: {
    subscriptionId: string
    price: number
    periodName: TimeInterval | null
    periodQuantity: number | null
    currency: string
    screenName: ScreenName
  }): void {
    !!window.gtag &&
      window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.PURCHASE, {
        event_label: screenName,
        transaction_id: subscriptionId,
        value: price,
        currency,
        items: [
          {
            id: createProductId({ periodName, periodQuantity, price }),
            price,
          },
        ],
      })
  }

  logFirstPageCompleted(): void {
    !!window.gtag &&
      window.gtag(
        GoogleAnalyticsAction.EVENT,
        GoogleAnalyticsEvent.FIRST_PAGE_COMPLETED,
        {
          event_category: GoogleAnalyticsEventCategory.TRANSITION,
        },
      )
  }

  logAccountCreated(): void {
    !!window.gtag &&
      window.gtag(
        GoogleAnalyticsAction.EVENT,
        GoogleAnalyticsEvent.ACCOUNT_CREATED,
      )
  }

  logDownloadButtonPressed(): void {
    !!window.gtag &&
      window.gtag(
        GoogleAnalyticsAction.EVENT,
        GoogleAnalyticsEvent.DOWNLOAD_BUTTON_PRESSED,
      )
  }

  logLiveChatOpened(): void {
    !!window.gtag &&
      window.gtag(
        GoogleAnalyticsAction.EVENT,
        GoogleAnalyticsEvent.LIVE_CHAT_OPENED,
      )
  }

  logLiveChatClosed(): void {
    !!window.gtag &&
      window.gtag(
        GoogleAnalyticsAction.EVENT,
        GoogleAnalyticsEvent.LIVE_CHAT_CLOSED,
      )
  }
}

export const googleAnalyticsLogger = new GoogleAnalyticsService()
