import styled, { css } from 'styled-components'

import hintIcon from 'assets/images/hint-question-icon.svg'

import { Color } from 'root-constants'

type TInputProps = {
  isValid?: boolean
  hasValue?: boolean
}

export const StyledInputWithHint = {
  Wrapper: styled.div`
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 12px;
  `,
  Input: styled.input<TInputProps>`
    display: block;
    width: 100%;
    height: 54px;
    padding: 20px 20px 6px;
    color: ${Color.DARK};
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    border-radius: 10px;
    box-shadow: ${({ isValid }) => (isValid ? '0 8px 20px 0 #dfe3f3' : 'none')};
    border: ${({ isValid }) =>
      isValid ? '2px solid transparent' : `1px solid ${Color.ERROR_SECONDARY}`};
    background-color: ${Color.WHITE};
    outline: none;
    transition: border 0.2s ease-out;

    &:disabled {
      color: ${Color.DARK};
      -webkit-text-fill-color: ${Color.DARK};
      opacity: 1;
      -webkit-opacity: 1;
    }

    ${({ hasValue }) =>
      hasValue &&
      css`
        & + span {
          top: 6px;
        }
      `}

    &:focus {
      & + span {
        top: 6px;
      }
    }
  `,
  Label: styled.span`
    position: absolute;
    top: 17px;
    left: 20px;
    color: ${Color.DARK};
    font-size: 13px;
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
  HintButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 52px;
    height: 54px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 20px 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${hintIcon});
  `,
  Hint: styled.div`
    position: absolute;
    top: 10px;
    right: -6px;
    padding: 12px 8px;
    border-radius: 6px;
    background-color: ${Color.DARK};
    color: ${Color.WHITE};
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    transform: translateY(-100%);
    min-width: 146px;
    max-height: 70px;
    width: min-content;

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      bottom: -6px;
      right: 27px;
      background-color: ${Color.DARK};
      clip-path: polygon(75% 0, 100% 100%, 0 75%);
      border-radius: 6px;
      transform: rotate(45deg);
    }
  `,
  ValidationText: styled.p`
    margin-top: 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    color: ${Color.ERROR_SECONDARY};
  `,
}
