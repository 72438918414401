import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import {
  selectUpgradeSubscriptionFullPrice,
  selectUpgradeSubscriptionPrice,
} from 'modules/purchase/redux/selects/common'

import { CENTS_IN_DOLLAR, TimeInterval } from 'root-constants'

import { StyledPurchaseUpgradeShortDisclaimer as S } from './PurchaseUpgradeShortDisclaimer.styles'
import {
  SHORT_DISCLAIMERS,
  SHORT_YEARLY_SUBSCRIPTIONS_DISCLAIMERS,
} from './constants'

export const PurchaseUpgradeShortDisclaimer: React.FC = () => {
  const amountToPay = useSelector(selectUpgradeSubscriptionPrice)
  const fullPrice = useSelector(selectUpgradeSubscriptionFullPrice)

  const { currency, periodQuantity, periodName } = usePurchaseStore()

  return (
    <S.DisclaimerText>
      <Trans
        i18nKey={
          periodName === TimeInterval.YEAR
            ? SHORT_YEARLY_SUBSCRIPTIONS_DISCLAIMERS[Number(periodQuantity)]
            : SHORT_DISCLAIMERS[Number(periodQuantity)]
        }
        values={{
          amountToPay: amountToPay / CENTS_IN_DOLLAR,
          fullPrice,
          currency: CURRENCY_SYMBOLS[currency],
        }}
        context={currency}
      />{' '}
      <TermsOfUseLink />.
    </S.DisclaimerText>
  )
}
