import styled from 'styled-components'

export const StyledMenstrualCycle = {
  TitleContainer: styled.header`
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
  `,
  OptionsContainer: styled.div``,
}
