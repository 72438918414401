import React from 'react'
import { useSelector } from 'react-redux'

import { Option } from 'components/Option'

import { CancelOfferSubscriptionItem } from 'modules/purchase/components/CancelOfferSubscriptionItem'
import { selectSubscription } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { OptionType } from 'root-constants'

import { StyledCancelOfferSubscriptionBlock as S } from './CancelOfferSubscriptionBlock.styles'

export const CancelOfferSubscriptionBlock: React.FC = () => {
  const subscription = useSelector(selectSubscription)

  return (
    subscription && (
      <S.Wrapper>
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription?.id}
          key={subscription?.id}
          withoutMargin
        >
          <CancelOfferSubscriptionItem
            subscription={subscription as ISubscription}
          />
        </Option>
      </S.Wrapper>
    )
  )
}
