import styled from 'styled-components'

import {
  StickyButtonContainer as ButtonContainer,
  customBackgroundStyles,
} from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants'

export const StyledLuvlyUsers = {
  Root: styled.div`
    padding-top: 25px;
    position: relative;

    ${customBackgroundStyles}
  `,
  Title: styled.header`
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    margin-bottom: 24px;
  `,
  Description: styled.div`
    margin-bottom: 64px;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: ${Color.DARK};
    text-align: center;
    text-wrap: pretty;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 360/288;
    margin-bottom: 43px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto 43px;
    }
  `,
  Image: styled.img`
    max-width: 100%;
    height: auto;
  `,
  StickyButtonContainer: styled(ButtonContainer)`
    max-width: 320px;
    margin: 0 auto;
  `,
}
