import { PaymentSystem, TCardPaymentSystem } from 'modules/purchase/constants'

import { IUserSubscriptionsInfo } from 'models/commonApi.model'
import { IAppState } from 'models/store.model'
import { IUserStatus } from 'models/user.model'

import { PageId, UPSELL_PAGES_MAP } from 'page-constants'
import { Cohort, LoginMethod } from 'root-constants'

export const selectUUID = (state: IAppState): string => state.user.uuid
export const selectUserStatus = (state: IAppState): IUserStatus | null =>
  state.user.status
export const selectUserOnboardingEmail = (state: IAppState): string =>
  state.user.status?.email?.onboardingEmail || ''
export const selectIsOnboardingSkipped = (state: IAppState): boolean =>
  state.user.status?.isOnboardingSkipped || false
export const selectUserAccountLoginMethod = (
  state: IAppState,
): LoginMethod | null => state.user.status?.account?.loginMethod || null
export const selectUserCountryCode = (state: IAppState): string =>
  state.user.status?.countryCode || ''
export const selectUserPaymentCurrency = (state: IAppState): string =>
  state.user.status?.config.paymentCurrency || ''
export const selectIsUpsellAvailable = (state: IAppState): boolean =>
  state.user.status?.upsell.isUpsellAvailable || false
export const selectIsInAppAvailable = (state: IAppState): boolean =>
  state.user.status?.inapp.isInAppAvailable || false
export const selectUserPaymentMethod = (state: IAppState): string =>
  state.user.status?.config.paymentMethod || ''
export const selectUserPaymentSystem = (state: IAppState): TCardPaymentSystem =>
  state.user.status?.config.paymentSystem || PaymentSystem.STRIPE
export const selectUserConfigData = (
  state: IAppState,
):
  | Record<
      string,
      string | number | boolean | string[] | Record<string, string>
    >
  | undefined => state.user.status?.config
export const selectGeneralSubscriptionPrice = (state: IAppState): string =>
  state.user.status?.config.subscriptionPrice || ''
export const selectGeneralSubscriptionDuration = (state: IAppState): string =>
  state.user.status?.config.subscriptionDuration || ''
export const selectGeneralSubscriptionPriceId = (state: IAppState): string =>
  state.user.status?.config.priceId || ''
export const selectGeneralSubscriptionTrialPeriod = (
  state: IAppState,
): string => state.user.status?.config.trialPeriod || ''
export const selectGeneralSubscriptionTrialPrice = (state: IAppState): string =>
  state.user.status?.config.trialPrice || ''
export const selectUpsellProduct = (state: IAppState): string | undefined =>
  state.user.status?.config.upsellProductName
export const selectUpsellGuide = (state: IAppState): string | undefined =>
  state.user.status?.config.upsellGuide
export const selectFailedInAppProduct = (
  state: IAppState,
): string | undefined => state.user.status?.config.failedInAppProductName
export const selectIsDownloadVisited = (state: IAppState): boolean =>
  state.user.status?.config.isDownloadVisited || false
export const selectIsDiscountApplied = (state: IAppState): boolean =>
  state.user.status?.config.isDiscountApplied || false
export const selectActiveUpsellPages = (state: IAppState): PageId[] =>
  state.user.status?.config.dynamicUpsellPages ||
  UPSELL_PAGES_MAP[
    state.common.currentVariant?.parentCohort || // Order is important here
      state.common.currentVariant?.cohort ||
      Cohort.QUIZ_2
  ]
export const selectAuthToken = (state: IAppState): string =>
  state.user.authToken
export const selectUserSubscriptionInfo = (
  state: IAppState,
): IUserSubscriptionsInfo | null => state.user.userSubscriptionsInfo
export const selectUserContactEmail = (state: IAppState): string =>
  state.user.userContactEmail
export const selectDeletedSubscriptionId = (state: IAppState): string =>
  state.user.deletedSubscriptionId
export const selectTestEnvironmentQueryParam = (state: IAppState): string =>
  state.user.testEnvironmentQueryParam
export const selectIsPersonalDataAllowed = (state: IAppState): boolean =>
  state.user.isPersonalDataAllowed || false
