import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Review } from 'components/CustomerReviews/Review'
import {
  DEFAULT_CUSTOMER_REVIEWS,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS,
} from 'components/CustomerReviews/constants'

import { StyledCustomerReviews as S } from './CustomerReviews.styles'

export type TReview = {
  name: string
  icon: string
  review: string
  emoji?: string[]
}

type TCustomerReviewsProps = {
  customerReviews?: TReview[]
  viewMoreCustomerReviews?: TReview[]
  className?: string
}

export const CustomerReviews: React.FC<TCustomerReviewsProps> = ({
  customerReviews = DEFAULT_CUSTOMER_REVIEWS,
  viewMoreCustomerReviews = DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS,
  className = '',
}) => {
  const { t } = useTranslation()
  const [areAdditionalReviewsShown, setAreAdditionalReviewsShown] =
    useState(false)

  const handleViewMoreClick = useCallback(
    () => setAreAdditionalReviewsShown(true),
    [],
  )

  return (
    <>
      <S.TitleContainer>
        <S.Title>{t`onboarding.shortProgramDescription.customerReviewsTitle`}</S.Title>
      </S.TitleContainer>
      {customerReviews.map((review) => (
        <Review
          key={review.name}
          icon={review.icon}
          name={review.name}
          review={review.review}
          emoji={review.emoji}
        />
      ))}
      {!areAdditionalReviewsShown ? (
        <S.ViewMoreLinkContainer className={className}>
          <S.ViewMoreLink onClick={handleViewMoreClick}>
            {t`onboarding.shortProgramDescription.viewMoreLink`}
          </S.ViewMoreLink>
        </S.ViewMoreLinkContainer>
      ) : (
        <>
          {viewMoreCustomerReviews.map((review) => (
            <Review
              key={review.name}
              icon={review.icon}
              name={review.name}
              review={review.review}
              emoji={review.emoji}
            />
          ))}
        </>
      )}
    </>
  )
}
