import React, { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goBack, goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { OptionType, UserAnswer } from 'root-constants'

import { StyledSkinDiagnosisIntro as S } from './SkinDiagnosisIntro.styles'
import { QUESTION } from './constants'

export const SkinDiagnosisIntro: FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const [answer, setAnswer] = useState<string>('')

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    if (answer === UserAnswer.YES) {
      handleContinue(answer)
      return
    }

    eventLogger.logQuestion({
      question: QUESTION,
      answers: answer,
    })
    goTo({ pathname: PageId.MOTIVATION, search })
  }, [answer, handleContinue, search])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setAnswer(value)
      },
    }),
    [pageId],
  )

  return (
    <>
      <Container>
        <S.Title>{t('skinDiagnosis.title')}</S.Title>
        <S.Description>{t('skinDiagnosis.description')}</S.Description>
        <Option {...optionProps} value={UserAnswer.YES}>
          <AnswerButton withCheckbox>
            {t('skinDiagnosis.yesAnswer')}
          </AnswerButton>
        </Option>
        <Option {...optionProps} value={UserAnswer.NO}>
          <AnswerButton withCheckbox>
            {t('skinDiagnosis.noAnswer')}
          </AnswerButton>
        </Option>
      </Container>
      <NavigationButtons
        onBackClick={goBack}
        onNextClick={handleNextClick}
        disabled={!answer}
      />
    </>
  )
}
