export const getStateByCode = (zipString: string): string | null => {
  const zipcode = parseInt(zipString, 10)

  /* Code cases alphabetized by state */
  if (zipcode >= 35000 && zipcode <= 36999) {
    return 'AL'
  }
  if (zipcode >= 99500 && zipcode <= 99999) {
    return 'AK'
  }
  if (zipcode >= 85000 && zipcode <= 86999) {
    return 'AZ'
  }
  if (zipcode >= 71600 && zipcode <= 72999) {
    return 'AR'
  }
  if (zipcode >= 90000 && zipcode <= 96699) {
    return 'CA'
  }
  if (zipcode >= 80000 && zipcode <= 81999) {
    return 'CO'
  }
  if (
    (zipcode >= 6000 && zipcode <= 6389) ||
    (zipcode >= 6391 && zipcode <= 6999)
  ) {
    return 'CT'
  }
  if (zipcode >= 19700 && zipcode <= 19999) {
    return 'DE'
  }
  if (zipcode >= 32000 && zipcode <= 34999) {
    return 'FL'
  }
  if (
    (zipcode >= 30000 && zipcode <= 31999) ||
    (zipcode >= 39800 && zipcode <= 39999)
  ) {
    return 'GA'
  }
  if (zipcode >= 96700 && zipcode <= 96999) {
    return 'HI'
  }
  if (zipcode >= 83200 && zipcode <= 83999 && zipcode !== 83414) {
    return 'ID'
  }
  if (zipcode >= 60000 && zipcode <= 62999) {
    return 'IL'
  }
  if (zipcode >= 46000 && zipcode <= 47999) {
    return 'IN'
  }
  if (zipcode >= 50000 && zipcode <= 52999) {
    return 'IA'
  }
  if (zipcode >= 66000 && zipcode <= 67999) {
    return 'KS'
  }
  if (zipcode >= 40000 && zipcode <= 42999) {
    return 'KY'
  }
  if (zipcode >= 70000 && zipcode <= 71599) {
    return 'LA'
  }
  if (zipcode >= 3900 && zipcode <= 4999) {
    return 'ME'
  }
  if (zipcode >= 20600 && zipcode <= 21999) {
    return 'MD'
  }
  if (
    (zipcode >= 1000 && zipcode <= 2799) ||
    zipcode === 5501 ||
    zipcode === 5544
  ) {
    return 'MA'
  }
  if (zipcode >= 48000 && zipcode <= 49999) {
    return 'MI'
  }
  if (zipcode >= 55000 && zipcode <= 56899) {
    return 'MN'
  }
  if (zipcode >= 38600 && zipcode <= 39999) {
    return 'MS'
  }
  if (zipcode >= 63000 && zipcode <= 65999) {
    return 'MO'
  }
  if (zipcode >= 59000 && zipcode <= 59999) {
    return 'MT'
  }
  if (zipcode >= 27000 && zipcode <= 28999) {
    return 'NC'
  }
  if (zipcode >= 58000 && zipcode <= 58999) {
    return 'ND'
  }
  if (zipcode >= 68000 && zipcode <= 69999) {
    return 'NE'
  }
  if (zipcode >= 88900 && zipcode <= 89999) {
    return 'NV'
  }
  if (zipcode >= 3000 && zipcode <= 3899) {
    return 'NH'
  }
  if (zipcode >= 7000 && zipcode <= 8999) {
    return 'NJ'
  }
  if (zipcode >= 87000 && zipcode <= 88499) {
    return 'NM'
  }
  if (
    (zipcode >= 10000 && zipcode <= 14999) ||
    zipcode === 6390 ||
    zipcode === 501 ||
    zipcode === 544
  ) {
    return 'NY'
  }
  if (zipcode >= 43000 && zipcode <= 45999) {
    return 'OH'
  }
  if (
    (zipcode >= 73000 && zipcode <= 73199) ||
    (zipcode >= 73400 && zipcode <= 74999)
  ) {
    return 'OK'
  }
  if (zipcode >= 97000 && zipcode <= 97999) {
    return 'OR'
  }
  if (zipcode >= 15000 && zipcode <= 19699) {
    return 'PA'
  }
  if (zipcode >= 300 && zipcode <= 999) {
    return 'PR'
  }
  if (zipcode >= 2800 && zipcode <= 2999) {
    return 'RI'
  }
  if (zipcode >= 29000 && zipcode <= 29999) {
    return 'SC'
  }
  if (zipcode >= 57000 && zipcode <= 57999) {
    return 'SD'
  }
  if (zipcode >= 37000 && zipcode <= 38599) {
    return 'TN'
  }
  if (
    (zipcode >= 75000 && zipcode <= 79999) ||
    (zipcode >= 73301 && zipcode <= 73399) ||
    (zipcode >= 88500 && zipcode <= 88599)
  ) {
    return 'TX'
  }
  if (zipcode >= 84000 && zipcode <= 84999) {
    return 'UT'
  }
  if (zipcode >= 5000 && zipcode <= 5999) {
    return 'VT'
  }
  if (
    (zipcode >= 20100 && zipcode <= 20199) ||
    (zipcode >= 22000 && zipcode <= 24699) ||
    zipcode === 20598
  ) {
    return 'VA'
  }
  if (
    (zipcode >= 20000 && zipcode <= 20099) ||
    (zipcode >= 20200 && zipcode <= 20599) ||
    (zipcode >= 56900 && zipcode <= 56999)
  ) {
    return 'DC'
  }
  if (zipcode >= 98000 && zipcode <= 99499) {
    return 'WA'
  }
  if (zipcode >= 24700 && zipcode <= 26999) {
    return 'WV'
  }
  if (zipcode >= 53000 && zipcode <= 54999) {
    return 'WI'
  }
  if ((zipcode >= 82000 && zipcode <= 83199) || zipcode === 83414) {
    return 'WY'
  }

  return null
}
