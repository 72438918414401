import { UserAge } from 'root-constants'

export const SENIOR_AGE_OPTIONS = [
  {
    value: UserAge.FOURTY,
    label: '35-44',
  },
  {
    value: UserAge.FIFTY,
    label: '45-54',
  },
  {
    value: UserAge.SIXTY,
    label: '55-64',
  },
  {
    value: UserAge.SEVENTY,
    label: '65+',
  },
]

export const SKIN_TYPE_OPTIONS = [
  { value: 'normal', label: 'onboarding.skinType.normal' },
  { value: 'combination', label: 'onboarding.skinType.combination' },
  { value: 'dry', label: 'onboarding.skinType.dry' },
  { value: 'oily', label: 'onboarding.skinType.oily' },
]

export const MIN_NAME_LENGTH = 1

export const CHAT_TARGET_AREA_OPTIONS = [
  {
    value: 'forehead',
    label: 'onboarding.likeChatProblematicAreas.options.forehead',
    key: 'Forehead',
  },
  {
    value: 'eyes',
    label: 'onboarding.likeChatProblematicAreas.options.eyes',
    key: 'Eyes',
  },
  {
    value: 'cheeks',
    label: 'onboarding.likeChatProblematicAreas.options.cheeks',
    key: 'Cheeks',
  },
  {
    value: 'jawline',
    label: 'onboarding.likeChatProblematicAreas.options.jawline',
    key: 'Jawline',
  },
  {
    value: 'mouth',
    label: 'onboarding.likeChatProblematicAreas.options.mouth',
    key: 'Mouth',
  },
  {
    value: 'chin',
    label: 'onboarding.likeChatProblematicAreas.options.chin',
    key: 'Chin',
  },
  {
    value: 'neck',
    label: 'onboarding.likeChatProblematicAreas.options.neck',
    key: 'Neck',
  },
  {
    value: 'neckline',
    label: 'onboarding.likeChatProblematicAreas.options.neckline',
    key: 'Décolleté (neckline)',
  },
]
