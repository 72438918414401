import styled, { css } from 'styled-components'

import { TLocaleStylesProps } from 'modules/purchase/types'

import { Color, DynamicDiscountTheme } from 'root-constants'

type TTheme = {
  theme: DynamicDiscountTheme
}

export const StyledLongPaywallShortUserProfile = {
  Wrapper: styled.div`
    margin-bottom: 24px;
  `,
  Title: styled.h2`
    text-align: center;
    color: ${Color.DARK};
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 16px;
  `,
  ContentWrapper: styled.div<TLocaleStylesProps>`
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 1px;
      background-color: #ccdce5;

      ${({ isEnLocaleStyle }) =>
        isEnLocaleStyle
          ? css`
              height: 36px;
              right: 178px;
            `
          : css`
              height: 100%;
              left: 50%;
              transform: translateX(-50%);
            `}
    }
  `,
  ContentImage: styled.img`
    width: 38px;
    height: 38px;
    margin-right: 8px;
  `,
  ContentBlock: styled.div<TLocaleStylesProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle
        ? css`
            flex-direction: row;

            &:last-of-type {
              width: 176px;
            }
          `
        : css`
            flex-direction: column;
            text-align: center;
            width: calc(50% - 4px);
          `}
  `,
  LevelContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  `,
  ContentSubtitle: styled.p<TLocaleStylesProps>`
    color: ${Color.LIGHT};
    font-weight: 400;
    line-height: 16px;

    ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle
        ? css`
            font-size: 14px;
          `
        : css`
            font-size: 12px;
            margin-top: 8px;
          `};
  `,
  UserProfileText: styled.p<TLocaleStylesProps>`
    color: ${Color.DARK};
    font-weight: 500;

    ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle
        ? css`
            font-size: 18px;
            line-height: 20px;
          `
        : css`
            font-size: 16px;
            line-height: 24px;
          `};
  `,
  TimerContainer: styled.div<TTheme>`
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    padding: 6px 8px 10px;

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: linear-gradient(91deg, #ff4b4b -8.21%, #cf467b 95.97%);
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: linear-gradient(270deg, #7470ff 0%, #e2a3f8 103.03%);
          `
        case DynamicDiscountTheme.BLACK:
          return css`
            background: linear-gradient(91deg, #ff4b4b -8.21%, #cf467b 95.97%);
          `
        case DynamicDiscountTheme.BLUE:
          return css`
            background: linear-gradient(270deg, #554ff2 0%, #00c3ff 103.03%);
          `
        default:
          return css`
            background: linear-gradient(270deg, #7470ff 0%, #e2a3f8 103.03%);
          `
      }
    }}
  `,
  TimerContainerText: styled.span<TLocaleStylesProps>`
    color: #fffefe;
    line-height: 28px;
    font-weight: 600;
    font-size: ${({ isEnLocaleStyle }) => (isEnLocaleStyle ? '16px' : '14px')};

    strong {
      font-size: 20px;
      font-weight: 700;
    }
  `,
  Discount: styled.div<TTheme>`
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 16px;

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: linear-gradient(
              91deg,
              rgba(255, 83, 67, 0.15) -8.21%,
              rgba(225, 34, 108, 0.15) 95.97%
            );
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: linear-gradient(
              269deg,
              rgba(116, 112, 255, 0.15) 0%,
              rgba(226, 163, 248, 0.15) 125.26%
            );
          `
        case DynamicDiscountTheme.BLACK:
          return css`
            background: linear-gradient(
              91deg,
              rgba(255, 83, 67, 0.15) -8.21%,
              rgba(225, 34, 108, 0.15) 95.97%
            );
          `
        case DynamicDiscountTheme.BLUE:
          return css`
            background: rgba(8, 185, 254, 0.15);
          `
        default:
          return css`
            background: linear-gradient(
              269deg,
              rgba(116, 112, 255, 0.15) 0%,
              rgba(226, 163, 248, 0.15) 125.26%
            );
          `
      }
    }}
  `,
  DiscountLabel: styled.span`
    color: #fffefe;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
    text-transform: uppercase;
    padding: 4px 12px;
    border-radius: 10px;
    margin-right: 16px;
    min-width: 102px;

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
          `
        case DynamicDiscountTheme.BLACK:
          return css`
            background: linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: linear-gradient(269deg, #7470ff 0%, #e2a3f8 125.26%);
          `
        case DynamicDiscountTheme.BLUE:
          return css`
            background: linear-gradient(270deg, #554ff2 0%, #00c3ff 103.03%),
              linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
          `
        default:
          return css`
            background: linear-gradient(269deg, #7470ff 0%, #e2a3f8 125.26%);
          `
      }
    }}
  `,
  DiscountText: styled.p`
    -webkit-text-fill-color: transparent;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: -webkit-linear-gradient(
              91deg,
              #ff5343 -8.21%,
              #e1226c 95.97%
            );
            -webkit-background-clip: text;
            background-clip: text;
          `
        case DynamicDiscountTheme.BLACK:
          return css`
            background: -webkit-linear-gradient(
              91deg,
              #ff5343 -8.21%,
              #e1226c 95.97%
            );
            -webkit-background-clip: text;
            background-clip: text;
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: -webkit-linear-gradient(
              269deg,
              #7470ff 0%,
              #e2a3f8 125.26%
            );
            -webkit-background-clip: text;
            background-clip: text;
          `
        case DynamicDiscountTheme.BLUE:
          return css`
            background: #3280f8;
            -webkit-background-clip: text;
            background-clip: text;
          `
        default:
          return css`
            background: -webkit-linear-gradient(
              269deg,
              #7470ff 0%,
              #e2a3f8 125.26%
            );
            -webkit-background-clip: text;
            background-clip: text;
          `
      }
    }}
  `,
}
