import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { GrowthBook } from '@growthbook/growthbook-react'

import { selectGrowthBookExperiments } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

export const growthBook = new GrowthBook({
  enableDevMode: true,
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
  subscribeToChanges: true,
})

export const useGrowthBook = (): void => {
  const uuid = useSelector(selectUUID)
  const location = useLocation()
  const growthBookExperiments = useSelector(selectGrowthBookExperiments)

  useEffect(() => {
    growthBook.loadFeatures({ autoRefresh: true })
  }, [])

  useEffect(() => {
    growthBook.setAttributes({
      id: uuid,
    })
  }, [uuid])

  useEffect(() => {
    growthBook.setURL(window.location.href)
  }, [location])

  useEffect(() => {
    growthBook.subscribe((experiment, result) => {
      if (!growthBookExperiments || !result.hashUsed) return

      const experimentKey = experiment.key || ''
      const variantId = String(result.variationId)

      eventLogger.logGrowthBookAbSegmentName({
        variantId,
        experimentKey,
        variantName: growthBookExperiments?.[experimentKey]?.[variantId],
      })
    })
  }, [growthBookExperiments])
}
