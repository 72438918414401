import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'

import { createProductId } from 'helpers/createProductId'

import {
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectUpgradeSubscriptionFullPrice,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import { ScreenName } from 'root-constants'

type TReturnPurchaseAnalytics = {
  sendGeneralPurchaseEvents: (shouldPurchaseShownBeLogged?: boolean) => void
}

export const usePurchaseUpgradeAnalytics = (): TReturnPurchaseAnalytics => {
  const price = useSelector(selectUpgradeSubscriptionFullPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)

  const screenName = ScreenName.LIVECHAT_UPGRADE

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price,
      }),
    [periodName, periodQuantity, price],
  )

  useEffect(() => {
    if (!periodName && !periodQuantity && !price) return
    eventLogger.logSalePageShown({ productIds: [productId], screenName })
  }, [productId, periodName, periodQuantity, screenName, price])

  const sendGeneralPurchaseEvents = useCallback(
    (shouldPurchaseShownBeLogged = true) => {
      if (shouldPurchaseShownBeLogged) {
        eventLogger.logPurchaseShown({
          productId,
          screenName,
          stripeAccountId,
          stripeAccountName,
        })

        window.obApi && window.obApi('track', 'Checkout')
        window._tfa &&
          window._tfa.push({ notify: 'event', name: 'start_checkout' })
      }
    },
    [productId, screenName, stripeAccountId, stripeAccountName],
  )

  return { sendGeneralPurchaseEvents }
}
