import React, { useEffect, useRef } from 'react'
import { Trans } from 'react-i18next'
import { useLocation } from 'react-router'

import lottie from 'lottie-web/build/player/lottie_light'

import animationConfig from 'assets/animation/sadCloudAnimation.json'

import { goTo } from 'browser-history'

import { TIME_TO_SHOW_CANCELING_PAGE } from '../constants'
import { StyledPage } from './styles'

export const CancelingSubscription: React.FC = () => {
  const { search } = useLocation()
  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        animationData: animationConfig,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      goTo({ pathname: 'canceled-subscription', search })
    }, TIME_TO_SHOW_CANCELING_PAGE)

    return () => clearTimeout(timer)
  }, [search])

  return (
    <>
      <StyledPage.Animation>
        <div ref={animationContainerRef} style={{ height: 180 }} />
      </StyledPage.Animation>
      <StyledPage.TitleSmall>
        <Trans
          i18nKey="unsubscribe.cancelingSubscriptionText"
          components={[<br />]}
        />
      </StyledPage.TitleSmall>
    </>
  )
}
