import styled from 'styled-components'

import { Container } from 'components/Container'
import { Header } from 'components/Header'

import { HolidaysCancelOfferReviews } from 'modules/purchase/components/HolidaysCancelOfferReviews'
import { HolidaysCancelOfferTestimonials } from 'modules/purchase/components/HolidaysCancelOfferTestimonials'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseUpgrade = {
  Hero: styled.img`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 0 auto;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Root: styled.div`
    padding-bottom: 40px;
  `,
  Header: styled(Header)`
    box-shadow: none;
    margin: 0 auto;
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
    color: ${Color.DARK};
    text-align: center;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  TitleDescription: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: ${Color.LIGHT};
    margin-bottom: 40px;
    text-align: center;
  `,
  ButtonContainer: styled.div`
    margin-top: 24px;
    margin-bottom: 40px;
  `,
  HolidaysCancelOfferReviews: styled(HolidaysCancelOfferReviews)`
    padding-bottom: 0;
  `,
  HolidaysCancelOfferTestimonials: styled(HolidaysCancelOfferTestimonials)`
    padding-bottom: 40px;
  `,
  Container: styled(Container)`
    margin-top: 40px;
  `,
  SecurityInfo: styled(SecurityInfo)`
    a {
      font-weight: 400;
      text-decoration: underline;
    }
  `,
}
