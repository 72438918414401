import React from 'react'
import { useLocation } from 'react-router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

type TProps = {
  children?: React.ReactNode
}

export const AnimationProvider: React.FC<TProps> = ({ children }) => {
  const { pathname } = useLocation()

  return (
    <TransitionGroup>
      <CSSTransition key={pathname} classNames="fade" timeout={350}>
        {children}
      </CSSTransition>
    </TransitionGroup>
  )
}
