import styled, { css } from 'styled-components'

type TTitleContainerProps = {
  isSmallSize?: boolean
}

type TOptionsContainerProps = {
  textAlign?: 'left' | 'center'
}

export const StyledSkinCareRoutineDescription = {
  TitleContainer: styled.header<TTitleContainerProps>`
    margin-bottom: 16px;
    font-weight: 500;
    text-align: center;

    ${({ isSmallSize }) =>
      isSmallSize
        ? css`
            font-size: 16px;
            line-height: 24px;
          `
        : css`
            font-size: 17px;
            line-height: 26px;
          `}
  `,
  OptionsContainer: styled.div<TOptionsContainerProps>`
    text-align: ${({ textAlign = 'center' }) => textAlign};
  `,
}
