import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

const lineStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 10px;
  height: 1px;
  background-color: ${Color.PRIMARY};
`

const resetDefaultButtonStyles = css`
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
`

interface IStyledAccordionCardProps {
  isCollapsed: boolean
  contentHeight: number
}
export const StyledHowToUnsubscribe = {
  Wrapper: styled.div<IStyledAccordionCardProps>`
    --collapseContentHeight: ${({ contentHeight, isCollapsed }) =>
      `${isCollapsed ? 0 : contentHeight}px`};
    padding-bottom: 30px;
  `,
  Button: styled.button`
    ${resetDefaultButtonStyles};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Plus: styled.span`
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 6px;
    border-radius: 50%;
    opacity: 0.5;
    border: solid 1px ${Color.PRIMARY};

    &::before {
      content: '';
      ${lineStyles}
    }
    &::after {
      content: '';
      ${lineStyles};
      transform: rotate(90deg);
      transform-origin: center center;
    }
  `,
  CollapseContent: styled.div`
    position: relative;
    overflow: hidden;

    & > * {
      position: absolute;
      left: 0;
      right: 0;
    }

    &::after {
      content: '';
      display: block;
      transition: all 0.3s ease-in;
      height: var(--collapseContentHeight);
    }
  `,
  Text: styled.p`
    font-size: 15px;
    line-height: 20px;
    text-align: center;
  `,
  Link: styled.button`
    ${resetDefaultButtonStyles};
    text-decoration: underline;
    font-weight: 500;
  `,
}
