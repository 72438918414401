import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { CustomDatePicker } from 'components/CustomDatePicker'

import { setAnswersAction } from 'root-redux/actions/common'
import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { replaceHistory } from 'browser-history'
import { StickyButtonContainer } from 'common-styles'
import { PageId } from 'page-constants'

import { StyledOccasionDate as S } from './OccasionDate.styles'
import { NO_OCCASION, QUESTION, SKIP_QUESTION } from './constants'

export const OccasionDate: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userAnswers = useSelector(selectAnswers)

  const [occasionDate, setOccasionDate] = useState<Date>(new Date())

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useLayoutEffect(() => {
    if (
      userAnswers?.[PageId.OCCASION] === NO_OCCASION ||
      !userAnswers?.[PageId.OCCASION]
    ) {
      replaceHistory(nextPagePath)
    }
  }, [userAnswers, nextPagePath])

  const onDatepickerChange = useCallback((date: Date) => {
    setOccasionDate(date)
  }, [])

  const handleNextClick = useCallback(
    (userAnswer: Date | string) => {
      dispatch(
        setAnswersAction({
          answers: userAnswer,
          pageId: PageId.OCCASION_DATE,
        }),
      )
      handleContinue(userAnswer)
    },
    [dispatch, handleContinue],
  )

  return (
    <Container>
      <S.Title>{t('onboarding.occasionDate.question')}</S.Title>
      <S.Description>{t('onboarding.occasionDate.description')}</S.Description>
      <S.DatePickerContainer>
        <CustomDatePicker
          selectedDate={occasionDate}
          onChange={onDatepickerChange}
        />
      </S.DatePickerContainer>
      <StickyButtonContainer customBackground="transparent">
        <Button onClick={() => handleNextClick(occasionDate)}>
          {t('actions.continue')}
        </Button>
        <S.LinkContainer>
          <S.Link onClick={() => handleNextClick(SKIP_QUESTION)}>
            {t('onboarding.occasionDate.skipQuestion')}
          </S.Link>
        </S.LinkContainer>
      </StickyButtonContainer>
    </Container>
  )
}
