import styled, { css, keyframes } from 'styled-components'

import appleIcon from 'assets/images/continue-with-apple.svg'
import emailIcon from 'assets/images/continue-with-email.svg'
import facebookIcon from 'assets/images/continue-with-facebook.svg'
import googleIcon from 'assets/images/continue-with-google.svg'

import { Color, MediaBreakpoint } from 'root-constants'

type TMenuProps = {
  isMenuVisible: boolean
}

export const StyledButtonBase = css`
  width: 100%;
  height: 50px;
  display: block;
  padding: 0 15px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: ${Color.PRIMARY};
  border-radius: 12px;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  text-align: center;
  color: ${Color.WHITE};
  transition: background-color 0.2s ease-out;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

const StyledLoginButtonBase = css`
  ${StyledButtonBase};
  width: 100%;
  margin-bottom: 14px;
  font-size: 15px;
  background-repeat: no-repeat;
  background-position: 20px center;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const baseMediaStyles = css`
  width: 300px;

  @media (min-width: ${MediaBreakpoint.SMALL_PHONE}px) {
    width: 340px;
  }
`

export const basePageStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const baseColumnStyles = css`
  ${baseMediaStyles};
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const commonBackdropStyles = css<TMenuProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: ${({ isMenuVisible }) => (isMenuVisible ? '1' : '-1')};
  backdrop-filter: blur(3px);
  opacity: ${({ isMenuVisible }) => (isMenuVisible ? '1' : '0')};
  pointer-events: ${({ isMenuVisible }) =>
    isMenuVisible ? 'default' : 'none'};
  transition: opacity 0.25s;
`

export const commonMenuStyles = css<TMenuProps>`
  width: 280px;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${Color.WHITE};
  position: fixed;
  z-index: 12;
  padding: 80px 25px;
  transition: transform 0.25s;
  transform: ${({ isMenuVisible }) =>
    isMenuVisible ? 'translateX(0)' : 'translateX(100%)'};
`

export const commonMenuCloseButtonStyles = css`
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 50%;
  background-color: #bfbfbf;
  position: absolute;
  top: 25px;
  left: 25px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &::after,
  &::before {
    display: block;
    content: '';
    width: 20px;
    height: 2px;
    background-color: ${Color.WHITE};
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 10px);
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
`

export const dotsAnimation = keyframes`
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
`

export const datesAnimation = keyframes`
  0% {margin-top: -360px;}
  6.25% {margin-top: -360px;}
  12.5% {margin-top: -315px;}
  18.75% {margin-top: -315px;}
  25% {margin-top: -270px;}
  31.25% {margin-top: -270px;}
  37.5% {margin-top: -225px;}
  43.75% {margin-top: -225px;}
  50% {margin-top: -180px;}
  56.25% {margin-top: -180px;}
  62.5% {margin-top: -135px;}
  68.75% {margin-top: -135px;}
  75% {margin-top: -90px;}
  81.25% {margin-top: -90px;}
  87.5% {margin-top: -45px;}
  93.75% {margin-top: -45px;}
  100% {margin-top: 0;}
`

type TStickyButtonContainer = {
  customBackground?: string
}

export const StickyButtonContainer = styled.div<TStickyButtonContainer>`
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  padding: 25px 0;
  background: ${({ customBackground }) =>
    customBackground ||
    `linear-gradient(
        180deg,
        rgba(251, 248, 243, 0) 0%,
        ${Color.SANDY} 17.19%)`};
  z-index: 1;
`

export const BottomButtonContainer = styled.div`
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding: 40px 20px;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  z-index: 2;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.0001) 0.25%,
    #ffffff 26.14%
  );
`

export const StyledLoginButton = {
  Email: styled.button`
    ${StyledLoginButtonBase};
    background-image: url(${emailIcon});
    &[data-icon='false'] {
      background-image: none;
    }
  `,
  Google: styled.button`
    ${StyledLoginButtonBase};
    border: 1px solid #979797;
    background-color: ${Color.WHITE};
    background-image: url(${googleIcon});
    color: ${Color.DARK};
  `,
  Facebook: styled.button`
    ${StyledLoginButtonBase};
    background-image: url(${facebookIcon});
    background-color: #395a98;
  `,
  Apple: styled.button`
    ${StyledLoginButtonBase};
    background-image: url(${appleIcon});
    background-color: #010002;
  `,
}

export const customBackgroundStyles = css`
  &::after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: ${Color.LIGHT_PURPLE};
  }
`

type TButtonContainerProps = {
  isShown: boolean
  pinedToBottom?: boolean
}

export const DelayedBottomContainer = styled(
  BottomButtonContainer,
)<TButtonContainerProps>`
  ${({ isShown, pinedToBottom }) =>
    isShown
      ? css`
          transition: all 0.5s ease;
          transform: scale(1);
          padding: 40px 0 32px;
          margin-top: ${pinedToBottom ? 'auto' : 0};
        `
      : css`
          position: absolute;
          z-index: -1;
          transform: scale(0);
          visibility: hidden;
          padding: 0;
        `}
`

export const Chat = styled.div`
  margin-bottom: auto;
`

export const DelayedContainer = styled.div<TButtonContainerProps>`
  width: 100%;
  max-width: 360px;

  ${({ isShown }) =>
    isShown
      ? css`
          transition: all 0.5s ease;
          transform: scale(1);
          padding-top: 24px;
        `
      : css`
          transform: scale(0);
          visibility: hidden;
          padding-top: 0;
        `}
`
