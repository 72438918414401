import styled from 'styled-components'

import { PageTitle as Title } from 'components/PageTitle'

import optionIconUrl from 'assets/images/check-icon-red.svg'

import { BottomButtonContainer } from 'common-styles'
import { Color } from 'root-constants'

export const StyledNaturalFacialPractice = {
  PageTitle: styled(Title)`
    text-align: start;
    margin: 16px auto 8px;
  `,
  Image: styled.img`
    width: 328px;
    height: 236px;
  `,
  Description: styled.p`
    color: ${Color.LIGHT};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  ListItem: styled.li`
    position: relative;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    padding: 0 5px 0 28px;
    color: ${Color.LIGHT};

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background: url(${optionIconUrl}) center no-repeat;
      background-size: contain;
    }
  `,
  ButtonContainer: styled(BottomButtonContainer)`
    padding: 40px 0;
  `,
}
