import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPurchaseVariant2 = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px 40px;
  `,
  Subtitle: styled.h2`
    margin-bottom: 16px;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  PaymentMethodTitle: styled.h2`
    margin: 30px 0 23px;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  ButtonContainer: styled.div`
    margin: 30px 0;
  `,
  PaymentContainer: styled.div`
    &[data-is-visible='false'] {
      display: none;
    }
  `,
  Disclaimer: styled.p`
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    color: ${Color.LIGHT};

    a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
}
