import styled from 'styled-components'

export const StyledCancelOfferSubscriptionBlock = {
  Wrapper: styled.div`
    margin-bottom: 9px;
  `,
  Title: styled.h2`
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
}
