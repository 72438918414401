import i18n from 'i18next'

import { getDateFromTimeStamp } from 'helpers/date'

import {
  IRawUserSubscriptionsInfo,
  IUserSubscriptionsInfo,
} from 'models/commonApi.model'

import {
  BILLING_CYCLE_TEXTS,
  SubscriptionStatus,
  TRIAL_BILLING_CYCLE_TEXTS,
} from 'root-constants'

export const getActiveUserSubscriptionFromRawUserSubscriptions = (
  rawUserSubscriptionsInfo: IRawUserSubscriptionsInfo[],
  language: string,
): IUserSubscriptionsInfo => {
  const [
    {
      id: subscriptionId,
      current_period_start: startDate,
      current_period_end: endDate,
      status,
      trial,
      recurring: { interval_count: intervalCount },
    },
  ] = rawUserSubscriptionsInfo.sort((a, b) => +b.id - +a.id)

  return {
    subscriptionId,
    trialStartDate: getDateFromTimeStamp(trial.start, language),
    trialEndDate: getDateFromTimeStamp(trial.end, language),
    startDate: getDateFromTimeStamp(startDate, language),
    endDate: getDateFromTimeStamp(endDate, language),
    trialBillingCycle: i18n.t(TRIAL_BILLING_CYCLE_TEXTS[trial.days_count]),
    billingCycle: i18n.t(BILLING_CYCLE_TEXTS[intervalCount]),
    status: i18n.t(`unsubscribe.subscriptionDetails.${status}`),
    isActiveOrTrial:
      status === SubscriptionStatus.ACTIVE ||
      status === SubscriptionStatus.TRIAL,
    isSubscriptionEndVisible:
      status === SubscriptionStatus.TRIAL ||
      status === SubscriptionStatus.CANCELLED,
  }
}
