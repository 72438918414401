export const QUESTION = 'Mental Health'

export const OPTION_VALUES = {
  WAS_STRESSED: 'was_stressed',
  HAVE_HAD_INSOMNIA: 'have_had_insomnia',
  SUFFERING_FROM_ANXIETY: 'suffering_from_anxiety',
  FEELING_DEPRESSED: 'feeling_depressed',
  NONE_OF_THE_ABOVE: 'none_of_the_above',
  PREFER_NOT_TO_ANSWER: 'prefer_not_to_answer',
}

export const MENTAL_HEALTH_OPTIONS = [
  {
    value: OPTION_VALUES.WAS_STRESSED,
    text: 'onboarding.mentalHealth.wasStressed',
  },
  {
    value: OPTION_VALUES.HAVE_HAD_INSOMNIA,
    text: 'onboarding.mentalHealth.haveHadInsomnia',
  },
  {
    value: OPTION_VALUES.SUFFERING_FROM_ANXIETY,
    text: 'onboarding.mentalHealth.sufferingFromAnxiety',
  },
  {
    value: OPTION_VALUES.FEELING_DEPRESSED,
    text: 'onboarding.mentalHealth.feelingDepressed',
  },
  {
    value: OPTION_VALUES.NONE_OF_THE_ABOVE,
    text: 'onboarding.mentalHealth.noneOfTheAbove',
  },
  {
    value: OPTION_VALUES.PREFER_NOT_TO_ANSWER,
    text: 'onboarding.mentalHealth.preferNotToAnswer',
  },
]

export const MENTAL_HEALTH_ANSWERS_MAP = {
  [OPTION_VALUES.WAS_STRESSED]: 'onboarding.mentalHealth.wasStressed',
  [OPTION_VALUES.HAVE_HAD_INSOMNIA]: 'onboarding.mentalHealth.haveHadInsomnia',
  [OPTION_VALUES.SUFFERING_FROM_ANXIETY]:
    'onboarding.mentalHealth.sufferingFromAnxiety',
  [OPTION_VALUES.FEELING_DEPRESSED]: 'onboarding.mentalHealth.feelingDepressed',
  [OPTION_VALUES.NONE_OF_THE_ABOVE]: 'onboarding.mentalHealth.noneOfTheAbove',
  [OPTION_VALUES.PREFER_NOT_TO_ANSWER]:
    'onboarding.mentalHealth.preferNotToAnswer',
}
