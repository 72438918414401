export const REVIEWS = [
  {
    author: 'guashaUpsell.reviews.review1.author',
    review: 'guashaUpsell.reviews.review1.text',
  },
  {
    author: 'guashaUpsell.reviews.review2.author',
    review: 'guashaUpsell.reviews.review2.text',
  },
  {
    author: 'guashaUpsell.reviews.review3.author',
    review: 'guashaUpsell.reviews.review3.text',
  },
]
