import styled from 'styled-components'

import { PageTitle as Title } from 'components/PageTitle'

export const StyledSkincareChallenges = {
  PageTitle: styled(Title)`
    margin: 0 auto 16px;
    max-width: 328px;
  `,
  TextWrapper: styled.div`
    padding-right: 16px;
  `,
}
