import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPage = {
  Animation: styled.div`
    margin: 80px auto 10px;

    svg {
      height: 180px !important;
    }
  `,
  Title: styled.h1`
    margin-bottom: 30px;
    font-size: 26px;
    line-height: 31px;
    font-weight: 500;
    text-align: center;
    color: ${Color.DARK};
  `,
  TitleSmall: styled.h1`
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  `,

  Subtitle: styled.h2`
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  `,
  Text: styled.p`
    margin-bottom: 200px;
    font-size: 16px;
    text-align: center;
  `,
}
