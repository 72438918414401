import styled from 'styled-components'

import { BottomButtonContainer as ButtonContainer } from 'common-styles'

export const StyledSkinConcerns = {
  TitleContainer: styled.header`
    max-width: 360px;
    padding: 0 10px;
    margin: 0 auto 16px;
  `,
  OptionsContainer: styled.div``,
  BottomButtonContainer: styled(ButtonContainer)`
    padding: 40px 16px;
  `,
}
