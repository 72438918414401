import styled, { keyframes } from 'styled-components'

import { SvgImage } from '../SvgImage'

const spinKeyframes = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`

export const StyledSpinner = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw;
    height: var(--full-height, 100vh);
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Circle: styled(SvgImage)`
    animation-name: ${spinKeyframes};
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  `,
}
