import styled from 'styled-components'

import { ChatAnswerButton } from 'components/ChatAnswerButton'

export const StyledLikeChatSkinConcerns = {
  OptionsWrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    & > label:not(:last-of-type) {
      width: calc(50% - 6px);
    }

    & > label:last-of-type {
      width: 100%;
    }
  `,
  OptionContentWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  OptionImage: styled.img`
    width: 36px;
    height: 36px;
    margin-right: 6px;
  `,
  ShortenedChatAnswerButton: styled(ChatAnswerButton)`
    margin-bottom: -4px;
  `,
}
