import {
  SubscriptionDuration,
  YearlySubscriptionDuration,
} from 'modules/purchase/pages/PurchaseUpgrade/constants'

export const SHORT_DISCLAIMERS = {
  [SubscriptionDuration.SIX_MONTHS]:
    'purchaseUpgrade.shortDisclaimer.sixMonths',
  [SubscriptionDuration.NINE_MONTHS]:
    'purchaseUpgrade.shortDisclaimer.nineMonths',
  [SubscriptionDuration.ONE_YEAR]: 'purchaseUpgrade.shortDisclaimer.oneYear',
}

export const SHORT_YEARLY_SUBSCRIPTIONS_DISCLAIMERS = {
  [YearlySubscriptionDuration.ONE_YEAR]:
    'purchaseUpgrade.shortDisclaimer.oneYear',
  [YearlySubscriptionDuration.TWO_YEARS]:
    'purchaseUpgrade.shortDisclaimer.twoYears',
}
