import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TWrapperProps = {
  isFullWidth: boolean
}

type TProgressProps = {
  isSlim: boolean
}

const progressValueStyles = css`
  background: ${Color.PRIMARY};
  transition: all 0.2s ease-out;
  border-radius: 5px;
`

export const StyledLinearProgressBar = {
  Wrapper: styled.div<TWrapperProps>`
    ${({ isFullWidth }) =>
      isFullWidth
        ? css`
            position: absolute;
            padding: 0;
            width: 100%;
            left: 0;
            top: 50px;
          `
        : css`
            max-width: 360px;
            margin: 0 auto 32px;
            padding: 0 16px;
          `}
  `,
  Progress: styled.progress<TProgressProps>`
    appearance: none;
    border: none;
    display: block;
    width: 100%;
    height: ${({ isSlim }) => (isSlim ? '2px' : '10px')};
    background: #e6e9f6;
    border-radius: 5px;

    &::-webkit-progress-bar {
      background: #e6e9f6;
      border-radius: 5px;
    }
    &::-webkit-progress-value {
      ${progressValueStyles}
    }
    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
}
