import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { PrimerPaymentFormV2 } from 'components/PrimerPaymentForm'
import { Spinner } from 'components/Spinner'

import { startFetching } from 'root-redux/actions/common'
import { selectActionList, selectScreenName } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'
import { useScrollToTop } from 'hooks/useScrollToTop'

import { createProductId } from 'helpers/createProductId'
import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import { PrimerPaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { StripeSeparatePaymentRequestButton } from 'modules/purchase/components/StripeSeparatePaymentRequestButton'
import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  DECORATING_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  INTRO_SALES_COHORTS_DISCOUNT_MAP,
  ONE_HUNDRED_PERCENTAGE,
  PaymentMethod,
} from 'modules/purchase/constants'
import { getDynamicDiscountText } from 'modules/purchase/helpers/rootHelpers'
import { usePurchaseStore } from 'modules/purchase/hooks'
import { useCancelPrimerDiscount } from 'modules/purchase/hooks/useCancelPrimerDiscount'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  CHECK_PAYPAL_REQUEST_BUTTON,
} from 'modules/purchase/redux/actions/common'
import {
  select3DSecureIframeUrl,
  selectDynamicDiscount,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import SecuritySystems from 'assets/images/security-systems.png'

import { PageId } from 'page-constants'
import { Cohort, ScreenName, SubscriptionProduct } from 'root-constants'

import { StyledCheckoutSeparateMethods as S } from './CheckoutSeparateMethods.styles'
import { PAYMENT_METHODS_IMAGES } from './constants'

type TCheckoutExtended = {
  handleCloseCheckout: () => void
  hasPaypalButton?: boolean
}

export const CheckoutSeparateMethodsMix: React.FC<TCheckoutExtended> = ({
  handleCloseCheckout,
  hasPaypalButton = false,
}: TCheckoutExtended) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const selectedSubscription = useSelector(selectSubscription)
  const fetchingActionsList = useSelector(selectActionList)
  const hasVatInfo = useVatInfo()
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const screenName = useSelector(selectScreenName)
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const { isDynamicDiscountCohort } = useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null)
  const [alternativePaymentMethods, setAlternativePaymentMethods] = useState<
    PaymentMethod[]
  >([])
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] =
    useState<boolean>(false)

  useScrollToTop()

  const {
    periodName,
    periodQuantity,
    trialPrice,
    price,
    currency,
    cohort,
    stripeAccountId,
    stripeAccountName,
    taxAmount,
    discountAmount,
    discountPercentage,
  } = usePurchaseStore()
  const { cancelDiscount } = useCancelPrimerDiscount()

  const isCancelOfferRoute = useIsCurrentRoute(
    PageId.PURCHASE_INTRO_CANCEL_OFFER,
  )

  const isCheckoutReady = useMemo(
    () =>
      !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON) &&
      !fetchingActionsList.includes(CHECK_PAYPAL_REQUEST_BUTTON),
    [fetchingActionsList],
  )

  const isCardSelected = useMemo(
    () => selectedPaymentMethod === PaymentMethod.CREDIT_CARD,
    [selectedPaymentMethod],
  )

  const currencyLabel = useMemo(() => currency.toUpperCase(), [currency])

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice || price,
      }),
    [periodName, periodQuantity, trialPrice, price],
  )

  const getCalculatedPrice = useCallback(
    (fullPrice: number) => {
      if (!hasVatInfo) return fullPrice

      return Number(getCalculatedPriceWithoutVAT(fullPrice, taxAmount))
    },
    [hasVatInfo, taxAmount],
  )

  useLayoutEffect(() => {
    if (
      alternativePaymentMethods.length &&
      alternativePaymentMethods.includes(PaymentMethod.GOOGLE_PAY)
    ) {
      setSelectedPaymentMethod(PaymentMethod.GOOGLE_PAY)
      return
    }

    if (
      alternativePaymentMethods.length &&
      alternativePaymentMethods.includes(PaymentMethod.APPLE_PAY)
    ) {
      setSelectedPaymentMethod(PaymentMethod.APPLE_PAY)
      return
    }

    if (
      alternativePaymentMethods.length &&
      alternativePaymentMethods.includes(PaymentMethod.PAYPAL)
    ) {
      setSelectedPaymentMethod(PaymentMethod.PAYPAL)
      return
    }
    setSelectedPaymentMethod(PaymentMethod.CREDIT_CARD)
  }, [alternativePaymentMethods])

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        stripeAccountId,
        stripeAccountName,
      })
    }
  }, [productId, screenName, stripeAccountId, stripeAccountName])

  useEffect(() => {
    dispatch(startFetching(CHECK_PAYPAL_REQUEST_BUTTON))
  }, [dispatch])

  const setPaymentMethod = useCallback((paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)
    eventLogger.logPaymentMethodSelected(paymentMethod)
  }, [])

  const getPriceWithFormattedDiscount = useCallback(
    (subscriptionPrice: number, discount: number) => {
      const calculatedOldPrice =
        subscriptionPrice /
        ((ONE_HUNDRED_PERCENTAGE - discount) / ONE_HUNDRED_PERCENTAGE)

      return (
        Math.ceil(getCalculatedPrice(calculatedOldPrice)) - DECORATING_DISCOUNT
      )
    },
    [getCalculatedPrice],
  )

  const subscription = useMemo(() => {
    if (isDynamicDiscountCohort && selectedSubscription) {
      const percentage =
        dynamicDiscount?.amount ||
        INTRO_SALES_COHORTS_DISCOUNT_MAP[cohortToUse] ||
        DEFAULT_DYNAMIC_DISCOUNT
      const totalAmount = getPriceWithFormattedDiscount(
        selectedSubscription.trialPrices.fullPrice,
        percentage,
      )

      return {
        percentage: isCancelOfferRoute
          ? percentage + CANCEL_OFFER_ADDITIONAL_DISCOUNT
          : percentage,
        totalAmount: totalAmount.toFixed(2),
        discountAmount: (
          totalAmount - selectedSubscription.trialPrices.fullPrice
        ).toFixed(2),
      }
    }

    if (selectedSubscription) {
      return {
        percentage: discountPercentage || 50,
        totalAmount:
          selectedSubscription.trialPrices.durationDays ===
          SubscriptionProduct.SEVEN_DAY
            ? getCalculatedPrice(
                selectedSubscription.trialPrices.oldPrices.fullPrice,
              )
            : getCalculatedPrice(selectedSubscription.mainPrices.fullPrice),
        discountAmount,
      }
    }

    return {
      percentage: discountPercentage || 50,
      totalAmount: 0,
      discountAmount,
    }
  }, [
    cohortToUse,
    discountAmount,
    discountPercentage,
    dynamicDiscount?.amount,
    getCalculatedPrice,
    getPriceWithFormattedDiscount,
    isCancelOfferRoute,
    isDynamicDiscountCohort,
    selectedSubscription,
  ])

  const totalDiscount = useMemo(() => {
    const discountValue = !isDynamicDiscountCohort
      ? getCalculatedPrice(discountAmount)
      : subscription.discountAmount

    return `-${discountValue} ${currencyLabel}`
  }, [
    currencyLabel,
    discountAmount,
    getCalculatedPrice,
    isDynamicDiscountCohort,
    subscription,
  ])

  if (threeDSecureIframeUrl) {
    return <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
  }

  return (
    selectedSubscription && (
      <S.Root>
        {!isCheckoutReady && <Spinner />}
        <S.ContentContainer isVisible={isCheckoutReady}>
          <S.PaymentMethodsTitle>
            {alternativePaymentMethods.length
              ? t('checkoutExtended.selectMethods')
              : t('checkoutExtended.checkoutTitle')}
          </S.PaymentMethodsTitle>
          <S.CloseButton
            data-testid="close-btn"
            onClick={handleCloseCheckout}
          />
          {!!alternativePaymentMethods.length && (
            <S.PaymentMethodsWrapperV2>
              <S.PaymentMethod
                isSelected={isCardSelected}
                onClick={() => setPaymentMethod(PaymentMethod.CREDIT_CARD)}
              >
                <S.PaymentMethodText>
                  {t('checkoutExtended.creditCard')}
                </S.PaymentMethodText>
                <S.PaymentMethodImage
                  isSelected={isCardSelected}
                  src={PAYMENT_METHODS_IMAGES[PaymentMethod.CREDIT_CARD]}
                  alt="credit-card-img"
                />
              </S.PaymentMethod>
              {alternativePaymentMethods.includes(PaymentMethod.PAYPAL) && (
                <S.PaymentMethod
                  isSelected={selectedPaymentMethod === PaymentMethod.PAYPAL}
                  onClick={() => {
                    setPaymentMethod(PaymentMethod.PAYPAL)
                    cancelDiscount()
                  }}
                >
                  <S.AlternativePaymentMethodImage
                    isSelected={selectedPaymentMethod === PaymentMethod.PAYPAL}
                    src={PAYMENT_METHODS_IMAGES[PaymentMethod.PAYPAL]}
                    alt=""
                  />
                </S.PaymentMethod>
              )}
              {alternativePaymentMethods.includes(PaymentMethod.GOOGLE_PAY) && (
                <S.PaymentMethod
                  isSelected={
                    selectedPaymentMethod === PaymentMethod.GOOGLE_PAY
                  }
                  onClick={() => setPaymentMethod(PaymentMethod.GOOGLE_PAY)}
                >
                  <S.AlternativePaymentMethodImage
                    isSelected={
                      selectedPaymentMethod === PaymentMethod.GOOGLE_PAY
                    }
                    src={PAYMENT_METHODS_IMAGES[PaymentMethod.GOOGLE_PAY]}
                    alt=""
                  />
                </S.PaymentMethod>
              )}
              {alternativePaymentMethods.includes(PaymentMethod.APPLE_PAY) && (
                <S.PaymentMethod
                  isSelected={selectedPaymentMethod === PaymentMethod.APPLE_PAY}
                  onClick={() => setPaymentMethod(PaymentMethod.APPLE_PAY)}
                >
                  <S.AlternativePaymentMethodImage
                    isSelected={
                      selectedPaymentMethod === PaymentMethod.APPLE_PAY
                    }
                    src={PAYMENT_METHODS_IMAGES[PaymentMethod.APPLE_PAY]}
                    alt=""
                  />
                </S.PaymentMethod>
              )}
            </S.PaymentMethodsWrapperV2>
          )}

          <S.PriceDescription>
            <S.PriceInfo>
              <S.PriceBlockText>
                {t('checkoutExtended.personalizedPlan')}
              </S.PriceBlockText>
              <S.PriceBlockText>
                {subscription.totalAmount} {currencyLabel}
              </S.PriceBlockText>
            </S.PriceInfo>
            <S.PriceInfo>
              <S.PriceBlockText>
                {isDynamicDiscountCohort ? (
                  getDynamicDiscountText({
                    discountAmount: subscription.percentage,
                    isCancelOffer: isCancelOfferRoute,
                    cohort: cohortToUse,
                    discountText: dynamicDiscount?.checkout,
                  })
                ) : (
                  <Trans
                    i18nKey="checkoutExtended.introductoryDiscount"
                    values={{
                      discountPercentage: subscription.percentage,
                    }}
                  />
                )}
              </S.PriceBlockText>
              <S.TotalDiscount>{totalDiscount}</S.TotalDiscount>
            </S.PriceInfo>
          </S.PriceDescription>

          <S.DailyPayment>
            <S.DailyText>{t('checkoutExtended.totalPerDay')}</S.DailyText>
            <S.DailyAmount>
              {getCalculatedPrice(selectedSubscription.trialPrices.daily)}{' '}
              {currencyLabel}
            </S.DailyAmount>
          </S.DailyPayment>

          <S.TotalPayment>
            <S.TotalDescription>
              <S.TotalText>
                <Trans
                  i18nKey={
                    hasVatInfo
                      ? t('checkoutExtended.totalWithVat')
                      : t('checkoutExtended.total')
                  }
                  components={[<br />]}
                />
              </S.TotalText>
              <S.TotalAmount>
                <Trans
                  i18nKey="checkoutExtended.totalAmount"
                  values={{
                    totalAmount: selectedSubscription.trialPrices.fullPrice,
                    currency: currencyLabel,
                    context: selectedSubscription.trialPrices.durationDays,
                  }}
                />
              </S.TotalAmount>
            </S.TotalDescription>
            <S.SaveText>
              <Trans
                i18nKey="checkoutExtended.savedAmount"
                values={{
                  savedAmount: subscription.discountAmount,
                  currency: currencyLabel,
                  discountPercentage: subscription.percentage,
                }}
              />
            </S.SaveText>
          </S.TotalPayment>

          <S.PaymentFormWrapper>
            <PrimerPaymentFormV2
              hasPayPalButton={hasPaypalButton}
              selectedPaymentMethod={
                selectedPaymentMethod || PaymentMethod.CREDIT_CARD
              }
              setAlternativePaymentMethodsCallback={
                setAlternativePaymentMethods
              }
            />
            <S.StripePaymentContainer
              isVisible={
                selectedPaymentMethod === PaymentMethod.GOOGLE_PAY ||
                selectedPaymentMethod === PaymentMethod.APPLE_PAY
              }
            >
              <StripeSeparatePaymentRequestButton
                key={selectedSubscription.id}
                setAlternativePaymentMethodsCallback={
                  setAlternativePaymentMethods
                }
              />
            </S.StripePaymentContainer>
          </S.PaymentFormWrapper>

          <S.PaymentsSystemImage src={SecuritySystems} alt="security-systems" />
          <S.PaymentsSystemText>{t`purchase2.checkout.moneyBackGuarantee`}</S.PaymentsSystemText>
        </S.ContentContainer>

        <PrimerPaymentWaitingModal
          isPaymentWaitingShown={isPaymentWaitingShown}
          setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
        />
      </S.Root>
    )
  )
}
