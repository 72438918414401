import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButtonWithImage } from 'components/AnswerButtonWithImage'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import heartFaceShape from 'assets/images/heart-face-shape.png'
import OvalFaceShape from 'assets/images/oval-face-shape.png'
import rectangleFaceShape from 'assets/images/rectangle-face-shape.png'
import roundFaceShape from 'assets/images/round-face-shape.png'
import squareFaceShape from 'assets/images/square-face-shape.png'
import triangleFaceShape from 'assets/images/triangle-face-shape.png'

import { goBack } from 'browser-history'
import { OptionType } from 'root-constants'

import { StyledFaceShape as S } from './FaceShape.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const FaceShape: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const includeNavigation = useIsNavigationOn()
  const { isSeniorCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(answer),
    [answer, handleContinue],
  )

  const handleAnswerChange = useCallback(
    (value: string) => {
      if (includeNavigation) {
        setAnswer(value)
        return
      }

      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, includeNavigation, setIsAnswersDisabled],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: handleAnswerChange,
    }),
    [handleAnswerChange, pageId],
  )

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <PageTitle>{t`onboarding.faceShape.question`}</PageTitle>
        </S.TitleContainer>
        <S.OptionsContainer>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.OVAL}
          >
            <AnswerButtonWithImage
              imageSrc={OvalFaceShape}
              withCheckbox={!isSeniorCohort}
            />
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.SQUARE}
          >
            <AnswerButtonWithImage
              imageSrc={squareFaceShape}
              withCheckbox={!isSeniorCohort}
            />
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.ROUND}
          >
            <AnswerButtonWithImage
              imageSrc={roundFaceShape}
              withCheckbox={!isSeniorCohort}
            />
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.TRIANGLE}
          >
            <AnswerButtonWithImage
              imageSrc={triangleFaceShape}
              withCheckbox={!isSeniorCohort}
            />
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.HEART}
          >
            <AnswerButtonWithImage
              imageSrc={heartFaceShape}
              withCheckbox={!isSeniorCohort}
            />
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.RECTANGLE}
          >
            <AnswerButtonWithImage
              imageSrc={rectangleFaceShape}
              withCheckbox={!isSeniorCohort}
            />
          </Option>
        </S.OptionsContainer>
      </Container>
      {includeNavigation && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
