import advancedLevelImage from 'assets/images/advanced-level.png'
import beginnerLevelImage from 'assets/images/beginner-level.png'
import intermediateLevelImage from 'assets/images/intermediate-level.png'

import { UserGoal } from 'root-constants'

export const enum UserLevel {
  NOT_SURE = 'not_sure',
  KNOW_FEW_THINGS = 'know_few_things',
  KNOW_EVERYTHING = 'know_everything',
}

export const USER_LEVELS_MAP = {
  [UserLevel.NOT_SURE]: {
    image: beginnerLevelImage,
    text: 'purchase7.userProfile.levelBeginner',
  },
  [UserLevel.KNOW_FEW_THINGS]: {
    image: intermediateLevelImage,
    text: 'purchase7.userProfile.levelIntermediate',
  },
  [UserLevel.KNOW_EVERYTHING]: {
    image: advancedLevelImage,
    text: 'purchase7.userProfile.levelAdvanced',
  },
}

export const USER_GOAL_TITLES_MAP: Record<UserGoal, string> = {
  [UserGoal.BOTH_OPTIONS]: 'purchase7.userProfile.subtitleBothOptions',
  [UserGoal.TIGHTEN_SKIN]: 'purchase7.userProfile.subtitleBothOptions',
  [UserGoal.REDUCE_FACE_FAT]: 'purchase7.userProfile.subtitleFaceFat',
  [UserGoal.WRINKLE_FREE_SKIN]: 'purchase7.userProfile.subtitleWrinkles',
}
