import React, { useCallback, useLayoutEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'

import { OPTION_VALUES as userAnswers } from '../AgingType/constants'
import { StyledAgingTypeFeedback as S } from './AgingTypeFeedback.styles'
import { FEEDBACK, QUESTION } from './constants'

export const AgingTypeFeedback: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  // eslint-disable-next-line no-undef
  const isPageAvailable = useFeatureIsOn('lv_313_aging_type_test')

  const userAnswersCollection = useSelector(selectAnswers)
  const userAgingType = useMemo(
    () => userAnswersCollection?.[PageId.AGING_TYPE],
    [userAnswersCollection],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useLayoutEffect(() => {
    if (!isPageAvailable) replaceHistory(nextPagePath)
  }, [isPageAvailable, nextPagePath])

  const handleNextClick = useCallback(() => {
    handleContinue('')
  }, [handleContinue])

  return (
    <S.Root>
      <S.ImageContainer>
        <S.Image
          src={FEEDBACK[userAgingType] || FEEDBACK[userAnswers.SINKER]}
          alt=""
        />
      </S.ImageContainer>
      <Container fields={16}>
        <S.Title>
          <Trans
            i18nKey="onboarding.agingTypeFeedback.question"
            components={[<strong />]}
            values={{ agingType: userAgingType || userAnswers.SINKER }}
          />
        </S.Title>
        <S.Description>
          {t(
            `onboarding.agingTypeFeedback.${
              userAgingType || userAnswers.SINKER
            }`,
          )}
        </S.Description>
      </Container>
      <S.StickyButtonContainer customBackground="transparent">
        <Button onClick={handleNextClick}>{t('actions.continue')}</Button>
      </S.StickyButtonContainer>
    </S.Root>
  )
}
