import styled from 'styled-components'

type TWrapperProps = {
  buttonHeight: number
  borderRadius: number
}

export const StyledStripeSeparatePaymentRequestButton = {
  Wrapper: styled.div<TWrapperProps>`
    height: ${({ buttonHeight }) => `${buttonHeight}px`};

    & * {
      border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    }
  `,
}
