import { sendAddToCartEvents } from 'helpers/sendAddToCartEvents'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'

import { eventLogger } from 'services/eventLogger.service'

import { ScreenName } from 'root-constants'

export const logStartedPayPalPayment = ({
  productId,
  price,
  paymentMethod,
  paymentSystem,
  currency,
  screenName,
  isTrialActive,
  stripeAccountId,
  stripeAccountName,
}: {
  productId: string
  price: number
  paymentMethod: PaymentMethod
  paymentSystem?: PaymentSystem
  currency: string
  screenName: ScreenName
  isTrialActive?: boolean
  stripeAccountId: string
  stripeAccountName: string
  uuid: string
}): void => {
  eventLogger.logPurchaseStarted({
    productId,
    priceDetails: {
      price,
      currency,
      trial: isTrialActive,
    },
    paymentMethod,
    screenName,
    stripeAccountId,
    stripeAccountName,
    paymentSystem,
  })

  sendAddToCartEvents()
}
