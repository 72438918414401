import styled, { css } from 'styled-components'

import closeSvg from 'assets/images/close-icon.svg'

import { Color } from 'root-constants'

type TContentContainerProps = {
  isVisible: boolean
}

type TPaymentMethodProps = {
  isSelected: boolean
}

type TPayPalContainerProps = {
  hasNegativeOffset: boolean
}

const hiddenStyles = css`
  position: absolute;
  z-index: -1;
  opacity: 0;
  transform: translateY(-100%);
  pointer-events: none;
`

export const StyledCheckoutSeparateMethods = {
  Root: styled.div``,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
  ContentContainer: styled.div<TContentContainerProps>`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    max-width: 360px;
    margin: 0 auto;
    padding-top: 40px;
  `,
  CloseButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 65px;
    right: 15px;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 24px 24px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeSvg});
  `,
  PaymentMethodsTitle: styled.h2`
    color: ${Color.DARK};
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    padding-bottom: 8px;
    text-align: center;
  `,
  PaymentMethodsWrapper: styled.div`
    display: flex;
    justify-content: center;
    margin: 16px 16px 0;
    padding-bottom: 32px;
    border-bottom: 1px solid #e9eaed;

    & > div:first-of-type {
      margin-right: 12px;
    }
  `,
  PaymentMethodsWrapperV2: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin: 16px 16px 0;
    padding-bottom: 32px;
    border-bottom: 1px solid #e9eaed;
  `,
  PaymentMethod: styled.div<TPaymentMethodProps>`
    width: 100%;
    max-width: 150px;
    height: 78px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid
      ${({ isSelected }) => (isSelected ? Color.PRIMARY : '#c4c4c4')};
  `,
  PaymentMethodText: styled.p`
    color: ${Color.DARK};
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 8px;
    text-align: center;
  `,
  PaymentMethodImage: styled.img<TPaymentMethodProps>`
    max-width: 94px;
    max-height: 16px;
    width: 100%;
    transition: 0.15s;

    ${({ isSelected }) => !isSelected && `filter: grayscale(1)`};
  `,
  AlternativePaymentMethodImage: styled.img<TPaymentMethodProps>`
    width: 100%;
    max-width: 72px;
    max-height: 46px;
    transition: 0.15s;

    ${({ isSelected }) => !isSelected && `filter: grayscale(1)`}
  `,
  PriceDescription: styled.div`
    margin: 12px 16px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9eaed;

    & > p:first-of-type {
      margin-bottom: 4px;
    }
  `,
  PriceInfo: styled.p`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  PriceBlockText: styled.span`
    color: ${Color.LIGHT};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  TotalDiscount: styled.span`
    color: ${Color.PRIMARY};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  DailyPayment: styled.div`
    margin: 14px 8px 0;
    border-radius: 8px;
    background-color: #f5faf7;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  DailyText: styled.span`
    color: ${Color.DARK};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  DailyAmount: styled.span`
    color: #7fb89d;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `,
  TotalPayment: styled.div`
    margin: 32px 16px;
  `,
  TotalDescription: styled.p`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${Color.DARK};
    font-size: 16px;
    line-height: 24px;
  `,
  TotalText: styled.span`
    font-weight: 600;
  `,
  TotalAmount: styled.span`
    font-weight: 400;

    & > strong {
      font-weight: 600;
    }
  `,
  SaveText: styled.p`
    color: ${Color.PRIMARY};
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  PaymentFormWrapper: styled.div`
    padding: 0 30px;
  `,

  StripePaymentWrapper: styled.div``,
  StripePaymentContainer: styled.div<TContentContainerProps>`
    ${({ isVisible }) => (isVisible ? 'position: static;' : hiddenStyles)}
  `,
  PaymentsSystemImage: styled.img`
    margin: 32px auto 16px;
    max-width: 256px;
  `,
  PaymentsSystemText: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: ${Color.LIGHT};
    text-align: center;
    padding-bottom: 32px;
  `,
  PayPalContainer: styled.div<TPayPalContainerProps>`
    ${({ hasNegativeOffset }) => hasNegativeOffset && 'margin-top: -32px;'}
  `,
}
