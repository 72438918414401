import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'

import { StyledWellnessBundleBenefits as S } from './WellnessBundleBenefits.styles'
import { WELLNESS_BUNDLE_BENEFITS } from './constants'

export const WellnessBundleBenefits: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <Container fields={16}>
        <S.Title>
          <Trans
            i18nKey="wellnessBundleUpsell.benefits.title"
            components={[<strong />]}
          />
        </S.Title>
        <S.List>
          {WELLNESS_BUNDLE_BENEFITS.map(({ benefit, icon, description }) => (
            <S.ListItem key={benefit}>
              <S.IconWrapper>
                <S.Icon src={icon} />
              </S.IconWrapper>
              <S.BenefitContent>
                <S.Benefit>{t(benefit)}</S.Benefit>
                <S.BenefitDescription>{t(description)}</S.BenefitDescription>
              </S.BenefitContent>
            </S.ListItem>
          ))}
        </S.List>
      </Container>
    </S.Wrapper>
  )
}
