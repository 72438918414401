import React, { useLayoutEffect } from 'react'

import { Spinner } from 'components/Spinner'

export const ProxyToStart = () => {
  useLayoutEffect(() => {
    window.location.href = window.location.hostname
  }, [])

  return <Spinner />
}
