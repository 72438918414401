import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  EventTypes,
  PaymentMethodType,
  PrimerHeadlessCheckout,
} from '@primer-io/checkout-web'

import { PRIMER_APPLE_PAY_ID, PaymentMethod } from 'modules/purchase/constants'

import { eventLogger } from 'services/eventLogger.service'

import { setPaymentMethodAction } from '../redux/actions/common'
import { usePrimerAnalytics } from './usePrimerAnalytics'

export const useInitPrimerApplePay = ({
  primerRef,
}: {
  primerRef: React.RefObject<PrimerHeadlessCheckout | null>
}) => {
  const { logPaymentStarted } = usePrimerAnalytics()
  const dispatch = useDispatch()

  const initApplePayButton = useCallback(
    async (callback?: () => void) => {
      const paymentMethodManager =
        await primerRef.current?.createPaymentMethodManager(
          PaymentMethodType.APPLE_PAY,
        )
      if (!paymentMethodManager) return

      const applePayButton = paymentMethodManager.createButton()

      await applePayButton.render(PRIMER_APPLE_PAY_ID, {
        style: {
          buttonType: 'plain',
          buttonColor: 'black',
        },
      })

      applePayButton.addEventListener(EventTypes.CLICK, () => {
        eventLogger.logPaymentMethodSelected(PaymentMethod.APPLE_PAY)
        dispatch(setPaymentMethodAction(PaymentMethod.APPLE_PAY))
        logPaymentStarted(PaymentMethod.APPLE_PAY)
        callback && callback()
      })
    },
    [primerRef, logPaymentStarted, dispatch],
  )

  return {
    initApplePayButton,
  }
}
