import styled from 'styled-components'

import giftBg from 'assets/images/gift-bg.png'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseCancelOffer = {
  Header: styled.div`
    position: relative;
    width: 100%;
    height: 50px;
    margin: 0 auto 15px;
    display: flex;
    justify-content: center;
    box-shadow: 0 5px 20px #dfe3f3;
    background-color: ${Color.WHITE};
    font-weight: 600;
    font-size: 24px;
    line-height: 50px;
    text-transform: uppercase;
  `,
  Wrapper: styled.div`
    padding-bottom: 40px;
  `,
  Container: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px;
  `,
  Title: styled.h1`
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  Subtitle: styled.h2`
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  ResultImageContainer: styled.div`
    max-width: 360px;
    padding: 0 15px;
    margin: 0 auto 24px;
  `,
  ResultImage: styled.img`
    max-width: 100%;
    image-rendering: -webkit-optimize-contrast;
  `,
  GiftContainer: styled.div`
    margin: 0 auto 24px;
    padding: 5px 112px 15px 15px;
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    background-image: url(${giftBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 360px;
    }
  `,
  PreviousDiscountText: styled.p`
    display: inline-block;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 31px;
    color: ${Color.RED};

    &:before {
      content: '';
      position: absolute;
      top: 16px;
      left: 0;
      width: calc(100% - 5px);
      height: 1px;
      background-color: ${Color.RED};
    }

    strong {
      padding: 1px 5px;
      border-radius: 16px;
      background-color: ${Color.WHITE};
    }
  `,
  CurrentDiscountText: styled.p`
    max-width: 228px;
    font-weight: 700;
    font-size: 18px;
    line-height: 31px;
    color: ${Color.RED};

    strong {
      padding: 2px 6px;
      border-radius: 16px;
      background-color: ${Color.WHITE};
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      font-size: 21px;
    }
  `,
  ButtonContainer: styled.div`
    position: sticky;
    position: -webkit-sticky;
    bottom: 25px;
    max-width: 360px;
    margin: 30px auto;
    padding: 0 20px;
  `,
}
