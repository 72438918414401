import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { CurrentEnvironment } from 'root-constants'

export const initSentry = (): void => {
  const isProduction = getCurrentEnv() === CurrentEnvironment.PROD
  const isStage = getCurrentEnv() === CurrentEnvironment.DEV

  if (isProduction || isStage) {
    Sentry.init({
      dsn: 'https://1760e002618440349ad796a1c9ade34c@o1132404.ingest.sentry.io/6272514',
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.0,
      ignoreErrors: [
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'conduitPage',
        'Script error.',
        '_avast_submit',
        'withrealtime/messaging',
        /gtag/,
        /illegal/,
      ],
    })
  }
}
