import styled from 'styled-components'

export const StyledLikeChatAge = {
  AnswersWrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    & > label {
      width: calc(50% - 6px);
    }
  `,
}
