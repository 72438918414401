import React from 'react'
import { useSelector } from 'react-redux'

import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js'

import {
  selectIsPaymentStatusShown,
  selectPayPalClientId,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useLockScroll } from 'hooks/useLockScroll'

import { PaymentStatusModal } from 'modules/purchase/components/PaymentStatusModal'
import { usePayPalButton } from 'modules/purchase/hooks'
import { selectPayPalPlanId } from 'modules/purchase/redux/selects/common'

import { SNAPCHAT_QUERY_PARAM } from 'root-constants'

import { StyledPayPalButton as S } from './PayPalButton.styles'

type TProps = {
  buttonHeight?: number
  buttonBorderRadius?: number
}

export const PayPalButton: React.FC<TProps> = ({
  buttonHeight = 55,
  buttonBorderRadius = 12,
}) => {
  const uuid = useSelector(selectUUID)
  const paypalPlanId = useSelector(selectPayPalPlanId)
  const paypalClientId = useSelector(selectPayPalClientId)
  const isPaymentStatusShown = useSelector(selectIsPaymentStatusShown)

  const {
    isButtonTouched,
    handlePaymentApprove,
    handlePaymentError,
    handlePaymentCancel,
    handleButtonClick,
  } = usePayPalButton()

  useLockScroll(isPaymentStatusShown)

  const FakePaypalButton = () => {
    const [{ isPending: isPaypalButtonLoading }] = usePayPalScriptReducer()
    return (
      <div>
        {!isPaypalButtonLoading && (
          <S.FakePaypalButton
            type="button"
            isTouched={isButtonTouched}
            buttonHeight={buttonHeight}
            buttonBorderRadius={buttonBorderRadius}
          />
        )}
      </div>
    )
  }

  return (
    <S.Root>
      <S.ButtonsContainer
        buttonHeight={buttonHeight}
        buttonBorderRadius={buttonBorderRadius}
      >
        {paypalPlanId && paypalClientId && (
          <PayPalScriptProvider
            options={{
              'client-id': paypalClientId,
              vault: true,
              'disable-funding': 'credit',
            }}
          >
            <PayPalButtons
              style={{
                label: 'paypal',
                tagline: false,
                layout: 'horizontal',
                height: buttonHeight,
              }}
              forceReRender={[paypalPlanId]}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: paypalPlanId, // Creates the subscription
                  custom_id: uuid,
                  application_context: {
                    return_url: `${window.location.origin}/paypalProxy${window.location.search}&${SNAPCHAT_QUERY_PARAM}`,
                  },
                })
              }}
              onApprove={handlePaymentApprove}
              onError={handlePaymentError}
              onCancel={handlePaymentCancel}
              onClick={handleButtonClick}
            />
            <FakePaypalButton />
          </PayPalScriptProvider>
        )}
      </S.ButtonsContainer>
      <PaymentStatusModal />
    </S.Root>
  )
}
