import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledPurchaseUpgradeShortDisclaimer = {
  DisclaimerText: styled.p`
    margin-top: 24px;
    color: ${Color.LIGHT};
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    text-align: center;

    a {
      font-weight: 400;
      text-decoration: underline;
      color: ${Color.LIGHT};
    }
  `,
}
