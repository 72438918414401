import styled from 'styled-components'

import closedEyeIcon from 'assets/images/close-eye.svg'
import openedEyeIcon from 'assets/images/open-eye.svg'

import { Color } from 'root-constants'

export const StyledInput = {
  Wrapper: styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 22px;

    &::after {
      display: block;
      position: absolute;
      left: 16px;
      color: ${Color.ERROR};
      font-size: 13px;
      line-height: 20px;
    }

    &[data-valid='false']::after {
      content: attr(data-validation-text);
    }
  `,
  Input: styled.input`
    display: block;
    width: 100%;
    height: 60px;
    padding: 32px 52px 8px 16px;
    color: ${Color.DARK};
    font-size: 13px;
    line-height: 1;
    border-radius: 10px;
    box-shadow: 0 8px 20px 0 #dfe3f3;
    border: 2px solid transparent;
    background-color: ${Color.WHITE};
    outline: none;
    transition: border 0.2s ease-out;
    &:valid,
    &:focus {
      & + span {
        top: 10px;
        color: ${Color.DARK};
        font-size: 13px;
        line-height: 1;
      }
    }
  `,
  Label: styled.span`
    position: absolute;
    top: 20px;
    left: 16px;
    color: ${Color.LIGHT};
    font-size: 16px;
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
  VisibilityButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 52px;
    height: 60px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 20px 20px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;

    &[data-visible='true'] {
      background-image: url(${openedEyeIcon});
    }

    &[data-visible='false'] {
      background-image: url(${closedEyeIcon});
    }
  `,
}
