import React, { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SwiperSlide } from 'swiper/react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { selectFaceScanResults } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { ML_DEFAULT_PROBLEM_AREAS } from 'root-constants'

import { DIAGNOSIS_MAP, PERCENTAGE } from '../FaceScanResults/constants'
import { StyledRecommendations as S } from './Recommendations.styles'
import { DIAGNOSIS_RECOMMENDATIONS_MAP, QUESTION } from './constants'

export const Recommendations: FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const results = useSelector(selectFaceScanResults)

  const problemAreas = useMemo(
    () => (!results.length ? ML_DEFAULT_PROBLEM_AREAS : results),
    [results],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <>
      <S.Wrapper>
        <Container>
          <S.Title>
            <Trans
              i18nKey="skinDiagnosis.recommendations.title"
              values={{
                percentage: PERCENTAGE[results.length] || PERCENTAGE['1'],
              }}
              components={[<strong />]}
            />
          </S.Title>
          {!!results.length && (
            <S.ResultsContainer>
              <S.HighlightedText>
                {t('skinDiagnosis.results.followingIssues')}
              </S.HighlightedText>
              <S.IssuesContainer>
                {results.map((issue) => (
                  <S.Issue key={issue}>{t(DIAGNOSIS_MAP[issue])}</S.Issue>
                ))}
              </S.IssuesContainer>
            </S.ResultsContainer>
          )}
          <S.Divider />
          <S.Title>
            <Trans
              i18nKey="skinDiagnosis.recommendations.subtitle"
              components={[<strong />]}
            />
          </S.Title>
        </Container>
        <S.CarouselContainer>
          <S.Carousel
            spaceBetween={8}
            slidesPerView={1.2}
            pagination
            centeredSlides
          >
            {problemAreas.map((value) => {
              const { title, tips } = DIAGNOSIS_RECOMMENDATIONS_MAP[value]
              return (
                <SwiperSlide key={value}>
                  <S.Card>
                    <S.Subtitle>
                      <Trans i18nKey={title} components={[<strong />]} />
                    </S.Subtitle>
                    {tips.map(({ text, icon, id }) => (
                      <S.Tip key={id}>
                        <S.Icon src={icon} height={40} />
                        <p>{t(text)}</p>
                      </S.Tip>
                    ))}
                  </S.Card>
                </SwiperSlide>
              )
            })}
          </S.Carousel>
        </S.CarouselContainer>
      </S.Wrapper>
      <S.ButtonContainer>
        <Button onClick={handleNextClick}>{t('actions.continue')}</Button>
      </S.ButtonContainer>
    </>
  )
}
