export const QUESTION = 'Choose your focus'

export const TAGS = [
  {
    value: 'face_sculpting',
    label: 'onboarding.obTags.faceSculpting',
  },
  {
    value: 'fresh_complexion',
    label: 'onboarding.obTags.freshComplexion',
  },
  {
    value: 'healthy_habits',
    label: 'onboarding.obTags.healthyHabits',
  },
  {
    value: 'collagen_boost',
    label: 'onboarding.obTags.collagenBoost',
  },
  {
    value: 'consistent_routine',
    label: 'onboarding.obTags.consistentRoutine',
  },
  {
    value: 'even_skin_tone',
    label: 'onboarding.obTags.skinTone',
  },
  {
    value: 'blemish_reduction',
    label: 'onboarding.obTags.blemishReduction',
  },
  {
    value: 'mental_health',
    label: 'onboarding.obTags.mentalHealth',
  },
  {
    value: 'double_chin_reduction',
    label: 'onboarding.obTags.doubleChinReduction',
  },
  {
    value: 'stress_relief',
    label: 'onboarding.obTags.stressRelief',
  },
  {
    value: 'face_lifting',
    label: 'onboarding.obTags.faceLifting',
  },
  {
    value: 'detox',
    label: 'onboarding.obTags.detox',
  },
]
