import styled, { css } from 'styled-components'

import arrowRight from 'assets/images/arrow-forward-gray.png'
import productSelectedIcon from 'assets/images/primary-check.png'

import { Color } from 'root-constants'

type TProductOptionProps = {
  isSelected: boolean
  image: string
}

export const StyledUpsellGuashaSubscriptionBlock = {
  Root: styled.div`
    margin-bottom: 40px;
  `,
  OptionsContainer: styled.div`
    margin-bottom: 24px;
  `,
  ProductItem: styled.div<TProductOptionProps>`
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 18px;
    margin-bottom: 12px;
    gap: 12px;
    height: 80px;

    ${({ isSelected }) =>
      isSelected
        ? css`
            border: 1px solid ${Color.PRIMARY};
            box-shadow: 0px 8px 20px 0px rgba(204, 207, 222, 0.8);
          `
        : css`
            border: 1px solid ${Color.GRAY_LIGHT};
          `}

    &::before {
      content: '';
      display: block;
      width: 78px;
      height: 78px;
      background-size: 78px 78px;
      background-repeat: no-repeat;
      border-radius: 18px 0 0 18px;
      margin-right: 8px;
      background-image: url(${({ image }) => image});
    }

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      mix-blend-mode: normal;
      border: 1px solid #c4c4c4;
      border-radius: 50%;
      /* opacity: 0.5; */
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 0.5s ease-out;
      ${({ isSelected }) =>
        isSelected &&
        `
        background-image: url(${productSelectedIcon});
        background-size: 20px 20px;
        opacity: 1;
        border: none;
      `}
    }
  `,
  ProductItemContent: styled.div``,
  Bestseller: styled.div`
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 16px;
    height: 28px;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    text-transform: uppercase;
    border-radius: 6px;
    background-color: ${Color.BLUE_DARK};
    color: ${Color.WHITE};
    border: 3px solid #fdfdff;
  `,
  ProductName: styled.p`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.DARK};
    max-width: 188px;
  `,
  ProductPrices: styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
  `,
  ProductOldPrice: styled.p`
    color: ${Color.LIGHT};
    position: relative;
    text-decoration: line-through;
    display: flex;
    align-items: center;

    &:after {
      content: '';
      display: block;
      background-image: url(${arrowRight});
      width: 12px;
      height: 12px;
      background-size: 12px 12px;
      margin-left: 2px;
    }
  `,
  ProductCurrentPrice: styled.p`
    color: ${Color.RED};
    margin-left: 2px;
  `,
  Discount: styled.p`
    display: flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid ${Color.RED};
    margin-left: 8px;
    color: ${Color.RED};
  `,
}
