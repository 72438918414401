import invertedSmileEmoji from 'assets/images/inverted-smile-emoji.png'
import loveEmoji from 'assets/images/love-emoji.png'
import surprisedEmoji from 'assets/images/surprised-emoji.png'

export const QUESTION = 'Have you tried Face Yoga before?'

export const OPTION_VALUES = {
  PRACTICE_YOGA: 'practice_yoga',
  TRIED_IT: 'tried_it',
  NEVER_TRIED: 'never_tried',
}

export const PRACTICE_ANSWERS_MAP = {
  [OPTION_VALUES.PRACTICE_YOGA]: {
    text: 'onboarding.likeChatFaceYoga.practiceFaceYoga',
    icon: loveEmoji,
  },
  [OPTION_VALUES.TRIED_IT]: {
    text: 'onboarding.likeChatFaceYoga.triedFaceYoga',
    icon: invertedSmileEmoji,
  },
  [OPTION_VALUES.NEVER_TRIED]: {
    text: 'onboarding.likeChatFaceYoga.neverTriedFaceYoga',
    icon: surprisedEmoji,
  },
}
