import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledHolidaysCancelOfferTestimonials = {
  Root: styled.div`
    background-color: #fef8f4;
    padding: 32px 0;

    & .swiper {
      padding-bottom: 36px;
    }

    & .swiper-pagination-bullets {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: ${Color.CREAMY};
    }

    & .swiper-pagination-bullet {
      background: #ff8276;
    }
  `,
  Title: styled.div`
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    margin-bottom: 24px;
  `,
  Stores: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 4px;
    margin-bottom: 24px;
  `,
  Store: styled.span`
    display: inline-flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${Color.GRAY};

    & svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  `,
  Card: styled.div`
    background: #ffffff;
    box-shadow: 0 8px 20px #f2e6e1;
    border-radius: 16px;
    padding: 16px;
    max-width: 320px;
    margin: 0 auto;
    min-height: 152px;
  `,
  CardCaption: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    & svg {
      width: 20px;
      height: 20px;
    }
  `,
  Name: styled.h4`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
  `,
  Testimonial: styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.GRAY};
  `,
}
