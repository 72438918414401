import styled from 'styled-components'

export const StyledDiet = {
  TitleContainer: styled.header`
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
  `,
  DescriptionContainer: styled.div`
    margin-bottom: 16px;
  `,
  OptionsContainer: styled.div`
    margin-bottom: 40px;
  `,
}
