import React from 'react'

import { StyledTyping as S } from './Typing.styles'

type TProps = {
  children?: React.ReactNode
}

export const Typing: React.FC<TProps> = ({ children }) => (
  <S.Text>
    {children}&nbsp;<S.Dot>.</S.Dot>
    <S.Dot>.</S.Dot>
    <S.Dot>.</S.Dot>
  </S.Text>
)
