import React, { FC, InputHTMLAttributes, forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useSelector } from 'react-redux'

import { selectUserCountryCode } from 'root-redux/selects/user'

import CalendarIcon from 'assets/images/calendar-icon.svg'

import { Country } from 'root-constants'

import { StyledCustomDatePicker as S } from './CustomDatePicker.styles'
import {
  DEFAULT_CALENDAR_WEEK_START_DATE,
  DEFAULT_DATE_FORMAT,
  US_CALENDAR_WEEK_START_DATE,
  US_DATE_FORMAT,
} from './constants'

type TProps = {
  onChange: (date: Date) => void
  selectedDate: Date | null
  minDate?: Date
}

type TInputProps = InputHTMLAttributes<HTMLInputElement>

export const CustomDatePicker = ({
  onChange,
  selectedDate,
  minDate = new Date(),
}: TProps) => {
  const userCountryCode = useSelector(selectUserCountryCode)

  const isWeekStartsOnSunday =
    userCountryCode === Country.USA || userCountryCode === Country.CANADA

  const CustomInput: FC = forwardRef<HTMLInputElement, TInputProps>(
    (inputProps, ref) => (
      <S.WrapperDataField onClick={inputProps.onClick}>
        <S.DateField ref={ref} {...inputProps}>
          {inputProps.value}
        </S.DateField>
        <S.CalendarIcon />
      </S.WrapperDataField>
    ),
  )

  return (
    <S.Wrapper>
      <DatePicker
        customInput={<CustomInput />}
        icon={<CalendarIcon />}
        selected={selectedDate}
        onChange={onChange}
        minDate={minDate}
        dateFormat={
          userCountryCode === Country.USA ? US_DATE_FORMAT : DEFAULT_DATE_FORMAT
        }
        useWeekdaysShort
        showPopperArrow={false}
        calendarStartDay={
          isWeekStartsOnSunday
            ? US_CALENDAR_WEEK_START_DATE
            : DEFAULT_CALENDAR_WEEK_START_DATE
        }
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, -62],
            },
          },
        ]}
      />
    </S.Wrapper>
  )
}
