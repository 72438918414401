import React, { HTMLAttributes } from 'react'

import { StyledChatAnswerButton as S } from './ChatAnswerButton.styles'

interface IChatAnswerButtonProps extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  iconSrc?: string
  iconWidth?: number
  iconHeight?: number
  withCheckbox?: boolean
  className?: string
}

export const ChatAnswerButton: React.FC<IChatAnswerButtonProps> = ({
  children,
  iconSrc,
  withCheckbox,
  iconWidth,
  iconHeight,
  className,
  ...props
}: IChatAnswerButtonProps) => {
  return (
    <S.Wrapper withCheckbox={withCheckbox} className={className} {...props}>
      <S.Content>
        {iconSrc && (
          <S.Icon
            src={iconSrc}
            iconWidth={iconWidth}
            iconHeight={iconHeight}
            alt="icon"
          />
        )}
        <S.Text>{children}</S.Text>
      </S.Content>
    </S.Wrapper>
  )
}
