import React from 'react'

import { TComponentProps } from 'models/common.model'

import { StyledPageTitleDescription as S } from './PageTitleDescription.styles'

export const PageTitleDescription: React.FC<TComponentProps> = ({
  children,
}) => {
  return <S.PageTitleDescription>{children}</S.PageTitleDescription>
}
