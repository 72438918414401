import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import dayjs from 'dayjs'

import { selectUserSubscriptionInfo } from 'root-redux/selects/user'

import { StyledPage } from './styles'

export const CanceledSubscription: React.FC = () => {
  const { t } = useTranslation()
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)

  const isOnTrial = useMemo(
    () => dayjs().isBefore(userSubscriptionInfo?.trialEndDate, 'day'),
    [userSubscriptionInfo],
  )

  return (
    <>
      <StyledPage.Title>{t`unsubscribe.canceledSubscription.title`}</StyledPage.Title>
      <StyledPage.Subtitle>
        <Trans
          i18nKey="unsubscribe.canceledSubscription.subtitle"
          components={[<br />]}
        />
      </StyledPage.Subtitle>
      <StyledPage.Text>
        <Trans
          i18nKey={
            isOnTrial
              ? 'unsubscribe.canceledSubscription.trialText'
              : 'unsubscribe.canceledSubscription.subscriptionText'
          }
          values={{
            date: isOnTrial
              ? userSubscriptionInfo?.trialEndDate
              : userSubscriptionInfo?.endDate,
          }}
          components={[<br />]}
        />
      </StyledPage.Text>
    </>
  )
}
