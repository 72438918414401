import { userApi } from 'api'
import firebase from 'firebase/app'
import i18n from 'i18next'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { setSecretAction, setUUIDAction } from 'root-redux/actions/user'
import { selectTestEnvironmentQueryParam } from 'root-redux/selects/user'

import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'

import { goTo } from 'browser-history'
import { FirebaseSignInErrorCode } from 'root-constants'

const MODULE_NAME = 'UNSUBSCRIBE_LOGIN'
const LOGIN_WITH_EMAIL_FIREBASE = `${MODULE_NAME}/LOGIN_WITH_EMAIL_FIREBASE`
const GET_UUID_AND_SECRET = `${MODULE_NAME}/GET_UUID_AND_SECRET`

export const SET_EMAIL_ERROR_MESSAGE = `${MODULE_NAME}/SET_EMAIL_ERROR_MESSAGE`
export const RESET_EMAIL_ERROR_MESSAGE = `${MODULE_NAME}/RESET_EMAIL_ERROR_MESSAGE`

export const SET_PASSWORD_ERROR_MESSAGE = `${MODULE_NAME}/SET_PASSWORD_ERROR_MESSAGE`
export const RESET_PASSWORD_ERROR_MESSAGE = `${MODULE_NAME}/RESET_PASSWORD_ERROR_MESSAGE`

export const resetEmailErrorMessageAction = (): IAction<never> => ({
  type: RESET_EMAIL_ERROR_MESSAGE,
})

export const setEmailErrorMessageAction = (
  message: string,
): IAction<string> => ({
  type: SET_EMAIL_ERROR_MESSAGE,
  payload: message,
})

export const resetPasswordErrorMessageAction = (): IAction<never> => ({
  type: RESET_PASSWORD_ERROR_MESSAGE,
})

export const setPasswordErrorMessageAction = (
  message: string,
): IAction<string> => ({
  type: SET_PASSWORD_ERROR_MESSAGE,
  payload: message,
})

export const loginWithEmailFirebaseAction =
  (email: string, password: string): TAppDispatchThunk<any> =>
  async (dispatch) => {
    dispatch(startFetching(LOGIN_WITH_EMAIL_FIREBASE))

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
    } catch (error: any) {
      if (error.code === FirebaseSignInErrorCode.WRONG_PASSWORD) {
        dispatch(setPasswordErrorMessageAction(i18n.t('login.invalidFields')))
      }

      if (
        error.code === FirebaseSignInErrorCode.INVALID_EMAIL ||
        error.code === FirebaseSignInErrorCode.USER_DISABLED ||
        error.code === FirebaseSignInErrorCode.USER_NOT_FOUND
      ) {
        dispatch(setEmailErrorMessageAction(i18n.t('login.invalidFields')))
      }
    }

    dispatch(stopFetching(LOGIN_WITH_EMAIL_FIREBASE))
  }

export const getSecretAndUUIDAction =
  (token: string, errorCallback: () => void): TAppActionThunk<any> =>
  async (dispatch, getState: () => IAppState) => {
    const state = getState()
    const testEnvironmentQueryParam = selectTestEnvironmentQueryParam(state)

    dispatch(startFetching(GET_UUID_AND_SECRET))

    const response = await userApi.getUserUUIDAndSecret(token)

    if (response.success && response.data) {
      const { uuid, secret } = response.data

      dispatch(setUUIDAction(uuid))
      dispatch(setSecretAction(secret))

      goTo(`/unsubscribe/details${testEnvironmentQueryParam}`)
    } else {
      const errorMessage =
        response.data?.error || i18n.t('login.somethingWentWrong')
      dispatch(setErrorAction(errorMessage))
      errorCallback()
    }

    dispatch(stopFetching(GET_UUID_AND_SECRET))
  }
