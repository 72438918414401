import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Chat, DelayedBottomContainer, DelayedContainer } from 'common-styles'
import {
  CHAT_PAGE_TRANSITION_DELAY,
  Cohort,
  I18N_CONTEXT_COHORT_MAP,
  OptionType,
} from 'root-constants'

import { StyledLikeChatSkinConcerns as S } from './LikeChatSkinConcerns.styles'
import {
  LIKE_CHAT_SKIN_CONCERNS_OPTIONS,
  OPTION_VALUES,
  QUESTION,
  SKIN_CONCERNS_ANSWERS_MAP,
} from './constants'

export const LikeChatSkinConcerns: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)
  const { handleChange } = useDelayedContinue(CHAT_PAGE_TRANSITION_DELAY)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  useEffect(() => {
    if (chatStep === ChatStep.FINISH) {
      const uniqueAnswers = [...new Set(answers)]
      handleChange(uniqueAnswers, handleContinue)
    }
  }, [answers, chatStep, handleChange, handleContinue])

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        const isNothingFromAboveOptionTouched =
          value === OPTION_VALUES.NOTHING_FROM_THE_ABOVE

        if (isChecked && isNothingFromAboveOptionTouched) {
          setAnswers([value])

          return
        }

        if (isChecked) {
          setAnswers(
            [...answers, value].filter(
              (item) => item !== OPTION_VALUES.NOTHING_FROM_THE_ABOVE,
            ),
          )

          return
        }

        setAnswers(answers.filter((item) => item !== value))
      },
    }),
    [answers, pageId],
  )

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.SHOW_OPTIONS)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_QUESTION ||
            chatStep === ChatStep.SHOW_OPTIONS
          }
        >
          {t('onboarding.likeChatSkinConcerns.title')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.USER}
          {...(chatStep === ChatStep.USER_ANSWER && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
        >
          {answers.length &&
            answers.map((item, i) => (
              <span key={item}>
                {i ? ', ' : ''}{' '}
                <Trans
                  i18nKey={SKIN_CONCERNS_ANSWERS_MAP[item]}
                  context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
                />
              </span>
            ))}
        </ChatMessage>
      </Chat>

      <DelayedContainer isShown={chatStep === ChatStep.SHOW_OPTIONS}>
        <S.OptionsWrapper>
          {LIKE_CHAT_SKIN_CONCERNS_OPTIONS.map(({ value, label }) => {
            return (
              <Option
                {...optionProps}
                value={value}
                key={value}
                withoutMargin
                checked={answers.includes(value)}
              >
                <S.ShortenedChatAnswerButton withCheckbox>
                  <S.OptionContentWrapper>
                    <Trans
                      i18nKey={label}
                      context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
                    />
                  </S.OptionContentWrapper>
                </S.ShortenedChatAnswerButton>
              </Option>
            )
          })}
        </S.OptionsWrapper>
      </DelayedContainer>
      <DelayedBottomContainer isShown={chatStep === ChatStep.SHOW_OPTIONS}>
        <Button
          onClick={() => setChatStep(ChatStep.USER_ANSWER)}
          disabled={!answers.length}
        >
          {t('actions.send')}
        </Button>
      </DelayedBottomContainer>
    </Container>
  )
}
