import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'

import { selectAnswers } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack, replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import { Gender, OptionType } from 'root-constants'

import { StyledMenstrualCycle as S } from './MenstrualCycle.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const MenstrualCycle: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(answer),
    [answer, handleContinue],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setAnswer(value)
      },
    }),
    [pageId],
  )

  useLayoutEffect(() => {
    const userGender = userAnswers?.[PageId.GENDER]
    if (!userGender || userGender !== Gender.FEMALE) {
      replaceHistory(nextPagePath)
    }
  }, [userAnswers, nextPagePath])

  return (
    <div>
      <Container>
        <S.TitleContainer>
          {t`onboarding.menstrualCycle.question`}
        </S.TitleContainer>
        <S.OptionsContainer>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.REGULAR}
          >
            <AnswerButton>{t`onboarding.menstrualCycle.regular`}</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.IRREGULAR}
          >
            <AnswerButton>{t`onboarding.menstrualCycle.irregular`}</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.DONT_HAVE_DUE_TO_AGE}
          >
            <AnswerButton>
              <Trans
                i18nKey="onboarding.menstrualCycle.dontHaveDueToAge"
                components={[<br />]}
              />
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.DONT_KNOW}
          >
            <AnswerButton>
              <Trans i18nKey="onboarding.menstrualCycle.dontKnow" />
            </AnswerButton>
          </Option>
        </S.OptionsContainer>
      </Container>
      <NavigationButtons
        onBackClick={goBack}
        onNextClick={handleNextClick}
        disabled={!answer}
      />
    </div>
  )
}
