import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { EMAIL_FROM_URL_REGEXP, EMAIL_QUERY_PARAM } from 'root-constants'

export const useChatBotEmail = (): void => {
  const { search } = useLocation()

  useEffect(() => {
    const [, email] = EMAIL_FROM_URL_REGEXP.exec(search) || []

    if (email) {
      const decodedEmail = decodeURIComponent(email)
      sessionStorage.setItem(EMAIL_QUERY_PARAM, decodedEmail)
    }
  }, [search])
}
