import React from 'react'

import { StyledIntroOptionWithImage as S } from './IntroOptionWithImage.styles'

type TIntroImageProps = {
  text: string
  image: string
}

export const IntroOptionWithImage: React.FC<TIntroImageProps> = ({
  text,
  image,
}: TIntroImageProps) => {
  return (
    <S.Action>
      <S.Image src={image} alt="" />
      <S.Answer />
      <S.AnswerWrapper>
        <S.AnswerValue>{text}</S.AnswerValue>
        <S.ChevronRight />
      </S.AnswerWrapper>
    </S.Action>
  )
}
