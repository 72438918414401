import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Button } from 'components/Button'
import { DomainsContainer } from 'components/DomainsContainer'
import { ErrorNotification } from 'components/ErrorNotification'
import { InputWithFloatPlaceholder } from 'components/InputWithFloatPlaceholder'
import { Modal } from 'components/Modal'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'
import { Spinner } from 'components/Spinner'

import { resetErrorAction } from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { I18N_CONTEXT_COHORT_MAP } from 'root-constants'

import { StyledEmail as S } from './Email.styles'
import { useEmail } from './hooks/useEmail'

export const Email: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const {
    handlePrefilledEmail,
    handleBlur,
    handleFocus,
    handleChange,
    handleSubmit,
    errorText,
    isStatusFetching,
    isEmailValid,
    error,
    isErrorModalShown,
    isAutoCompleteVisible,
    email,
    domainsList,
    cohortToUse,
    setIsErrorModalShown,
  } = useEmail()

  return isStatusFetching ? (
    <Spinner />
  ) : (
    <div>
      <S.Container>
        <S.TitleContainer>
          <PageTitle>
            <Trans
              i18nKey="onboarding.email.title"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </PageTitle>
        </S.TitleContainer>
        <S.DescriptionContainer>
          <PageTitleDescription>
            {t('onboarding.email.subtitle')}
          </PageTitleDescription>
        </S.DescriptionContainer>
        <form onSubmit={handleSubmit}>
          <S.InputContainer>
            <InputWithFloatPlaceholder
              value={email.value}
              data-testid="email-input"
              isValid={isEmailValid}
              labelName={t`onboarding.email.inputPlaceholder`}
              hasValidationIcon
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {isAutoCompleteVisible && (
              <DomainsContainer>
                {domainsList.map((item) => (
                  <button
                    type="button"
                    key={item}
                    value={item}
                    onClick={handlePrefilledEmail}
                  >
                    {item}
                  </button>
                ))}
              </DomainsContainer>
            )}
          </S.InputContainer>
          <S.Disclaimer>
            <Trans
              i18nKey="onboarding.email.disclaimer"
              components={[<br />]}
            />
          </S.Disclaimer>
          <S.ButtonContainer>
            <ErrorNotification errorText={errorText} />
            <Button
              type="submit"
              disabled={!email.value || !isEmailValid}
              data-testid="confirm-email-btn"
            >
              {t`actions.getMyPlan`}
            </Button>
          </S.ButtonContainer>
        </form>
      </S.Container>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
        error={error}
      />
    </div>
  )
}
