import amplitude from 'amplitude-js'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { getOperatingSystemVersion } from 'helpers/getOperatingSystemVersion'

import { IEventLogger, TCommonEventProperties } from 'models/events.model'

import { EventLoggerInstanceName } from 'root-constants'

interface IAmplitude {
  getInstance: (amplitudeInstanceName?: string) => typeof amplitude
  init: (
    apiKey: string,
    userId: string | null,
    options?: Record<string, unknown>,
  ) => void
  setUserId: (userId: string) => void
  setUserProperties: (properties: Record<string, string>) => void
  logEvent: (
    event: string,
    eventProperty?: Record<string, unknown>,
    cb?: () => void,
  ) => void
}

export class Amplitude implements IEventLogger {
  public readonly name: EventLoggerInstanceName
  private readonly amplitude: IAmplitude
  private utmTags: Record<string, string>
  private cohort = ''
  private userProperties: Record<string, string> = {}
  private readonly isTestEnvironment: boolean
  private readonly isDevEnvironment: boolean

  constructor({
    instanceName,
    apiKey,
    userId,
    utmTags,
    isTestEnvironment,
    isDevEnvironment,
    options,
  }: {
    instanceName: EventLoggerInstanceName
    apiKey: string
    userId: string
    utmTags: Record<string, string>
    isTestEnvironment: boolean
    isDevEnvironment: boolean
    options?: Record<string, unknown>
  }) {
    this.utmTags = utmTags
    this.name = instanceName
    this.amplitude = amplitude.getInstance(instanceName)
    this.amplitude.init(apiKey, userId || null, options)
    this.isTestEnvironment = isTestEnvironment
    this.isDevEnvironment = isDevEnvironment
  }

  log(
    event: string,
    eventProperty?: Record<string, unknown>,
    cb?: () => void,
  ): void {
    const mergedEventProperty: TCommonEventProperties = {
      cohort: this.cohort,
      attribution: this.utmTags,
      is_test_environment: this.isTestEnvironment,
      is_dev_environment: this.isDevEnvironment,
      operating_system_version: getOperatingSystemVersion(),
      ...eventProperty,
    }

    this.amplitude.logEvent(event, mergedEventProperty, cb)
  }

  configure({ cohort }: { cohort: string }): void {
    this.cohort = cohort
    this.setUserProperties(cohort)
  }

  updateUserProperties(
    stripeAccountId: string,
    stripeAccountName: string,
  ): void {
    const updatedUserProperties = {
      ...this.userProperties,
      stripe_account_id: stripeAccountId,
      stripe_account_name: stripeAccountName,
    }
    this.userProperties = updatedUserProperties
    this.amplitude.setUserProperties(updatedUserProperties)
  }

  private setUserProperties(cohort: string): void {
    const userProperties = {
      'Device Type': getMobileOperatingSystem(),
      'Operating System Version': getOperatingSystemVersion(),
      cohort_name: cohort,
    }
    this.userProperties = userProperties
    this.amplitude.setUserProperties(userProperties)
  }
}
