import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Option } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { SelectSubscriptionItem } from 'modules/purchase/components/SelectSubscriptionItem'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { OptionType, SubscriptionProduct } from 'root-constants'

import { StyledSubscriptionsBlock as S } from './SubscriptionsBlock.styles'

type TSubscriptionsBlockProps = {
  isCancelOffer?: boolean
  className?: string
}

export const SubscriptionsBlock: React.FC<TSubscriptionsBlockProps> = ({
  isCancelOffer = false,
  className = '',
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  const handleChange = useCallback(
    (value: string) => {
      const checkedSubscription = subscriptions.find(
        (subscription) => subscription.id === value,
      )
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.Wrapper className={className}>
      <S.Title>
        {isCancelOffer
          ? t`purchase1.subscription.title`
          : t`purchase1.subscription.unleashBeauty`}
      </S.Title>
      {subscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.id}
          onChange={handleChange}
          disabled={
            isCancelOffer &&
            subscription.trialPrices.durationDays ===
              SubscriptionProduct.SIX_MONTHS
          }
          withoutMargin
        >
          <SelectSubscriptionItem
            subscription={subscription}
            isSelected={subscription.id === selectedSubscriptionId}
            isOutOfStock={
              isCancelOffer &&
              subscription.trialPrices.durationDays ===
                SubscriptionProduct.SIX_MONTHS
            }
            isCancelOffer={isCancelOffer}
          />
        </Option>
      ))}
    </S.Wrapper>
  )
}
