import { useCallback, useState } from 'react'

import { PAGE_TRANSITION_DELAY } from 'root-constants'

export const useDelayedContinue = (
  delay?: number,
): {
  isAnswersDisabled: boolean
  handleChange: (
    value: string | string[] | null,
    handleContinue: (value: string | string[]) => void,
  ) => void
  setIsAnswersDisabled: (value: boolean) => void
} => {
  const [isAnswersDisabled, setIsAnswersDisabled] = useState<boolean>(false)

  const handleChange = useCallback(
    (value, handleContinue) => {
      setTimeout(() => {
        handleContinue(value)
      }, delay || PAGE_TRANSITION_DELAY)
    },
    [delay],
  )

  return { isAnswersDisabled, handleChange, setIsAnswersDisabled }
}
